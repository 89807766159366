import React, { useState } from 'react';
// import './order-confirmed.scss'
import { v360organizationDataTypeProps } from 'custom-type';


// const V360EntityRegistrationStatus: React.FC<v360organizationDataTypeProps> = (props) => {
export const V360EntityRegistrationStatus: React.FC<v360organizationDataTypeProps> = (props) => {
  return (
    <div className="main-content-sec padd-content-top full-width">
      <div className="confirmed-info-container">
        <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
          <img
            alt="Logo"
            height="93"
            src={props.organization_logo}
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
            width="221"
          />
        </div>
        <strong className="d-block pb-1 text-center" style={{ fontSize: '22px', color: `${props.registrationStatus ? 'green' : 'red'}` }}>
          <span className={props.registrationStatus ? 'bi bi-check-lg fs-20' : 'bi bi-x-lg fs-20'}></span>
          <span>{props.message}</span>
        </strong>
        <br />
        <p style={{ color: '#0097d0', fontSize: '25px', marginBottom: '10px' }}>Thank You</p>
        <p style={{ fontSize: '18px', margin: '0px', display: 'inline-block' }}>Team {props.organization_name}</p>
      </div>
    </div>
  );
};

export default V360EntityRegistrationStatus;