import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import MaterialSymbol from "../icons/material-symbol";
import InstagramIcon1 from "../icons/instagram-icon1";
import IconYouTube from "../icons/icon-youtube";
import { Link } from "react-router-dom";

interface Props {
  proposalId: number;
  campaignImage: string;
  campaignTitle: string;
  campaignType: string;
  campaignStatus: string;
  campaignId: number;
  completed: string;
  completedAt: Date;
  campaignPlatform: string;
  proposalStatus: string;
  creativeApproveCount: number;
  creativePendingCount: number;
  creativeRejectedCount: number;
  AppliedDate: string;
  invoiceCount: number | string;
  handleCampaign: (
    url: string,
    campaignId: number,
    campaignPlatform: string
  ) => void;
  markProposalComplete: (proposal_id: number, campaign_title: string) => any;
}

const MyCampaignCard: React.FC<Props> = ({
  proposalId,
  invoiceCount,
  campaignImage,
  campaignTitle,
  campaignType,
  campaignStatus,
  campaignPlatform,
  campaignId,
  completed,
  completedAt,
  proposalStatus,
  creativeApproveCount,
  creativePendingCount,
  creativeRejectedCount,
  AppliedDate,
  handleCampaign,
  markProposalComplete,
}) => {
  const campingStatus = [
    {
      name: "Applied",
      url: `/influencer/campaign/proposal`,
      applied_date: AppliedDate,
    },
    { name: "Proposal", url: `/influencer/campaign/proposal` },
    {
      name: "Creatives",
      url: `/influencer/campaign/creative`,
      approve: creativeApproveCount,
      pending: creativePendingCount,
      reject: creativeRejectedCount,
    },
    { name: "Analytics", url: `/influencer/campaign/analytics` },
    {
      name: completed === "true" ? "Marked Complete" : "Mark Complete",
      url: ``,
    },
  ];
  const [currentStepIndex, setCurrentStepIndex] = useState<number | null>(null);
  const [accordionActive, setAccordionActive] = useState(false);

  useEffect(() => {
    const findCurrentStepIndex = () => {
      for (let i = 0; i < campingStatus.length; i++) {
        const status = campingStatus[i];
        if (
          status.name === "Proposal" &&
          ["Pending", "Accepted", "Rejected", "Negotiating"].includes(
            proposalStatus
          )
        ) {
          return i;
        }
        if (
          status.name === "Creatives" &&
          proposalStatus === "Accepted" &&
          campaignType !== "Barter" &&
          ["Hold", "Live"].includes(campaignStatus)
        ) {
          return i;
        }
        if (
          status.name === "Analytics" &&
          creativeApproveCount > 0 &&
          ["Hold", "Live"].includes(campaignStatus)
        ) {
          return i;
        }
      }
      return null;
    };

    setCurrentStepIndex(findCurrentStepIndex());
  }, [
    campingStatus,
    proposalStatus,
    campaignStatus,
    campaignType,
    creativeApproveCount,
  ]);

  const handleAccordionClick = (
    e: any,
    campaignId: number,
    campaignPlatform: string
  ) => {
    e.stopPropagation();
    setAccordionActive((prev: boolean) => !prev);
  };

  // function handleCLickEvents(e: any) {
  //   if (e.target.classList.contains("mark-complete-event")) {
  //     e.preventDefault();
  //     markProposalComplete(proposalId, campaignTitle);
  //   } else {
  //     handleCampaign(
  //       "/influencer/campaign/campaigndetails",
  //       campaignId,
  //       campaignPlatform
  //     );
  //   }
  //   // handleCampaign('/influencer/campaign/campaigndetails', campaignId, campaignPlatform)
  // }
   const isDisabled = (
    proposalStatus === "Accepted" &&
    Number(creativeApproveCount) > 0 &&
    Number(invoiceCount) > 0
  );
    const isDisabledOther = (
    proposalStatus !== "Accepted" &&
    Number(creativeApproveCount) === 0 &&
    Number(invoiceCount) === 0
  );
  function handleCLickEvents(e: any) {
  if (!isDisabled && !isDisabledOther) {
    e.stopPropagation(); // Stop event propagation
    e.preventDefault();  // Prevent default action
    return;
  }

  if (e.target.classList.contains("mark-complete-event")) {
    e.preventDefault();
    markProposalComplete(proposalId, campaignTitle);
  } else {
    handleCampaign(
      "/influencer/campaign/campaigndetails",
      campaignId,
      campaignPlatform
    );
  }
}
  return (
    <div onClick={!isDisabled && !isDisabledOther ? undefined : (e) => handleCLickEvents(e)} className="my-campaign-card">
      <div className="my-campaign-card-left">
        <img src={campaignImage} alt="" />
        <div className="d-flex flex-column flex-md-row w-100 align-items-md-center align-items-start">
          <h5
            style={{ color: "#00aae5" }}
            onClick={() =>
              handleCampaign(
                "/influencer/campaign/campaigndetails",
                campaignId,
                campaignPlatform
              )
            }
          >
            {campaignTitle}
          </h5>

          <span className="badge badge-primary-outline d-inline-flex ms-md-2">
            {campaignPlatform === "Instagram" && (
              <>
                <InstagramIcon1 width={20} height={20} />
                {campaignType}
              </>
            )}

            {campaignPlatform === "Youtube" && (
              <>
                <IconYouTube width={20} height={20} />
                {campaignType}
              </>
            )}
          </span>
        </div>
        <IconButton
          className="ms-auto d-inline-flex d-md-none"
          onClick={(e: any) =>
            handleAccordionClick(e, campaignId, campaignPlatform)
          }
        >
          <MaterialSymbol
            className={accordionActive ? "active" : ""}
            iconName={`${
              accordionActive ? "keyboard_arrow_up" : "keyboard_arrow_down"
            }`}
            fontweight="200"
          />
        </IconButton>
      </div>
      <div
        className={`my-campaign-card-right ${accordionActive ? "active" : ""}`}
      >
        <>
          {campingStatus?.map((status, index) => {
            const isPreviouslySelected =
              currentStepIndex !== null && index < currentStepIndex;
            const isCurrentStep =
              currentStepIndex !== null && index === currentStepIndex;
                const isDisabled = (
                  proposalStatus === "Accepted" &&
                  Number(creativeApproveCount) > 0 &&
                  Number(invoiceCount) > 0
                );
                 const isDisabledOther = (
                  proposalStatus !== "Accepted" &&
                  Number(creativeApproveCount) === 0 &&
                  Number(invoiceCount) === 0
                );
            return (
              
              <Fragment key={index}>
                
                {status.applied_date && (
                  
                  <>
                    <span
                      className={`status-heading ${
                        isCurrentStep ? "selected" : ""
                      } ${isPreviouslySelected ? "selected previous" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCampaign(
                          status.url,
                          campaignId,
                          campaignPlatform
                        );
                      }}
                    >
                      <div className="status-heading-inner">
                        <strong>
                          {status.name}{" "}
                          <span
                            className="fs-14 d-flex d-md-block ms-1"
                            style={{ fontWeight: 400 }}
                          >
                            {status.applied_date && (
                              <span className="">{` on ${dayjs(
                                status.applied_date
                              ).format("LL")}`}</span>
                            )}
                          </span>
                        </strong>
                      </div>
                    </span>
                    <span
                      className={`arrow ${
                        isPreviouslySelected ? "selected previous" : ""
                      }`}
                    >
                      <span className="material-symbols-rounded">
                        keyboard_arrow_right
                      </span>
                    </span>
                  </>
                )}

                {status.name === "Proposal" && (
                  <>
                    <span
                      className={`status-heading status-proposal ${
                        [
                          "Pending",
                          "Accepted",
                          "Rejected",
                          "Negotiating",
                        ].includes(proposalStatus)
                          ? `proposal-${proposalStatus.toLowerCase()} ${
                              isCurrentStep ? "selected" : ""
                            } ${
                              isPreviouslySelected ||
                              creativeApproveCount > 0 ||
                              creativePendingCount > 0 ||
                              creativeRejectedCount > 0 ||
                              (proposalStatus === "Accepted" &&
                                campaignType !== "Barter" &&
                                ["Hold", "Live"].includes(campaignStatus))
                                ? "selected previous"
                                : ""
                            }`
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCampaign(
                          status.url,
                          campaignId,
                          campaignPlatform
                        );
                      }}
                    >
                      <div className="status-heading-inner">
                        <strong>
                          Status:{" "}
                          <span
                            className="fs-14 d-flex d-md-block ms-1"
                            style={{ fontWeight: 400 }}
                          >
                            {proposalStatus}
                          </span>
                        </strong>
                      </div>
                    </span>
                    <span
                      className={`arrow ${
                        isPreviouslySelected ? "selected previous" : ""
                      }`}
                    >
                      <span className="material-symbols-rounded">
                        keyboard_arrow_right
                      </span>
                    </span>
                  </>
                )}

                {(status.name === "Creatives" ||
                  status.name === "Send Invoice") && (
                  <>
                    {status.name === "Creatives" && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCampaign(
                            status.url,
                            campaignId,
                            campaignPlatform
                          );
                        }}
                        className={`status-heading ${
                          proposalStatus === "Accepted" &&
                          campaignType !== "Barter" &&
                          ["Hold", "Live"].includes(campaignStatus)
                            ? "selected"
                            : ""
                        } ${isCurrentStep ? "selected" : ""} ${
                          isPreviouslySelected || creativeApproveCount > 0
                            ? "selected previous"
                            : ""
                        }`}
                      >
                        <strong>Creatives</strong>
                        <ul className="campaign-creative">
                          {status.approve !== 0 &&
                            status.approve !== undefined && (
                              <Tooltip title="Approved" arrow>
                                <li>
                                  <span className="d-md-none">Approved:</span>
                                  <span className="status color-approve ms-1">{`${status.approve}`}</span>
                                </li>
                              </Tooltip>
                            )}
                          {status.pending !== 0 &&
                            status.pending !== undefined && (
                              <li>
                                <span className="d-md-none">Pending:</span>
                                <span className="status color-pending ms-1">{`${status.pending}`}</span>
                              </li>
                            )}
                          {status.reject !== 0 &&
                            status.reject !== undefined && (
                              <li>
                                <span className="d-md-none">Rejected:</span>
                                <span className="status color-reject ms-1">{`${status.reject}`}</span>
                              </li>
                            )}
                        </ul>

                        {proposalStatus === "Accepted" &&
                          campaignType !== "Barter" &&
                          ["Hold", "Live"].includes(campaignStatus) && (
                            <div className="send-invoice-sec">
                              <button
                                className="btn btn-outline-primary btn-sm mt-1"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCampaign(
                                    "/influencer/campaign/sendinvoice",
                                    campaignId,
                                    campaignPlatform
                                  ); // Use the specific Send Invoice URL
                                }}
                              >
                                Send Invoice
                              </button>
                            </div>
                          )}
                      </span>
                    )}

                    <span
                      className={`arrow ${
                        isPreviouslySelected ? "selected previous" : ""
                      }`}
                    >
                      <span className="material-symbols-rounded">
                        keyboard_arrow_right
                      </span>
                    </span>
                  </>
                )}

                {status.name === "Analytics" && (
                  <>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCampaign(
                          status.url,
                          campaignId,
                          campaignPlatform
                        );
                      }}
                      className={`status-heading ${
                        creativeApproveCount > 0 &&
                        ["Hold", "Live"].includes(campaignStatus)
                          ? "selected"
                          : ""
                      } ${isCurrentStep ? "selected" : ""} ${
                        isPreviouslySelected ? "selected previous" : ""
                      }`}
                    >
                      <strong>Analytics</strong>
                      {creativeApproveCount > 0 &&
                        ["Hold", "Live"].includes(campaignStatus) && (
                          <span className="fs-12">
                            Add post links to get analytics
                          </span>
                        )}
                    </span>
                    <span
                      className={`arrow ${
                        isPreviouslySelected ? "selected previous" : ""
                      }`}
                    >
                      <span className="material-symbols-rounded">
                        keyboard_arrow_right
                      </span>
                    </span>
                  </>
                )}
                
                {["Mark Complete", "Marked Complete"].includes(status.name) && (
                  <span
                    id={`mark-complete-${proposalId}`}
                    className={`status-heading ${
                      status.name === "Marked Complete"
                        ? "selected"
                        : "mark-complete-event"
                    } ${isDisabled ? "" : "disabled"} ${isDisabledOther ? "enable" : ""}`}
                  >
                    <div
                      className={`status-heading-inner ${
                        status.name === "Marked Complete"
                          ? ""
                          : "mark-complete-event"
                      }`}
                    >
                      <strong
                        className={`${
                          status.name === "Marked Complete"
                            ? ""
                            : "mark-complete-event d-flex align-items-center"
                        }`}
                      >
                        {status.name}
                        {(!isDisabled && !isDisabledOther) ? <Tooltip arrow placement="top"
                        title={
                          <span>
                            <strong>Please note:</strong>  
                            <br />
                            The campaign cannot be marked as complete during the middle stages.
                            Completion is only possible once all agreed-upon deliverables have been fulfilled and reviewed.
                            <br /><br />
                            Feel free to reach out if you have any questions.
                          </span>
                        }>
                        <IconButton>
                          <MaterialSymbol iconName="info" fontweight="200" />
                        </IconButton>
                      </Tooltip> : ""}
                      </strong>
                      {completedAt && status.name === "Marked Complete" && (
                        <span className="fs-14 ms-1">{` on ${dayjs(
                          completedAt
                        ).format("LL")}`}</span>
                      )}
                    </div>
                  </span>
                )}
              </Fragment>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default MyCampaignCard;
