import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Container, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export const SocialConnect_Confirmation: React.FC = () => {
    const [authCode, setAuthCode] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            setAuthCode(code);
            setLoading(false);
        }, 1000);
    }, []);

    const handleContinue = () => {
        if (authCode) {
            window.opener?.postMessage({ type: 'social-auth-success', code: authCode }, window.location.origin);
            window.close();
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', mt: 8, borderRadius: 3 }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        {authCode ? (
                            <>
                                <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
                                <Typography variant="h5" fontWeight="bold">
                                    Authentication Successful
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1, mb: 3, color: 'gray' }}>
                                    Your account has been successfully authenticated. Click the button below to continue.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={handleContinue} sx={{ fontSize: 16, padding: '10px 20px' }}>
                                    Continue
                                </Button>
                            </>
                        ) : (
                            <>
                                <ErrorIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
                                <Typography variant="h5" fontWeight="bold">
                                    Authentication Failed
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1, color: 'gray' }}>
                                    No authentication code found. You can close this window and try again.
                                </Typography>
                            </>
                        )}
                    </>
                )}
            </Paper>
        </Container>
    );
};
