import React from "react";
import { TableComponent } from "../../../../components/table-component/table";
import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { DefaultUserProfile } from "../../../../config/config";
import { Methods } from "../../../../core/utility";
import { Link } from "react-router-dom";
import CustomDataGrid from "../../../../components/common-table-component/common-data-grid";
import { GridColDef } from "@mui/x-data-grid";

const methods: Methods = new Methods();
type Props = {
  postFilterData?: any;
  columnDisplayName: any;
  orderByColumnName?: any;
  inputDataSource: any;
  sortingColumnType: any;
  renderData: any;
  setRenderData: any;
  selectCampaign?: any;
  loading?: boolean;
};

const DetailedInsightsInfluencer = (props: Props) => {
  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      cellClassName: "",
      width: 550,
      maxWidth: 500,
      filterable: false, // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: false,
      hideable: false,

      renderCell: (params) => (
        <>
          <div className="grid-div-post d-flex">
                          <Link
                            target="_blank"
                            to={
                              props?.selectCampaign?.campaign_platform ===
                              "Instagram"
                                ? `https://www.instagram.com/${params.row?.username}`
                                : `https://www.youtube.com/${params.row?.custom_url}`
                            }
                          >
                            <img
                              src={
                                props?.selectCampaign?.campaign_platform ===
                                "Instagram"
                                  ? params.row?.gcp_profile_pic_url &&
                                    params.row?.gcp_profile_pic_url !== ""
                                    ? params.row?.gcp_profile_pic_url
                                    : DefaultUserProfile.influencer_unknown_default
                                  : props?.selectCampaign?.campaign_platform ===
                                    "Youtube"
                                  ? params.row?.profile_image_url &&
                                    params.row?.profile_image_url !== ""
                                    ? params.row?.profile_image_url
                                    : DefaultUserProfile.influencer_unknown_default
                                  : DefaultUserProfile.influencer_unknown_default
                              }
                              alt={
                                props?.selectCampaign?.campaign_platform ===
                                "Instagram"
                                  ? params.row?.gcp_profile_pic_url
                                  : params.row?.profile_image_url
                              }
                              className="grid-image me-2"
                            />
                          </Link>
                          <div className="d-flex flex-column  align-items-start">
                            {/* INSTAGRAM */}
                            {props?.selectCampaign?.campaign_platform ===
                              "Instagram" && (
                              <span className="d-block">{params.row.full_name}</span>
                            )}
                            {props?.selectCampaign?.campaign_platform ===
                              "Youtube" && (
                              <span className="d-block">{params.row.title}</span>
                            )}
                            <Link
                              target="_blank"
                              className="btn btn-text-link"
                              to={
                                props?.selectCampaign?.campaign_platform ===
                                "Instagram"
                                  ? `https://www.instagram.com/${params.row?.username}`
                                  : `https://www.youtube.com/${params.row?.custom_url}`
                              }
                            >
                              {props?.selectCampaign?.campaign_platform ===
                              "Instagram"
                                ? params?.row?.username
                                : params?.row?.custom_url}
                            </Link>
                          </div>
                        </div>
        </>
      ),
    },
    {
      field: "post",
      headerName: "Post",
      cellClassName: "",
      width: 150,
      filterable: false, // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      renderCell: (params) => (
        <>{params?.row?.total_post}</>
      ),
    },
    {
      field: "er",
      headerName: "ER",
      cellClassName: "",
      width: 150,
      filterable: false, // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      valueGetter: (params) => 
      params?.row?.total_er !== undefined && params?.row?.total_er !== null
        ? params?.row.total_er
        : null,
      renderCell: (params) => (
        <>{params?.row?.total_er !== undefined && params?.row?.total_er !== null
                          ? `${params?.row.total_er}%`
                          : "NA"}</>
      ),
    },
    {
      field: "views",
      headerName: "Views",
      cellClassName: "",
      width: 120,
      filterable: false, // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      
      valueGetter: (params) =>
      params?.row?.video_plays
        ? methods.formatInfluencerProfileNumber(params?.row?.video_plays)
        : methods.formatInfluencerProfileNumber(params?.row?.total_views),
      renderCell: (params) => (
        <>
         {methods.formatInfluencerProfileNumber(
                          params?.row?.video_plays
                            ? params?.row?.video_plays
                            : params?.row?.total_views
                        )}
        </>
      ),
    },
    {
      field: "likes",
      headerName: "Likes",
      cellClassName: "",
      width: 120,
      filterable: false, // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      valueGetter: (params) => params?.row?.total_likes || 0, 
      renderCell: (params) => (
        <>
         {methods.formatInfluencerProfileNumber(
            params?.row?.total_likes
          )}
        </>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      cellClassName: "",
      width: 150,
      filterable: false, // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      valueGetter: (params) => params?.row?.total_comments || 0,
      renderCell: (params) => (
        <>
          {methods.formatInfluencerProfileNumber(
            params?.row?.total_comments
          )}
        </>
      ),
    },

  ];
  return (
    <>
      {(() => {
        if (props?.inputDataSource.length > 0) {
          return (
            <div className="infl-detail-table">
              <CustomDataGrid
                disableColumnFilter
                rows={props?.inputDataSource}
                rowHeight={100}
                columns={columns}
                loading={props?.loading}
              />
            </div>
          );
        }
      })()}
    </>
  );
};

export default DetailedInsightsInfluencer;
