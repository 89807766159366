import React, { useState, useEffect } from "react";
// import "./detailed-analytics.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { AnalyticsService } from "../../../core/services/analytics.service"
import "./analytics.scss";
import {
  Tab,
  Box,
  Skeleton,
} from "@mui/material";
import {
  SortingColumnsType,
} from "../../../../src/components/table-component/table"

import AddAnalytics from "./add_analytics";
import AnalyticsDashboard from './components/analytics-dashboard';
import DetailedInsightsPost from './components/detailed-insights-post';
import * as gtUtil from "../../../core/utility";
import { ApplyCampaignService } from '../../../core/services';
import CommonPopUp from "../../../components/popup/common-popup";


interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
}

const applyCampaignService = new ApplyCampaignService();
const analyticsService = new AnalyticsService();
const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_views: "Views",
  like_count: "Likes",
  comments: "Comments",
  created_at: "Posted on"

};


const PostDetailedData: SortingColumnsType[] = [
  {
    name: "",
    columnSize: "50%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "10%",
  },
];




const InfluencerAnalyticsYoutube: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [analyticsCumulativeDataYtNumericData, setanalyticsCumulativeDataYtNumericData] = useState<any>([]);
  const [analyticsCumulativeDataYtMediaData, setanalyticsCumulativeDataYtMediaData] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [creativeData, setCreativeData] = useState()
  const [graphData, setgraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0)
  const [campaignPlatform, setCampaignPlatform] = useState("")
  const [smId, setSmId] = useState(0)
  const [totalCost, setTotalCost] = useState(0);
  const [loading, setLoading] = useState(false)
  const [disablePostLink, set_disablePostLink] = useState(true);
  const [noActionPopup, setNoActionPopup] = useState(false);


  // -----------------Open Model-----------------------


  const [openModalAddPost, setOpenModalAddPost] = useState<boolean>();


  // -------------

  // -----------

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };


  // For Posts................................................

  // .....................................................................................

  const handleOpenModalAnalytics = (campaign_id: number, campaign_platform: string, sm_id: number) => {
    setOpenModalAddPost(true);
    setCampaignId(campaign_id);
    setCampaignPlatform(campaign_platform);
    setSmId(sm_id);

  }


  useEffect(() => {
    analyticsData();
    getCreativeDetails();
  }, []);

  const getCreativeDetails = async () => {
    try {
      const response = await applyCampaignService.influencerCreativeDetail({
        campaign_id: props.selectCampaign.campaign_id,
        smId: props.selectCampaign.smId,
      });
  
      if (response.status === gtUtil.Constants.SUCCESS) {
        for (const row of response.data.rows) {
          if (row.status === "Approved") {
            set_disablePostLink(false);
            break;
          }
        }
      } else {
        console.error("Failed to fetch creative details. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching creative details:", error);
    }
  };

  const analyticsData = async () => {
    try {
      setLoading(true)
      let campaign_id = props?.selectCampaign?.campaign_id;
      const res: any = await analyticsService.brandAnalyticsYoutube({
        campaign_id: campaign_id, sm_id: props.selectCampaign.smId
      });
      if(res.status === gtUtil.Constants.SUCCESS){
        if (res.data.rows[0].post_data_content.length > 0) {
          setTotalCost(res.data.rows[0].amount_spent)
          setanalyticsCumulativeDataYtMediaData(res?.data?.rows[0]?.analytics_data_yt_media[0])
          setanalyticsCumulativeDataYtNumericData(res?.data?.rows[0]?.analytics_data_yt_numeric[0])
          setPostFilterData(res?.data?.rows[0]?.post_data_content)
          setInfluencerFilterData(res?.data?.rows[0]?.influencer_data_content)
          setgraphData(res?.data?.rows[0]?.graph_data_array)
          setCreativeData(res?.data?.rows[0]?.no_of_creatives)
        }
      }
    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false)
    }
  }

  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Likes',
      },
    },
    series: [
      {
        name: 'Date',
        data: graphData?.map((entry: any) => entry.likes),
      },
    ],
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Views Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Views',
        data: graphData?.map((entry: any) => entry.views),
      },
    ],
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Comments',
      },
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Date',
        data: graphData?.map((entry: any) => entry.comments),
      },
    ],
  };
  const erOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'ER Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'ER',
      },
    },
    series: [
      {
        name: 'Date',
        data: graphData?.map((entry: any) => entry.er),
      },
    ],
  };
   const [postDisablePopup, setPostDisablePopup] = useState(false);
    const handlePostDisablePopup = () => {
      setPostDisablePopup(true)
    }
  // For Insights by the types of Creatives
  return (
    <>

      {openModalAddPost && (
        <AddAnalytics open={openModalAddPost}
          onClose={() => setOpenModalAddPost(false)}
          campaign_id={campaignId}
          post_platform={campaignPlatform}
          sm_id={smId}

        />
      )}
      <div className="infl-analytics-youtube detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="container custom-container">
            <div className="row project-detail-sec">
              <div className="disable-msg-post-link">

                <div className="button-div">
                  {props?.selectCampaign?.completedStatus === "false" && (
                    <><button className="btn btn-outline-primary add btn-wide"
                    disabled={disablePostLink}
                      onClick={(e) => {
                        if(postFilterData?.length === 0) {
                        handleOpenModalAnalytics(props.selectCampaign.campaign_id, props.selectCampaign.campaign_platform, props.selectCampaign.smId)}
                        
                      
                      else {
                        handlePostDisablePopup()
                      }
                    }}
                      >
                      Add Post
                    </button>
                      {postDisablePopup && <CommonPopUp
                        className={`modal-medium mx-auto`}
                        openModal={postDisablePopup}
                        modalTitle="Contact Us:"
                        onClose={() => setPostDisablePopup(false)}
                      >
                        <span>
                          You can add only 1 link. You've reached the maximum limit. To add more links, please contact us.
                        </span>
                        <div className="popup-footer d-flex justify-content-end pb-2">
                          <a className="btn btn-primary ms-2 btn-mui" href="https://www.clanconnect.ai/contact_us">Contact us</a>
                        </div>
                      </CommonPopUp>}
                    {disablePostLink && <span className="error-msg-post-link">At least one creative must be approved before adding a post.</span>}</>
                  )}
                  {props?.selectCampaign?.completedStatus === "true" && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-primary add btn-wide"
                          onClick={() => setNoActionPopup(true)}
                        >
                          Add Post
                        </button>
                        <CommonPopUp
                          className={`modal-medium mx-auto`}
                          openModal={noActionPopup}
                          modalTitle=""
                          onClose={() => setNoActionPopup(false)}
                        >
                          <span>
                            This campaign is marked as complete, so no further actions can
                            be taken
                          </span>
                        </CommonPopUp>
                      </>
                    )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="upper-grid">
                  <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataYtMediaData} campaign_platform={props?.selectCampaign?.campaign_platform} analyticsCumulativeDataNumericData={analyticsCumulativeDataYtNumericData} totalCost={totalCost} UserReducer={props?.UserReducer} loading={loading} />
                </div>

              </div>
            </div>
          </div>
        </div>

        {postFilterData.length > 0 &&
          <>
            <div className="toggle-button-div sec-stockchart-w-tab">
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  className='nav sub-tabs'
                >
                  <Tab className='nav-link'
                    label={`Total Views`}
                    value="1"
                    icon={<span className="material-symbols-rounded">visibility</span>}
                    style={{
                      textTransform: "none",
                      fontSize: "16px",
                      flexGrow: 1,
                    }}
                  />
                  <Tab className='nav-link'
                    label={`Total Likes`}
                    value="2"
                    icon={<span className="material-symbols-rounded">favorite</span>}
                    style={{
                      textTransform: "none",
                      fontSize: "16px",
                      flexGrow: 1,
                    }}
                  />
                  <Tab className='nav-link'
                    label={`Total Comments`}
                    value="3"
                    icon={<span className="material-symbols-rounded">chat_bubble</span>}
                    style={{
                      textTransform: "none",
                      fontSize: "16px",
                      flexGrow: 1,
                    }}
                  />
                </TabList>

                <TabPanel className="px-0" value="1">
                  {loading ? <Skeleton /> : <HighchartsReact highcharts={Highcharts} options={playsOptions} />}
                </TabPanel>
                <TabPanel className="px-0" value="2">
                  <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                </TabPanel>
                <TabPanel className="px-0" value="3">
                  <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                </TabPanel>
              </TabContext>
            </div>
            <div className="toggle-button-div2">
              <div className="toggle-button-div3">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value4}>
                    <TabList
                      onChange={handleChange4}
                      className="filter-tab-list nav sub-tabs"
                    >
                      <Tab
                        className='nav-link'
                        label={`Posts`}
                        value="1"
                        style={{ textTransform: "none", fontSize: "16px" }}
                      />
                    </TabList>

                    <TabPanel value="1" className='px-0'>
                      <div className="post-table">
                        <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                          orderByColumnName=""
                          inputDataSource={postFilterData}
                          sortingColumnType={PostDetailedData}
                          renderData={postFilterData}
                          setRenderData={(e: any) => setPostFilterData(e)}
                          selectCampaign={props?.selectCampaign}
                        />
                      </div>
                    </TabPanel>

                  </TabContext>
                </Box>
              </div>

            </div>
          </>
        }


      </div>
    </>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerAnalyticsYoutube);

export { connectToRedux as InfluencerAnalyticsYoutube };
