import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormValidation } from "../../core/utility";
import { UserService } from "../../core/services";
import * as gtUtil from "../../core/utility";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { MuiOtpInput } from "mui-one-time-password-input";

interface ErrorTypes {
  status: boolean;
  message: string;
}

interface FormData {
  password: string;
  confirm_password: string;
  pin: string;
  confirm_pin: string;
}

const defaultFormData: FormData = {
  password: "",
  confirm_password: "",
  pin: "",
  confirm_pin: "",
};

const userService = new UserService();

export const ResetPasswordPIN: React.FC = () => {
  const formValidation = new FormValidation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [form_data, set_form_data] = useState<FormData>(defaultFormData);
  const [loader, setLoader] = useState<boolean>(true);
  const [remainingSeconds, setRemainingSeconds] = useState<number | null>(null);
  const [purpose, setPurpose] = useState<String>("");

  // Validation states
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [errorConfirmPassword, setErrorConfirmPassword] =
    useState<ErrorTypes>();
  const [errorPin, setErrorPin] = useState<ErrorTypes>();
  const [errorConfirmPin, setErrorConfirmPin] = useState<ErrorTypes>();

  const navigate = useNavigate();

  useEffect(() => {
    let intervalId: any;

    (async () => {
      // Extract the key from the URL query string
      const searchParams = new URLSearchParams(window.location.search);
      const key = searchParams.get("key");

      if (!key) {
        console.error("No key found in URL");
        navigate("/login");
      }

      try {
        // Call the updateUrlHitCount API to increment the count
        const updateRes = await userService.updateUrlHitCount({ key });

        if (updateRes.status == gtUtil.Constants.SUCCESS) {
          // If the update is successful, define a function to call checkKeyValidation
          const checkKeyValidation = async () => {
            try {
              const validationRes = await userService.checkKeyValidation({
                key,
              });
              if (validationRes.status === gtUtil.Constants.AUTH_ERROR) {
                //navigate to invalid page
                navigate(`/request-status?key=${key}`);
              } else {
                setRemainingSeconds(validationRes.data.remaining_seconds);
                setPurpose(validationRes.data.purpose);
                if (validationRes.data.remaining_seconds <= 0) {
                  navigate(`/request-status?key=${key}`);
                }
                setLoader(false);
              }
            } catch (error) {
              console.error("Validation API call error:", error);
            }
          };

          // Call validation immediately
          checkKeyValidation();

          // Start polling every 5 seconds
          intervalId = setInterval(checkKeyValidation, 5000);
        } else {
          //navigate to login page
          if (updateRes.status == gtUtil.Constants.AUTH_ERROR) {
            navigate(`/request-status?key=${key}`);
          } else {
            navigate("/login");
          }
        }
      } catch (error) {
        console.error("updateUrlHitCount API call error:", error);
      }
    })();

    // Clean up the interval on unmount
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  // Separate useEffect for the countdown timer
  useEffect(() => {
    if (remainingSeconds === null || remainingSeconds <= 0) return;

    const intervalId = setInterval(() => {
      setRemainingSeconds((prev) => (prev !== null && prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingSeconds]);

  // Convert seconds to minutes and seconds format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}m ${secs}s`;
  };

  // for password--------------------------------------------------------------------------------

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // When the user leaves the password field, perform validation
  const handlePassword = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    set_form_data({ ...form_data, password: value });
    setErrorPassword(formValidation.GetPasswordValidate(value));
  };

  // Validate the confirm password field on blur
  const handleConfirmPassword = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value !== form_data.password) {
      setErrorConfirmPassword({
        status: false,
        message: "Passwords do not match",
      });
    } else {
      set_form_data({ ...form_data, confirm_password: value });
      setErrorConfirmPassword({ status: true, message: "" });
    }
  };

  const handleSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the password field
    const passwordValidation = formValidation.GetPasswordValidate(
      form_data.password
    );
    setErrorPassword(passwordValidation);

    // Check if passwords match
    if (form_data.password !== form_data.confirm_password) {
      setErrorConfirmPassword({
        status: false,
        message: "Passwords do not match",
      });
      return;
    }

    // Proceed only if password is valid
    if (passwordValidation.status) {
      // Extract key from URL
      const searchParams = new URLSearchParams(window.location.search);
      const key = searchParams.get("key");

      if (!key) {
        navigate("/login");
      }

      try {
        // Call the forgotUpdatePassword API with key and new password
        const response = await userService.updatePasswordPin({
          key,
          password: form_data.password,
        });
        if (
          response.status === gtUtil.Constants.SUCCESS ||
          response.status === gtUtil.Constants.AUTH_ERROR
        ) {
          navigate(`/request-status?key=${key}`);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error updating password:", error);
      }
    }
  };

  // for pin-----------------------------------------------------------------------------

  // Update the primary PIN value
  const handlePINChange = (newValue: string) => {
    const filteredValue = newValue.replace(/\D/g, "");
    set_form_data({ ...form_data, pin: filteredValue });
  };

  // Update the confirm PIN value
  const handleConfirmPINChange = (newValue: string) => {
    const filteredValue = newValue.replace(/\D/g, "");
    set_form_data({ ...form_data, confirm_pin: filteredValue });
  };

  // Only allow numeric characters
  const validateChar = (value: string) => {
    return /^\d*$/.test(value);
  };

  // Validate that both PIN inputs match before submitting
  const handleSubmitPIN = async (e: React.FormEvent) => {
    e.preventDefault();

    if (form_data.pin.length === 4) {
      setErrorPin({
        status: false,
        message: "",
      });

      if (form_data.pin !== form_data.confirm_pin) {
        setErrorConfirmPin({ status: true, message: "PINs do not match!" });
        return;
      }

      // Clear errors if any and proceed
      setErrorConfirmPin({ status: false, message: "" });

      try {
        // Extract key from URL
        const searchParams = new URLSearchParams(window.location.search);
        const key = searchParams.get("key");

        if (!key) {
          navigate("/login");
        }

        const response = await userService.updatePasswordPin({
          key,
          pin: form_data.pin,
        });

        if (
          response.status === gtUtil.Constants.SUCCESS ||
          response.status === gtUtil.Constants.AUTH_ERROR
        ) {
          navigate(`/request-status?key=${key}`);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error updating password:", error);
      }
    } else {
      setErrorPin({
        status: true,
        message: "Invalid PIN",
      });
    }
  };

  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            maxWidth: "400px",
            margin: "40px auto",
            padding: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
            {purpose == "forgot password" ? "Set New Password" : "Set New Pin"}
          </h4>

          {/* Countdown Timer UI */}
          {remainingSeconds !== null && (
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
                padding: "12px",
                borderRadius: "8px",
                backgroundColor:
                  remainingSeconds > 300
                    ? "#e3f2fd" // Blue (Safe: >5 min)
                    : remainingSeconds > 120
                    ? "#fff3cd" // Orange (Warning: 2-5 min)
                    : "#f8d7da", // Red (Critical: <2 min)
                color:
                  remainingSeconds > 300
                    ? "#1565c0" // Blue Text
                    : remainingSeconds > 120
                    ? "#856404" // Orange Text
                    : "#721c24", // Red Text
                border:
                  remainingSeconds > 300
                    ? "1px solid #90caf9"
                    : remainingSeconds > 120
                    ? "1px solid #ffeeba"
                    : "1px solid #f5c6cb",
                marginBottom: "15px",
                transition: "all 0.3s ease-in-out",
              }}
            >
              {remainingSeconds > 0
                ? `⏳ Time left: ${formatTime(remainingSeconds)}`
                : "❌ Link expired"}
            </div>
          )}
          {purpose === "forgot password" && (
            <form onSubmit={handleSubmitPassword}>
              {/* Create Password Field */}
              <div className="row mb-3">
                <div className="col">
                  <FormControl
                    variant="outlined"
                    className="w-100 input-common"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Create Password
                    </InputLabel>
                    <OutlinedInput
                      id="password_id"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onBlur={handlePassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Create Password"
                    />
                  </FormControl>
                  <span
                    className="fs-12"
                    style={{
                      lineHeight: "14px",
                      display: "block",
                      paddingTop: "5px",
                    }}
                  >
                    Password must contain at least 1 uppercase letter, 1
                    lowercase letter, 1 number, and 1 special character.
                  </span>
                  <span className="error">{errorPassword?.message}</span>
                </div>
              </div>
              {/* Confirm Password Field */}
              <div className="row mb-3">
                <div className="col">
                  <FormControl
                    variant="outlined"
                    className="w-100 input-common"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-confirm-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="confirm_password_id"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirm_password"
                      onBlur={handleConfirmPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                  </FormControl>
                  {errorConfirmPassword && !errorConfirmPassword.status && (
                    <span className="error">
                      {errorConfirmPassword?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col text-end">
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    variant="contained"
                    disabled={!errorPassword?.status}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          )}
          {purpose === "forgot pin" && (
            <form onSubmit={handleSubmitPIN}>
              {/* PIN Input */}
              <div className="row mb-3">
                <div className="col">
                  <div style={{ marginBottom: "5px" }}>Enter PIN</div>
                  <div className="__wa_column">
                    <MuiOtpInput
                      value={form_data.pin}
                      onChange={handlePINChange}
                      length={4}
                      TextFieldsProps={{
                        size: "small",
                        placeholder: "0",
                        type: "tel",
                      }}
                      validateChar={validateChar}
                    />
                  </div>
                  <span className="error">{errorPin?.message}</span>
                </div>
              </div>
              {/* Confirm PIN Input */}
              <div className="row mb-3">
                <div className="col">
                  <div style={{ marginBottom: "5px" }}>Confirm PIN</div>
                  <div className="__wa_column">
                    <MuiOtpInput
                      value={form_data.confirm_pin}
                      onChange={handleConfirmPINChange}
                      length={4}
                      TextFieldsProps={{
                        size: "small",
                        placeholder: "0",
                        type: "tel",
                      }}
                      validateChar={validateChar}
                    />
                  </div>
                  <span className="error">{errorConfirmPin?.message}</span>
                </div>
              </div>
              <div className="row">
                <div className="col text-end">
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};
