import React, { useState, useEffect } from 'react';
import useBodyClass from '../../../hooks/useBodyClass';
import { ThirdPartyRegistrationForm } from './registration-form';
import V360EntityRegistrationStatus from './v360-entity-registration-confirmed';
import { CommonService, LocalService } from '../../../core/services';
import { useNavigate, useLocation } from 'react-router-dom';
import { v360organizationDataTypeProps } from 'custom-type';
import * as gtUtil from "../../../core/utility";

const commonService:CommonService = new CommonService();
const localService: LocalService = new LocalService();

export const V360ClientRegistration: React.FC = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('sessionId') || '';

  // interface v360organizationDataTypeProps {
  //   organization_id: string,
  //   organization_name: string,
  //   organization_logo: string
  // }

  const initialV360OrganizationData: v360organizationDataTypeProps = {
    organization_id: 0,
    organization_name: '',
    organization_logo: '',
    entity_type: 'Client'
  }

  const [v360organizationData, setV360OrganizationData] = React.useState<v360organizationDataTypeProps>(initialV360OrganizationData);

  const [status, setStatus] = React.useState<boolean>(false);

  const [showRegistrationStatus, setShowRegistrationStatus] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState('');

  // Callback to handle after form submission step.
  const showSubmitStatus = (responseData: {status: boolean, message: string}) => {
    setShowRegistrationStatus(true);
    setRegistrationStatus(responseData.status);
    setShowResponseMessage(responseData.message);
  };

  useEffect(() => {
    // Fetch live data initially
    commonService.validateV360Organization({sessionId})
      .then((res: any) => {
        if (res.status === gtUtil.Constants.SUCCESS) {
          setStatus(true);
          setV360OrganizationData({...v360organizationData, ...res.data.rows[0]});
        } else {
          localService.toastify(res.info, 'error', 1000);
          navigate('/');
        }
    })
      .catch((e: any) => setV360OrganizationData(initialV360OrganizationData));

    // // Set up interval to fetch live data every 5 seconds
    // const intervalId = setInterval(() => {
    //     LiveDashboardService.getLiveDashboardData(sessionId)
    //     .then((res: any) => {
    //         const data = res?.data?.rows || [];
    //         if (data.length === 0) {
    //           navigate('/');
    //         } else {
    //           setLiveData(data);
    //         }
    //     })
    //     .catch((e: any) => setLiveData([]));
    // }, 5000);

    // // Clean up the interval when the component is unmounted
    // return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      { v360organizationData.organization_id > 0 && status &&
        (
          !showRegistrationStatus 
          ?
          <ThirdPartyRegistrationForm organization_id={v360organizationData.organization_id} organization_name={v360organizationData.organization_name} organization_logo={v360organizationData.organization_logo} entity_type={v360organizationData.entity_type} showSubmitStatus={showSubmitStatus} />
          :
          <V360EntityRegistrationStatus organization_id={v360organizationData.organization_id} organization_name={v360organizationData.organization_name} organization_logo={v360organizationData.organization_logo} entity_type={v360organizationData.entity_type} message={showResponseMessage} registrationStatus={registrationStatus} />
        )
      }
    </>
  )
};