import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Loading } from "../../../../../../redux/action";
import {
  PaymentComponentService,
  InvoiceService,
  LocalService,
} from "../../../../../../core/services";
import "./payment.scss";
import ProposalPaymentTable from "./proposal_payment_table";
import MaterialSymbol from "../../../../../../components/icons/material-symbol";
import IconPayment from "../../../../../../components/icons/icon-payments";
import * as gtUtil from "../../../../../../core/utility"

const paymentComponent: PaymentComponentService = new PaymentComponentService();
const invoiceService: InvoiceService = new InvoiceService();
const localService: LocalService = new LocalService();
interface Props {
  selectedCampaign: any;
  UserReducer: any;
  loading: (data: any) => void;
}

interface Invoice {
  file_url: string;
  file_name: string;
  total_amount: number;// invoice total amount.
}

interface Proposal {
  total_price: number;
  name: string;
  influencer_id: number;
  proposal_id: number;
  payment_status: string;
  account_type?: string;
  bank_name?: string;
  ifsc_code?: string;
  account_holder_name?: string;
  account_number?: string;
  address?: string;
  micr_code?: string;
  // file_urls: string;
  invoices: Invoice[];
}

const BrandPayment: React.FC<Props> = ({selectedCampaign, UserReducer, ...props}: Props) => {
  // to store accepted proposal details
  const [acceptedProposalPaymentDetails, setAcceptedProposalPaymentDetails] = useState<Proposal[]>([]);
  // to store total amount of accepted proposals
  const [totalCampaignAmount, setTotalCampaignAmount] = useState(0);
  // To toggle bank details section
  const [showBankDetails, setShowBankDetails] = useState(false);

  const [updateAcceptedProposalPaymentDetails, setUpdateAcceptedProposalPaymentDetails] = useState(false);

  const toggleBankDetails = () => {
    setShowBankDetails(!showBankDetails);
  };

  async function getAcceptedProposalPaymentDetails() {
    try {
      const response: any = await invoiceService.getAcceptedProposalPaymentDetails({
        campaign_id: selectedCampaign.campaign_id,
        influencer_id: 0
      });

      if (response.status === gtUtil.Constants.SUCCESS) {
        setAcceptedProposalPaymentDetails(response.data.rows);
        // const totalSum = response.data.rows.reduce((accumulator, currentObject) => {
        //   return accumulator + currentObject.total_price;
        // }, 0);
        const totalSum = response.data.rows.reduce((accumulator: number, currentObject: { total_price: number }) => {
          return accumulator + currentObject.total_price;
        }, 0);
        setTotalCampaignAmount(totalSum);
      } else {
        setAcceptedProposalPaymentDetails([]);
      }
    } catch (error) {
      throw new Error(`getAcceptedProposalPaymentDetails error :: ${error}`);
    }
  }

  useEffect(() => {
    getAcceptedProposalPaymentDetails();
    setUpdateAcceptedProposalPaymentDetails(false);
  }, [updateAcceptedProposalPaymentDetails]);

  return (
    <>
      <div className="snapshot-wrapper snapshot-info d-flex flex-wrap pb-4">
        <strong className="snapshot-card-title">Estimated Campaign Amount (Excluding Taxes) -  </strong> 
        <span className="d-flex flex-wrap">
          <span className="ms-1">
            <IconPayment height="16px"/>
          </span>
          {totalCampaignAmount}
        </span>
        {/* <div id="estimated-payment" className="est-pay-sec snapshot-card-row">
          <div className="est-pay-row d-flex flex-wrap w-100">
            <div className="col est-pay-row-left">
              <div className="snapshot-card-row">
                <span className="est-pay-title">Total </span>
                <p className="est-pay-value"></p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <ProposalPaymentTable UserReducer={UserReducer} acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={selectedCampaign} setUpdateAcceptedProposalPaymentDetails={setUpdateAcceptedProposalPaymentDetails}/>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandPayment);

export { connectToRedux as BrandPayment };
