import React, { ChangeEvent, useEffect, useState } from 'react';
import './social-connect.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookLogin from 'react-facebook-login';
import { DefaultUserProfile, FACEBOOK_APP_ID } from '../../../../config/config';
import { useGoogleLogin } from '@react-oauth/google';
import { HandlesService } from '../../../../core/services/handles.service';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Divider, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, SwipeableDrawer, TextareaAutosize, TextField, useMediaQuery } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card } from '../../../../components';
import { LocalService, PublicService, UserService } from '../../../../core/services';
import { createPortal } from 'react-dom';
import MaterialSymbol from '../../../../components/icons/material-symbol';
import GenericSwipeableDrawer from '../../../../components/generic-swipe-drawer/generic-swiper-drawer';
// import { InfluencerPersonalInfo } from '../personal-info/personal-info'
import { UserLogin } from '../../../../redux/action';
import SocialConnectRestriction from '../../../../components/popup/social-connect-restrict';
import Loading from '../../../../components/loader/loading';
import { BASE_URL_UI } from '../../../../config/config';
import GuideToConnectContent from '../../../../components/popup/guide-to-connect-content';
import CommonPopUp from '../../../../components/popup/common-popup';
import { FormLabel } from 'react-bootstrap';
import { FormValidation } from '../../../../core/utility';
import { InstagramDMAutomation } from '../../../../core/services/dm-automation-instagram.service';
import { CLIENT_ID } from '../../../../config/config';
import * as gtUtil from "../../../../core/utility";
import ContentLoading from '../../../../components/loader/loading';

const handlesService = new HandlesService();
const localService = new LocalService();
const publicService = new PublicService();
const userService = new UserService();
const formValidation = new FormValidation();
const instagramDMAutomationService = new InstagramDMAutomation();


interface Props {
    userData: any;
    updateUser: any;
    // loading: (data: any) => void;
}
const InfluencerSocialConnect: React.FC<Props> = (props: Props) => {
    const [login, setLogin] = React.useState(false);
    const [data, setData] = React.useState<any>({});
    const [picture, setPicture] = React.useState('');
    const [choosePrimary, setChoosePrimary] = React.useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const [account, setAccount] = React.useState<any>([]);
    const [googleaccount, setGoogleAccount] = React.useState<any>([]);
    const [instagramAccount, setInstagramAccount] = React.useState<any>([]);
    const [value, setValue] = React.useState('');
    const [connectButton, setConnectButton] = React.useState('Connect');
    const [connectButtonGoogle, setConnectButtonGoogle] =
        React.useState('Connect');
    const [connectButtonInstagram, setConnectButtonInstagram] =
        React.useState('Connect');
    const [accountSwitchButton, setAccountSwitchButton] = React.useState('');
    const [facebookAccounts, setFacebookAccounts] = React.useState<any>([]);
    const [disconnectButton, setDisconnectButton] = React.useState(true);
    const [disconnectButtonInstagram, setDisconnectButtonInstagram] = React.useState(true);
    const [disconnectButtonGoogle, setDisconnectButtonGoogle] =
        React.useState(true);
    const [loadingIg, setLoadingIg] = React.useState(false);
    const [loadingInstagram, setLoadingInstagram] = React.useState(false);
    const [loadingYt, setLoadingYt] = React.useState(false);
    const [openSwitchAccount, setOpenSwitchAccount] = React.useState(false);
    const [previousValue, setPreviousValue] = React.useState('');
    const [username, setUserName] = React.useState('');
    const [userNameError, setUserNameError] = React.useState('');
    const [faceBookButton, setFaceBookButton] = React.useState(true);
    const [verifyUserName, setVerifyUserName] = React.useState(true);
    // const [influencerData, setInfluencerData] = React.useState(true);
    const [addAddressPopupOpen, setAddAddressPopupOpen] = React.useState(false);
    // const [socialRestrictionPopup_YT, setSocialRestrictionPopup_YT] = React.useState(false);
    // const [socialRestrictionPopup_IG, setSocialRestrictionPopup_IG] = React.useState(false);
    const [socialRestrictionPopup, set_socialRestrictionPopup] = useState({
        youtube: false,
        instagram: false
    })
    const [channel_details, setChannel_details] = React.useState<any>({});
    const [google_auth_response, setGoogle_auth_response] = React.useState<any>({});
    const [responseUsernameIG, setResponseUsernameIG] = React.useState<any>('');
    const [openModal, setOpenModal] = useState(false)
    const [otherRemark, setOtherRemark] = useState(false)
    const [remark, setRemark] = useState("");
    const [remarkDescription, setRemarkDescription] = useState("");
    const [isGoogleAccount, setIsGoogleAccount] = useState(false)
    const [isInstagramAccount, setIsInstagramAccount] = useState(false)
    const redirectUri = '/influencer/settings/socialconnect'
    const [username_verify, set_username_verify] = useState<String | null>(null);
    const [ig_data, set_ig_data] = useState({});
    const [yt_data, set_yt_data] = useState({});

    const [formError, setFormError] = useState({
        remark: { status: true, message: "" },
        remarkDescription: { status: true, message: "" },
    });

    const [social_loader, set_social_loader] = useState<boolean>(false);

    const handleClose = () => {
        setValue(previousValue);
        setOpenSwitchAccount(false);
    };

    const handleCloseSocialPopup_YT = async (id: any) => {
        // setSocialRestrictionPopup_YT(false);
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            youtube: false
        }));
        
        try {
            const response = await handlesService.continueConnect({
                yt_id: id,
                influencer_id: props.userData.influencer_id,
                continues: false,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer()
                getYoutubeAccounts();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };
    
    const handleContinueConnect_YT = async (id: any) => {
        // setSocialRestrictionPopup_YT(false);
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            youtube: false 
        }));
    
        try {
            const response = await handlesService.continueConnect({
                yt_id: id,
                influencer_id: props.userData.influencer_id,
                continues: true,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer();
                getYoutubeAccounts();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };
    
    const handleCloseSocialPopup_IG = async (id: any) => {
        // setSocialRestrictionPopup_IG(false);
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            instagram: false 
        }));
    
        try {
            const response = await handlesService.continueConnect({
                ig_id: id,
                influencer_id: props.userData.influencer_id,
                continues: false,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer();
                getInstagramAccounts();
                getFacebookAccounts();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };
    
    const handleContinueConnect_IG = async (id: any) => {
        // setSocialRestrictionPopup_IG(false);
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            instagram: false 
        }));
    
        try {
            const response = await handlesService.continueConnect({
                ig_id: id,
                influencer_id: props.userData.influencer_id,
                continues: true,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer();
                getInstagramAccounts();
                getFacebookAccounts();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };
    

    const onClose = (value: boolean) => {
        setOpenModal(false)
        setRemark('')
        setRemarkDescription('')
        setOtherRemark(false)
        setIsGoogleAccount(false)
        setIsInstagramAccount(false)
    }

    const handleFeedback = (buttonType:any) => {
        if (buttonType === "googleButton") {
            setIsGoogleAccount(true)
        }
        if (buttonType === "instagramButton") {
            setIsInstagramAccount(true)
        }
        setOpenModal(true);
    };

    const handleFeedbackConfirmationGoogle = () => {
        handleGoogleDisconnect()
    } 

    const handleFeedbackConfirmationFacebook = () => {
        handleFacebookDisconnect()
    } 

    const handleFeedbackConfirmationInstagram = () => {
        handleInstagramDisconnect()
    } 

    const handleContinueFuntionality_YT = async () => {
        try {
            setLoadingYt(true);
            const res = await handlesService.handleContinueGoogle({
                google_auth_response: google_auth_response,
                channel_details: channel_details,
                influencer_id: props?.userData?.influencer_id,
            });

            if (res?.data?.status?.status) {
                handlesService
                    .getGoogleConnectedAccounts(
                        props?.userData?.influencer_id
                    )
                    .then((res: any) => {
                        if (res?.data?.rows.length > 0) {
                            // const connectedAccountData =
                            //     res?.data?.rows.filter(
                            //         (filter: any) =>
                            //             filter.token_status ===
                            //             'Connected'
                            //     );
                            // setGoogleAccount(connectedAccountData);
                            // setDisconnectButtonGoogle(false);
                            // setConnectButtonGoogle('Reconnect');
                                const yt_id = res?.data?.rows[0]?.yt_id;
                                // updateYoutubePermissions(yt_id, res?.data?.rows[0].access_token);
                            getYoutubeAccounts();
                        }
                    })
                    .catch((err: any) => console.log(err));
                updateUserReducer();
            }

        } catch (error) {
            console.log('error', error);
        }finally{
            setLoadingYt(false);
        }
    }

    // const loginGoogle = useGoogleLogin({
    //     flow: 'auth-code',
    //     scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly',
    //     onSuccess: async (tokenResponse: any) => {
    //         const data: any = {
    //             code: tokenResponse.code,
    //             influencer_id: props?.userData?.influencer_id,
    //         };
    //         try{
    //             setLoadingYt(true);
    //         handlesService
    //             .googleAuth(data)
    //             .then((res: any) => {
    //                 if (res?.status !== gtUtil.Constants.SUCCESS) {
    //                     if (res?.info === 'This account already exists.') {
    //                         setSocialRestrictionPopup_YT(true);
    //                         setChannel_details(res?.data?.rows[0]?.channel_details);
    //                         setGoogle_auth_response(res?.data?.rows[0]?.google_auth_response);
    //                         return;
    //                     }
    //                 }
    //                 else if (res?.status === gtUtil.Constants.SUCCESS) {
    //                     handlesService
    //                         .getGoogleConnectedAccounts(
    //                             props?.userData?.influencer_id
    //                         )
    //                         .then((res: any) => {
    //                             if (res?.data?.rows.length > 0) {
    //                                 const yt_id = res?.data?.rows[0]?.yt_id;
    //                                 // updateYoutubePermissions(yt_id, res?.data?.rows[0].access_token);
    //                                 getYoutubeAccounts();
    //                             }
    //                         })
    //                         .catch((err: any) => console.log(err));
    //                             updateUserReducer();
    //                 }
    //             })
    //             .catch((err: any) => {
    //                 console.log(err);
    //             });
    //     }catch(err){
    //         console.log(err);
    //     }finally{
    //         setLoadingYt(false);
    //     }
    //     },
    //     onError: (errorResponse) => console.log(errorResponse),
    // });

    // const updateYoutubePermissions = async (yt_id: string, googleTokenData: string) => {
    //     try {
    //         const permissionsApiUrl = `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${googleTokenData}`;
    //         const permissionsResponse = await fetch(permissionsApiUrl);
            
    //         if (!permissionsResponse.ok) {
    //             throw new Error('Failed to fetch permissions from Facebook API');
    //         }
    
    //         const permissionsData = await permissionsResponse.json();
    
    //         const { user_id, email, scope, access_type } = permissionsData;
    
    //         const dataToSend = {
    //             yt_id,
    //             user_id,
    //             email,
    //             scope,
    //             access_type,
    //         };
    
    //         const permissionsUpdateResponse: any = await handlesService.updateYtPermissions(dataToSend);
    
    //         if (permissionsUpdateResponse?.data?.status) {
    //             // toast.success(permissionsUpdateResponse.data.status.message);
    //         } else {
    //             // toast.error(permissionsUpdateResponse?.data?.message || 'Failed to update permissions');
    //         }
    //     } catch (error) {
    //         console.error('Error updating permissions:', error);
    //         // toast.error('An error occurred while updating permissions');
    //     }
    // };
    
    
    

    async function getFacebookAccounts() {
        try{
        setLoadingIg(true)
        handlesService
            .getConnectedAccounts(
                props?.userData?.influencer_id
            )
            .then((res: any) => {
                if (res?.data?.rows.length > 0) {
                    const connectedAccountData = res?.data?.rows.filter(
                        (filter: any) => filter.account_type === 'Primary'
                    );
                    setAccount(res?.data?.rows);
                    setFacebookAccounts(res?.data?.rows);
                    setValue(connectedAccountData[0]?.ig_id);
                    setConnectButton('Reconnect');
                    setDisconnectButton(false);
                    setResponseUsernameIG('');
                    setUserName('');
                    setVerifyUserName(true);
                    setLoadingIg(false)
                }
            })
            .catch((err: any) => {
                console.log(err)
                setLoadingIg(false)
            })}catch(err){
                console.log(err)
                setLoadingIg(false)
            }finally{
                setLoadingIg(false)
            }
    }

    async function getInstagramAccounts() {
        try{
        setLoadingInstagram(true)
        handlesService
            .getConnectedInstagramAccounts(
                props?.userData?.influencer_id
            )
            .then((res: any) => {
                if (res?.data?.rows.length > 0) {
                    setInstagramAccount(res?.data?.rows);
                }
            })
            .catch((err: any) => {
                console.log(err)
                setLoadingInstagram(false)
            })}catch(err){
                console.log(err)
                setLoadingInstagram(false)
            }finally{
                setLoadingInstagram(false)
            }
    }

    async function getYoutubeAccounts() {
        try{
        setLoadingYt(true)
        handlesService
            .getGoogleConnectedAccounts(
                props?.userData?.influencer_id
            )
            .then((res: any) => {
                if (res?.data?.rows.length > 0) {
                    const connectedAccountData = res?.data?.rows.filter(
                        (filter: any) => filter.token_status === 'Connected'
                    );
                    setGoogleAccount(connectedAccountData);
                    setDisconnectButtonGoogle(false);
                    setConnectButtonGoogle('Reconnect');
                }
            })
            .catch((err: any) => console.log(err));
            updateUserReducer();
        }catch(err){
            console.log(err)
        }finally{
            setLoadingYt(false)
        }
    }


    useEffect(() => {
        getFacebookAccounts();
        getYoutubeAccounts();
        getInstagramAccounts();
    }, []);

    async function updateUserReducer() {
        let latestUserReducerData = await userService.getUpdatedUserReducer(props.userData);
        if (![undefined, null, ''].includes(latestUserReducerData)) {
            props.updateUser(latestUserReducerData)
        }
    }

    // const responseFacebook = async (response: any) => {
    //     try {
    //         if (response.status === 'unknown') {
    //             alert('Login failed!');
    //             setLogin(false);
    //             return false;
    //         }
    //         setLoadingIg(true);
    //         setData(response);
    //         setPicture(response.picture.data.url);
    //         if (response.accessToken) {
    //             let influencer_data = localService.getStorageItem('influencerData');
    //             let influencerData = JSON.parse(influencer_data || '');
    //             localStorage.removeItem('influencerData');
    //             const data = {
    //                 influencer_id: props?.userData?.influencer_id,
    //                 shortLiveToken: response.accessToken,
    //                 account_id: props.userData.account_id,
    //                 user_id: props.userData.user_id,
    //                 facebookUserId: response.id,
    //                 facebookName: response.name,
    //                 facebookEmail: response.email ? response.email : '',
    //                 facebookProfileImage: response?.picture?.data?.url
    //                     ? response?.picture?.data?.url
    //                     : '',
    //                 influencerData: influencerData
    //             };
    //             setLogin(true);
    //             const facebookHandlesResponse: any = await
    //                 handlesService.facebookHandles(data)
    //             if (facebookHandlesResponse?.status === gtUtil.Constants.SUCCESS) {
    //                 toast.success(facebookHandlesResponse?.info);
    //                 const getConnectedAccountsResponse: any = await handlesService
    //                     .getConnectedAccounts(
    //                         props?.userData?.influencer_id
    //                     )

    //                 if (getConnectedAccountsResponse?.data?.rows.length > 0) {
    //                     const connectedAccountData =
    //                         getConnectedAccountsResponse?.data?.rows.filter(
    //                             (filter: any) => filter.account_type === 'Primary' && filter.token_status === 'Connected');

    //                     if (connectedAccountData?.length > 0) {
    //                         // setAccount(getConnectedAccountsResponse?.data?.rows);
    //                         // setFacebookAccounts(getConnectedAccountsResponse?.data?.rows);
    //                         // setValue(
    //                         //     connectedAccountData[0]?.ig_id
    //                         // );
    //                         // setResponseUsernameIG('');
    //                         // setUserName('');
    //                         // const ig_id = connectedAccountData[0]?.ig_id;

    //                     // Call the Facebook API to get permissions
    //                     // const permissionsApiUrl = `https://graph.facebook.com/me/permissions?access_token=${response.accessToken}`;
    //                     // const permissionsResponse = await fetch(permissionsApiUrl);
    //                     // const permissionsData = await permissionsResponse.json();

    //                     // if (permissionsData?.data) {
    //                     //     // Send ig_id and permissions data to backend for further processing
    //                     //     const permissionsUpdateResponse: any = await handlesService.updateIgPermissions({
    //                     //         ig_id,
    //                     //         permissions: permissionsData.data
    //                     //     });

    //                     //     if (permissionsUpdateResponse?.data?.status) {
    //                             // console.log('Permissions update response', permissionsUpdateResponse);
    //                             // toast.success(permissionsUpdateResponse.data.status.message);
    //                         // } else {
    //                             // toast.error(permissionsUpdateResponse?.data?.message || 'Failed to update permissions');
    //                         // }
    //                     // }
    //                         getFacebookAccounts();
    //                     } else {
    //                         setOpen(true);
    //                         setAccountSwitchButton('Continue');
    //                         setChoosePrimary(getConnectedAccountsResponse?.data?.rows);
    //                         setLoadingIg(false);
    //                     }
    //                 }
    //             } else {
    //                 toast.error(facebookHandlesResponse.info)
    //             }
    //             updateUserReducer();
    //         } else {
    //             setLogin(false);
    //         }
    //     } catch (error) {
    //         console.log('Error responseFacebook', error);
    //     } finally {
    //         setLoadingIg(false);
    //     }

    // }


    async function faceBookAction(e?: any) {

        try {
            setLoadingIg(true);
            // if(username != responseUsernameIG){
            // const res: any = await handlesService.checkIgHandle({
            //     username: username,
            //     influencer_id: props?.userData?.influencer_id,
            // });

            // if (res?.status === gtUtil.Constants.SUCCESS) {
            //     if (res?.info === 'Account exists') {
            //         setSocialRestrictionPopup_IG(true);
            //         setResponseUsernameIG(res.data.rows[0].username);
            //         return;
            //     }
            // }}
            // if (res?.status !== gtUtil.Constants.SUCCESS) {
            //     if (res?.info === 'This account already exists.') {
            //         setSocialRestrictionPopup_IG(true);
            //         setResponseUsernameIG(res.data.rows[0].username);
            //         return;
            //     }
            // }}
            const getPublicData = await publicService.instagramVerify(username);
            if (getPublicData?.status === 1) {
                set_username_verify(username);
                // console.log('getPublicData',getPublicData)
                // setInfluencerData(getPublicData.data);
                // localService.setStorageItem('influencerData', JSON.stringify(getPublicData.data))
                setFaceBookButton(false);
                setLoadingIg(false);
                toast.success('Username Verified');
            } else {
                setLoadingIg(false);
                toast.success('Username not verified, This is not a Business/Creator account');
            }

        } catch (err) {
            console.log('Error faceBookAction ', err);
            setLoadingIg(false);
        }finally{
            setLoadingIg(false);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPreviousValue(value);
        setValue(event.target.value);
        if (accountSwitchButton !== 'Continue') {
            setOpenSwitchAccount(true);
        } else {
            setValue((event.target as HTMLInputElement).value);
        }
    };

    const handleSwitchPrimaryAccount = () => {
        const data = {
            ig_id: value,
            influencer_id: props?.userData?.influencer_id,
        };
        handlesService
            .setInstagramPrimaryAccount(data)
            .then((res: any) => {
                if (res?.data?.status?.status) {
                    setValue(value);
                    setOpenSwitchAccount(false);
                    localService.toastify(
                        'Account has been switched',
                        'success',
                        1000
                    );
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const handleSetAccount = () => {
        if (value !== '') {
            const data = {
                ig_id: value,
                influencer_id: props?.userData?.influencer_id,
            };

            handlesService
                .setInstagramPrimaryAccount(data)
                .then((res: any) => {
                    if (res?.data?.status?.status) {
                        setOpen(false);
                        setAccountSwitchButton('');
                        setAccount(choosePrimary);
                        setFacebookAccounts(choosePrimary);
                        setConnectButton('Reconnect');
                        setDisconnectButton(false);
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
            setOpen(false);
        }
    };

    const handleFacebookDisconnect = () => {
        const isValidRemark = remark.length > 0
        const isValidRemarkDescription = formValidation.GetNameValidate(remarkDescription).status;

        if (!isValidRemark) {
            localService.toastify("Please select a valid reason", "error", 1000);
            return;
        }
        
        if (remark === "Other" && !isValidRemarkDescription) {
            setFormError((prev) => ({
                ...prev,
                remarkDescription: { status: false, message: "This Field is required" },
            }));
            return;
        }
        const data = {
            username: facebookAccounts[0]?.username,
            influencer_id: props?.userData?.influencer_id,
            remark: remark,
            remark_description:remarkDescription
        };
        handlesService.disconnectFacebookAccount(data).then((res: any) => {
            if (res?.status === gtUtil.Constants.SUCCESS) {
                toast.success(res?.info);
                setAccount([]);
                setFacebookAccounts([]);
                setOpenModal(false)
                setRemark('')
                setRemarkDescription('')
                setOtherRemark(false)
                setConnectButton('Connect');
                setFaceBookButton(true);
                updateUserReducer();
            } else {
                setOpenModal(false)
                setRemark('')
                setRemarkDescription('')
            }
        });
    };

    const handleInstagramDisconnect = () => {
        const isValidRemark = remark.length > 0
        const isValidRemarkDescription = formValidation.GetNameValidate(remarkDescription).status;

        if (!isValidRemark) {
            localService.toastify("Please select a valid reason", "error", 1000);
            return;
        }
        
        if (remark === "Other" && !isValidRemarkDescription) {
            setFormError((prev) => ({
                ...prev,
                remarkDescription: { status: false, message: "This Field is required" },
            }));
            return;
        }
        const data = {
            username: instagramAccount[0]?.username,
            influencer_id: props?.userData?.influencer_id,
            remark: remark,
            remark_description:remarkDescription
        };
        handlesService.disconnectInstagramAccount(data).then((res: any) => {
            if (res?.status === gtUtil.Constants.SUCCESS) {
                toast.success(res?.info);
                setOpenModal(false)
                setRemark('')
                setRemarkDescription('')
                setOtherRemark(false)
                updateUserReducer();
                setIsInstagramAccount(false)
                setConnectButtonInstagram('Connect')
                setDisconnectButtonInstagram(true);
                setInstagramAccount([]);
            } else {
                setIsInstagramAccount(false)
                setOpenModal(false)
                setRemark('')
                setRemarkDescription('')
            }
        });
    };

    const handleGoogleDisconnect = () => {
        const isValidRemark = remark.length > 0
        const isValidRemarkDescription = formValidation.GetNameValidate(remarkDescription).status;

        
        if (!isValidRemark) {
            // setFormError((prev) => ({
            //     ...prev,
            //     remark: { status: false, message: "Please select a reason" },
            // }));
                localService.toastify("Please select a valid reason", "error", 1000);
            return;
        }
        
        if (remark === "Other" && !isValidRemarkDescription) {
            setFormError((prev) => ({
                ...prev,
                remarkDescription: { status: false, message: "This Field is required" },
            }));
            return;
        }

        const yt_id = googleaccount[0]?.yt_id;
        const data = {
            influencer_id: props?.userData?.influencer_id,
            yt_id: yt_id,
            remark: remark,
            remark_description:remarkDescription
        };
        handlesService
            .disconnectGoogleAccount(data)
            .then((res: any) => {
                if (res?.status === gtUtil.Constants.SUCCESS) {
                    toast.success(res?.info);
                    setGoogleAccount([]);
                    setDisconnectButtonGoogle(true);
                    setOpenModal(false)
                    setRemark('')
                    setRemarkDescription('')
                    setIsGoogleAccount(false)
                    setOtherRemark(false)
                    setConnectButtonGoogle('Connect');
                    updateUserReducer();
                } else {
                    setIsGoogleAccount(false)
                    setOpenModal(false)
                    setRemark('')
                    setRemarkDescription('')
                }
            })
            .catch((err: any) => {
                console.log(err);
            });
    };
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down('sm')
    );
    const drawerAnchor = isSmallScreen ? 'bottom' : 'right';
    const handleAddressPopupClose = () => {
        setAddAddressPopupOpen(false);
    };
    const handleOpenAddressPopup = (data: any) => {
        setAddAddressPopupOpen(true);

    };
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleGuideDrawer = () => {
        setDrawerOpen(true);
    };

    const handleStateUpdate = (value: any) => {
        setUserName(value);
        setVerifyUserName(!value);
    };

    const handlePaste = (e: any) => {
        const pastedText = e.clipboardData.getData('Text');
        handleStateUpdate(pastedText); // Or handle the pasted text as needed
    };

    const handleInput = (e: any) => {
        const inputValue = e.target.value;
        handleStateUpdate(inputValue); // Update the state or handle input
        setUserName(inputValue); // Set the username
        inputValue ? setVerifyUserName(false) : setVerifyUserName(true); // Enable/disable button
    };
    // useEffect(() => {
    //     setVerifyUserName(!username);
    // }, [username]);

    // To Set Remark Description and handle error field
    const handleRemarkDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = (event.target as HTMLInputElement).value;
        setRemarkDescription(selectedValue);
        const isValidRemarkDescription = formValidation.GetNameValidate(selectedValue).status;
        
        if (remark === "Other" && !isValidRemarkDescription) {
            setFormError((prev) => ({
                ...prev,
                remarkDescription: { status: false, message: "This Field is required" },
            }));
            // return;
        } else {
            setFormError((prev) => ({
                ...prev,
                remarkDescription: { status: true, message: "" },
            }));
        }
        // setFormData({ ...formData, reason: (event.target as HTMLInputElement).value });
        // validateForm();
    };

      const onSelectChange = async (data:any) => {
        try{
            setRemark(data);
            if(data == "Other"){
                setOtherRemark(true);
            }else{
                setOtherRemark(false);
                setRemarkDescription("");
            }
              
        } catch (error) {
              console.log(`onSelectChange error :: ${JSON.stringify(error)}`);
        }
      };

    const loginGoogleyt = async () => {
        try {
            const response = await handlesService.googleConnected();
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                const popup = window.open(
                    response.data.url,
                    "GoogleOAuthPopup",
                    "width=500,height=600,left=100,top=100"
                );
    
                if (!popup) {
                    console.error("Popup blocked by the browser.");
                    return;
                }
      
                // Listen for messages from the popup
                const messageListener = async (event: MessageEvent) => {
                    if (event.origin !== window.location.origin) return;
      
                    if (event.data?.type === 'social-auth-success') {
                        const authCode = event.data.code;

                        try {
    
                            // Send auth code to backend
                            set_social_loader(true);
    
                            // Send auth code to backend
                            const tokenResponse = await handlesService.postGoogleConnected({
                                code: authCode,
                                influencer_id: props.userData.influencer_id
                            });
    
                            if (tokenResponse.status === gtUtil.Constants.SUCCESS) {
                                updateUserReducer()
                                getYoutubeAccounts();
                                set_social_loader(false);
                            } else {
                                if(tokenResponse.info == 'Social account already connected to the other account.'){
                                    set_yt_data(tokenResponse.data)
                                    set_socialRestrictionPopup(prevState => ({
                                        ...prevState,
                                        youtube: true 
                                    }));
                                }
                            }

                        } catch (error) {
                            console.error("Error processing Youtube auth response:", error);
                            toast.error("Youtube authentication failed.");
                        } finally {
                            // Remove event listener after handling response
                            set_social_loader(false);
                            window.removeEventListener("message", messageListener);
                        }

                    }
                };

                window.addEventListener("message", messageListener);
            }
        } catch (error) {
            console.error("Login Error:", error);
            toast.error("Google Connect failed");
        }finally{
            set_social_loader(false);
        }
    };
    

    const instagramConnect = async () => {
        try {
            const response = await handlesService.instagramConnect();
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                // Open Instagram OAuth in a popup window
                const popup = window.open(
                    response.data.url, // Ensure this is correct
                    "InstagramOAuthPopup",
                    "width=500,height=600,left=100,top=100"
                );
    
                if (!popup) {
                    console.error("Popup blocked by the browser.");
                    return;
                }
        
                // Message listener for receiving auth code
                const messageListener = async (event: MessageEvent) => {
                    if (event.origin !== window.location.origin) return;
        
                    if (event.data?.type === 'social-auth-success') {
                        const authCode = event.data.code;
        
                        try {
                            set_social_loader(true);
                            // Send auth code to backend
                            const response = await handlesService.postInstagramConnect({
                                code: authCode,
                                influencer_id: props.userData.influencer_id, // Use the correct ID
                            });
    
                            if (response.status === gtUtil.Constants.SUCCESS) {
                                updateUserReducer()
                                getInstagramAccounts();
                                set_social_loader(false);
                            } else {
                                if(response.info == 'Social account already connected to the other account.'){
                                    set_ig_data(response.data)
                                    set_socialRestrictionPopup(prevState => ({
                                        ...prevState,
                                        instagram: true 
                                    }));
                                }
                            }
    
                        } catch (error) {
                            console.error("Error processing Instagram auth response:", error);
                            toast.error("Instagram authentication failed.");
                        } finally {
                            // Remove event listener after handling response
                            set_social_loader(false);
                            window.removeEventListener("message", messageListener);
                        }
                    }
                };
                // Attach event listener
                window.addEventListener("message", messageListener);
            } else {
                console.error("Error fetching Instagram OAuth URL:", response.info);
                toast.error("Failed to get Instagram authentication URL.");
            }
        } catch (error) {
            console.error("Instagram Login Error:", error);
            toast.error("Instagram login failed");
        }finally{
            set_social_loader(false);
        }
      };
    

    const fb_instagramConnect = async () => {
        try {

            if (!username_verify) {
                console.error("Username is required for Facebook authentication.");
                toast.error("Username is required.");
                return;
            }
                const response = await handlesService.fb_instagramConnect();
            
                if (response.status === gtUtil.Constants.SUCCESS) {
                    // Open Instagram OAuth in a popup window
                    const popup = window.open(
                        response.data.url, // Ensure this is correct
                        "FacebookOAuthPopup",
                        "width=500,height=600,left=100,top=100"
                    );
        
                    if (!popup) {
                        console.error("Popup blocked by the browser.");
                        return;
                    }
          
                    // Message listener for receiving auth code
                    const messageListener = async (event: MessageEvent) => {
                        if (event.origin !== window.location.origin) return;
          
                        if (event.data?.type === 'social-auth-success') {
                            const authCode = event.data.code;
          
                            try {
                                set_social_loader(true);
        
                                // Send auth code to backend
                                const tokenResponse = await handlesService.postFacebookConnect({
                                    code: authCode,
                                    influencer_id: props.userData.influencer_id, // Use the correct ID
                                    username: username_verify
                                });
        
                                if (tokenResponse.status === gtUtil.Constants.SUCCESS) {
                                    updateUserReducer()
                                    getFacebookAccounts();
                                    set_social_loader(false);
                                } else {
                                    // console.error("Failed to get fb access token:", tokenResponse);
                                    if(tokenResponse.info == 'Social account already connected to the other account.'){
                                        set_ig_data(tokenResponse.data)
                                        set_socialRestrictionPopup(prevState => ({
                                            ...prevState,
                                            instagram: true 
                                        }));
                                    }
                                }
        
                            } catch (error) {
                                console.error("Error processing Facebook auth response:", error);
                                toast.error("Facebook authentication failed.");
                            } finally {
                                // Remove event listener after handling response
                                set_social_loader(false);
                                window.removeEventListener("message", messageListener);
                            }
                        }
                    };
          
                    // Attach event listener
                    window.addEventListener("message", messageListener);
                } else {
                    console.error("Error fetching Facebook OAuth URL:", response.info);
                    toast.error("Failed to get Facebook authentication URL.");
                }
        } catch (error) {
            console.error("Facebook Login Error:", error);
            toast.error("Facebook Connect failed.");
        }finally{
            set_social_loader(false);
        }
    };

    return (
        <>
            {/* <InfluencerPersonalInfo /> */}
            {social_loader ?
                <ContentLoading width='150' height='150' /> :
                <><div className=' _influencer_social_connect_main_container'>
                <div className='list-section-content rounded'>
                    <div className='row align-items-center'>
                        <div className='d-flex align-items-center'>
                            <strong className='pe-1'>Connect Social Channel</strong> (atleast one)
                        </div>
                        <p className='fs-14 mb-0'>
                            Connect to generate your social channel profile on
                            ClanConnect for brands to view and shortlist your profile
                            for their campaigns.
                        </p>
                        <p className='mb-0'><button className='btn btn-text-link'
                            onClick={handleGuideDrawer}>
                            Guide to connect</button></p>

                    </div>
                </div>

                <div className='row'>
                    <Grid container className='d-flex flex-column flex-lg-row'>
                        <Grid item xs>
                            <strong className="mb-2 d-block">Connect Instagram via Facebook</strong>
                            <div className="col _influencer_social_connect_facebook_icon">
                                <Card>
                                    {facebookAccounts.length > 0 && (
                                        <div className="d-flex _influencer_social_connect-header">
                                            {loadingIg ? (
                                                <Skeleton variant="circular" height={40} width={40} />
                                            ) : (
                                                <img
                                                    src={
                                                        facebookAccounts[0]?.fb_profile_image_url ||
                                                        DefaultUserProfile.influencer_male_default
                                                    }
                                                    alt="fb_profile_pic"
                                                    className="_influencer_social_connect-img"
                                                />
                                            )}
                                            <span className="d-flex flex-column">
                                                {loadingIg ? (
                                                    <Skeleton height={18} width={18} />
                                                ) : (
                                                    <FacebookIcon />
                                                )}
                                                {loadingIg ? <Skeleton height={36} /> : <>{facebookAccounts[0]?.fb_name}</>}
                                            </span>
                                        </div>
                                    )}

                                    <div className="connected-account-buttons">
                                        {facebookAccounts.length === 0 && (
                                            <div className="connected-account-input d-flex mb-2">
                                                <TextField
                                                    className="influencer_personal_info"
                                                    size="small"
                                                    fullWidth
                                                    name="username"
                                                    type="text"
                                                    label="Instagram username"
                                                    placeholder="e.g., virat.kohli"
                                                    variant="outlined"
                                                    onPaste={handlePaste}
                                                    onInput={handleInput}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const username = e.target.value;
                                                        setUserName(username);
                                                        setVerifyUserName(!username);
                                                    }}
                                                />
                                                <span style={{ color: "red" }}>{userNameError}</span>
                                                <Button
                                                    variant="outlined"
                                                    className="btn ms-2"
                                                    onClick={faceBookAction}
                                                    disabled={verifyUserName}
                                                >
                                                    Verify
                                                </Button>
                                            </div>
                                        )}

                                        {facebookAccounts.length == 0 && (
                                            loadingIg ? (
                                                <Skeleton variant="rounded" height={40} width={102} />
                                            ) : (
                                                <Button
                                                    variant="outlined"
                                                    className="btn me-2"
                                                    color="error"
                                                    onClick={() => fb_instagramConnect()}
                                                    disabled={faceBookButton}
                                                >
                                                    Connect
                                                </Button>
                                            )
                                        )}
                                    </div>

                                    {loadingIg && <Loading width="100" height="100" />}
                                </Card>
                            </div>
                        </Grid>

                        <Divider
                            orientation='vertical'
                            flexItem
                            className='px-4'
                        >
                            OR
                        </Divider>
                        <Grid item xs>
                            <strong className="mb-2 d-block">Connect YouTube via Google</strong>
                            <Card>
                                <div className="google-accounts">
                                    {googleaccount.length > 0 && (
                                        googleaccount.map((data: any) => (
                                            <div key={data?.id} className="d-flex _influencer_social_connect-header">
                                                {loadingYt ? (
                                                    <Skeleton variant="circular" height={40} width={40} />
                                                ) : (
                                                    <img
                                                        src={data?.profile_image_url}
                                                        alt="YouTube Profile Pic"
                                                        className="_influencer_social_connect-img"
                                                    />
                                                )}
                                                <span className="d-flex flex-column">
                                                    {loadingYt ? (
                                                        <Skeleton variant="rectangular" height={20} width={20} />
                                                    ) : (
                                                        <GoogleIcon />
                                                    )}
                                                    {loadingYt ? <Skeleton height={30} /> : <>{data?.title}</>}
                                                </span>
                                            </div>
                                        ))
                                    ) }
                                </div>

                                <div className="connected-account-buttons">
                                    {googleaccount.length == 0 && (
                                        <Button
                                            variant="outlined"
                                            className="btn btn-outline-primary"
                                            onClick={() => loginGoogleyt()}
                                            disabled={loadingYt}
                                        >
                                            Connect
                                        </Button>
                                    )}
                                </div>

                                {loadingYt && <Loading width="100" height="100" />}
                            </Card>
                        </Grid>

                        <Divider
                            orientation='vertical'
                            flexItem
                            className='px-4'
                        >
                            OR
                        </Divider>
                        <Grid item xs>
                            <strong className='mb-2 d-block'>Connect Instagram</strong>
                            <Card>
                                <div className="google-accounts">
                                    {instagramAccount.length > 0 &&
                                        instagramAccount.map((data: any, index: number) => (
                                            <div key={index} className="d-flex _influencer_social_connect-header">
                                                {loadingInstagram ? (
                                                    <Skeleton variant="circular" height={40} width={40} />
                                                ) : (
                                                    <img
                                                        src={data?.profile_image_url || "default_profile.png"} // Fallback image
                                                        alt="Instagram-profile-pic"
                                                        className="_influencer_social_connect-img"
                                                    />
                                                )}
                                                <span className="d-flex flex-column">
                                                    {loadingInstagram ? (
                                                        <Skeleton variant="rectangular" height={20} width={20} />
                                                    ) : (
                                                        <InstagramIcon />
                                                    )}
                                                    {loadingInstagram ? (
                                                        <Skeleton height={30} />
                                                    ) : (
                                                        <>{data?.insta_name || "Unknown User"}</> // Fallback text
                                                    )}
                                                </span>
                                            </div>
                                        ))}
                                </div>

                                <div className="connected-account-buttons">
                                    {instagramAccount.length === 0 && ( // Safe comparison
                                        <Button
                                            variant="outlined"
                                            className="btn btn-outline-primary"
                                            onClick={instagramConnect}
                                        >
                                            Connect
                                        </Button>
                                    )}
                                </div>

                                {loadingInstagram && <Loading width="100" height="100" />}
                            </Card>


                        </Grid>
                    </Grid>
                </div>
                {socialRestrictionPopup.youtube &&
                    <SocialConnectRestriction open={socialRestrictionPopup.youtube} data = {yt_data} onClose={handleCloseSocialPopup_YT} continueConnect={handleContinueConnect_YT} />
                }

                {socialRestrictionPopup.instagram &&
                    <SocialConnectRestriction open={socialRestrictionPopup.instagram} data = {ig_data} onClose={handleCloseSocialPopup_IG} continueConnect={handleContinueConnect_IG} />
                }

                <div >
                    <p style={{ fontWeight: 700 , fontSize: 18}}>
                        Join our Community
                    </p>
                    <p>
                        Want to stay in the loop with our latest campaigns and offers? Join our community!
                    </p>
                    <p> <a className="" href={props.userData.env.WHATSAPP_COMMUNITY} target="_blank">Click Here</a>{' '}to join{' '}<strong>WhatsApp</strong>{' '}community</p>
                    <p><a className="" href={props.userData.env.TELEGRAM_COMMUNITY} target="_blank">Click Here</a>{' '}to join{' '}<strong>Telegram</strong>{' '}community</p>
                </div>

            </div>
            <Dialog
                open={open}
                fullWidth
                className='select-account-dialog'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {'Select Primary Account'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <FormControl className='w-100'>
                            <RadioGroup
                                aria-labelledby='demo-controlled-radio-buttons-group'
                                name='controlled-radio-buttons-group'
                                value={value}
                                onChange={handleChange}
                            >
                                {choosePrimary.map((data: any) => {
                                    return (
                                        <div className='d-flex notification-checklist w-100 align-items-center'>
                                            <FormControlLabel
                                                value={data?.ig_id}
                                                control={<Radio />}
                                                label=''
                                                className='me-0'
                                            />
                                            <img
                                                className='_influencer_social_connect-img select-account-image'
                                                src={data?.profile_image_url}
                                                alt='profile'
                                            ></img>
                                            <span>{data?.username}</span>
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSetAccount} autoFocus>
                        {accountSwitchButton}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSwitchAccount}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {'Switch Account ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to switch account.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleSwitchPrimaryAccount} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            {createPortal(
                <GenericSwipeableDrawer
                    className='guide-to-connect'
                    drawerAnchor={drawerAnchor}
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    onOpen={handleDrawerOpen}
                    title='Guide to Connect'
                    content={<GuideToConnectContent />}
                />, document.body)}
            <CommonPopUp
                modalTitle="Disconnect"
                modalContent=""
                openModal={openModal}
                onClose={() => onClose(false)}
                className="modal-large mx-auto"
            >
                <div className=''>
                    <div>Your Social Account <img className='_influencer_social_connect-img' src={
                        isInstagramAccount
                            ? instagramAccount[0]?.profile_image_url || DefaultUserProfile.influencer_unknown_default
                            : isGoogleAccount
                                ? googleaccount[0]?.profile_image_url || DefaultUserProfile.influencer_unknown_default
                                : facebookAccounts[0]?.fb_profile_image_url || DefaultUserProfile.influencer_unknown_default
                    }
                    />{ <span className='_influencer_social_connect-title'>
                        {isInstagramAccount
                            ? instagramAccount[0]?.insta_name || ''
                            : isGoogleAccount
                                ? googleaccount[0]?.title || ''
                                : facebookAccounts[0]?.fb_name || ''}
                    </span>} will be disconnected.</div>
                    <div className='my-2'>This may significantly reduce your chances to get shortlisted for campaigns as brands won't be able to see your updated profile analytics.</div>
                    <div className='mb-2'>Are you sure you want to Disconnect?</div>
                    <FormControl fullWidth size="small" className="mt-2">
                        <FormLabel id="demo-radio-buttons-group-label" style={{fontSize:"14px"}}>Select Reason*</FormLabel>
                        {formError.remark.message && <div style={{ color: 'red', marginTop: '8px' }}>{formError.remark.message}</div>}
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            id="gender_id"
                            value={remark}
                            onChange={(e) => {
                                onSelectChange(e.target.value);
                            }}
                        >
                            <FormControlLabel value="Not interested anymore" control={<Radio className='py-0' size='small'/>} label="Not interested anymore" />
                            <FormControlLabel value="Feeling insecure" control={<Radio className='py-0' size='small'/>} label="Feeling insecure" />
                            <FormControlLabel value="Can't see my profile" control={<Radio className='py-0'size='small' />} label="Can’t see my profile" />
                            <FormControlLabel value="Other" control={<Radio className='py-0' size='small' />} label="Other" onClick={() => setOtherRemark(true)} />
                            {otherRemark && (
                                <div>
                                    <TextField
                                        className="w-100"
                                        id="remarkDescription"
                                        name="remarkDescription"
                                        multiline
                                        rows={4}
                                        value={remarkDescription}
                                        error={!formError.remarkDescription.status}
                                        helperText={formError.remarkDescription.message}
                                        onChange={handleRemarkDescription}
                                        onBlur={() => {
                                            setFormError({
                                              ...formError,
                                              remarkDescription: formValidation.GetNameValidate(
                                                remarkDescription
                                              ),
                                            });
                                        }}
                                    />
                                    {/* {formError.remarkDescription.message && <div style={{ color: 'red', marginTop: '8px' }}>{formError.remarkDescription.message}</div>} */}
                                </div>
                            )}
                        </RadioGroup>
                    </FormControl>
                    <div className='popup-footer'>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className="btn btn-outline-primary btn-mui ms-2"
                                    onClick={() => onClose(true)}
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="btn btn-primary btn-mui  ms-2"
                                    onClick={() => 
                                        isInstagramAccount
                                          ? handleFeedbackConfirmationInstagram()
                                          : isGoogleAccount
                                          ? handleFeedbackConfirmationGoogle()
                                          : handleFeedbackConfirmationFacebook()
                                      }
                                    variant="outlined"
                                >
                                    Disconnect
                                </Button>
                            </div>
                        </div>
                </div>
            </CommonPopUp></>
            }
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateUser: (data: any) => dispatch(UserLogin(data)),
    }
}

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(InfluencerSocialConnect);

export { connectToRedux as InfluencerSocialConnect };
