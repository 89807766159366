import { Tooltip, Fade } from '@mui/material';
import React from 'react'
import InstagramIcon from '../../../../components/icons/instagram-icon';
import { YouTube } from '@mui/icons-material';
import IconYouTube from '../../../../components/icons/icon-youtube';

type Props = {
  campaignDetail?: any
  applyButtonClass?: any;
  disabledApplyBtn?: any
  userReducer?: any;
  applyCampaign?: any;
  handleOpen?: any;
  checkInfType?: boolean;
}


const CampaignDetailTitle = ({ applyButtonClass, campaignDetail, userReducer, disabledApplyBtn, applyCampaign, handleOpen, ...props }: Props) => {
  return <div className="title-top campaign-detail-title-top">
    <div className="title-top-title d-flex flex-md-row align-items-center">

      <h2 className='d-flex flex-column align-items-start'>
        {(campaignDetail?.reveal_brand_name === 'true') &&
          <span className='fs-14 fw-500'>{campaignDetail?.brand_name}</span>
        }
        <Tooltip
          title={
            campaignDetail &&
            campaignDetail.campaign_title
          }
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: 600,
          }}
          arrow
        >
          <span>{campaignDetail.campaign_title}</span>

        </Tooltip>

      </h2>


    </div>
    <div className="campaign-heading-tags">
      <div className="campaign-heading-tags-btm">
        <span className='badge badge-primary-outline  d-inline-flex me-2'>
          {campaignDetail.campaign_platform === 'Instagram' && (<InstagramIcon />)}
          {campaignDetail.campaign_platform === 'Youtube' && (<IconYouTube width="20px" height="20px" />)}
          <span className='ps-1'>{campaignDetail && campaignDetail.campaign_type}</span>
        </span>
        {
          (campaignDetail?.days_old < 1 && 
            (campaignDetail?.influencer_gender === "Any" || userReducer == null ||
              campaignDetail?.influencer_gender === userReducer?.influencer_ids[0]?.gender
            )
          ) 
          ? 
            (
              <button onClick={userReducer != null ? applyCampaign : handleOpen} className="btn btn-primary btn-mui btn-rounded btn-lg">
                {applyButtonClass}
              </button>         
            ) 
          : 
            (
              applyButtonClass === 'Apply Now' 
              ?
                <button className="btn btn-primary btn-mui btn-rounded btn-lg disabled ms-2">
                  {applyButtonClass}
                </button> 
              :
                <button onClick={userReducer != null ? applyCampaign : handleOpen} className="btn btn-primary btn-mui btn-rounded btn-lg ms-2">
                  {applyButtonClass}
                </button>
            )
        }   
      </div>
      {campaignDetail && campaignDetail?.days_old >= 1 && applyButtonClass === 'Apply Now' &&
        <span className='d-flex justify-content-start' style={{ color: '#E50000', marginTop:'10px;' }} >
          Application Closed
        </span>
      }
      {userReducer !== null && applyButtonClass === 'Apply Now' && (
            (campaignDetail?.influencer_gender !== "Any" && campaignDetail?.influencer_gender !== userReducer?.influencer_ids[0]?.gender) ||
            props?.checkInfType
          ) && (
            <span className='error'>
              You are not eligible to apply on this campaign.
            </span>
        )}

    </div>
  </div>
}

export default CampaignDetailTitle