import React, { useState, useEffect } from "react";
import { Autocomplete, Checkbox, createFilterOptions, FormHelperText, TextField } from "@mui/material";
import { connect } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { PublicService } from "../../../../../../core/services";
// import { InfluencerSelector } from "../../../../../../components/influencer-selector/influencer-selector";
import { DeliverablesComponent } from '../campaign-deliverables';
// import { CampaignDeliverables } from '../../../../../../core/models';
import { TransformDeliverables } from '../de-comman';
import { CreateCampaign } from '../../../../../../core/services/createcampaign.service';
import { TrashIcon2 } from '../../../../../../assets/icons/common-icons';
import "./campaign-influencer-all.scss";
import { Link } from "react-router-dom";
import { MenuPropsAutoComplete, MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect, MenuPropsSelectSmall } from "../../../../../../core/utility/style";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const publicService = new PublicService();
const transform: TransformDeliverables = new TransformDeliverables();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type IntialFormData = {
  deliverable: string;
  ig_static_image: number | null;
  quantity: number | null;
  influencer_type: number[];
};

const intialFormData: IntialFormData = {
  deliverable: '',
  ig_static_image: null,
  quantity: null,
  influencer_type: []
};

interface Props {
  open_all: any;
  updateOpen_all: (data: any) => void;
  platformType: 'instagram' | 'youtube';
  campaignReducer: any;
  UserReducer: any;
  getValidationStatus: any;
  getSubscriptionDetail: any;
}

const CampaingInfluencerAll: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const [categoryList, setCategoryList] = useState([]);
  const [location, setLocation] = useState<any>(null);
  const [locationValue, setLocationValue] = useState<string[]>([]);
  const [formDataCollection, setFormDataCollection] = useState<IntialFormData[]>([]);
  const [formData, setFormData] = useState<IntialFormData>(intialFormData);
  const [error, setError] = useState<number | null>();
  const [focCategoryError, setFocCategoryError] = useState('');
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    let temp: string[] = [];
    if (location) {
      if (props.open_all.location_id.length > 0) {
        const filterData = location.filter((item: any) =>
          props.open_all.location_id.includes(item.location_id)
        );
        for (let data of filterData) {
          temp.push(`${data.city}, ${data.state}, ${data.country}`);
        }

        setLocationValue(temp);
      }
    }
  }, [props.open_all, location]);


  useEffect(() => {

    /**
     * Get Genre Data
     */
    PublicService.getGenre()
      .then((res: any) => {
        setCategoryList(res.data.rows);
      })
      .catch((err) => {
        throw new Error(`Error get Genre :: ${err}`);
      });

    /**
     * Get Location Data 
     */
    async function getLocations() {
      try {
        const res = await publicService.getLocation();
        if (res.data.status) {
          setLocation(res.data.rows);
        } else {
          setLocation([]);
        }
      } catch (error) {
        setLocation([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {

      }
    }
    getLocations();
  }, []);

  useEffect(() => {
    props.updateOpen_all({ influencer_selector: transform.transformCollectionForDatabase(formDataCollection) })
  }, [formDataCollection]);

  useEffect(() => {
    /* Filter Data the data set according to platfrom like instagram, youtube and etc...  */
    let temp: any[] = [];
    let platfrom = '';
    switch ((props.platformType).toLowerCase()) {
      case 'instagram':
        platfrom = 'ig';
        break;
      case 'youtube':
        platfrom = 'yt';
        break;
      default:
        platfrom = 'ig';
        break;
    };

    CreateCampaign.getDeliverables(props.campaignReducer.createCampaign.campaign_id).then((res: any) => {
      if (res.data.status > 0) {
        for (let data of transform.transformDbForFormObj(res.data.rows)) {
          if (['digital_rights', 'available_for_exclusivity'].includes(data.deliverable)) {
            temp.push(data);
          } else if (data.deliverable.includes(platfrom)) {
            temp.push(data);
          }
        }
        setFormDataCollection(temp);
      }
    })

    // /* Validation Skip on digital rights and available_for_exclusivity these are prefill */
    // for (let data of transform.transformDbForFormObj(props.open_all.influencer_selector)) {
    //   if (['digital_rights', 'available_for_exclusivity'].includes(data.deliverable)) {
    //     temp.push(data);
    //   } else if (data.deliverable.includes(platfrom)) {
    //     temp.push(data);
    //   }
    // }
    // setFormDataCollection(temp)
  }, [])

  /**
   * Handles the change event of the category select input.
   * 
   * @param event - The select change event.
   */
  const handleChange = (
    event: SelectChangeEvent<typeof props.open_all.category>
  ) => {
    if(props.getSubscriptionDetail && props.getSubscriptionDetail.isBasicUser && ((props.campaignReducer.createCampaign.campaign_type).toLowerCase() === 'paid')){
      const {
        target: { value },
      } = event;
      if (value.length > 2) {
        props.getValidationStatus.category.status = false
        props.getValidationStatus.category.message = (
          <>
            You can select upto 2 categories.{' '}
            <Link to="/brand/settings/subscription">Upgrade</Link> to select more categories.
          </>
        );
        setSelectOpen(false);
      } else {
        props.updateOpen_all({ category: value });
      }
    }else{
      const {
        target: { value },
      } = event;
      props.updateOpen_all({ category: value });
    }
    
  };
  
  const handleOpen = () => {
    setSelectOpen(true);
  };

  const handleClose = () => {
    setSelectOpen(false);
  };

  
  useEffect(() => {

  }, [props.getValidationStatus])
  /**
   * Handles the location selection.
   * 
   * @param e - The event object.
   * @param v - The selected values.
   */
  const handleLocation = (e: any, v: any) => {
    let location_ids: any = [];
    v.forEach((res: string, index: number) => {
      let splitValue = res.split(", ");
      for (let data of location) {
        if (
          data.city === splitValue[0] &&
          data.state === splitValue[1] &&
          data.country === splitValue[2]
        ) {
          location_ids.push(data.location_id);
        }
      }
    });

    setLocationValue(v);
    props.updateOpen_all({ location_id: location_ids });
  };



  function handleAddDeliverables() {
    if(formData.deliverable === "ig_static"){
      if (formData.quantity === null || formData.influencer_type.length === 0 || formData.ig_static_image === null) {
        return;
      }
    }
    if (formData.deliverable === "" ||formData.quantity === null || formData.influencer_type.length === 0) {
      return;
    }
    setFormDataCollection([...formDataCollection, formData]);
    setFormData(intialFormData);
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });


  return (
    <div className="container-fluid camp_inf_main_container">
      <div className="row">
        <div className="col">
          <h5>Influencer Details</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">Gender</div>
        <div className="col-md-10">
          <FormControl className="d-flex flex-row mb-3">
            <RadioGroup
              defaultValue="any"
              onChange={(e) => props.updateOpen_all({ gender: e.target.value })}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                className="me-4"
                value="any"
                control={
                  <Radio
                    style={{
                      padding: 0,
                      paddingRight: "5px",
                    }}
                  />
                }
                label="Any"
              />
              <FormControlLabel
                className="me-4"
                value="male"
                control={
                  <Radio
                    style={{
                      padding: 0,
                      paddingRight: "5px",
                    }}
                  />
                }
                label="Male"
              />
              <FormControlLabel
                className="me-4"
                value="female"
                control={
                  <Radio
                    style={{
                      padding: 0,
                      paddingRight: "5px",
                    }}
                  />
                }
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Category</div>
        <div className="col-md-10">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-name-label">Category</InputLabel>
            <Select
              className="custom_category"
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={props.open_all.category}
              onChange={handleChange}
              onClose={handleClose}
              onOpen={handleOpen}
              open={selectOpen}
              input={
                <OutlinedInput
                  error={!props.getValidationStatus.category.status}
                  label="Category"
                />
              }
              renderValue={(selected) =>
                categoryList
                  .filter((cat:any) => selected.includes(cat.category_id))
                  .map((cat:any) => cat.category)
                  .join(", ")
              }
              MenuProps={MenuPropsSelectSmall}
            >
              {categoryList.length > 0 ? (
                categoryList.map((cat: { category_id: number; category: string }) => (
                  <MenuItem key={cat.category_id} value={cat.category_id}>
                    <Checkbox
                                    className='custom-checkbox'
                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                        checkedIcon={<CheckBox fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={props.open_all.category.includes(cat.category_id)}
                                    />
                    {/*  */}
                    {cat.category}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={1} value={""}>
                  No Data
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {!props.getValidationStatus.category.status && <span className="error">{props.getValidationStatus.category.message}</span>}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">Location</div>
        <div className="col-md-10">
          {/* <Autocomplete
            className="inputFieldSp"
            multiple
            size="small"
            disablePortal
            id="combo-box-demo"
            onChange={handleLocation}
            value={locationValue}
            options={
              location
                ? location?.map(
                  (res: {
                    id: number;
                    city: string;
                    state: string;
                    country: string;
                  }) => {
                    return `${res.city}, ${res.state}, ${res.country}`;
                  }
                )
                : ["No Data"]
            }
            ListboxProps={MenuPropsAutoCompleteNoCheckBox.PaperProps}
            renderInput={(params) => {
              return (
                <TextField name="location_id" error={!props.getValidationStatus.location.status} {...params} label="Location" />
              );
            }}
          />
          {!props.getValidationStatus.location.status && <span className="error">{props.getValidationStatus.location.message}</span>} */}
          <Autocomplete
            className="inputFieldSp"
            multiple
            size="small"
            disablePortal
            id="combo-box-demo"
            onChange={handleLocation}
            filterOptions={filterOptions}
            value={locationValue}
            // options={
            //   location
            //     ? location?.map(
            //       (res: {
            //         id: number;
            //         city: string;
            //         state: string;
            //         country: string;
            //       }) => {
            //         return `${res.city}, ${res.state}, ${res.country}`;
            //       }
            //     )
            //     : ["No Data"]
            // }
            options={
              location
              ? location?.map(
                (res: {
                  id: number;
                  city: string;
                  state: string;
                  country: string;
                }) => {
                  return `${res.city}, ${res.state}, ${res.country}`;
                }
              )
              : ["No Data"]
            }
            disableCloseOnSelect
            ListboxProps={MenuPropsAutoComplete.PaperProps}
            renderOption={(props: any, option: any) => {
              const label = option;
              const isSelected = locationValue.includes(option); // Check if option is selected
              return (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={isSelected}
                  />
                  {label}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField name="location_id" error={!props.getValidationStatus.location.status} {...params} label="Location" />
              );
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2">
          Deliverables
        </div>
        <div className="col">
          <DeliverablesComponent
            formDataCollection={formDataCollection}
            value={formData}
            deliverable_type={formData.deliverable}
            platform={props.platformType}
            onChange={(values: any) => setFormData(values)} />
            {!props.getValidationStatus.deliverables.status && <span className="error">{props.getValidationStatus.deliverables.message}</span>}
        </div>  
        <div className="col-auto">
          <button onClick={handleAddDeliverables} className="btn btn-primary">Add Deliverable</button>
        </div>
      </div>
      <div className="row justify-content-end">

        <div className="col-md-10 mt-3">
          {
            formDataCollection.map((data, index) => {
              let deliverable = <div className="row mb-3" key={index}>
                <div className="col">
                  <DeliverablesComponent
                    platform={props.platformType}
                    disableDeliverables={true}
                    disableNoOfImages={true}
                    formDataCollection={formDataCollection}
                    deliverable_type={data.deliverable}
                    value={data}
                    error={error === index && true}
                    onChange={(values: any) => {
                      if(values.influencer_type.length > 0){
                        let temp = [...formDataCollection];
                        temp[index] = values;
                        setFormDataCollection(temp);
                        setError(null);
                      }else{
                        setError(index);
                      }
                    }} />
                </div>
                <div className="col-auto">
                  <span 
                  onClick={() => {
                    let temp = [...formDataCollection];
                    temp.splice(index, 1);
                    setFormDataCollection(temp);
                  }}
                  >
                    <TrashIcon2 size={30} />
                  </span>
                </div>
              </div>

              return deliverable;
            }
            )
          }
        </div>
      </div>
      {/* <div className="row mb-3">
        <div className="col-md-2">Type:</div>
        <div className="col-md-10">
          <InfluencerSelector
            influencerSelectorData={props.open_all.influencer_selector}
            updateInfluencerSelectorData={(e) =>
              props.updateOpen_all({
                influencer_selector: {
                  ...props.open_all.influencer_selector,
                  ...e,
                },
              })
            }
            platformType={props.platformType + ""}
          />
        </div>
      </div> */}

      <div className="col">
        <FormControl className="row w-100 d-flex flex-row">
          {props.campaignReducer.createCampaign.campaign_type
            .toString()
            .toLocaleLowerCase()
            .includes("paid") && (
              <div className="col-md-2">
                <FormLabel id="id_rb-costing-only">
                  <span className="field_header" style={{marginLeft:"-11px", color:"#2b2b2b"}}> Costing Model </span>{" "}
                </FormLabel>
              </div>
            )}
          <div className="col-md-10">
            <RadioGroup
              defaultValue="individual"
              className="p-0"
              onChange={(e) =>
                props.updateOpen_all({
                  costing_module: e.target.value,
                })
              }
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="rb-costing-only"
            >
              {props.campaignReducer.createCampaign.campaign_type
                .toString()
                .toLocaleLowerCase()
                .includes("paid") && (
                  <div className="row w-100 mt-0">
                    <div className="d-flex flex-column col-md-6 input-w-helper">
                      <FormControlLabel
                        value="individual"
                        control={<Radio className="p-0 me-2" />}
                        label="Individual Deliverable Costing"
                      />
                      <FormHelperText>
                      <ul style={{listStyle:'inherit'}}>
                        <li>This model provides the cost for each deliverable individually.</li>
                        <li>It allows you to negotiate the price for each deliverable separately.</li>
                        <li>Ideal if you have not yet finalized the exact deliverables.</li>
                      </ul>
                    
                    </FormHelperText>
                    </div>
                    <div className="d-flex flex-column col-md-6 ">
                      <FormControlLabel
                        value="package"
                        control={<Radio className="p-0 me-2" />}
                        label="Package Costing"
                      />
                      {/* <span className="fs-14  input-w-helper-text">
                        This will allow you to get the cost of complete package.
                      </span> */}
                      <FormHelperText>
                      <ul style={{listStyle:'inherit'}}>
                      <li>This model offers a single package cost for all deliverables combined.</li>
                      <li>It enables you to negotiate the total price at the package level.</li>
                      <li>Best suited if you have finalised all the deliverables in advance.</li>
                      </ul>
                    
                    </FormHelperText>
                    </div>
                  </div>
                )}
            </RadioGroup>
          </div>
        </FormControl>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    getSubscriptionDetail: state.Subscription.subscription_detail
  };
};

const connectToRedux = connect(mapStateToProps, null)(CampaingInfluencerAll);

export { connectToRedux as CampaingInfluencerAll };
