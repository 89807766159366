import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../redux/action';
import dayjs from 'dayjs';
import { PublicService } from '../../../../../../core/services';
import { Methods } from '../../../../../../core/utility';
import { Link } from 'react-router-dom';
import IconGender from '../../../../../../components/icons/icon-gender';
import IconCategory from '../../../../../../components/icons/icon-category';
import IconLocation from '../../../../../../components/icons/icon-location';
import IconCalendar from '../../../../../../components/icons/icon-calendar';
import IconClock from '../../../../../../components/icons/icon-clock';
import { DummyCampaignImage } from '../../../../../../config/config';
import MaterialSymbol from '../../../../../../components/icons/material-symbol';
const methods = new Methods()
interface Props {
    UserReducer: any;
    loading: (data: any) => void;
    selectCampaign: any;
};

const publicService = new PublicService();


const BrandCampaignDetails: React.FC<Props> = (props: Props) => {
    const [campaignDetail, setCampaignDetail] = useState<any>(null);
    // const getlocation = useLocation();
    const myref: any = useRef(null);
    const productref: any = useRef(null);
    const [showAll, setShowAll] = useState(false);
    const influencersToShow = showAll
        ? (campaignDetail?.invited_influencers || [])
        : (campaignDetail?.invited_influencers?.slice(0, 5) || []);

    useEffect(() => {
        if (myref.current && campaignDetail) {
            myref.current.innerHTML = campaignDetail.campaign_description
            if (campaignDetail.hasOwnProperty('product_description')) {
                if (productref.current) {
                    productref.current.innerHTML = campaignDetail.product_description;
                }
            }
        }
    }, [myref.current, campaignDetail])
    useEffect(() => {
        setCampaignDetail(null);
        props.loading(true);
        publicService.influencerCampaignDetail(null, props?.selectCampaign?.campaign_id, null).then(
            (res: any) => {
                setCampaignDetail(res.data.rows);
                // if (res.data.rows.hasOwnProperty('product_description')) {
                //     debugger
                //     productref.current.innerHTML = res.data.rows.product_description
                // }
            }
        )
            .catch(error => console.log(`Campaign Detail Brand Side function failed : ${error.toString()}`))
            .finally(() => props.loading(false));
    }, [props]);
    const postedDate: Date | string = campaignDetail?.posted_at || '';
    const currentDate: Date = new Date();
    const daysDifference: number = methods.calculateDaysDifference(postedDate, currentDate);
    const getValidUrl = (url: string) => {
        if (!url) return "";
        return url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
    }; 
    return (
        <div className='inf-camp-detail'>
            {campaignDetail && <><div className='camp-detail-card'>
                <div className="camp-detail-card-left">
                    <figure className='camp-detail-img'>
                        <img
                            className='camp-cover-image w-100'
                            src={campaignDetail.campaign_cover_url ? campaignDetail.campaign_cover_url : DummyCampaignImage.default_campaign_image}
                            alt='cover Image'
                        />
                    </figure>
                </div>
                <div className="camp-detail-card-right">
                    <div className='camp-description'>
                        <span className='d-block color-blk-5d fs-14 mb-3'>Posted {`${daysDifference} days ago`}</span>
                        <strong className='camp-detail-card-sec-title'>Description</strong>
                        <div
                            id='shortDescription'
                            className=''
                            ref={myref}
                        ></div>
                    </div>
                </div>
            </div>
                {/* PRODUCT DETAIL SECTION FOR BARTER + (PAID + BARTER) */}
                {(campaignDetail.campaign_type + '')
                    .toLocaleLowerCase()
                    .includes('barter') && (
                        <div className='camp-detail-card grid-2-row'>
                            <strong className='camp-detail-card-sec-title'>Product Details</strong>
                            <div className='camp-detail-card-inner'>
                                <div className='camp-detail-card-left'>

                                    <div className='product-detail-info'>
                                        <div className='product-detail-img'>
                                            <img
                                                className='product-image'
                                                src={
                                                    campaignDetail.product_image_url
                                                }
                                                alt='product cover'
                                            />
                                        </div>
                                        <div className='product-detail-price-info'>
                                            <span>
                                                {
                                                    campaignDetail.product_name
                                                }
                                            </span>
                                            <span>
                                                Worth <strong>{
                                                    campaignDetail.product_cost
                                                }</strong>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className='camp-detail-card-right'>
                                    <div className='row'>
                                        {productref &&
                                            <div
                                                className='product-description'
                                                ref={productref}
                                            ></div>
                                        }
                                        {campaignDetail?.product_ref_lnk_1 && (<p><a target="_blank" rel="noopener noreferrer" href={getValidUrl(campaignDetail?.product_ref_lnk_1)}>{campaignDetail?.product_ref_lnk_1}</a></p>)}
                                        {campaignDetail?.product_ref_lnk_2 && (<p><a target="_blank" rel="noopener noreferrer" href={getValidUrl(campaignDetail?.product_ref_lnk_2)}>{campaignDetail?.product_ref_lnk_2}</a></p>)}
                                        {campaignDetail?.product_ref_lnk_3 && (<p><a target="_blank" rel="noopener noreferrer" href={getValidUrl(campaignDetail?.product_ref_lnk_3)}>{campaignDetail?.product_ref_lnk_3}</a></p>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                {/* END PRODUCT DETAIL SECTION FOR BARTER + (PAID + BARTER) */}
                {/* BUDGET SECTION STARTS */}
                {campaignDetail?.budget !== 0 &&
                <div className='camp-detail-card grid-1-col'>
                    <strong className='mb-0 camp-detail-card-sec-title d-flex'>Budget
                        <span className='ps-4 d-flex align-items-center'>
                            <MaterialSymbol className='fs-18' iconName='currency_rupee' fontweight='200' />
                            {campaignDetail?.budget}
                        </span>
                    </strong>
                </div>}
                {/* BUDGET SECTION ENDS */}
                {(campaignDetail.campaign_type !== 'Analytics' ) && 
                    <>{/* DELIVERABLE SECTION */}
                    <div className='camp-detail-card grid-1-col'>
                        <strong className='mb-3 d-block camp-detail-card-sec-title'>Deliverables</strong>
                        <div className='camp-detail-card-inner grid-3-col camp-detail-card-brand-side'>
                            {campaignDetail.deliverables &&
                                campaignDetail.deliverables.map(
                                    (
                                        data: any,
                                        index: number
                                    ) => {
                                        return (
                                            <div className='deliverable-list ' key={index}>
                                                <strong>{data?.influencer_type}</strong>
                                                <ul>
                                                    {data.deliverables.map(
                                                        (
                                                            res: any,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <li
                                                                    key={
                                                                        index
                                                                    }
                                                                >
                                                                    {
                                                                        res
                                                                    }
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        )
                                    })}
                        </div>
                        <div className='package-detail mt-4'>
                            <span className='fs-14'>Costing model: {campaignDetail?.campaign_cost_model}</span>
                        </div>
                    </div>
                    </>
                }
                {/* DELIVERABLE SECTION */}
                {/* INFLUENCER DETAILS */}
                {(campaignDetail.campaign_type !== 'Analytics') &&
                <div className='camp-detail-card grid-2-row'>
                    <strong className='camp-detail-card-sec-title'>Influencer Details</strong>
                    <div className='infl-det-list-sec'>
                        {campaignDetail.influencer_gender &&
                            <div className='infl-det-list'>
                                <div className='infl-det-list-icon'>
                                    <IconGender />
                                </div>
                                <div className='infl-det-list-content'>
                                    <strong>Gender </strong>
                                    <ul>
                                        <li>{campaignDetail.influencer_gender}</li>
                                    </ul>
                                </div>
                            </div>
                        }

                        {campaignDetail.categories &&
                            <div className='infl-det-list'>
                                <div className='infl-det-list-icon'>
                                    <IconCategory />
                                </div>
                                <div className='infl-det-list-content'>
                                    <strong>Category </strong>


                                    <ul>
                                        {campaignDetail && campaignDetail.categories && typeof campaignDetail.categories === 'string' && campaignDetail.categories.split(",").map((cate: string) => (
                                            <li key={cate}>
                                                {cate.includes(",") ? cate.split(" ").join(", ") : cate}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        }
                        <div className='infl-det-list'>
                            <div className='infl-det-list-icon'>
                                <IconLocation />
                            </div>
                            <div className='infl-det-list-content'>
                                <strong>Location </strong>
                                <ul>
                                    {campaignDetail?.group_locations &&
                                        campaignDetail?.group_locations.trim() !== "" ?
                                        (campaignDetail?.group_locations && typeof campaignDetail?.group_locations === 'string' && campaignDetail?.group_locations?.split(",").map((loc: string) => (
                                            <li key={loc}>
                                                {loc.includes(",") ? loc.split(" ").join(", ") : loc}
                                            </li>
                                        ))) : <li>All Locations</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        {campaignDetail.invited_influencers?.length > 0 && (
                            <div className='infl-det-list'>
                                <div className='infl-det-list-content'>
                                    <strong className='fs-14 font-weight-bold invited-title'>Invitees</strong>
                                    <ul>
                                        {influencersToShow.map((influencer: any, index: number) => (
                                            <li key={index}>
                                                {influencer.platform_type === 'Instagram' && <Link className='invited-influencer-link' target="_blank" to={`/brand/profile-insight?sm_id=${influencer.ig_id}&influencerId=${influencer.influencer_id}`}> {influencer.username}</Link>}
                                                {influencer.platform_type === 'Youtube' && <Link className='invited-influencer-link' target="_blank" to={`/brand/profile-insight-yt?sm_id=${influencer.yt_id}&influencerId=${influencer.influencer_id}`}> {influencer.title}</Link>}
                                            </li>
                                        ))}
                                        {campaignDetail.invited_influencers.length > 5 && !showAll && (
                                            <li className='more-button'>
                                                <span onClick={() => setShowAll(true)}>more...</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                }

                {/* END INFLUENCER DETAILS */}
                {/* REFERENCE LINK DETAILS */}
                {(campaignDetail?.reference_link_1 || campaignDetail?.reference_link_2 || campaignDetail?.reference_link_3) &&
                    <div className='camp-detail-card grid-2-row'>
                        <strong className='camp-detail-card-sec-title'>Reference Links</strong>
                        <div className='camp-detail-card-left'>
                            {campaignDetail?.reference_link_1 && (<p className='reference-link'><Link target="_blank" to={campaignDetail?.reference_link_1}>{campaignDetail?.reference_link_1}</Link></p>)}
                            {campaignDetail?.reference_link_2 && (<p className='reference-link'><Link target="_blank" to={campaignDetail?.reference_link_2}>{campaignDetail?.reference_link_2}</Link></p>)}
                            {campaignDetail?.reference_link_3 && (<p className='reference-link'><Link target="_blank" to={campaignDetail?.reference_link_3}>{campaignDetail?.reference_link_3}</Link></p>)}
                        </div>
                    </div>
                }

                {/* END REFERENCE LINK DETAILS */}
                {/* COMMENTS DETAILS */}
                {campaignDetail?.comments &&
                    <div className='camp-detail-card grid-2-row'>
                        <strong className='camp-detail-card-sec-title'>Comments</strong>
                        <div className='camp-detail-card-left'>
                            <p className='commemt'>{campaignDetail?.comments}</p>
                        </div>
                    </div>
                }
                {/* END COMMENTS DETAILS */}

                {/* CAMPAIGN LIVE DATE */}

                <div className='camp-detail-card grid-2-row'>
                    <div className='infl-det-list-sec proposal-dur-info'>
                        {campaignDetail.proposal_dt &&
                            <div className='infl-det-list'>
                                <div className='infl-det-list-icon'>
                                    <IconClock />
                                </div>
                                <div className='infl-det-list-content'>
                                    <strong>{dayjs(campaignDetail.proposal_dt).format('LL')} </strong>
                                    <ul>
                                        <li>Proposal Deadline</li>
                                    </ul>
                                </div>
                            </div>
                        }
                        {campaignDetail.go_live_dt &&
                            <div className='infl-det-list'>
                                <div className='infl-det-list-icon'>
                                    <IconCalendar />
                                </div>
                                <div className='infl-det-list-content'>
                                    <strong>{dayjs(campaignDetail.go_live_dt).format('LL')} </strong>


                                    <ul>
                                        <li>Campaign Live Date</li>
                                    </ul>
                                </div>
                            </div>
                        }


                    </div>
                </div>
                {/* END CAMPAIGN LIVE DATE*/}
            </>
            }
        </div >
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        selectCampaign: state.InfSelectCamp.campaign,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandCampaignDetails);

export { connectToRedux as BrandCampaignDetails };