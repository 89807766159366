import React, { useState } from "react";
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { Skeleton, Tooltip } from "@mui/material";
import { DefaultUserProfile } from "../../config/config";
import { Methods } from "../../core/utility";
import UpgradeBrandPopUp from "../popup/upgrade-brand-popup";
import { GridColDef } from '@mui/x-data-grid';
import CustomDataGrid from "./common-data-grid";
import { Link } from "react-router-dom";

interface TableProps {
  platformType: string;
  selectedInfluencer?: any;
  data?: any;
  loading?: boolean;
  total_records?: number;
  currentPage?: number;
  rowPerPage?: number;
  handleCheckbox?: (checked: boolean, data: any) => void;
  handleProfileView?: (sm_id: number, influencerId: number) => void;
  handleNavigate?: (id: number | string, influencerId: string | number) => void;
  setCurrentPage?: (e: number) => void;
  setRowPerPage?: (e: number) => void;
  getSubscriptionDetail?: any;
}

const youtubeFields = [
  {
    name: "yt_id",
    columnSize: "70px",
  },
  {
    name: "title",
    columnSize: "70px",
  },
  {
    name: "custom_url",
    columnSize: "200px",
    width: "200px",
    maxWidth: "200px",
  },
  {
    name: "subscriber_count",
    columnSize: "90px",
  },
  {
    name: "avg_views",
    columnSize: "70px",
  },
  {
    name: "video_count",
    columnSize: "130px",
  },
];

const youtubeDisplayName: Record<string, string> = {
  yt_id: " ",
  title: "Name",
  custom_url: "Channel Name",
  subscriber_count: "Subscribers",
  avg_views: "Avg. Views",
  video_count: "Total Videos",
};

const instagramFields = [
  {
    name: "ig_id",
    columnSize: "50px",
  },
  {
    name: "insta_name",
    columnSize: "200px",
    width: "200px",
    maxWidth: "200px",
  },
  {
    name: "username",
    columnSize: "200px",
  },
  {
    name: "followers",
    columnSize: "100px",
  },
  {
    name: "er",
    columnSize: "80px",
  },
  {
    name: "category",
    columnSize: "200px",
  },
  {
    name: "location",
    columnSize: "200px",
  },
];

const instagramDisplayName: Record<string, string> = {
  ig_id: " ",
  insta_name: "Name",
  username: "Handle Name",
  followers: "Followers",
  er: "ER",
  category: "Category",
  location: "Location",
};

const curatedInstagramFields = [
  {
    name: "ig_id",
    columnSize: "70px",
  },
  {
    name: "insta_name",
    columnSize: "200px",
    width: "200px",
    maxWidth: "200px",
  },
  {
    name: "username",
    columnSize: "200px",
  },
  {
    name: "followers",
    columnSize: "130px",
  },
  {
    name: "er",
    columnSize: "130px",
  },
  {
    name: "location",
    columnSize: "220px",
  },
];

const curatedInstagramDisplayName: Record<string, string> = {
  ig_id: " ",
  insta_name: "Name",
  username: "Handle Name",
  followers: "Followers",
  er: "ER",
  location: "Location",
};

const fieldDecider: Record<string, any[]> = {
  instagram: instagramFields,
  youtube: youtubeFields,
  curatedInstagram: curatedInstagramFields,
};

const aliesDecider: Record<string, Record<string, string>> = {
  instagram: instagramDisplayName,
  youtube: youtubeDisplayName,
  curatedInstagram: curatedInstagramDisplayName,
};

const methods: Methods = new Methods();

export const CommonTableComponent: React.FC<TableProps> = ({
  platformType,
  selectedInfluencer,
  data,
  loading,
  total_records = 0, // Default to 0 if undefined
  currentPage = 0,    // Default to 0 if undefined
  rowPerPage = 10,    // Default to 10 if undefined
  handleCheckbox = () => { }, // Default no-op function
  handleProfileView = () => { }, // Default no-op function
  handleNavigate = () => { }, // Default no-op function
  setCurrentPage = () => { }, // Default no-op function
  setRowPerPage = () => { },  // Default no-op function
  getSubscriptionDetail = () => { },  // Default no-op function
  ...props
}: TableProps) => {

  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openUpgradeBrandModal: false
  });

  function handleOpenUpgradeBrandModal(){
    setPopUps({
      openUpgradeBrandModal: true
    })
  }

  function handleClosePopUps() {
    setPopUps({
      openUpgradeBrandModal: false
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (getSubscriptionDetail.isBasicUser){
      handleOpenUpgradeBrandModal();
    }else{
      setRowPerPage(parseInt(event.target.value, 10))
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (getSubscriptionDetail.isBasicUser){
      handleOpenUpgradeBrandModal();
    }else{
      setCurrentPage(newPage);
    }
  };

const categoryColumn = platformType === 'instagram' ? {
  field: 'category',
  headerName: 'Category',
  cellClassName: 'category-cell',
  sortable: false,
  width: 250,
  disableColumnMenu: true,
  renderCell: (params:any) => (
    !loading ? (
      [params?.row?.catg_1, params?.row?.catg_2, params?.row?.catg_3, params?.row?.catg_4]
        .filter(Boolean)
        .join(', ')
    ) : (
      <Skeleton animation="wave" width={250} height={25} />
    )
  ),
} : null; // Return null if platformType is Instagram

const locationColumn = platformType === 'instagram' ? {
  field: 'location',
      headerName: 'Location',
      cellClassName: 'location-cell',
      sortable: false,
      width: 180,
      disableColumnMenu: true,
      renderCell: (params:any) => (
        <>
      {!loading ? (
       [params?.row?.city, params?.row?.state, params?.row?.country]
            .filter(Boolean)
            .join(', ')
      ) : (
        <Skeleton animation="wave" width={180 } height={25} />
      )}
        </>
      ),
} : null; 

const videoCountColumn = platformType === 'youtube' ? {
      field: 'video_count',
      headerName: "Total Videos",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params:any) => (
      !loading ? (
        <span> {params.row?.video_count}</span>
      ) : (
        <Skeleton animation="wave" width={100} height={25} />
      ))
    } : null;

const columns: GridColDef[] = [
  {
    field: "", 
      headerName: "",
      cellClassName:"checkbox-cell", 
      width: 50,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: false,
      hideable: false,
      renderCell: (params) => (
          <Checkbox
            className='p-0 d-flex justify-content-center' 
            id={`${params?.row?.ig_id}`}
            checked={selectedInfluencer?.some(
              (selected: any) =>
                JSON.stringify(selected) ===
                JSON.stringify(params?.row)
            )}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
              {(params?.row?.foc == 'true') ? handleOpenUpgradeBrandModal() : handleCheckbox(e.target.checked, params?.row)}
            }
          />
      ),
  },
   { 
      field: "profile_picture", 
      headerName: "Name", 
      width: 300,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      valueGetter: (params:any) => params.row?.platform_type === 'Instagram' ? params.row?.insta_name : params.row?.title  || "Unknown", // Provide a value for filtering
      renderCell: (params) => (
        
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        gap: "10px", 
        width: "100%" 
      }}>
        {/* Profile Picture */}
        {params.row.subscription_status && (
        !loading ? (
          <span className="position-relative discover-dots">
            <Tooltip
              arrow
              title={
                params.row.subscription_status === 'non_subscribed'
                  ? 'Non - subscriber'
                  : params.row.subscription_status === 'active'
                  ? 'Active subscription'
                  : 'Expired subscription'
              }
            >
              <span
                className={`discover_subscription_dot discover_subscription_dot_${params.row.subscription_status}`}
              ></span>
            </Tooltip>
          </span>
        ) : <Skeleton animation="wave" width={12} height={12} variant="circular" />
      )}
      {!loading ?
      <Link className="" to="#"
        onClick={() => 
          {
            (params?.row?.foc == 'true') 
            ?
              handleOpenUpgradeBrandModal()
            : 
              params?.row?.ig_id ? handleProfileView(params?.row?.ig_id, params?.row?.influencer_id) : handleProfileView(params?.row?.yt_id, params?.row?.influencer_id)
          }
        }
        >
        <img 
          src={(params.row?.gcp_profile_image_url ? params.row?.gcp_profile_image_url : params.row?.profile_image_url) || DefaultUserProfile?.influencer_unknown_default}
          alt="Profile" 
          style={{
            width: "40px", 
            height: "40px", 
            borderRadius: "50%", 
            objectFit: "cover",
          }} 
        />
        </Link>
        :  <Skeleton animation="wave" width={40} height={40} variant="circular" /> }
        {/* Instagram Name */}
        {!loading ?
        <span  
          onClick={() => 
            (params?.row?.foc == 'true') 
              ? handleOpenUpgradeBrandModal()
              : params?.row?.platform_type === 'Instagram' ?handleProfileView(params?.row?.ig_id, params?.row?.influencer_id) : handleProfileView(params?.row?.yt_id, params?.row?.influencer_id)
          } 
          style={{ 
            fontSize: "14px", 
            fontWeight: "400", 
            color: "#00aae5", 
            cursor: 'pointer', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
          }}>
            <Tooltip
              arrow
              title={
                params?.row?.platform_type === 'Instagram' ? params?.row?.insta_name : params?.row?.title
              }
            >
              <span>{params?.row?.platform_type === 'Instagram' ? params?.row?.insta_name : params?.row?.title  || "Unknown"}</span>
            </Tooltip>
          
        </span>
        : <Skeleton animation="wave" width={200}  variant="rectangular" /> }
      </div>
      ),
    },
    { field: 'username', headerName: platformType === 'instagram' ? "Handle Name" : "Channel Name", 
      width: platformType === 'instagram' ? 200 : 250, disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
      !loading ? (
        <span>
          {params?.row?.platform_type === 'Instagram' ? params?.row?.username : params?.row?.custom_url  || "Unknown"}
          </span>
      ) : (
        <Skeleton animation="wave" width={200} height={25} />
      ))},
    {
      field: 'followers',
      headerName: platformType === 'instagram' ? "Followers" : "Subscribers",
      width: platformType === 'instagram' ? 120 : 200,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      valueGetter: (params:any) => params.row?.platform_type === 'Instagram' ? params.row?.followers : params.row?.subscriber_count  || "Unknown", 
      renderCell: (params) => (
      !loading ? (
        <span>
        {params?.row?.platform_type === 'Instagram' ? methods.convertNumber(parseInt(params.row?.followers)) : methods.convertNumber(parseInt(params?.row?.subscriber_count))  || "Unknown"}
        
        </span>
      ) : (
        <Skeleton animation="wave" width={120} height={25} />
      ))
      
    },
    {
      field: 'er',
      headerName: platformType === 'instagram' ? "ER" : "Avg. Views",
      width: platformType === 'instagram' ? 100 : 200,
      disableColumnMenu: true,
      valueGetter: (params:any) => params.row?.platform_type === 'Instagram' ? params.row?.er : methods.convertNumber(parseInt(params?.row?.avg_views))  || "Unknown", 
      renderCell: (params) => (
      !loading ? (
        <span> {params?.row?.platform_type === 'Instagram' ? `${params.row?.er}%` : methods.convertNumber(parseInt(params?.row?.avg_views))}</span>
      ) : (
        <Skeleton animation="wave" width={100} height={25} />
      ))
    },
    ...(videoCountColumn ? [videoCountColumn] : []),
    ...(categoryColumn ? [categoryColumn] : []),
    ...(locationColumn ? [locationColumn] : []),
];

  return (
    <div className="infl-detail-table">
      {platformType === 'instagram' && Array.isArray(data) &&
        <CustomDataGrid disableColumnFilter rows={data?.map((row:any, index: number) => ({ ...row, id: index }))}  
      columns={columns} loading={loading}  
        />
      }
      {platformType === 'youtube' && Array.isArray(data) &&
                <CustomDataGrid disableColumnFilter rows={data?.map((row:any, index: number) => ({ ...row, id: index }))}  
      columns={columns} loading={loading}  
        />
              }
      
        <div className="data-grid-pagination">
          <TablePagination
            count={total_records}
            className="data-grid-pagination"
            page={currentPage}
            colSpan={7}
            rowsPerPage={rowPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "#00aae5",
              marginTop: "10px",
            }}
          />
      </div>
      
      {popUps.openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={popUps.openUpgradeBrandModal}
          onClose={() => handleClosePopUps()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}
    </div>
  );
};

