import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react'
import { SortingColumnsType } from '../../../../components/table-component/table';
import SampleDetailedInsightsPost from './sample-data/sample-detailed-insights-post';
import { SampleMostEngagingPosts } from './sample-data/sample-most-engaging-posts';
import { SampleMostEngagingInfluencer } from './sample-data/sample-most-engaging-influencer';
import SampleDetailedInsightsInfluencer from './sample-data/sample-detailed-insights-influencer';

type Props = {
    platform?: any;    
    sampleMostEngagingPosts?:any;
    sampleInfluencerDetailedData?:any;
    sampleMostEngagingInfluencers?:any;
    samplePostDetailedData?:any;
}

const SampleDetailedAnalytics = ({sampleMostEngagingPosts, sampleInfluencerDetailedData, sampleMostEngagingInfluencers, samplePostDetailedData,platform}: Props) => {
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [sampleData, setSampleData] = useState(true)

  const sampleColumnDisplayNamePostDetailedData = {
    post: "Post",
    er: "ER",
    view: "Views",
    likes: "Likes",
    comment: "Comments",
    created_at: "Posted on"

  };
 
  const sampleColumnDisplayNameInfluencerDetailedData = {
    total_post: "Post",
    total_er: "ER",
    total_views: "Views",
    like_count: "Likes",
    total_comments: "Comments"

  };

  const samplePostDetailedColumnData: SortingColumnsType[] = [
    {
      name: "Post",
      columnSize: "35%",
    },
    {
      name: "ER",
      columnSize: "14%",
    },
    {
      name: "Views",
      columnSize: "10%",
    },
    {
      name: "Likes",
      columnSize: "10%",
    },
    {
      name: "Comments",
      columnSize: "10%",
    },
    {
      name: "Posted on",
      columnSize: "20%"
    },

  ];


  const sampleInfluencerDetailedColumnData: SortingColumnsType[] = [
    {
      name: "Influencer",
      columnSize: "35%",
    },
    {
      name: "Post",
      columnSize: "10%",
    },
    {
      name: "ER",
      columnSize: "14%",
    },
    {
      name: "Views",
      columnSize: "10%",
    },
    {
      name: "Likes",
      columnSize: "10%",
    },
    {
      name: "Comments",
      columnSize: "10%",
    },
  ];


  const sampleGraphData = [
    {
      post_date: new Date('2024-01-01').getTime(),
      likes: 120,
      views: 1500,
      comments: 50,
    },
    {
      post_date: new Date('2024-01-02').getTime(),
      likes: 200,
      views: 1800,
      comments: 70,
    },
    {
      post_date: new Date('2024-01-03').getTime(),
      likes: 150,
      views: 1700,
      comments: 65,
    },
    {
      post_date: new Date('2024-01-04').getTime(),
      likes: 180,
      views: 1600,
      comments: 80,
    },
    {
      post_date: new Date('2024-01-05').getTime(),
      likes: 250,
      views: 2000,
      comments: 90,
    },
    {
      post_date: new Date('2024-01-06').getTime(),
      likes: 220,
      views: 2100,
      comments: 75,
    },
    {
      post_date: new Date('2024-01-07').getTime(),
      likes: 270,
      views: 2300,
      comments: 100,
    },
  ];

  const samplelikesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: sampleGraphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Likes',
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Likes',
        data: sampleGraphData?.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const sampleplaysOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Views Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: sampleGraphData?.map((entry: any) => entry.post_date),

    },
    legend: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: 'Views',
      },
    },
    credits: {
      enabled: false
    },

    series: [
      {
        name: 'Views',
        data: sampleGraphData?.map((entry: any) => entry.views),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };
  const samplecommentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: sampleGraphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Comments',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Comments',
        data: sampleGraphData?.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };




  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };

  return (
    <>
      <div className="toggle-button-div sec-stockchart-w-tab">

        <TabContext value={value}>

          <TabList
            onChange={handleChange}
            className='nav sub-tabs'
          >
            <Tab className='nav-link'
              label={`Views`}
              value="1"
              icon={<span className="material-symbols-rounded">visibility</span>}
              style={{
                textTransform: "none",
                fontSize: "16px",
                flexGrow: 1,
              }}
            />
            <Tab className='nav-link'
              label={`Likes`}
              value="2"
              icon={<span className="material-symbols-rounded">favorite</span>}
              style={{
                textTransform: "none",
                fontSize: "16px",
                flexGrow: 1,
              }}
            />
            <Tab className='nav-link'
              label={`Comments`}
              value="3"
              icon={<span className="material-symbols-rounded">chat_bubble</span>}
              style={{
                textTransform: "none",
                fontSize: "16px",
                flexGrow: 1,
              }}
            />
          </TabList>

          <TabPanel className="px-0" value="1">
            <HighchartsReact highcharts={Highcharts} options={sampleplaysOptions} />

          </TabPanel>
          <TabPanel className="px-0" value="2">
            <HighchartsReact highcharts={Highcharts} options={samplelikesOptions} />
          </TabPanel>
          <TabPanel className="px-0" value="3">
            <HighchartsReact highcharts={Highcharts} options={samplecommentOptions} />
          </TabPanel>
        </TabContext>

      </div>
      <div className='d-flex flex-column'>
        <strong className='me-auto mb-3'>Most Engaging Posts</strong>
        <div>
          {(() => {
            const sortedData = sampleMostEngagingPosts && sampleMostEngagingPosts.sort((a: any, b: any) => (b.selectedValue > a.selectedValue) ? 1 : (b.selectedValue < a.selectedValue) ? -1 : 0).slice(0, 5);
            return (
              <SampleMostEngagingPosts sortedData={sortedData.length > 0 && sortedData} sampleData={sampleData} campaignPlatform={platform} />
            );
          })()}
        </div>
        {(
          <div className="analytics-most-engaging">
            <div className='d-flex flex-column'>
              <strong className="mb-3">Most Engaging Influencers</strong>
              {(() => {
                const sortedData = sampleMostEngagingInfluencers.sort((a: any, b: any) => (b.total_er > a.total_er) ? 1 : (b.total_er < a.total_er) ? -1 : 0).slice(0, 5);
                return (
                  <SampleMostEngagingInfluencer sortedData={sortedData} sampleData={sampleData} platform={platform} />
                );
              })()}
            </div>
          </div>
        )}
      </div>
      <div className="toggle-button-div2">

        <TabContext value={value2}>


          <div className="toggle-button-div3">
            <TabContext value={value4}>
              <TabList
                onChange={handleChange4}
                aria-label="lab API tabs example"
                className="filter-tab-list"
              >
                <Tab
                  label={`Posts`}
                  value="1"
                  style={{ textTransform: "none", fontSize: "16px" }}
                />
                <Tab
                  label={`Influencers`}
                  value="2"
                  style={{ textTransform: "none", fontSize: "16px" }}
                />
              </TabList>

              <TabPanel className='px-0' value="1">
                <div className="post-table">
                  <SampleDetailedInsightsPost sampleData={sampleData} columnDisplayName={sampleColumnDisplayNamePostDetailedData}
                    orderByColumnName=""
                    inputDataSource={samplePostDetailedData}
                    sortingColumnType={samplePostDetailedColumnData}
                    renderData={samplePostDetailedData}
                    setRenderData={() => { }} platform={platform} />

                </div>
              </TabPanel>
              <TabPanel className='px-0' value="2">
                <div className="influencer-table">
                  <SampleDetailedInsightsInfluencer
                    sampleData={sampleData}
                    columnDisplayName={sampleColumnDisplayNameInfluencerDetailedData}
                    orderByColumnName=""
                    inputDataSource={sampleInfluencerDetailedData}
                    sortingColumnType={sampleInfluencerDetailedColumnData}
                    renderData={sampleInfluencerDetailedData}
                    setRenderData={() => { }} platform={platform} />
                </div>
              </TabPanel>
            </TabContext>

          </div>
        </TabContext>

      </div>
    </>
  )
}

export default SampleDetailedAnalytics