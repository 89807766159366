import React, { useState } from 'react'
import { TableComponent } from '../../../../components/table-component/table'
import { Checkbox, IconButton, Skeleton, TableCell, TableRow, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { Methods } from '../../../../core/utility';
import { Link } from 'react-router-dom';
import MaterialSymbol from '../../../../components/icons/material-symbol';
import CustomDataGrid from '../../../../components/common-table-component/common-data-grid';
import { GridColDef } from '@mui/x-data-grid';


type Props = {
  postFilterData?: any;
  columnDisplayName: any;
  orderByColumnName?: any;
  inputDataSource: any;
  sortingColumnType: any;
  renderData: any;
  setRenderData: any;
  selectCampaign?: any;
  postAnalyticsEr?: any;
  selectDeleteRecord?: any;
  handleOpenDeleteURL?:any;
  hasShareAnalytics?:any;
  loading?:any;
}

const methods: Methods = new Methods()

const DetailedInsightsPost = (props: Props) => {
  
  const columns: GridColDef[] = [
  {
    field: "image", 
      headerName: "",
      cellClassName:"", 
      width: 550,
      maxWidth:500,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: false,
      hideable: false,
      
      renderCell: (params) => (
        <>
          <Link target='_blank' to={props?.selectCampaign?.campaign_platform === 'Instagram' ? `https://www.instagram.com/p/${params?.row?.shortcode}` : `https://www.youtube.com/watch?v=${params?.row?.video_id}`}>
            <img src={props?.selectCampaign?.campaign_platform === 'Instagram' ? params?.row?.gcp_display_url : params?.row?.thumbnails} className="grid-image me-2" style={{maxWidth:'80px'}}  />
          </Link>
          <div className='d-flex flex-column align-items-start justify-content-between flex-wrap'>
            <div className='d-flex flex-column align-items-start  flex-wrap'>
              <span>{params?.row.full_name}</span>
              <Link target='_blank' className='btn btn-text-link mb-1' to={props?.selectCampaign?.campaign_platform === 'Instagram' ? `https://www.instagram.com/${params?.row?.username}` : `https://www.youtube.com/channel/${params?.row?.channel_id}`}>{props?.selectCampaign?.campaign_platform === 'Instagram' ? params?.row?.username : params?.row?.channel_title}</Link>
            </div>

            {props?.selectCampaign?.campaign_platform === 'Instagram' ? (
              <span style={{ color: '#787878' }}>Duration: {params?.row?.__typename === "GraphVideo" ? `${methods.formatDuration(params?.row?.video_duration)}` : ""}</span>
            ) : (
              <Link target='_blank' className=' white-space-normal text-wrap mb-1 text-black text-decoration-none' to={`https://www.youtube.com/watch?v=${params?.row?.video_id}`}>
                <span>{params?.row?.title} </span>
              </Link>
            )
            }
            </div>
          </>
      ),
  },
    {
    field: "er", 
      headerName: "ER",
      cellClassName:"", 
      width: 70,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      renderCell: (params) => (
        <>
          {params?.row?.er && params?.row?.er ? `${params?.row?.er}%` : "NA"}
          </>
      ),
  },
{
    field: "views", 
      headerName: "Views",
      cellClassName:"", 
      width: 120,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      valueGetter: (params) =>
    props?.selectCampaign?.campaign_platform === "Instagram"
      ? params?.row?.__typename === "GraphVideo"
        ? params?.row?.video_plays
        : 0
      : params?.row?.view_count,
      renderCell: (params) => (
        <>
          {props?.selectCampaign?.campaign_platform === 'Instagram'
            ? (params?.row?.__typename === 'GraphVideo'
              ? methods.formatInfluencerProfileNumber(params?.row?.video_plays)
              : "--")
            : methods.formatInfluencerProfileNumber(params?.row?.view_count)}
          </>
      ),
  },
  {
    field: "likes", 
      headerName: "Likes",
      cellClassName:"", 
      width: 120,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      valueGetter: (params) =>
    props?.selectCampaign?.campaign_platform === "Instagram"
       ? (params?.row?.likes !== 0
                              ? methods.formatInfluencerProfileNumber(params?.row?.likes)
                              : "likes are disabled by the user")
                            : methods.formatInfluencerProfileNumber(params?.row?.like_count),
      renderCell: (params) => (
        <>
          {props?.selectCampaign?.campaign_platform === 'Instagram'
                            ? (params?.row?.likes !== 0
                              ? methods.formatInfluencerProfileNumber(params?.row?.likes)
                              : "likes are disabled by the user")
                            : methods.formatInfluencerProfileNumber(params?.row?.like_count)}
          </>
      ),
  },
  {
    field: "comments", 
      headerName: "Comments",
      cellClassName:"", 
      width: 150,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      valueGetter: (params) =>
    props?.selectCampaign?.campaign_platform === 'Instagram' ? methods.formatInfluencerProfileNumber(params?.row?.comments) : methods.formatInfluencerProfileNumber(params?.row?.comment_count),
      renderCell: (params) => (
        <>
            {props?.selectCampaign?.campaign_platform === 'Instagram' ? methods.formatInfluencerProfileNumber(params?.row?.comments) : methods.formatInfluencerProfileNumber(params?.row?.comment_count)}
          </>
      ),
  },

    {
    field: "posted_on", 
      headerName: "Posted On",
      cellClassName:"", 
      width: 180,  
      filterable: false,  // Ensure filter is enabled
      disableColumnMenu: true,
      sortable: true,
      hideable: false,
      renderCell: (params) => (
        <>  {dayjs(params?.row?.created_at).format("MMM D, YYYY")}
           
          </>
      ),
  },





  

    
];

  return (
    <>
      {
        (() => {
          if (props?.inputDataSource.length > 0) {
            return (
              <div className='infl-detail-table'>
                <CustomDataGrid disableColumnFilter rows={props?.inputDataSource} rowHeight={100} columns={columns} loading={props?.loading} />
              </div>
            )
          }

        })()

      }
    </>
  )
}

export default DetailedInsightsPost