import React, { useEffect, useState } from 'react';
import { DefaultUserProfile } from '../../../../../../../config/config';
import { DeliverableItemKeys } from './enums';
import dayjs from 'dayjs';
import { Methods } from '../../../../../../../core/utility';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';
import { DiscoverService } from '../../../../../../../core/services';
import UpgradeBrandPopUp from '../../../../../../../components/popup/upgrade-brand-popup';
import ProfileViewRestriction from '../../../../../../../components/popup/profile-view-restriction';
import { Link } from 'react-router-dom';

const discoverService: DiscoverService = new DiscoverService();

type Props = {
    profileData: any,
    data?: any
    deliverableHeader?: any;
    showDate?: boolean;
    creativeStatus?: boolean;
    creativePopup?:boolean;
    userReducer?: any;
}
type DefaultProps = {
    showDate: boolean;
    creativeStatus: boolean;
} & Props;



const methods: Methods = new Methods()


export const Profile = ({
    profileData, data, deliverableHeader, showDate = true,
    creativeStatus = false, userReducer, creativePopup= false }: Props) => {
    const [enumdeliverableItems] = useState<Record<DeliverableItemKeys, string>>({
        ig_reel: 'Reel',
        ig_story: 'Story',
        ig_carousel_image: 'Carousel Image Post',
        yt_shorts: 'Short',
        available_for_exclusivity: 'Exclusivity',
        ig_static: 'Static',
        digital_rights: 'Digital Rights',
        yt_dedicated_video: 'Dedicated Video',
        yt_integrated_video: 'Integrated Video',
        ["Package Cost 1"]: 'Package Cost'
    });
    const [approvedCreatives, setApprovedCreatives] = useState([]);
    const [rejectedCreatives, setRejectedCreatives] = useState([]);
    const [pendingCreatives, setPendingCreatives] = useState([]);

    // FOC useStates
    // set profile view limit Modal variables and usestate
    // const [profileViewLimitPopup, setProfileViewLimitPopup] = useState(false);
    // set foc popup and gstin popup state
    const [popUps, setPopUps] = useState({
        profileViewLimitPopup: false,
        openUpgradeBrandModal: false
    });
    const [profileViewCount, setProfileViewCount] = useState(0);
    const [profileViewLimit, setProfileViewLimit] = useState(0);
    const [profileViewModalTitle, setProfileViewModalTitle] = useState('View Profile');
    // const [selectedSmId, setSelectedSmId] = useState(0);


    const filterCreativesByStatus = (creatives: any, status: string) => {
        return creatives?.filter((creative: any) => creative.status === status);
    };

    useEffect(() => {
        const { creatives_status: creatives } = profileData || {};
        if (creatives) {
            setApprovedCreatives(filterCreativesByStatus(creatives, 'Approved'));
            setRejectedCreatives(filterCreativesByStatus(creatives, 'Rejected'));
            setPendingCreatives(filterCreativesByStatus(creatives, 'Pending'));
        }
    }, [profileData]);

    const getPriceOfPackageCost = (deliverables: any) => {
        const packageCost = deliverables.find((item: any) => item.deliverable_item === 'Package Cost 1');
        if (packageCost) {
            return packageCost ? packageCost.price : 0;
        }
        return deliverables.reduce((sum: any, item: any) => sum + item.price, 0);
    }

    const handleNavigate = () => {
        if (profileData.platform_type.toLowerCase() === "instagram") {
            window.open(`/brand/profile-insight?sm_id=${profileData.sm_id}&influencerId=${profileData.influencer_id}`, "_blank");
        }else {
            window.open(`/brand/profile-insight-yt?sm_id=${profileData.sm_id}&influencerId=${profileData.influencer_id}`, "_blank");
        }
    };

    // To handle profile view
    async function handleProfileView(e: any) {
        try {
            e.preventDefault();
            // loading(true);
            // setSelectedSmId(sm_id);
            const response = await discoverService.canViewProfile({
                user_id: userReducer.user_id,
                account_id: userReducer.account_id,
                brand_id: userReducer.brand_id,
                influencer_id: userReducer.influencer_id,
                sm_id: profileData.sm_id
            })
            if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_profile_limit_popup)) {
                handleNavigate();
            }
            else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_profile_limit_popup) {
                setProfileViewCount(response?.data?.profile_view_count);
                setProfileViewLimit(response?.data?.profile_view_limit);
                // setProfileViewLimitPopup(true);
                setPopUps({ ...popUps, profileViewLimitPopup: true })
            }
            // else if (response.data.status && response.data.basic_user ){
            //   setProfileViewLimitPopup(true);
            // }
            else if (!response.data.status && response.data.message.includes('upgrade')) {
                // localService.toastify(response.data.message, 'info', 1000);
                setPopUps({...popUps, openUpgradeBrandModal: true});
            }
            else if (!response.data.status) {
                // localService.toastify(response.data.message, 'error', 1000);
                console.error(`error:: ${response.data.message}`)
            }
        } catch (error) {
            console.error(`handleProfileView error :: ${JSON.stringify(error)}`);
        } finally {
            // loading(false);
        }
    };

    // To handle profile view
    async function handleFocProfileView() {
        try {
            // props.loading(true);
            handleClosePopUps();
            handleNavigate();
        } catch (error) {
            console.error(`handleFocProfileView error :: ${JSON.stringify(error)}`);
        } finally {
            // props.loading(false);
        }
    };

    function handleClosePopUps() {
        setPopUps({
            profileViewLimitPopup: false,
            openUpgradeBrandModal: false
        })
    }

    // Foc campaign logic end.
    return (
        <div className='profile-detail'>
            <div className='infl-profile-img-sec'>
                <Link to="javascript:void(0);" onClick={(e) => { profileData.foc === 'true' ? e.preventDefault() : handleProfileView(e) }} className='remove-link-style'>
                <span className='infl-profile-img'>
                    <img 
                        className='profile-info-img' 
                        src={
                            (profileData?.gcp_profile_image_url || profileData?.profile_image_url)
                                ? (profileData?.gcp_profile_image_url ? profileData?.gcp_profile_image_url : profileData?.profile_image_url)
                                : DefaultUserProfile.influencer_unknown_default
                        } 
                        alt={profileData?.platform_type === 'Youtube' ? profileData?.title : profileData?.insta_name} 
                    />
                </span>
                </Link>
                <Link to="javascript:void(0);" onClick={(e) => { profileData.foc === 'true' ? e.preventDefault() : handleProfileView(e) }} className='remove-link-style pe-2'>
                    <span className='d-flex flex-column'>
                        <span className="infl-profile-name">
                            {profileData?.platform_type === 'Youtube' && profileData?.title}
                            {profileData?.insta_name}
                        </span>
                        <span className='infl-profile-username'>
                            {profileData?.username}
                        </span>
                    </span>
                </Link>
            </div>
            {(profileData?.proposal_status !== 'Rejected' && profileData?.proposal_status !== 'Accepted') && (
                <ul className='infl-profile-info'>
                    <li>
                        <span className='infl-profile-count'>
                            {profileData?.platform_type === 'Youtube'
                                ? (profileData?.subscriber_count
                                    ? methods.formatInfluencerProfileNumber(profileData?.subscriber_count || 0)
                                    : "NA")
                                : profileData?.platform_type === 'Instagram'
                                    ? (profileData?.followers
                                        ? methods.formatInfluencerProfileNumber(profileData?.followers || 0)
                                        : "NA")
                                    : "NA"}
                        </span>
                        <span className='infl-profile-text'>
                            {profileData?.platform_type === 'Youtube' && <>Subscribers</>}
                            {profileData?.platform_type === 'Instagram' && <>Followers</>} </span>
                    </li>
                    {profileData?.platform_type !== 'Youtube' &&
                        <li>
                            <span className='infl-profile-count'>
                                {profileData?.platform_type === 'Instagram' && `${methods.formatNumber(profileData?.er || 0)}%`}
                            </span>
                            <span className='infl-profile-text'>ER</span>
                        </li>
                    }
                    <li >
                        <span className='infl-profile-count'>
                            {profileData?.platform_type === 'Youtube' && (methods.formatNumber(profileData?.avg_views || 0))}
                            {profileData?.platform_type === 'Instagram' && methods.formatInfluencerProfileNumber(profileData?.reach || 0)} </span>
                        <span className='infl-profile-text'>{profileData?.platform_type === 'Youtube' && <>Avg. Views</>}
                            {profileData?.platform_type === 'Instagram' && <>Reach</>}
                        </span>
                    </li>
                    {profileData?.proposal_status === 'Pending' && profileData?.campaign_type === 'Paid' && 
                    <li className='infl-profile-total-price'>
                        <span className='infl-profile-count'>
                        <MaterialSymbol iconName='currency_rupee' className='fs-14 align-middle' fontweight='200' />
                            {profileData?.platform_type === 'Youtube' && (getPriceOfPackageCost(profileData?.deliverables) || 0)}
                            {profileData?.platform_type === 'Instagram' && (getPriceOfPackageCost(profileData?.deliverables) || 0)} </span>
                        <span className='infl-profile-text'>
                            {/* {profileData?.platform_type === 'Youtube' && <>Avg. Views</>} */}
                            {profileData?.platform_type === 'Instagram' && <>Total Price</>}
                        </span>
                    </li>
            }
                    {showDate &&
                        <li className='not-badge'>
                            <span className="proposal-date">{dayjs(profileData?.proposal_datetime).format("DD MMM, YYYY")}</span>
                        </li>
                    }
                </ul>
            )
            }
            {!creativePopup && <>
            {((profileData?.proposal_status === 'Rejected' || profileData?.proposal_status === 'Accepted')) && (
                <>
                    <ul className={`infl-profile-info 
                    ${(profileData?.proposal_status === 'Rejected' || profileData?.proposal_status === 'Accepted') ? "proposal-accepted-rejected" : ""}`}>

                        <>{profileData.deliverables && profileData.deliverables?.map((item: any, index: number) => (
                            <React.Fragment key={index}>{item?.deliverable_item !== 'Package Cost 1' && (
                            <li>
                                <span className="infl-profile-count">
                                   
                                        <span>{enumdeliverableItems[item.deliverable_item as DeliverableItemKeys]}</span>
                                    
                                    {(item.deliverable_item === 'digital_rights') &&
                                        profileData.deliverables
                                            ?.filter((pr: any) => (pr.deliverable_item === 'digital_rights'))
                                            .map((pr: any) => `: ${pr.quantity} days`)}
                                    {(item.deliverable_item === 'available_for_exclusivity') &&
                                        profileData.deliverables
                                            ?.filter((pr: any) => (pr.deliverable_item === 'available_for_exclusivity'))
                                            .map((pr: any) => `: ${pr.quantity} days`)}

                                    {(item.deliverable_item === 'ig_static') &&
                                        deliverableHeader
                                            ?.filter((pr: any) => pr.influencer_type_id === profileData?.infTypeId)
                                            .map((pr: any) => `(${pr["ig_static_image"]} images)`)
                                    }
                                    {(item.deliverable_item.toLowerCase() == 'ig_reel' || item.deliverable_item.toLowerCase() == 'ig_static' || item.deliverable_item === "ig_story" || item.deliverable_item === "yt_shorts" || item.deliverable_item === "yt_dedicated_video" || item.deliverable_item === "yt_integrated_video") && `: ${item.quantity}`}
                                </span>
                            </li>
                            )} 
                             
                            </React.Fragment>


                        ))}</>
                    </ul>
                   {!creativePopup &&<>
                    {(profileData?.campaign_type !== 'Barter') && 
                        <span className='infl-profile-total-cost'>Total Cost:
                            <MaterialSymbol iconName='currency_rupee' className='fs-16 vertical-align-middle' fontweight='200' />{profileData.deliverables.filter((item:any) => item.step_status !== "Rejected").reduce((sum:any, item:any) => sum + item.price, 0)}
                        </span>
                    }
                   </>}
                    {(creativeStatus && profileData.creatives_status.length > 0) &&
                        <div className='creative-status-indicator'>
                            <ul className="campaign-legend"><li className="campaign-legend_pending" data-attr={pendingCreatives.length}></li><li className="campaign-legend_accepted" data-attr={approvedCreatives.length}></li>
                                <li className="campaign-legend_rejected" data-attr={rejectedCreatives.length}></li></ul>
                        </div>
                    }

                </>
            )}
            </>}
            {/* Upgrade brand popup */}
            {popUps.openUpgradeBrandModal && (
                <UpgradeBrandPopUp
                openUpgradeBrandModal={popUps.openUpgradeBrandModal}
                onClose={() => handleClosePopUps()}
                popupMinWidth='630px'
                >
                </UpgradeBrandPopUp>
            )}

            {/* FOC Profile view limit popup */}
            {
                popUps.profileViewLimitPopup && (
                <ProfileViewRestriction
                    openModal={popUps.profileViewLimitPopup}
                    onClose={() => handleClosePopUps()}
                    profileViewCount={profileViewCount}
                    profileViewLimit={profileViewLimit}
                    handleFocProfileView={() => handleFocProfileView()}
                />
                )
            }


        </div>
    );
}