import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import RenderAcknoledgeReceiptForm from "./render-acknowledge-receipt-form";
import MaterialSymbol from "../../../../../../components/icons/material-symbol";

interface Proposal {
  name: string;
  total_price: number;
  payment_status: string;
  account_type?: string;
  bank_name?: string;
  account_holder_name?: string;
  account_number?: string;
  ifsc_code?: string;
  address?: string;
  micr_code?: string;
  influencer_id: number;
  proposal_id: number;
  // file_urls: string;
  invoices?: any;
}

interface Invoice {
  file_url: string;
  file_name: string;
  total_amount: number; // invoice total amount.
}

type Props = {
  proposal: Proposal;
  UserReducer?: any;
  acceptedProposalPaymentDetails: any;
  selectedCampaign: any;
  handleUploadReceipt?: any;
  setUpdateAcceptedProposalPaymentDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const RenderDownloadInvoiceButtons: React.FC<Props> = ({
  proposal,
  UserReducer,
  acceptedProposalPaymentDetails,
  selectedCampaign,
  handleUploadReceipt,
  setUpdateAcceptedProposalPaymentDetails
}) => {
  // const [fileUrls, setFileUrls] = useState<string[]>([]);
  // useEffect(() => {
  //   if (proposal?.invoices !== null) {
  //     // TODO Need to change as per new flow.
  //     // setFileUrls(proposal.file_urls.split(','));
  //   }
  // }, [proposal?.invoices]);
  return (
    <Table className="table invoice-detail-table">
      <TableHead className="invoice-detail-table-header">
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Total Amount</TableCell>
          <TableCell>Payment Status</TableCell>
          <TableCell>File Name</TableCell>
          <TableCell>Payment Advice</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="invoice-detail-table-body">
        <>
          {proposal?.invoices &&
            JSON.parse(proposal?.invoices)?.map(
              (invoice: any, index: number) => {
                return (
                  <TableRow key={invoice.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{invoice.total_amount}</TableCell>
                    <TableCell>{invoice.payment_status}</TableCell>
                    <TableCell>
                      <Tooltip title={invoice.file_url} arrow>
                        <NavLink
                          target="_blank"
                          key={`campaign_invoice_${index}`}
                          to={invoice.file_url}
                          data-tip={invoice.file_url}
                        >
                          <IconButton size="small">
                            <MaterialSymbol
                              iconName="description"
                              fontweight="200"
                              fontSize="20"
                            />
                          </IconButton>
                        </NavLink>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <RenderAcknoledgeReceiptForm
                        invoiceId={invoice?.id}
                        invoicePaymentStatus={invoice?.payment_status}
                        proposal={proposal}
                        UserReducer={UserReducer}
                        selectedCampaign={selectedCampaign}
                        acceptedProposalPaymentDetails={
                          acceptedProposalPaymentDetails
                        }
                        handleUploadReceipt={handleUploadReceipt}
                        setUpdateAcceptedProposalPaymentDetails={setUpdateAcceptedProposalPaymentDetails}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </>
      </TableBody>
    </Table>
  );
};

export default RenderDownloadInvoiceButtons;
