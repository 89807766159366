import { Skeleton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { DefaultUserProfile } from "../../../config/config";
import InstagramIcon from "../../../components/icons/instagram-icon";
import { Link } from "react-router-dom";
import { Boy, Girl } from "@mui/icons-material";
import { Methods } from "../../../core/utility";
import MaterialSymbol from "../../../components/icons/material-symbol";
import dayjs from "dayjs";
import IconYouTube from "../../../components/icons/icon-youtube";

type Props = {
  ytInfluencerData: any;
  email?: any;
  phone?: any;
  loading: boolean;
  location?: any;
  category?: any;
  language?: any;
  subscription_status?: any;
};

const methods = new Methods();

const YTInfluencerProfileLeft = ({
  ytInfluencerData,
  email,
  phone,
  location,
  category,
  language,
  loading,
  subscription_status,
}: Props) => {
  const [showMoreText, setShowMoreText] = useState(false);
  const handleMoreText = () => {
    setShowMoreText((prev: boolean) => !prev);
  };
  const dob = ytInfluencerData?.detail?.[0]?.dob;
  const shouldRenderAge = dob && dob !== "1970-01-01";
  return (
    <>
      {/* // FOR DESKTOP VIEW */}
      <div className="influencer-profile__left-inner d-none d-lg-flex">
        <div className="influencer-profile__image">
          {loading ? (
            <Skeleton width={130} height={130} variant="circular" />
          ) : (
            <img
              src={
                ytInfluencerData &&
                ytInfluencerData.detail &&
                ytInfluencerData.detail.length > 0 &&
                ((ytInfluencerData.detail[0].gcp_profile_image_url
                  ? ytInfluencerData.detail[0].gcp_profile_image_url
                  : ytInfluencerData.detail[0].profile_image_url) ||
                  DefaultUserProfile?.influencer_unknown_default)
              }
              alt="Influencer Profile"
              style={{ maxWidth: "130px", height: "130px" }}
            />
          )}
          <div className="influencer-profile__image--curve"></div>
          <div className="influencer-profile__image--curve-left"></div>
        </div>
        <div className="influencer-user__info">
          <h3 className="">
            {loading ? (
              <Skeleton width={130} height={57} />
            ) : ytInfluencerData && ytInfluencerData?.detail?.[0]?.title ? (
              ytInfluencerData && ytInfluencerData?.detail?.[0]?.title
            ) : (
              "NA"
            )}
          </h3>

          <span className={`fs-14 ${loading ? "d-flex" : ""}`}>
            {(loading || shouldRenderAge) && (
              <>
                {loading ? (
                  <Skeleton
                    width={20}
                    style={{ display: "inline-flex", marginRight: "8px" }}
                  />
                ) : (
                  `${methods.calculateAge(dob)}, `
                )}
              </>
            )}
            {loading ? (
              <Skeleton width={75} />
            ) : (
              ytInfluencerData?.detail?.[0]?.gender !== "Other" &&
              ytInfluencerData?.detail?.[0]?.gender
            )}
          </span>
        </div>
        <div className="influencer-user__location">
          <div className="d-flex">
            {(loading || subscription_status.status) && (
              <p className="d-flex align-items-center">
                <Tooltip
                  arrow
                  title={
                    subscription_status.status == "non_subscribed"
                      ? "Non - subscriber"
                      : subscription_status.status == "active"
                      ? "Active subscription"
                      : "Expired subscription"
                  }
                >
                  {loading ? (
                    <Skeleton
                      width={12}
                      height={12}
                      style={{ marginRight: "10px" }}
                      variant="circular"
                    />
                  ) : (
                    <span
                      className={`me-3 ms-1 discover_subscription_dot discover_subscription_dot_${subscription_status.status}`}
                    ></span>
                  )}
                </Tooltip>
              </p>
            )}
            {(loading || subscription_status.plan_start_date) && (
              <p className="d-flex align-items-center">
                <Tooltip arrow title="Plan date">
                  <span>
                    {loading ? (
                      <Skeleton width={200} />
                    ) : (
                      `${dayjs(subscription_status.plan_start_date).format(
                        "D MMM YYYY"
                      )} - ${dayjs(subscription_status.plan_end_date).format(
                        "D MMM YYYY"
                      )}`
                    )}
                  </span>
                </Tooltip>
              </p>
            )}
          </div>

          {(loading || (email && email.length > 0)) && (
            <p className="d-flex align-items-center">
              {loading ? (
                <Skeleton width={20} height={24} />
              ) : (
                <MaterialSymbol
                  iconName="mail"
                  fontweight="200"
                  color="#929292"
                  iconFilled
                />
              )}{" "}
              <span className="ms-2">
                {loading ? <Skeleton width={150} height={24} /> : email}
              </span>
            </p>
          )}

          {(loading || (phone != 0 && phone)) && (
            <p className="d-flex align-items-center">
              {loading ? (
                <Skeleton width={20} height={24} />
              ) : (
                <MaterialSymbol
                  iconName="call"
                  fontweight="200"
                  color="#929292"
                  iconFilled
                />
              )}
              <span className="ms-2">
                {loading ? <Skeleton width={150} height={24} /> : phone}
              </span>
            </p>
          )}
          {(loading || (location && location.length > 0)) && (
            <p className="d-flex align-items-center">
              {loading ? (
                <Skeleton width={20} height={24} />
              ) : (
                <MaterialSymbol
                  iconName="location_on"
                  fontweight="200"
                  color="#929292"
                  iconFilled
                />
              )}
              <span className="ms-1">
                {loading ? <Skeleton width={150} height={24} /> : location}
              </span>
            </p>
          )}
        </div>
        <div
          className={`influencer-user__intro ${
            showMoreText ? "more-text" : ""
          }`}
        >
          <p className="mb-0">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                {ytInfluencerData?.detail?.[0]?.description !== ""
                  ? ytInfluencerData?.detail?.[0]?.description
                  : "NA"}
              </>
            )}
          </p>
          {ytInfluencerData?.detail?.[0]?.description &&
            ytInfluencerData?.detail?.[0]?.description.length > 85 && (
              <button onClick={handleMoreText} className="btn btn-text-link">
                {showMoreText ? "less" : "more"}
              </button>
            )}
        </div>
        <div className="influencer-user__category">
          {(loading ||
            category[0] ||
            category[1] ||
            category[2] ||
            category[3]) && (
            <div className="influencer-user__category">
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                category[0] && <span>{category[0]}</span>
              )}
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                category[1] && <span>{category[1]}</span>
              )}
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                category[2] && <span>{category[2]}</span>
              )}
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                category[3] && <span>{category[3]}</span>
              )}
            </div>
          )}
        </div>
        <div className="influencer-user__category">
          {(loading ||
            language[0] ||
            language[1] ||
            language[2] ||
            language[3]) && (
            <div className="influencer-user__category">
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                language[0] && <span>{language[0]}</span>
              )}
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                language[1] && <span>{language[1]}</span>
              )}
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                language[2] && <span>{language[2]}</span>
              )}
              {loading ? (
                <Skeleton height={26} width={50} />
              ) : (
                language[3] && <span>{language[3]}</span>
              )}
            </div>
          )}
        </div>
      </div>

      {/* // FOR MOBILE VIEW */}
      <div className="influencer-profile__left-inner influencer-profile__left-inner-mobile d-lg-none">
        <div className="influencer-user-profile">
          <div className="influencer-profile__image influencer-user-profile-left">
            {loading ? (
              <Skeleton
                width={88}
                height={88}
                variant="circular"
                style={{ backgroundColor: "#111" }}
              />
            ) : (
              <img
                src={
                  ytInfluencerData &&
                  ytInfluencerData.profile_detail &&
                  ytInfluencerData.profile_detail.length > 0 &&
                  ((ytInfluencerData.profile_detail[0].gcp_profile_image_url
                    ? ytInfluencerData.profile_detail[0].gcp_profile_image_url
                    : ytInfluencerData.profile_detail[0].profile_image_url) ||
                    DefaultUserProfile?.influencer_unknown_default)
                }
                alt="Influencer Profile"
              />
            )}
          </div>
          <div className="influencer-user-profile-right">
            <Link
              className="influencer-profile__social-chan"
              target="_blank"
              to={`https://www.youtube.com/channel/${
                ytInfluencerData && ytInfluencerData?.detail?.[0]?.channel_id
              }`}
            >
              <span className="influ-prof-info-social-username d-flex justify-content-center align-items-baseline">
                <h3 className="">
                  {loading ? (
                    <Skeleton
                      width={200}
                      height={24}
                      style={{ backgroundColor: "#111" }}
                    />
                  ) : ytInfluencerData &&
                    ytInfluencerData?.detail?.[0]?.title ? (
                    ytInfluencerData && ytInfluencerData?.detail?.[0]?.title
                  ) : (
                    "NA"
                  )}
                </h3>
              </span>
            </Link>
            {(loading || ytInfluencerData) && (
              <span className="d-flex d-lg-none">
                {loading ? (
                  <Skeleton width={20} style={{ backgroundColor: "#111" }} />
                ) : (
                  <IconYouTube width="22px" />
                )}
                <Link
                  target="_blank"
                  className={`${loading ? "" : "btn btn-text-link"}`}
                  to={`https://www.youtube.com/${ytInfluencerData?.detail?.[0]?.custom_url}`}
                >
                  <span>
                    {loading ? (
                      <Skeleton
                        width={150}
                        style={{ backgroundColor: "#111" }}
                      />
                    ) : (
                      ytInfluencerData?.detail?.[0]?.custom_url
                    )}
                  </span>
                </Link>
              </span>
            )}
            <div className="influencer-user__info">
              {/* <h3>
                {(!loading) ? (
                  <Skeleton height={30} style={{ backgroundColor: "#111" }} />
                ) : (
                  ytInfluencerData &&
                  ytInfluencerData?.profile_detail?.[0]?.insta_name
                )}
              </h3> */}

              {(loading || (email && email.length > 0)) && (
                <p className="d-flex align-items-center mb-0">
                  <MaterialSymbol
                    iconName="mail"
                    fontweight="200"
                    color="#929292"
                    iconFilled
                  />{" "}
                  <span className="ms-lg-2">
                    {loading ? (
                      <Skeleton
                        width={200}
                        style={{ backgroundColor: "#111" }}
                      />
                    ) : (
                      email
                    )}
                  </span>
                </p>
              )}
              {(loading || (phone != 0 && phone)) && (
                <p className="d-flex align-items-center mb-0">
                  <MaterialSymbol
                    iconName="call"
                    fontweight="200"
                    color="#929292"
                    iconFilled
                  />
                  <span className="ms-lg-2">
                    {loading ? (
                      <Skeleton
                        width={200}
                        style={{ backgroundColor: "#111" }}
                      />
                    ) : (
                      phone
                    )}
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            borderTop: "1px solid #444",
            borderBottom: "1px solid #444",
            padding: "1rem 0",
          }}
        >
          <span className={`fs-14 ${loading ? "d-flex" : ""}`}>
            {loading ? (
              <Skeleton width={20} style={{ backgroundColor: "#111" }} />
            ) : ytInfluencerData?.detail?.[0]?.dob ? (
              `${methods.calculateAge(ytInfluencerData?.detail?.[0]?.dob)}, `
            ) : (
              "NA"
            )}
            {loading ? (
              <Skeleton width={100} style={{ backgroundColor: "#111" }} />
            ) : (
              ytInfluencerData?.detail?.[0]?.gender
            )}
          </span>
          <div className="influencer-user__location">
            {(loading || (location && location.length > 0)) && (
              <p className="d-flex align-items-center mb-0">
                <MaterialSymbol
                  className="d-none d-lg-inline-flex"
                  iconName="location_on"
                  fontweight="200"
                  color="#929292"
                  iconFilled
                />
                <span className="ms-lg-1">{loading ? <Skeleton width={130} style={{ backgroundColor: "#111" }} /> : location}</span>
              </p>
            )}
            {subscription_status.plan_start_date && (
              <>
                <p className="d-flex align-items-center mb-0">
                  {subscription_status.status && (
                    <Tooltip
                      arrow
                      title={
                        subscription_status.status == "non_subscribed"
                          ? "Non - subscriber"
                          : subscription_status.status == "active"
                          ? "Active subscription"
                          : "Expired subscription"
                      }
                    >
                      <span
                        className={`discover_subscription_dot discover_subscription_dot_${subscription_status.status}`}
                      ></span>
                    </Tooltip>
                  )}
                  <MaterialSymbol
                    iconName="workspace_premium"
                    fontweight="200"
                    color="#929292"
                    iconFilled
                  />
                  <Tooltip arrow title="Plan date">
                    <span>
                      {loading ? <Skeleton width={150} style={{ backgroundColor: "#111" }} /> : `${dayjs(subscription_status.plan_start_date).format(
                        "D MMM YYYY"
                      )} - ${dayjs(subscription_status.plan_end_date).format(
                        "D MMM YYYY"
                      )}`}
                    </span>
                  </Tooltip>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="influencer-user__intro">
          <p className="mb-0">
            {loading ? (
              <Skeleton width={300} style={{ backgroundColor: "#111" }} />
            ) : (
              <>
                {ytInfluencerData?.detail?.[0]?.description !== ""
                  ? ytInfluencerData?.detail?.[0]?.description
                  : "NA"}
              </>
            )}
          </p>
        </div>
        <div>
          {(loading ||
            category[0] ||
            category[1] ||
            category[2] ||
            category[3]) && (
            <div className="influencer-user__category">
              {(loading || category[0]) && (
                <span>{loading ? <Skeleton /> : category[0]}</span>
              )}
              {(loading || category[1]) && (
                <span>{loading ? <Skeleton /> : category[1]}</span>
              )}
              {(loading || category[2]) && (
                <span>{loading ? <Skeleton /> : category[2]}</span>
              )}
              {(loading || category[3]) && (
                <span>{loading ? <Skeleton /> : category[3]}</span>
              )}
            </div>
          )}

          {(loading ||
            language[0] ||
            language[1] ||
            language[2] ||
            language[3]) && (
            <div className="influencer-user__category">
              {(loading || language[0]) && (
                <span>{loading ? <Skeleton /> : language[0]}</span>
              )}
              {(loading || language[1]) && (
                <span>{loading ? <Skeleton /> : language[1]}</span>
              )}
              {(loading || language[2]) && (
                <span>{loading ? <Skeleton /> : language[2]}</span>
              )}
              {(loading || language[3]) && (
                <span>{loading ? <Skeleton /> : language[3]}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default YTInfluencerProfileLeft;
