import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

//mui metrial
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InstagramIcon from "../../../../../components/icons/instagram-icon";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import {Tabs, Tab} from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@mui/material";

import {
  CreateCampaign as CreateCampaignService,
  ApplyCampaignService,
  LocalService,
  ListService,
  SubscriptionService
} from "../../../../../core/services";
import { CreateCampaign, Loading } from '../../../../../redux/action';
import { createCampaignInitialState } from "../../../../../redux/reducer/reducer";
import "./campaign-list.scss";
import { YouTube } from "@mui/icons-material";

import CommonPopUp from '../../../../../components/popup/common-popup';
import PlanDetailDrawer from '../../../../../components/plan-detail-drawer/plan-detail-drawer';
import ConfirmPopup from '../../../../../components/confirm-popup/confirm-popup';
import MarkCompletePopup from "../../../../../components/popup/mark-complete-popup";
import { createPortal } from 'react-dom';
import { DummyCampaignImage } from '../../../../../config/config';
import IconAnalyticsCampaign from "../../../../../components/icons/campaign-analytics-icon";
// For mark complete campaign
// import UseAnimations from 'react-useanimations';
// import checkmark from 'react-useanimations/lib/checkmark';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import { Methods } from "../../../../../core/utility";
import * as gtUtil from "../../../../../core/utility";

const methods: Methods = new Methods();

const campaignFlowService: ApplyCampaignService = new ApplyCampaignService();
const localService: LocalService = new LocalService();
const listService: ListService = new ListService()

// For foc republish logic
const subscriptionService: SubscriptionService = new SubscriptionService();

interface Props {
  UserReducer: any;
  UserID: any;
  campaignReducer: any;
  CreateCampaignReducer?: (data: any) => void;
  loading: (status: boolean) => void;
  search: string;
  selectPlan: string;
  planId: number;
  updatePlan: (planName: string) => void;
}

interface CampaignStatus {
  name: string;
  count: number;
}

const campaignStatusOptions: CampaignStatus[] = [
  {
    name: 'Live',
    count: 0
  },
  {
    name: 'Pause',
    count: 0
  },
  {
    name: 'Draft',
    count: 0
  },
  {
    name: 'Completed',
    count: 0
  },
  {
    name: 'Archived',
    count: 0
  },
  {
    name: 'Rejected',
    count: 0
  },
  {
    name: 'Under Review',
    count: 0
  },
]


const CampaingList: React.FC<Props> = (props: Props) => {
  const [campaignList, setCampaignList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [planList, setplanList] = useState([]);
  const [radiovalue, setRadioValue] = useState(planList.length > 0 ? 2 : 1);
  const [selectPlan, setSelectPlan] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [filteredSearchData, setFilteredSearchData] = React.useState([]);
  const [paginationCountBoolean, setPaginationCountBoolean] = React.useState(false);
  const [showPlanNeed, setShowPlanNeed] = useState(false)
  const [basicUser, setBasicUser] = useState(true);
  const [openPlanDetailPopup, setOpenPlanDetailPopup] = useState(false);

  const [value, setValue] = React.useState<number | 0>(0);
  const [selectedCampaignStatus, setSelectedCampaignStatus] = useState('');
  const [campaignStatuses, setCampaignStatuses] = useState<CampaignStatus[]>(campaignStatusOptions);
  const [planDescription, setPlanDescription] = useState('');

  // const [classValue, setClassValue] = useState("primary");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // setClassValue("outline-primary");
    campaignStatuses.map((data: any, index: number) => {
      if (index === newValue) {
        setSelectedCampaignStatus(data.name);
      }
    })
  };


  // for campaign list confirm popups
  const [popUps, setPopUps] = useState({
    confirmPausePlayCampaign: false,
    confirmMarkCampaignComplete: false,
    confirmMarkUnarchive: false,
    unarchiveWarning: false
  });
  // const [openPausePlayCampaignPopup, setOpenPausePlayCampaignPopup] = useState(false);
  const [selectedCampaignData, setSelectedCampaignData] = useState<any>({
    campaign_id: '',
    Status: '',
    Title: ''
  });
  const [confirmPopupInnerText, setConfirmPopupInnerText] = useState("Are you sure want to pause the campaign?");
  // const [confirmMarkCompletePopupInnerText, setConfirmMarkCompletePopupInnerText] = useState("Are you sure?");

  // const [filteredPlanListDetail, setFilteredPlanListDetail] = useState()
  const [planInfo, setPlanInfo] = useState({
    industry: props.UserReducer.brand_category || '',
    category: props.UserReducer.brand_category || '',
    organization: props.UserReducer.brand_organization || '',
    brand: props.UserReducer.brand_name || '',
    description: ''
  });
  // const filterplanListDetail = () => {
  //   const filteredPlanList: any = planList?.filter((plan: any) => {
  //     return plan?.name === props?.selectPlan

  //   })
  //   return setFilteredPlanListDetail(filteredPlanList)
  // }
  // useEffect(() => {
  //   filterplanListDetail()
  // }, [])
  const headCells = [
    {
      id: "Title",
      label: "Campaign Name",
      minWidth: '220px',
      maxWidth: '220px',
    },
    {
      id: "Status",
      label: "Status",
      minWidth: '80px',
      maxWidth: '80px',
    },
    {
      id: "Influencers",
      label: "Influencers",
      minWidth: '110px',
      maxWidth: '110px',
    },
    {
      id: "Creatives",
      label: "Creatives",
      minWidth: '90px',

      maxWidth: '90px',
    },
    // {
    //   id: "er",
    //   label: "ER",
    //   minWidth: '30px',

    //   maxWidth: '30px',
    // },
    {
      id: "Budget",
      label: "Budget",
      minWidth: '10%',

      maxWidth: '10%',
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const sortedData: any = React.useMemo(() => {
    const sortedArray = [...campaignList].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
      return 0;
    });
    return sortedArray;
  }, [campaignList, orderBy, order]);

  useEffect(() => {
    getCampaignList();
  }, [props.UserReducer.brand_id, props.UserReducer.user_id, props.selectPlan, selectedCampaignStatus]);

  useEffect(() => {
    checkBasicUser();
  }, []);

  async function checkBasicUser() {
    try {
      const response: any =
        await subscriptionService.getSubscription_Remaining_Days({
          user_id: props?.UserReducer?.user_id,
          account_id: props?.UserReducer?.account_id,
          influencer_id: props?.UserReducer?.influencer_id
        });
      if (response.status === gtUtil.Constants.SUCCESS) {
        setBasicUser(false);
      }
    } catch (error) {
      console.log(
        `checkBasicUser error in checkBasicUser in My campaigns brand Section :: ${JSON.stringify(
          error
        )}`
      );
    }
  }

  const getCampaignList = async () => {
    props.loading(true);
    try {
      listService.campaign_list({
        brand_id: props.UserReducer.brand_id,
        campaign_status: selectedCampaignStatus,
        plan_id: props.planId
      })
        .then((res: any) => {
          try {
            let campaign_counts_data: any = []
            for (const key in res.data.campaign_counts) {
              let name: string;
              switch (key) {
                case 'live_count':
                  name = 'Live';
                  break;
                case 'pause_count':
                  name = 'Pause';
                  break;
                case 'draft_count':
                  name = 'Draft';
                  break;
                case 'completed_count':
                  name = 'Completed';
                  break;
                case 'archived_count':
                  name = 'Archived';
                  break;
                case 'rejected_count':
                  name = 'Rejected';
                  break;
                case 'under_review_count':
                  name = 'Under Review';
                  break;
                case 'total_campaign_count':
                  name = 'Unknown'
                  break;
                default:
                  name = 'Live';
                  break;
              }
              // If the name is known, add it to the data
              if (name !== 'Unknown') {
                campaign_counts_data.push({ name, count: res.data.campaign_counts[key] });
              }
            }
            // setSelectedCampaignStatus(res.data.active_tab);
            setPlanDescription(res.data?.rows[0]?.plan_description);
            setValue(res.data.active_tab_index);
            setCampaignStatuses(campaign_counts_data);
            if (res.data.status) {
              let filteredCampaignList = [];

              if (props?.selectPlan?.length > 0) {
                filteredCampaignList = res.data.rows.filter(
                  (data: any) => data["plan_id"] === props.planId
                );
              } else {
                filteredCampaignList = res.data.rows;
              }
              setCampaignList(filteredCampaignList);
              setPage(0);
              props.loading(false);
            } else {
              // localService.toastify(res.data.status.message, "error", 1000);
            }
          } catch (err) {
            throw new Error(`Data Align Error :: ${err}`);
          }
        })
        .catch((e) => {
          throw new Error(`Campaign List function error :: ${e}`);
        });

      listService.planList({
        user_id: props.UserReducer.user_id,
        brand_id: props.UserReducer.brand_id,
      }).then((res: any) => {
        setplanList(res.data.rows);
      });
    } catch (error) {
      throw new Error(`campaign List error :: ${JSON.stringify(error)} `);
    } finally {
      props.loading(false);
    }
  }

  const handlePlanUpdate = (plan_name: string) => {
    props.updatePlan(plan_name);
     getCampaignList(); 
  };


  useEffect(() => {
    setPlanInfo(prevState => ({
      ...prevState,
      description: planDescription
    }));
  }, [planDescription]);


  useEffect(() => {
    if (props.search.length > 0) {
      const filteredSearchDataArray = sortedData.filter((data: any) => {
        return Object.values(data)
          .join("")
          .toLocaleLowerCase()
          .includes(props.search.toLocaleLowerCase());
      });
      setPaginationCountBoolean(true);
      setFilteredSearchData(filteredSearchDataArray);
      setPage(0);
    } else {
      setPaginationCountBoolean(false);
    }
  }, [props.search]);

  //
  const handleAddCampaign = (plan_id: any) => {
    listService.add_Campaign({
      brand_id: props.UserReducer.brand_id,
      user_id: props.UserReducer.user_id,
      plan_id: plan_id,
    }).then((res: any) => {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer({
          ...createCampaignInitialState,
          createCampaign: {
            campaign_id: res?.data?.status.campaign_id,
          },
          createPlan: res?.data?.rows[0],
          navigation: {
            ...createCampaignInitialState.navigation,
            campaign_create: true,
          },
          completeStep: {
            ...createCampaignInitialState.completeStep,
            createPlan: true,
          },
        });

        navigate("/brand/campaign/createcampaign");
      }
    });
  };

  const handleContinue = () => {
    if (radiovalue === 2) {
      //get Plan details
      if ((selectPlan + "").length > 0) {
        handleAddCampaign(selectPlan);
      } else {
        // toast.info("Please Select Plan.");
        localService.toastify("Please select plan", "info", 1000);
      }
    } else {
      if (props.CreateCampaignReducer) {
        props.CreateCampaignReducer(createCampaignInitialState);
      }
      navigate("/brand/campaign/createplan");
    }
  };

  const handleEdit = (data: any) => {
    props.loading(true);
    CreateCampaignService.edit_Campaign({
      brand_id: data.brand_id,
      user_id: props.UserReducer.user_id,
      plan_id: data.plan_id,
      campaign_id: data.campaign_id,
    })
      .then((res: any) => {
        if (res.data.status.status) {
          if (props.CreateCampaignReducer) {
            props.CreateCampaignReducer(res.data.rows);
            navigate("/brand/campaign/createplan");
          }
        }
        props.loading(false);
      })
      .catch((error) => {
        props.loading(false);
        throw new Error(
          `handle Edit campaign List error :: ${JSON.stringify(error)}`
        );
      });
  };

  async function deleteCampaign(data: { campaign_id: number }) {
    try {
      const confirmed = window.confirm("Are you sure you want to delete this campaign?");
      if (confirmed) {
        const response = await campaignFlowService.deleteCampaign({
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          campaign_id: data.campaign_id,
        });
        if (response.data.status.status) {
          getCampaignList();
        }
      }
    } catch (error) {
      throw new Error(
        `deleteCampaign function error :: ${JSON.stringify(error)}`
      );
    }
  }

  const handleAnalyticsNavigate = (data: any) => {
    localStorage.setItem('activeCampaignDetailMenu', 'Analytics');
    navigate(`/brand/campaigns/analytics?planID=${data.plan_id}&campaignID=${data.campaign_id}&planName=${data["Plan Name"]}`);
  }

  const handleMarkComplete = (data: { campaign_id: number, Status: string, Title: string }) => {
    setConfirmPopupInnerText(`Once the campaign is marked as complete, the analytics<br>for the added links will be updated till 14 more days.<br><br>Are you sure you want to mark campaign <strong>${data.Title}</strong> as complete?`);
    setSelectedCampaignData(data);
    // setPopUps({...popUps, confirmMarkCampaignComplete: true})
    setPopUps({ confirmMarkCampaignComplete: true, confirmPausePlayCampaign: false, confirmMarkUnarchive: false, unarchiveWarning: false })
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handlePausePlayCampaignConfirm = (data: { campaign_id: number, Status: string }) => {
    setConfirmPopupInnerText(data.Status === 'Pause' ? 'Are you sure want to resume the campaign?' : 'Are you sure want to pause the campaign?');
    setSelectedCampaignData(data);
    // setOpenPausePlayCampaignPopup(true);
    // setPopUps({...popUps, confirmPausePlayCampaign: true})
    setPopUps({ confirmMarkCampaignComplete: false, confirmPausePlayCampaign: true, confirmMarkUnarchive: false, unarchiveWarning: false })
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const checkHandleUnarchiveCampaignConfirm = async (data: { campaign_id: number, Status: string, Title: string }) => {
    setSelectedCampaignData(data);
    const response = await campaignFlowService.can_unarchive_campaign({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      brand_id: props.UserReducer.brand_id,
      influencer_id: props.UserReducer.influencer_id,
      campaign_id: data.campaign_id
    });
    if (response.data.status) {
      if (!response.data.basic_user || response.data.unarchive_allowed){
        handleUnarchiveCampaignConfirm(data);
      }else {
        setPopUps({ confirmMarkCampaignComplete: false, confirmPausePlayCampaign: false, confirmMarkUnarchive: false, unarchiveWarning: true })
      }
    }
  }

  const handleUnarchiveCampaignConfirm = (data: { campaign_id: number, Status: string, Title: string }) => {
    setSelectedCampaignData(data);
    setConfirmPopupInnerText(`Are you sure you want to unarchive the campaign <strong>${data.Title}</strong>?`);
    setSelectedCampaignData(data);
    // setPopUps({...popUps, confirmMarkCampaignComplete: true})
    setPopUps({ confirmMarkCampaignComplete: false, confirmPausePlayCampaign: false, confirmMarkUnarchive: true, unarchiveWarning: false })
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }
  const handleCloseConfirmationPopUp = () => {
    setPopUps({ confirmMarkCampaignComplete: false, confirmPausePlayCampaign: false, confirmMarkUnarchive: false, unarchiveWarning: false })
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  async function handlePauseResumeCampaign() {
    try {
      const response = await campaignFlowService.updateCampaignStatus({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        campaign_id: selectedCampaignData.campaign_id,
        status: (selectedCampaignData.Status === 'Live' ? 'Pause' : 'Live'),
        reason: ''
      });
      if (response.data.status) {
        getCampaignList();
      }
    } catch (error) {
      throw new Error(
        `updateCampaignStatus function error :: ${JSON.stringify(error)}`
      );
    }
  }

  async function handleUnarchiveCampaign() {
    try {
      const response = await campaignFlowService.updateCampaignStatus({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        campaign_id: selectedCampaignData.campaign_id,
        status: 'Pause',
        reason: ''
      });
      if (response.data.status) {
        getCampaignList();
      }
    } catch (error) {
      throw new Error(
        `updateCampaignStatus function error :: ${JSON.stringify(error)}`
      );
    }
  }

  async function markCampaignComplete(data: any) {
    try {
      const response = await campaignFlowService.updateCampaignStatus({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        campaign_id: selectedCampaignData.campaign_id,
        status: data.status,
        reason: data.reason
      });
      if (response.data.status) {
        getCampaignList();
        handleCloseConfirmationPopUp();
      }
    } catch (error) {
      throw new Error(
        `updateCampaignStatus function error :: ${JSON.stringify(error)}`
      );
    }
  }

  const navigateCampaignList = (data: any) => {
    if (data["Status"] !== 'Draft') {
      localStorage.setItem('activeCampaignDetailMenu', 'Campaign Details');
      navigate(`/brand/campaigns/campaign-details?planID=${data.plan_id}&campaignID=${data.campaign_id}`);
    }
  };

  const handleCloseModal = () => {
    setShowPopup(false)
  }
  const handlePlanNeed = () => {
    setShowPlanNeed(prev => !prev)

  }

  const handlePlanDetailPopup = (data: any) => {
    setOpenPlanDetailPopup(true);
  };
  const handlePlanDetailPopupClose = (id: any) => {
    setOpenPlanDetailPopup(false);
  };
  useEffect(() => {
    if (planList.length > 0) {
      setRadioValue(2);
    }
  }, [planList]);
  return (
    <div className="pln_camp_main_container">
      {showPopup && (
        <CommonPopUp
          modalTitle="Create campaign"
          modalContent=""
          openModal={showPopup}
          onClose={() => handleCloseModal()}
          className="popup-select-plan modal-medium">
          <div className="create_new_campaign_popup">
            <div className="row">
              <div className="col">
                <span className="select_plan_campaign fw-600">
                  Select a plan for the Campaign
                </span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <FormControl size="small">
                  <RadioGroup
                    onChange={(e: any) => setRadioValue(+e.target.value)}
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={radiovalue}
                    name="radio-buttons-group"
                  >
                    <div className='d-flex flex-wrap'>
                      <FormControlLabel
                        value={1}
                        checked={radiovalue === 1}

                        control={<Radio />}
                        label="New Plan"
                      />
                      <span className='btn btn-text-link align-self-center fs-12' onClick={handlePlanNeed} style={{ cursor: 'pointer' }}>Why is a plan needed?</span>
                      {showPlanNeed && (
                        <p className='fs-14'>Brands can create plans for their diverse products/services, each aiming to achieve different objectives such as driving awareness, performance, or engagement.</p>
                      )}
                    </div>

                    <FormControlLabel
                      value={2}
                      disabled={planList.length < 1}
                      control={<Radio />}
                      defaultChecked={planList.length > 0 && radiovalue === 2}
                      checked={planList.length > 0 && radiovalue === 2}
                      label="Existing Plan"
                    />

                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {(planList.length > 0 && (radiovalue === 2)) &&
              <div className="row mb-3">
                <div className="col">
                  <FormControl
                    fullWidth
                    size="small"
                    className="pln_popup_select input-common input-style-select"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select Campaign
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectPlan}
                      label="Select Campaign"
                      onChange={(e) => setSelectPlan(e.target.value)}
                      inputProps={{
                        MenuProps: {
                          disableScrollLock: true,
                        },
                      }}
                    >
                      {planList.length > 0 ? (
                        planList.map((res: any, index: number) => {
                          return (
                            <MenuItem key={index} value={res.plan_id}>
                              {res.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem key={1} value={1}>
                          {"No Data"}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
            }
            <div className="col text-end plan_camp_btn_group popup-footer">
              <Button
                className="btn btn-outline-primary btn-mui"
                onClick={() => setShowPopup(!showPopup)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary btn-mui ms-2"
                onClick={handleContinue}
                variant="contained"
              >
                Continue
              </Button>
            </div>
          </div>
        </CommonPopUp>
      )}
      <PlanDetailDrawer onClose={handlePlanDetailPopupClose} closeDrawer={handlePlanDetailPopupClose} onOpen={handlePlanDetailPopup} open={openPlanDetailPopup} planInfo={planInfo} selectedPlanName={props?.selectPlan} planId={props.planId} onSuccess={handlePlanUpdate}/>
      {campaignList.length > 0 ? (
        <>
        <div className="create_plan_top_buttons">
          <div className='plan-details'>
            {!props?.selectPlan &&
              <strong>All Plans</strong>
            }
            <div className='scrolling-text-sec'>
              <div className='inner-scrolling-text-container'>
                <strong className={`${props?.selectPlan.length > 30 ? 'inner-scrolling-text' : ""}`}>{props?.selectPlan && props.selectPlan}</strong>
              </div>
            </div>
            {props?.selectPlan &&
              <span className='cursor-pointer' onClick={handlePlanDetailPopup}>View Plan Details</span>
            }
          </div>
        
        </div>
        <div className="campaign-status-tabs-sec">
          <div className="campaign-status-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              className="sub-tabs nav-rounded"
              TabIndicatorProps={{ hidden: true }}
            >
              {campaignStatuses.map((res: any, index: number) => {
                return (
                  <Tab
                    disabled={res.count < 1}
                    wrapped
                    className={`${index === value ? "sub-tabs-link active" : "sub-tabs-link"
                      }`}
                    key={index}
                    label={
                      <>{(res?.name.length > 30) ? (
                        <div className='scrolling-text-sec'>
                          <div className='inner-scrolling-text-container'>
                            <span className="inner-scrolling-text camp-title">
                              {res?.name} ({res.count})
                            </span>
                          </div>
                        </div>
                      )
                        : <span className="inner-scrolling-text camp-title">
                          {res?.name} ({res.count})
                        </span>
                      }
                      </>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
          <ul className="campaign-legend">
            <li className="campaign-legend_pending">Pending</li>
            <li className="campaign-legend_rejected">Rejected</li>
            <li className="campaign-legend_negotiating">Negotiating</li>
            <li className="campaign-legend_accepted">Accepted</li>
          </ul>
        </div>
        <div className="pb-3 campaign-list-table">
          <TableContainer className="common-table">
            <Table sx={{ minWidth: 650 }}>
              <TableHead className="common-table-header">
                <TableRow>
                  {headCells.map((res, index) => {
                    return (
                      <TableCell className="header_text" align="center" key={res?.id}>
                        <TableSortLabel
                          active={orderBy === res.id}
                          direction={order}
                          onClick={() => handleSort(res.id)}
                          sx={{
                            width: res.minWidth,
                          }}
                        >
                          {res.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="common-table-body">
                {sortedData
                  .filter((data: any) => {
                    return Object.values(data)
                      .join("")
                      .toLocaleLowerCase()
                      .includes(props.search.toLocaleLowerCase());
                  })
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((data: any, indexs: number) => {
                    return (
                      <React.Fragment key={indexs}>
                        <TableRow
                          key={indexs}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className={(data["Status"] + "").toLocaleLowerCase()}
                        >

                          <TableCell
                            className="table_cell"
                            component="td"
                            scope="row"
                          >
                            <div className='d-flex me-auto'>
                              <div className='campaign-img'>
                                <img src={data?.campaign_cover_url ? data?.campaign_cover_url : DummyCampaignImage.default_campaign_image} alt="" style={{ width: '48px', height: '48px', borderRadius: '50%', marginRight: '10px', border: '1px solid rgba(0,0,0,0.3)', objectFit: 'contain' }} />
                              </div>

                              <span
                                className="table-plan-name me-auto"
                              >
                                <span className={`table-plan-title ${data["Status"] === 'Draft' ? 'table-plan-title-draft' : 'table-plan-title-non-draft'}`} onClick={() => {
                                  navigateCampaignList(data);
                                }}
                                ><Tooltip title={data["Title"]}><span>{data["Title"]}</span></Tooltip></span>
                                <div className='d-flex flex-column'>
                                  <div className='d-flex align-items-center'>
                                    <span className='me-1'>{data["Platform"] === "Instagram" ? <InstagramIcon className='icon-instagram mb-1' width={20} height={17} /> : <YouTube className='icon-youtube' />}</span>
                                    <span>{data["Type"]}{data["Visibility"] == "Private" ? " (Private)" : ""}</span>
                                  </div>
                                </div>
                              </span>
                            </div>
                            <div className="d-flex align-items-center me-5">
                              {!["Live", "Deleted", "Under Review", "Pause", "Hold", "External"].includes(data["Status"]) && (
                                <span className="span_icon me-2 align-self-center cursor-pointer">
                                  <Tooltip title="Delete campaign" arrow>
                                    <span onClick={() => deleteCampaign(data)}
                                      className='edit_icon icons material-symbols-rounded'>
                                      delete
                                    </span>
                                  </Tooltip>

                                </span>
                              )}
                              {(['Draft', 'Rejected'].includes(data["Status"])) && (
                                <span className="span_icon me-2 align-self-center cursor-pointer">
                                  <Tooltip title="Edit campaign" arrow>
                                    <span onClick={() => handleEdit(data)}
                                      className='edit_icon icons material-symbols-rounded'>
                                      edit
                                    </span>
                                  </Tooltip>
                                </span>
                              )}
                              {["Live", "Pause"].includes(data["Status"]) && data['posted_days_count'] <= data['live_brief_days_limit'] && (new Date(data['Proposal']) >= new Date()) && (
                                <>
                                  <span
                                    className="span_icon me-2 align-self-center cursor-pointer"
                                  >
                                    {
                                      (data["Status"] === 'Live')
                                        ?
                                        <Tooltip title="Pause campaign" arrow>
                                          <span onClick={() => handlePausePlayCampaignConfirm(data)} className=' edit_icon icons material-symbols-rounded material-symbols-fw-200-filled'>pause</span>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Resume campaign" arrow>
                                          <span onClick={() => handlePausePlayCampaignConfirm(data)} className='edit_icon icons material-symbols-rounded'>play_arrow</span>
                                        </Tooltip>
                                    }
                                  </span>
                                </>
                              )}

                              {/* {["Live", "Hold"].includes(data["Status"]) && data['posted_days_count'] > data['live_brief_days_limit'] && data['republish_count'] < data['allowed_republish_count'] && basicUser && data['foc'] == 'true' && (
                                <span className="span_icon me-2 align-self-center cursor-pointer">
                                  <Button
                                    className='btn btn-primary buy-btn'
                                    variant="contained"
                                    onClick={() => handleEdit(data)}
                                  >
                                    Republish
                                  </Button>
                                </span>
                              )} */}

                              {["Live", "Completed", "Pause", "Hold", "Archived"].includes(data["Status"]) && (
                                <>
                                  <span className="span_icon me-2 align-self-center cursor-pointer">
                                    <Tooltip title="Campaign analytics" arrow>
                                      <span onClick={() => handleAnalyticsNavigate(data)}>
                                        <IconAnalyticsCampaign />
                                      </span>
                                    </Tooltip>
                                  </span>

                                  <span className="span_icon me-2 align-self-center cursor-pointer">
                                    <Tooltip title={data.Status === 'Completed' ? 'Marked Complete' : (data.Status === 'Archived' ? 'Click to unarchive' : 'Mark Complete')} arrow>
                                      {['Completed', 'Archived'].includes(data.Status) ? (
                                        <CheckCircleIcon style={{ color: 'green' }} onClick={(e) => data.Status === 'Archived' ? checkHandleUnarchiveCampaignConfirm(data) : e.preventDefault()} />
                                      ) : (
                                        // <UseAnimations 
                                        //   animation={checkmark} 
                                        //   // size={56} 
                                        //   strokeColor="currentColor" 
                                        //   onClick={() => {
                                        //     handleMarkComplete(data);
                                        //   }}
                                        // />
                                        <DoneIcon style={{ color: 'currentColor' }} onClick={() => handleMarkComplete(data)} />
                                      )}
                                    </Tooltip>
                                  </span>
                                </>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className=''>
                            <div className='d-flex flex-column'>
                              <span>
                                {data["Status"]}
                              </span>
                              <span>
                                {data["Status"] === 'Completed' && methods.formatDatetime(new Date(data["completed_at"]), 'yyyy-mm-dd')} 
                                {data["Status"] === 'Archived' && methods.formatDatetime(new Date(data["archived_at"]), 'yyyy-mm-dd')} 
                                {data["Status"] === 'Rejected' && methods.formatDatetime(new Date(data["reviewed_on"]), 'yyyy-mm-dd')} 
                                {!['Completed', 'Archived', 'Rejected'].includes(data["Status"]) && methods.formatDatetime(new Date(data["posted_at"]), 'yyyy-mm-dd')}
                              </span>
                            </div>
                          </TableCell>

                          <TableCell>
                            {
                              (data["Type"] == "Analytics")
                                ?
                                <span className="d-flex justify-content-center align-items-center">-</span>
                                :
                                <>
                                  <Tooltip
                                    arrow
                                    title={`Pending - ${data.proposal_pending}`}
                                    style={{ backgroundColor: '#FFEEB2' }}
                                  >
                                    <span className="camp-status draft">
                                      {data?.proposal_pending}
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    arrow
                                    title={`Rejected - ${data.proposal_rejected}`}
                                    style={{ backgroundColor: 'rgb(255, 202, 202)' }}
                                  >
                                    <span className="camp-status under-review">
                                      {data?.proposal_rejected}
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    arrow
                                    title={`Negotiating - ${data.proposal_negotiating}`}
                                    style={{ backgroundColor: '#f6e9ff' }}
                                  >
                                    <span className="camp-status negotiating">
                                      {data?.proposal_negotiating}
                                    </span>
                                  </Tooltip>
                                  <Tooltip arrow
                                    title={`Accepted - ${data.proposal_accepted}`}
                                    style={{ backgroundColor: '#caffe6' }}
                                  >
                                    <span className="camp-status" >
                                      {data?.proposal_accepted}
                                    </span>
                                  </Tooltip>
                                </>
                            }


                          </TableCell>
                          <TableCell>
                            {
                              (data["Type"] == "Analytics") ? <span className="d-flex justify-content-center align-items-center">-</span>
                                :
                                <>
                                  <Tooltip
                                    arrow
                                    title={`Pending - ${data.creative_pending}`}
                                    style={{ backgroundColor: '#FFEEB2' }}
                                  >
                                    <span className="camp-status draft">
                                      {data?.creative_pending}
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    arrow
                                    title={`Rejected - ${data.creative_rejected}`}
                                    style={{ backgroundColor: 'rgb(255, 202, 202)' }}
                                  >
                                    <span className="camp-status under-review">
                                      {data?.creative_rejected}
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    arrow
                                    title={`Approved - ${data.creative_approved}`}
                                    style={{ backgroundColor: '#caffe6' }}
                                  >
                                    <span className="camp-status">
                                      {data?.creative_approved}
                                    </span>
                                  </Tooltip>
                                </>
                            }
                          </TableCell>
                          {/* <TableCell
                          >
                            {data.er}
                          </TableCell> */}
                          <TableCell>{data?.Budget == 0 ? "NA" : data?.Budget}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className='common-pagination'
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={
              paginationCountBoolean
                ? filteredSearchData.length
                : sortedData.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "#00aae5",
            }}
          />
        </div>
        </>

      ) : (
        <>
          <div className="campaign-status-tabs-sec">
            <div className="campaign-status-tabs">
              <Tabs
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                className="sub-tabs nav-rounded"
                TabIndicatorProps={{ hidden: true }}
              >
                {campaignStatuses.map((res: any, index: number) => {
                  
                  return (
                    <Tab
                      wrapped
                      className={`${index === value ? "sub-tabs-link active" : "sub-tabs-link"
                        } ${res.count > 0 ? "" : "disabled"}`}
                      key={index}
                      label={
                        <>{(res?.name.length > 30) ? (
                          <div className='scrolling-text-sec'>
                            <div className='inner-scrolling-text-container'>
                              <span className="inner-scrolling-text camp-title">
                                {res?.name} ({res.count})
                              </span>
                            </div>
                          </div>
                        )
                          : <span className="inner-scrolling-text camp-title">
                            {res?.name} ({res.count})
                          </span>
                        }
                        </>
                      }
                    />
                  );
                })}
              </Tabs>
            </div>
          </div>
          <p className="no-list-comment">You have not created any campaign yet.</p>
        </>
      )}

      {popUps.confirmPausePlayCampaign && createPortal(<ConfirmPopup confirmPopupInnerText={confirmPopupInnerText} openPopup={popUps.confirmPausePlayCampaign} confirmClosePopup={handleCloseConfirmationPopUp} confirmClickAction={() => handlePauseResumeCampaign()} drawerTitle="" />, document.body)}

      {/* {popUps.confirmMarkCampaignComplete && createPortal(<ConfirmPopup confirmPopupInnerText={confirmPopupInnerText} openPopup={popUps.confirmMarkCampaignComplete} confirmClosePopup={handleCloseConfirmationPopUp} confirmClickAction={() => markCampaignComplete()} drawerTitle="" />, document.body)} */}

      {popUps.confirmMarkCampaignComplete && createPortal(
        <MarkCompletePopup 
          // confirmPopupInnerText={confirmPopupInnerText} 
          openPopup={popUps.confirmMarkCampaignComplete} 
          confirmClosePopup={handleCloseConfirmationPopUp} 
          confirmClickAction={markCampaignComplete} 
          drawerTitle="" 
          selectedCampaignData={selectedCampaignData}
        />, document.body
      )}

      {popUps.confirmMarkUnarchive && createPortal(<ConfirmPopup confirmPopupInnerText={confirmPopupInnerText} openPopup={popUps.confirmMarkUnarchive} confirmClosePopup={handleCloseConfirmationPopUp} confirmClickAction={() => handleUnarchiveCampaign()} drawerTitle="" />, document.body)}

      {popUps.unarchiveWarning && createPortal(
        <CommonPopUp 
          modalTitle="Alert"
          openModal={popUps.unarchiveWarning} 
          onClose={handleCloseConfirmationPopUp}
          className="modal-medium mx-auto"
        >
          <div className="common-popup">
            <p className="para1">
              Archive or mark your previous campaign as complete to unarchive <strong>{selectedCampaignData['Title']}</strong> or <Link to={'/brand/settings/subscription'} className="btn btn-text-link">upgrade to premium.</Link>
            </p>
          </div>
          <div className='gap-2 d-flex popup-footer justify-content-end'>
            <Button
              type="button"
              variant="contained"
              onClick={(e) => { handleCloseConfirmationPopUp() }}
              className="btn btn-outline-primary"
            >
              OK
            </Button>
          </div>
        </CommonPopUp>, document.body)
      }
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    campaignReducer: state.CreateCampaignReducer,
    UserID: state.UserID.userID,
  };
};

const mapDispatchToState = (dispatch: any) => {
  return {
    CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
    loading: (status: boolean) => dispatch(Loading(status)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToState
)(CampaingList);

export { connectToRedux as CampaingList };
