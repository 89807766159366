import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { InvoiceService, LocalService } from "../../../../../../core/services";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  InputAdornment,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
} from "@mui/material";
// important common popup component
import CommonPopUp from "../../../../../../components/popup/common-popup";
// import { useParams } from "react-router-dom";
import MaterialSymbol from "../../../../../../components/icons/material-symbol";
import * as gtUtil from "../../../../../../core/utility";
import ConfirmPopup from "../../../../../../components/confirm-popup/confirm-popup";
import { createPortal } from "react-dom";

const invoiceService = new InvoiceService();
const localService = new LocalService();

// interface Proposal {
//   name: string;
//   total_price: number;
//   payment_status: string;
//   account_type?: string;
//   account_holder_name?: string;
//   account_number?: string;
//   ifsc_code?: string;
//   address?: string;
//   influencer_id: number;
//   proposal_id: number;
//   file_urls: string;
//   file_url?: string;
// }

interface Invoice {
  file_url: string;
  file_name: string;
  total_amount: number; // invoice total amount.
}

interface Proposal {
  total_price: number;
  name: string;
  influencer_id: number;
  proposal_id: number;
  payment_status: string;
  account_type?: string;
  bank_name?: string;
  ifsc_code?: string;
  account_holder_name?: string;
  account_number?: string;
  address?: string;
  micr_code?: string;
  // file_urls: string;
  invoices: Invoice[];
}

interface Row {
  [key: string]: any; // Allows any key with any value
}

interface RenderAcknoledgeReceiptFormProps {
  UserReducer?: any;
  acceptedProposalPaymentDetails?: Proposal[];
  proposal?: any;
  onUpdatePaymentStatus?: (updatedProposal: Proposal) => void;
  selectedCampaign?: any;
  handleUploadReceipt?: any;
  invoiceId?: any;
  invoicePaymentStatus?: string;
  setUpdateAcceptedProposalPaymentDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const RenderAcknoledgeReceiptForm: React.FC<
  RenderAcknoledgeReceiptFormProps
> = ({
  proposal,
  onUpdatePaymentStatus,
  UserReducer,
  acceptedProposalPaymentDetails,
  selectedCampaign,
  handleUploadReceipt,
  invoiceId,
  invoicePaymentStatus,
  setUpdateAcceptedProposalPaymentDetails
}) => {
  interface PaymentReceipt {
    id: number;
    proposal_invoice_doc_id: number;
    amount: number;
    payment_acknowledgement: string;
    proposal_id: string;
    transaction_id: string;
    comments: string;
    fileUrls: string[];
    file_url?: string;
    file_name?: string;
    sm_id: number;
    payment_status: string;
    invoice_file_name: string;
    invoice_dt: null | string;
  }

  // Initialize the state with an array of PaymentReceipt objects
  const [paymentReceipts, setPaymentReceipts] = useState<PaymentReceipt[]>([]);
  // const { smId } = useParams();

  // Acknowledgement Modal form data
  const [acknowledgementFormData, setAcknowledgementFormData] =
    useState<PaymentReceipt>({
      id: 0,
      proposal_invoice_doc_id: 0,
      amount: 0,
      payment_acknowledgement: "",
      proposal_id: "",
      transaction_id: "",
      comments: "",
      fileUrls: [],
      file_name: "",
      sm_id: 0,
      payment_status: "Pending",
      invoice_file_name: "",
      invoice_dt: null
    });

  // set Modal variables and usestate
  const [openAcknowledgementModal, setOpenAcknowledgementModal] =
    useState(false);
  const [acknowledgementModalContent, setAcknowledgementModalContent] =
    useState("");
  const [acknowledgementModalTitle, setAcknowledgementModalTitle] =
    useState("Payment Details");
  const handleOpenAcknowledgementModal = () =>
    setOpenAcknowledgementModal(true);
  function handleCloseAcknowledgementModal() {
    setOpenAcknowledgementModal(false);
    setAcknowledgementFormData({
      id: 0,
      proposal_invoice_doc_id: 0,
      amount: 0,
      payment_acknowledgement: "",
      proposal_id: "",
      transaction_id: "",
      comments: "",
      fileUrls: [],
      file_name: "",
      sm_id: 0,
      payment_status: "Pending",
      invoice_file_name: "",
      invoice_dt: null
    });
  }

  async function getPaymentReceipts() {
    const response: any = await invoiceService.getProposalReceipts({
      proposal_id: proposal.proposal_id,
      invoice_id: invoiceId,
    });
    // const checkStatus = (response.status === gtUtil.Constants.SUCCESS) ? 'success' : 'error';
    if (response.status === gtUtil.Constants.SUCCESS) {
      const rows: Row[] = response.data.rows;
      // Process the rows to store the array of objects
      const processedReceipts: PaymentReceipt[] = rows.map((row) => ({
        id: row.id,
        proposal_invoice_doc_id: row.proposal_invoice_doc_id,
        amount: row.amount,
        transaction_id: row.transaction_id,
        comments: row.comments,
        fileUrls: row.file_url ? row.file_url.split(",") : [],
        payment_acknowledgement: row.payment_acknowledgement,
        proposal_id: row.proposal_id,
        sm_id: row.sm_id,
        payment_status: row.payment_status,
        invoice_file_name: row.invoice_file_name,
        invoice_dt: row.invoice_dt
      }));
      setPaymentReceipts(processedReceipts);
    }
  }
  
  const handleReceiptAckClick = (event: any) => {
    const dataset = event.currentTarget.dataset;
    setAcknowledgementFormData({
      id: dataset.id,
      proposal_invoice_doc_id: dataset.proposal_invoice_doc_id,
      amount: dataset.amount,
      payment_acknowledgement: dataset.payment_acknowledgement,
      proposal_id: dataset.proposal_id,
      transaction_id: dataset.transaction_id,
      comments: dataset.comments,
      fileUrls: [dataset.file_url],
      sm_id: dataset.sm_id,
      payment_status: dataset.payment_status,
      invoice_file_name: dataset.invoice_file_name,
      invoice_dt: dataset.invoice_dt
    });
    // Open the modal
    handleOpenAcknowledgementModal();
  };

  // const handleReceiptAckChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   try {
  //     if (event.target.value === "Received") {
  //       const newAcknowledgementFormData = {
  //         ...acknowledgementFormData,
  //         payment_acknowledgement: event.target.value
  //       };
  //       setAcknowledgementFormData(newAcknowledgementFormData);
  //       const response: any = await invoiceService.submitProposalReceiptAck(
  //         newAcknowledgementFormData
  //       );
  //       // to update the acceptedProposalPaymentDetails
  //       setUpdateAcceptedProposalPaymentDetails(true);
  //       const checkStatus = (response.status === gtUtil.Constants.SUCCESS) ? "success" : "error";
  //       localService.toastify(response.info, checkStatus, 1000);
  //       if (checkStatus === "success") {
  //         proposal.payment_status = "Paid";
  //         // onUpdatePaymentStatus(proposal);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(`Something went wrong :: ${error}`);
  //   } finally {
  //     handleCloseAcknowledgementModal();
  //     getPaymentReceipts();
  //   }
  // };


  const handleReceiptAckChange = async (status: "Received" | "Not Received") => {
  try {
    if (status === "Received") {
      const newAcknowledgementFormData = {
        ...acknowledgementFormData,
        payment_acknowledgement: status
      };
      setAcknowledgementFormData(newAcknowledgementFormData);
      
      const response: any = await invoiceService.submitProposalReceiptAck(newAcknowledgementFormData);
      
      // to update the acceptedProposalPaymentDetails
      setUpdateAcceptedProposalPaymentDetails(true);
      const checkStatus = response.status === gtUtil.Constants.SUCCESS ? "success" : "error";
      localService.toastify(response.info, checkStatus, 1000);
      
      if (checkStatus === "success") {
        // proposal.payment_status = "Paid";
        const newAcknowledgementFormData = {
          ...acknowledgementFormData,
          payment_acknowledgement: "Received",
          payment_status: "Paid"
        };
        setAcknowledgementFormData(newAcknowledgementFormData);
      }
    }
  } catch (error) {
    console.error(`Something went wrong :: ${error}`);
  } finally {
    handleClosePaymentReceivedModal();
    // getPaymentReceipts();
  }
};

  useEffect(() => {
    getPaymentReceipts();
  }, [proposal, onUpdatePaymentStatus]);
  
  const [openPaymentReceivedModal, setOpenPaymentReceivedModal] = useState(false)
  
  // const [openPaymentNotReceivedModal, setOpenPaymentNotReceivedModal] = useState(false)
  

  function handleOpenPaymentReceivedModal() {
    setOpenPaymentReceivedModal(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  function handleClosePaymentReceivedModal() {
    setOpenPaymentReceivedModal(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  }
  function handleOpenPaymentNotReceivedModal() {
    setOpenAcknowledgementModal(false); 
      document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active'); 
  }

  // function handleClosePaymentNotReceivedModal() {
  //   setOpenPaymentNotReceivedModal(false);
  //   document
  //     .getElementsByTagName('body')[0]
  //     .classList.remove('custom-drawer-active');
  // }
  return (
    <>
      {
        <div className="d-flex align-items-start">
          {paymentReceipts.length === 0 &&
            proposal.invoices !== null &&
            UserReducer?.influencer_id !== proposal?.influencer_id &&
            invoicePaymentStatus === "Pending" &&
            UserReducer?.account_type === "Brand" && (
              <button
                className="btn btn-text-link"
                data-campaign_id={selectedCampaign.campaign_id}
                data-proposal_id={proposal.proposal_id}
                data-user_id={UserReducer.user_id}
                data-account_id={UserReducer.account_id}
                data-influencer_id={UserReducer.influencer_id}
                data-invoice_id={invoiceId}
                onClick={(e) => handleUploadReceipt(e)}
              >
                Upload
              </button>
            )}
        </div>
      }
      {paymentReceipts.length > 0 && (
        <div className="d-flex">
          {paymentReceipts.map(
            (receipt, index) =>
              Number(receipt?.proposal_id) ===
                Number(proposal?.proposal_id) &&
              receipt.fileUrls.length > 0 &&
              receipt.fileUrls.map((url, idx) => (
                <Tooltip
                  key={`proposal_receipt_${index}_${idx}`}
                  title={
                    url.split("proposal_receipts/")[
                      url.split("proposal_receipts/").length - 1
                    ]
                  }
                  arrow
                >
                  <div
                    onClick={(e) => handleReceiptAckClick(e)}
                    data-tip={
                      url.split("proposal_receipts/")[
                        url.split("proposal_receipts/").length - 1
                      ]
                    }
                    data-amount={receipt.amount}
                    data-transaction_id={receipt.transaction_id}
                    data-comments={receipt.comments}
                    data-payment_acknowledgement={
                      receipt.payment_acknowledgement
                    }
                    data-proposal_id={receipt.proposal_id}
                    data-id={receipt.id}
                    data-file_url={url}
                    data-sm_id={receipt.sm_id}
                    data-proposal_invoice_doc_id={receipt.proposal_invoice_doc_id}
                    data-payment_status={receipt.payment_status}
                    data-invoice_file_name={receipt.invoice_file_name}
                    data-invoice_dt={receipt.invoice_dt}
                    // data-file_urls={receipt.fileUrls.strigify}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <i className="bi bi-file-earmark-text-fill"></i> */}
                    <IconButton>
                      <MaterialSymbol
                        fontSize="20"
                        iconName="description"
                        fontweight="200"
                      />
                    </IconButton>
                  </div>
                </Tooltip>
              ))
          )}
        </div>
      )}

      {openAcknowledgementModal && (
        <CommonPopUp
          modalTitle={acknowledgementModalTitle}
          modalContent={acknowledgementModalContent}
          openModal={openAcknowledgementModal}
          onClose={() => handleCloseAcknowledgementModal()}
          className="modal-medium"
        >
          <TableContainer className="" style={{ border: "1px solid #eee" }}>
            <Table className="" aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell style={{ minWidth: "140px" }}>
                    <strong>Amount</strong>
                  </TableCell>
                  <TableCell>{acknowledgementFormData.amount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ minWidth: "140px" }}>
                    <strong>Transaction Id</strong>
                  </TableCell>
                  <TableCell>
                    {acknowledgementFormData.transaction_id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ minWidth: "140px" }}>
                    <strong>Comments</strong>
                  </TableCell>
                  <TableCell>{acknowledgementFormData.comments}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ minWidth: "140px" }}>
                    <strong>Receipt</strong>
                  </TableCell>
                  <TableCell>
                    {acknowledgementFormData.fileUrls &&
                      acknowledgementFormData.fileUrls.map((url, index) => (
                        <React.Fragment key={index}>
                          <span className="me-2">
                            {
                              url.split("proposal_receipts/")[
                                url.split("proposal_receipts/").length - 1
                              ]
                            }
                          </span>
                          <Tooltip
                            title={
                              url.split("proposal_receipts/")[
                                url.split("proposal_receipts/").length - 1
                              ]
                            }
                            arrow
                          >
                            <NavLink
                              target="_blank"
                              key={`proposal_receipt_${index}`}
                              to={url}
                              data-tip={
                                url.split("proposal_receipts/")[
                                  url.split("proposal_receipts/").length - 1
                                ]
                              }
                            >
                              {/* <i className="bi bi-file-earmark-text-fill"></i> */}
                              <MaterialSymbol
                                iconName="description"
                                fontweight="200"
                              />
                            </NavLink>
                          </Tooltip>
                        </React.Fragment>
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ minWidth: "140px" }}>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell>{acknowledgementFormData.payment_status}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {acknowledgementFormData.payment_acknowledgement === 'Not Received' && (UserReducer?.influencer_id === proposal?.influencer_id) && (
            <div className="pt-4 pb-3">
              <p className="mb-2">Kindly confirm, if you have received the payment or not</p>
               <button className="btn btn-outline-primary me-2 btn-sm" onClick={handleOpenPaymentReceivedModal}>Received</button>
               <button className="btn btn-outline-primary  btn-sm" onClick={handleOpenPaymentNotReceivedModal}>Not Received</button>
              
            </div>
          )}
        </CommonPopUp>
      )}

      {createPortal(<ConfirmPopup confirmPopupInnerText="Please confirm if you have received the payment.?" openPopup={openPaymentReceivedModal} confirmClosePopup={handleClosePaymentReceivedModal} confirmClickAction={() => handleReceiptAckChange("Received")} drawerTitle="" />, document.body)}
    </>
  );
};

export default RenderAcknoledgeReceiptForm;
