import React, { useState, useEffect } from "react";
import { UserService } from "../../core/services";
import { useNavigate } from "react-router-dom";
import * as gtUtil from "../../core/utility";
import { Button, Typography, Box, CircularProgress } from "@mui/material";
import { LocalService } from "../../core/services";

const userService = new UserService();
const localService = new LocalService();

const statusMessages: Record<string, string> = {
  pending: "Your request is being processed. Please wait...",
  "signature invalid": "The signature is invalid. Please request a new link.",
  "token expired": "The reset link has expired. Please request a new one.",
  "request limit exceeded":
    "You have exceeded the request limit. Try again later.",
  "invalid token format":
    "The reset link format is incorrect. Please check and try again.",
  successful: "Your reset request was successful. You may now proceed with logging in.",
  "unexpected error": "An unexpected error occurred. Please try again later.",
};

export const RequestStatus: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const key = searchParams.get("key");

      if (!key) {
        console.error("No key found in URL");
        navigate("/login");
        return;
      }

      try {
        const forgotTokenResponse = await userService.forgotTokenResponse({
          key,
        });

        if (forgotTokenResponse.status === gtUtil.Constants.SUCCESS) {
          const statusMessage =
            statusMessages[forgotTokenResponse.data] ||
            "Unknown response. Please try again.";

          if (forgotTokenResponse.data === "pending") {
            navigate("/login");
          } else {
            setMessage(statusMessage);
            if(forgotTokenResponse.data === 'successful'){
              localService.reset_logout();
            }
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("forgotTokenResponse API call error:", error);
        setMessage("An error occurred while processing your request.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      // height="100vh"
      textAlign="center"
      p={3}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Request Status
          </Typography>
          <Typography variant="body1" color="textSecondary" mb={2}>
            {message}
          </Typography>
          <Button
            className="btn btn-primary"
            type="button"
            variant="contained"
            onClick={() => navigate("/login")}
          >
            Go to Login
          </Button>
        </>
      )}
    </Box>
  );
};
