import React, { useState } from 'react';
import CommonPopUp from './common-popup';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip, useMediaQuery, Skeleton } from '@mui/material';
import { Button, TextField } from '@mui/material';
import { HandlesService } from '../../core/services/handles.service';
import { PublicService, UserService } from '../../core/services';
import { toast } from 'react-toastify';
import InstagramIcon from '../icons/instagram-icon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import verified from '../../assets/images/verified.svg';
import { UserLogin } from '../../redux/action';
import { connect } from 'react-redux';
import SocialConnectRestriction from '../popup/social-connect-restrict';
import { createPortal } from 'react-dom';
import GenericSwipeableDrawer from '../generic-swipe-drawer/generic-swiper-drawer';
import GuideToConnectContent from './guide-to-connect-content';
import * as gtUtil from "../../core/utility";
import ContentLoading from '../loader/loading';

interface ApplyRestrictionPopupProps {
    openModal: boolean;
    onClose: (closed: boolean) => void;
    applyRestrictionData: any;
    platform: string;
    UserReducer: any;
    updateUser: any;
    stateToUpdateUserReducer?: any
    stateToUpdateUserReducerfuntion?: (data: boolean)=>void;
}

const handlesService = new HandlesService();
const publicService = new PublicService();
const userService = new UserService();

const ApplyRestrictionPopup: React.FC<ApplyRestrictionPopupProps> = ({ openModal, onClose, applyRestrictionData, platform, ...props }) => {
    const [username, setUserName] = React.useState('');
    const [faceBookButton, setFaceBookButton] = React.useState(true);
    const [verifyUserName, setVerifyUserName] = React.useState(true);
    const [stateToUpdateUserReducer, setStateToUpdateUserReducer] = React.useState<boolean>(props.stateToUpdateUserReducer);
    const [username_verify, set_username_verify] = useState<String | null>(null);
    const [ig_data, set_ig_data] = useState({});
    const [yt_data, set_yt_data] = useState({});
    const [loadingIg, setLoadingIg] = React.useState(false);
    const [socialRestrictionPopup, set_socialRestrictionPopup] = useState({
        youtube: false,
        instagram: false
    });
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [social_loader, set_social_loader] = useState<boolean>(false);

    async function faceBookAction(e?: any) {

        try {
            setLoadingIg(true);
            const getPublicData = await publicService.instagramVerify(username);
            if (getPublicData?.status === 1) {
                set_username_verify(username);
                setFaceBookButton(false);
                setLoadingIg(false);
                toast.success('Username Verified');
            } else {
                setLoadingIg(false);
                toast.success('Username not verified, This is not a Business/Creator account');
            }

        } catch (err) {
            console.log('Error faceBookAction ', err);
            setLoadingIg(false);
        }finally{
            setLoadingIg(false);
        }
    }

    const handleStateUpdate = (value: any) => {
        setUserName(value);
        setVerifyUserName(!value);
    };

    const handlePaste = (e: any) => {
        const pastedText = e.clipboardData.getData('Text');
        handleStateUpdate(pastedText); // Or handle the pasted text as needed
    };
    const handleInput = (e: any) => {
        const inputValue = e.target.value;
        handleStateUpdate(inputValue); // Update the state or handle input
        setUserName(inputValue); // Set the username
        inputValue ? setVerifyUserName(false) : setVerifyUserName(true); // Enable/disable button
    };
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down('sm')
    );
    const drawerAnchor = isSmallScreen ? 'bottom' : 'right';
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleGuideDrawer = () => {
        setDrawerOpen(true);
    };

    async function updateUserReducer() {
        let latestUserReducerData = await userService.getUpdatedUserReducer(props.UserReducer);
        if (![undefined, null, ''].includes(latestUserReducerData)) {
            props.updateUser(latestUserReducerData)
        }
        if (props.stateToUpdateUserReducerfuntion) {
            props.stateToUpdateUserReducerfuntion(!stateToUpdateUserReducer);
        }
    }

    const handleCloseSocialPopup_YT = async (id: any) => {
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            youtube: false
        }));
        
        try {
            const response = await handlesService.continueConnect({
                yt_id: id,
                influencer_id: props?.UserReducer?.influencer_id,
                continues: false,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer()
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };

    const handleContinueConnect_YT = async (id: any) => {
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            youtube: false 
        }));
    
        try {
            const response = await handlesService.continueConnect({
                yt_id: id,
                influencer_id: props?.UserReducer?.influencer_id,
                continues: true,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };
    
    const handleCloseSocialPopup_IG = async (id: any) => {
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            instagram: false 
        }));
    
        try {
            const response = await handlesService.continueConnect({
                ig_id: id,
                influencer_id: props?.UserReducer?.influencer_id,
                continues: false,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };
    
    const handleContinueConnect_IG = async (id: any) => {
        set_socialRestrictionPopup(prevState => ({
            ...prevState,
            instagram: false 
        }));
    
        try {
            const response = await handlesService.continueConnect({
                ig_id: id,
                influencer_id: props?.UserReducer?.influencer_id,
                continues: true,
            });
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                updateUserReducer();
            } else {
                console.log('Error occurred');
            }
        } catch (error) {
            console.error('API Error:', error);
        }finally{
            set_social_loader(false);
        }
    };

    const loginGoogleyt = async () => {
        try {
            const response = await handlesService.googleConnected();
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                const popup = window.open(
                    response.data.url,
                    "GoogleOAuthPopup",
                    "width=500,height=600,left=100,top=100"
                );
    
                if (!popup) {
                    console.error("Popup blocked by the browser.");
                    return;
                }
      
                // Listen for messages from the popup
                const messageListener = async (event: MessageEvent) => {
                    if (event.origin !== window.location.origin) return;
      
                    if (event.data?.type === 'social-auth-success') {
                        const authCode = event.data.code;

                        try {
    
                            // Send auth code to backend
                            set_social_loader(true);
    
                            // Send auth code to backend
                            const tokenResponse = await handlesService.postGoogleConnected({
                                code: authCode,
                                influencer_id: props?.UserReducer?.influencer_id
                            });
    
                            if (tokenResponse.status === gtUtil.Constants.SUCCESS) {
                                updateUserReducer()
                                set_social_loader(false);
                            } else {
                                if(tokenResponse.info == 'Social account already connected to the other account.'){
                                    set_yt_data(tokenResponse.data)
                                    set_socialRestrictionPopup(prevState => ({
                                        ...prevState,
                                        youtube: true 
                                    }));
                                }
                            }

                        } catch (error) {
                            console.error("Error processing Youtube auth response:", error);
                            toast.error("Youtube authentication failed.");
                        } finally {
                            // Remove event listener after handling response
                            set_social_loader(false);
                            window.removeEventListener("message", messageListener);
                        }
    
              }
          };

          window.addEventListener("message", messageListener);
      }
  } catch (error) {
      console.error("Login Error:", error);
      toast.error("Google Connect failed");
  }finally{
    set_social_loader(false);
  }
};

    const instagramConnect = async () => {
        try {
            const response = await handlesService.instagramConnect();
    
            if (response.status === gtUtil.Constants.SUCCESS) {
                // Open Instagram OAuth in a popup window
                const popup = window.open(
                    response.data.url, // Ensure this is correct
                    "InstagramOAuthPopup",
                    "width=500,height=600,left=100,top=100"
                );
    
                if (!popup) {
                    console.error("Popup blocked by the browser.");
                    return;
                }
        
                // Message listener for receiving auth code
                const messageListener = async (event: MessageEvent) => {
                    if (event.origin !== window.location.origin) return;
        
                    if (event.data?.type === 'social-auth-success') {
                        const authCode = event.data.code;
        
                        try {
                            set_social_loader(true);
    
                            // Send auth code to backend
                            const response = await handlesService.postInstagramConnect({
                                code: authCode,
                                influencer_id: props?.UserReducer?.influencer_id, // Use the correct ID
                            });
    
                            if (response.status === gtUtil.Constants.SUCCESS) {
                                updateUserReducer()
                                set_social_loader(false);
                            } else {
                                if(response.info == 'Social account already connected to the other account.'){
                                    set_ig_data(response.data)
                                    set_socialRestrictionPopup(prevState => ({
                                        ...prevState,
                                        instagram: true 
                                    }));
                                }
                            }
    
                        } catch (error) {
                            console.error("Error processing Instagram auth response:", error);
                            toast.error("Instagram authentication failed.");
                        } finally {
                            // Remove event listener after handling response
                            window.removeEventListener("message", messageListener);
                            set_social_loader(false);
                        }
                    }
                };
                // Attach event listener
                window.addEventListener("message", messageListener);
            } else {
                console.error("Error fetching Instagram OAuth URL:", response.info);
                toast.error("Failed to get Instagram authentication URL.");
            }
        } catch (error) {
            console.error("Instagram Login Error:", error);
            toast.error("Instagram login failed");
        }finally{
            set_social_loader(false);
        }
      };
    

    const fb_instagramConnect = async () => {
        try {

            if (!username_verify) {
                console.error("Username is required for Facebook authentication.");
                toast.error("Username is required.");
                return;
            }
                const response = await handlesService.fb_instagramConnect();
            
                if (response.status === gtUtil.Constants.SUCCESS) {
                    // Open Instagram OAuth in a popup window
                    const popup = window.open(
                        response.data.url, // Ensure this is correct
                        "FacebookOAuthPopup",
                        "width=500,height=600,left=100,top=100"
                    );
        
                    if (!popup) {
                        console.error("Popup blocked by the browser.");
                        return;
                    }
          
                    // Message listener for receiving auth code
                    const messageListener = async (event: MessageEvent) => {
                        if (event.origin !== window.location.origin) return;
          
                        if (event.data?.type === 'social-auth-success') {
                            const authCode = event.data.code;
          
                            try {
                                set_social_loader(true);
        
                                // Send auth code to backend
                                const tokenResponse = await handlesService.postFacebookConnect({
                                    code: authCode,
                                    influencer_id: props?.UserReducer?.influencer_id, // Use the correct ID
                                    username: username_verify
                                });
        
                                if (tokenResponse.status === gtUtil.Constants.SUCCESS) {
                                    updateUserReducer()
                                    set_social_loader(false);
                                } else {
                                    // console.error("Failed to get fb access token:", tokenResponse);
                                    if(tokenResponse.info == 'Social account already connected to the other account.'){
                                        set_ig_data(tokenResponse.data)
                                        set_socialRestrictionPopup(prevState => ({
                                            ...prevState,
                                            instagram: true 
                                        }));
                                    }
                                }
        
                            } catch (error) {
                                console.error("Error processing Facebook auth response:", error);
                                toast.error("Facebook authentication failed.");
                            } finally {
                                // Remove event listener after handling response
                                window.removeEventListener("message", messageListener);
                                set_social_loader(false);
                            }
                        }
                    };
          
                    // Attach event listener
                    window.addEventListener("message", messageListener);
                } else {
                    console.error("Error fetching Facebook OAuth URL:", response.info);
                    toast.error("Failed to get Facebook authentication URL.");
                }
            } catch (error) {
                console.error("Facebook Login Error:", error);
                toast.error("Facebook Connect failed.");
            }finally{
                set_social_loader(false);
            }
          };

    return (
        <>
        <CommonPopUp
            modalTitle="Requirements to apply on a campaign"
            modalContent=""
            openModal={openModal}
            onClose={() => onClose(false)}
            className="modal-medium mx-auto"
        >
            {social_loader ?
                <ContentLoading width='150' height='150' /> :
            <div className='mb-4'>
                <ol className='guide-to-connect-step'>
                    <li>
                        <h6>1. {platform.toLowerCase() == 'instagram' ? 'Followers' : 'Subscribers'}</h6>
                        <p className='position-relative'>You need to have at least 1k {platform.toLowerCase() == 'instagram' ? 'followers' : 'subscribers'} to be eligible to apply.{applyRestrictionData.followers.status && <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                                <img src={verified} alt="verified" width="24" height="24" />
                            </IconButton>
                        </Tooltip>}</p>
                    </li>
                    {/* <li>
                        <h6>2. Permission</h6>
                        <p className='position-relative'>Permission required to be eligible to apply.{applyRestrictionData.connected_account_permissions.status && <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                                <img src={verified} alt="verified" width="24" height="24" />
                            </IconButton>
                        </Tooltip>}</p>
                    </li> */}
                    <li>
                            <h6>3. Connect Social Account <button className='btn btn-text-link fs-12'
                                onClick={handleGuideDrawer}>
                                Guide to connect</button></h6>
                        <p> You need to connect your social media account on
                            {platform === 'Instagram' ? (
                                <span style={{ margin: '0 5px', display: 'inline-flex' }}>
                                    <InstagramIcon /> Instagram
                                </span>
                            ) : (
                                <span style={{ margin: '0 5px' }}>
                                    <YouTubeIcon /> Youtube
                                </span>
                            )}
                            as per campaign's requirement.
                            {applyRestrictionData.social_connect.status && <Tooltip className="icon-verified" title="verified" arrow>
                                <IconButton size="small" className="p-0">
                                    <img src={verified} alt="verified" width="24" height="24" />
                                </IconButton>
                            </Tooltip>}

                            {!applyRestrictionData.social_connect.status && (
                                <>
                                    {platform === "Instagram" && (
                                        <>
                                            <div className="apply-connected-account-buttons mb-3">
                                                <Button
                                                    variant="outlined"
                                                    className={`btn me-2 ${selectedOption === "facebook" ? "active" : ""}`}
                                                    onClick={() => setSelectedOption("facebook")}
                                                >
                                                    Connect via Facebook
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    className={`btn me-2 ${selectedOption === "instagram" ? "active" : ""}`}
                                                    onClick={() => setSelectedOption("instagram")}
                                                >
                                                    Connect Directly
                                                </Button>
                                            </div>

                                            {/* Connect via Facebook */}
                                            {selectedOption === "facebook" && (
                                                <>
                                                    <span>Connect Instagram via Facebook</span>
                                                    <div className="connected-account-buttons">
                                                        <div className="connected-account-input d-flex mb-2">
                                                            <TextField
                                                                className="influencer_personal_info"
                                                                size="small"
                                                                fullWidth
                                                                name="username"
                                                                type="text"
                                                                label="Instagram username"
                                                                placeholder="e.g., virat.kohli"
                                                                variant="outlined"
                                                                onPaste={handlePaste}
                                                                onInput={handleInput}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    const username = e.target.value;
                                                                    setUserName(username);
                                                                    setVerifyUserName(!username);
                                                                }}
                                                            />
                                                            <Button
                                                                variant="outlined"
                                                                className="btn ms-2"
                                                                onClick={faceBookAction}
                                                                disabled={verifyUserName}
                                                            >
                                                                Verify
                                                            </Button>
                                                        </div>

            
                                                            {(loadingIg ? (
                                                                <Skeleton variant="rounded" height={40} width={102} />
                                                            ) : (
                                                                <Button
                                                                    variant="outlined"
                                                                    className="btn me-2"
                                                                    color="error"
                                                                    onClick={() => fb_instagramConnect()}
                                                                    disabled={faceBookButton}
                                                                >
                                                                    Connect
                                                                </Button>
                                                            ))}
                                                    </div>
                                                </>
                                            )}

                                            {/* Connect Directly to Instagram */}
                                            {selectedOption === "instagram" && (
                                                <>
                                                    <span>Connect Instagram</span>
                                                    <div className="connected-account-buttons">
                                                        <div className="connected-account-input d-flex mb-2">
                                                            <Button
                                                                variant="outlined"
                                                                className="btn btn-outline-primary"
                                                                onClick={instagramConnect}
                                                            >
                                                                Connect
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}

                                    {/* Youtube Connection */}
                                    {platform === "Youtube" && (
                                        <div className="connected-account-buttons">
                                            <Button
                                            variant="outlined"
                                            className="btn btn-outline-primary"
                                            onClick={() => loginGoogleyt()}
                                            >
                                                Connect
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}

                        </p>

                    </li>
                    <li>
                        <h6>
                            4. Upgrade to Premium
                            {
                                applyRestrictionData.subscription.status && 
                                <Tooltip className="icon-verified" title="verified" arrow>
                                    <IconButton size="small" className="p-0">
                                        {/* <MaterialSymbol iconName="verified" fontweight="200" /> */}
                                        <img src={verified} alt="verified" width="24" height="24" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </h6>
                        {!applyRestrictionData.subscription.status && <Link className='btn btn-outline-primary btn-sm' to="/influencer/settings/subscription">Premium</Link>}

                    </li>
                </ol>
            </div>}
        </CommonPopUp>
      
                {socialRestrictionPopup.youtube &&
                    <SocialConnectRestriction open={socialRestrictionPopup.youtube} data = {yt_data} onClose={handleCloseSocialPopup_YT} continueConnect={handleContinueConnect_YT} />
                }

                {socialRestrictionPopup.instagram &&
                    <SocialConnectRestriction open={socialRestrictionPopup.instagram} data = {ig_data} onClose={handleCloseSocialPopup_IG} continueConnect={handleContinueConnect_IG} />
                }
            {createPortal(
                <GenericSwipeableDrawer
                    className='guide-to-connect apply-restriction-popup'
                    drawerAnchor={drawerAnchor}
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    onOpen={handleDrawerOpen}
                    title='Guide to Connect'
                    content={<GuideToConnectContent />}
                />, document.body)} 
        </>
    );
    
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateUser: (data: any) => dispatch(UserLogin(data)),
    }
}

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(ApplyRestrictionPopup);

export { connectToRedux as ApplyRestrictionPopup };

