import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Signup,
  Influencer,
  Agency,
  Brand,
  Talent_Patner,
} from "../pages/signup";
import { BrandList, MainTalentPartner } from "../pages/talent-partner";
import { TalentPartnerDashboard } from "../pages/talent-partner/dashboard/dashboard";
// import BrandList from "../pages/talent-partner/brand-list/brand-list";
import { AgencyDashboard, MainAgency } from "../pages/agency";
import {
  BrandDashboard,
  BrandSetting,
  Discover,
  MainBrand,
  Utilities,
  // BrandCompanyDetails,
  BrandClanShop,
  AddProduct,
  BrandProductIntegeration,
  AffiliateDashboard,
  BrandSubscriptionConfirmed
} from '../pages/brand';

import { Security } from '../pages/common/security/security';
import {
  CampaignCreate,
  CampaingInfluencer,
  CampaingPost,
  CampaingProduct,
  MainCampaign,
  MainPlan,
  PlanCreate,
  CampaignView,
  BrandProposal,
  BrandPayment,
  BrandCreatives,
  AddInfluencers,
  BrandCampaignDetails
} from "../pages/brand/plans";
import {
  InfluencerDashbaord,
  MainInfluencer,
  InfluencerSocialConnect,
  PaymentMethod,
  InfluencerPreferences,
  ReferEarn,
  InfluencerSetting,
  InfluencerMainCampDetail,
  CampaignDetails,
  InfluencerProposal,
  InfluencerCreative,
  InfluencerMyCampaign,
  InfluencerClanShop,
  InfluencerShop,
  ProductShop,
  ProductDetailsInfl,
  InfluencerSendInvoice,
  InfluencerSubscriptionConfirmed
} from '../pages/influencer';

// import { InfluencerAnalytics } from '../pages/common/analytics/analytics';

import { AnalyticsTab } from '../pages/common/analytics/analytics_tab';

import {
  Terms_Conditions,
  SwitchAccounts,
  SwitchUser,
  ThankuPage,
  WrongRoute,
  SubscriptionInvoice,
  PrintInvoice,
} from "../pages/common";

import { Login } from "../pages/login/login";
import { ResetPasswordPIN } from "../pages/reset-password-pin/reset_password_pin";
import { RequestStatus } from "../pages/reset-password-pin/request_status";
// import Material from '../components/material/material'; //temp route
import { ProtectedRoute } from "./protected-route"; //Protected Rood
import { ClanShopRoute } from "./clanshop-route";
import { FAQ } from "../pages/common/faq/faq";
import { ClanShopUser } from '../pages/clanshop-user/user';
import { ProductDetails } from '../pages/clanshop-user/product-details';
import { UserDetails } from '../pages/clanshop-user/user-details';
import { UserPlaceOrder } from '../pages/clanshop-user/user-place-order';
import OrderDetails from '../pages/clanshop-user/order-details';
import { ClanShopCart } from '../pages/clanshop-user/clan-shop-cart';
import { UserBrief } from '../pages/brand/admin/admin-dashboard/userbase/user-brief/user-briefs';
import { UserBase } from "../pages/brand/admin/admin-dashboard/userbase/userbase/user-base";
import { CampaignVerification } from '../pages/brand/admin/admin-dashboard/verification/campaign-verification/campaign-verification';
import { TalentVerification } from '../pages/brand/admin/admin-dashboard/verification/talent-verification/talent-verification';
import { InfluencerDatabaseHome } from '../pages/brand/admin/influencer-database/influencer-database-home';
import { Influencers } from '../pages/brand/admin/admin-dashboard/signup/influencer/influencer';
import { Brands } from "../pages/brand/admin/admin-dashboard/signup/brand/brand";
import { PlatformCampaignDetails } from '../pages/brand/admin/admin-dashboard/platform/campaign-details/campaign-details';
import { DisconnectFeedbacks } from "../pages/brand/admin/admin-dashboard/platform/disconnect-feedbacks/disconnect-feedbacks";
import { Crud } from '../pages/brand/admin/admin-dashboard/crud/crud';
import { AdminDashboard } from '../pages/brand/admin/admin-dashboard/dashboard';
import { CompareIG } from '../pages/brand/compare/compare-ig';
import { CompareYT } from '../pages/brand/compare/compare-yt';
import { CampaignConfigurator } from '../pages/brand/campaign-configurator/campaign-configurator';
import { CampaignConfigure1 } from '../pages/brand/campaign-configurator/campaign-configure-1';
import { CampaignConfigure2 } from '../pages/brand/campaign-configurator/campaign-configure-2';
import { ProfileInsight } from '../pages/brand/profile-insight/profile-insight';
import { YoutubeProfileInsight } from "../pages/influencer/profile-view/yt-profile-insight";
import { CampaignMsg } from "../pages/brand/chat-inbox/campaign-msg";
import { DirectMsg } from "../pages/brand/chat-inbox/direct-msg";
import { InfluencerCampaignMsg } from "../pages/influencer/chat-inbox/campaign-msg";
import { InfluencerDirectMsg } from "../pages/influencer/chat-inbox/direct-msg";
// To import CommonMyAccount component for influencers and brands
import { CommonMyAccount } from '../pages/common/settings/my-accounts';
import { CommonCompanyDetails } from "../pages/common";
import { UserPersonalInfo } from "../pages/common/settings/user-info";
import { UserSubscription } from "../pages/common/settings/subscription/subscription";
import { UserSubscriptionHistory } from "../pages/common/settings/subscription/subscription-history";
import { UserNotification } from "../pages/common/settings/notifications/notifications";
import { ShippingAddress } from "../pages/common/settings/shipping-address/shipping-address";
import OrderStatus from "../pages/clanshop-user/order-status";
import AllClanShops from '../pages/clanshop-user/all-clanshops';
import LiveDashboard from "../pages/live-dashboard/live-dashboard";
import { ProfileViewHome } from "../pages/influencer/profile-view/profile-view-home";
import { RequestDemo } from "../pages/common/request-a-demo/request-a-demo"
import { V360ClientRegistration } from "../pages/common/client-registration/v360-client-registration";
import { BrandsData } from "../pages/brand/brand-data/brand-data";
import { AgencySetting } from "../pages/agency/agency-settings/agency-settings";
import { AgencyPersonalInfo } from "../pages/agency/agency-settings/agency-personal-info";
import { AgreementService } from '../core/services/influencer-agreement.services';
import { InfluencerAgreements } from '../pages/brand/utlities/influencers-agreements/influencers-agreements';
import { InfluencerList } from '../pages/brand/utlities/influencers-list/influencers-list';
import { ThankuBrandPage } from '../pages/common/thankusignup/thanku-brand';
import { ThankuAgencyPage } from '../pages/common/thankusignup/thanku-agency';
import { ThankuTalentPage } from '../pages/common/thankusignup/thanku-talent';
import { InfluencerPersonalInfo } from "../pages/influencer";
import { InternalCampaign } from "../pages/brand/plans/campaings/internal-campaigns/internal-campaign";
import { Loading } from "../redux/action";
import * as Services from '../core/services';
import { PrivacyPolicy } from "../pages/common/terms/privacy-policy";
import { AgencyBrand } from "../pages/brand/admin/admin-dashboard/agency-brand/AgencyBrand";
import { ABTIUserSubscription } from "../pages/agency/agency-settings/agency-brands-tp-infl-subscription";
import { ABTIUserSubscriptionHistory } from "../pages/agency/agency-settings/agency-brands-tp-infl-subs-history";
import { TalentPartnerSetting } from "../pages/talent-partner/brand-list/talent-settings";
import { InfluencerTools } from "../pages/influencer/tools/tools";
import NewAutomation from "../pages/influencer/tools/new-automation";
import ExistingAutomation from "../pages/influencer/tools/existing-automation";
import { ShareAnalytics } from "../pages/common/analytics/share-analytics";
import { ShareAnalyticsYouTube } from "../pages/common/analytics/share-analytics-youtube";
import { BrandPulse } from "../pages/brand/brand-data/brand-pulse";
import { SocialConnect_Confirmation } from "../pages/common/socialconnect_confirmation/socialconnect_confirmation";


const localService: Services.LocalService = new Services.LocalService();

interface Props {
  guest_user: any;
}

const RoutesComponent: React.FC<Props> = () => {
  const [isRender, setIsRender] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    try {
      const userReducer: any = localService.getStorageItem("userreducer"); 
      const getParams = new URLSearchParams(location.search);
      let campaignId:any = getParams.get('campaignId');
      if(campaignId){
        sessionStorage.setItem('campaignId',campaignId)
      }
      if (userReducer) {
        const parseUserReducer = JSON.parse(userReducer);
        //handle account type 
        let accountType = '';
        if ((parseUserReducer.account_type + '').toLowerCase() === 'influencer' || (parseUserReducer.account_type + '').toLowerCase() === 'talent partner') {
          accountType = 'influencer';
        } else if ((parseUserReducer.account_type + '').toLowerCase() === 'brand' || (parseUserReducer.account_type + '').toLowerCase() === 'agency') {
          accountType = 'brand';
        };

        if (location.pathname == '/' && ['agency', 'talent_partner'].includes((parseUserReducer.account_type + '').toLowerCase())){
          navigate(`/switch/user`);
        }
        else if (location.pathname == '/') {
          navigate(`/${accountType}/dashboard`);
        }
      } else if (location.pathname === '/' && localService.getStorageItem('DeviceId')) {
        navigate('/login');
      }
    } catch (error) {

    } finally {
      setIsRender(true);
    }


  }, [])
  return (
    //all routes use "-" instead of "_"
    <Fragment>
      {isRender &&
        <Routes>

          <Route path='/*' element={<WrongRoute />} />
          <Route path='/' Component={Signup} />
          <Route path='/live-subscriber-count' Component={LiveDashboard} />
          <Route path='/signup/influencer' Component={Influencer} />
          <Route path='/signup/agency' Component={Agency} />
          <Route path='/signup/brand' Component={Brand} />
          <Route path='/signup/talentpartner' Component={Talent_Patner} />
          <Route path='/welcome/brand' element={<ThankuBrandPage />} />
          <Route path='/welcome/agency' element={<ThankuAgencyPage />} />
          <Route path='/welcome/talentpartner' element={<ThankuTalentPage />} />
          <Route path='/welcome/influencer' element={<ThankuPage />} />
          <Route path='/terms_condition' Component={Terms_Conditions} />
          <Route path='/privacy_policy' Component={PrivacyPolicy} />
          <Route path='/login' Component={Login} />
          <Route path='/reset-password' Component={ResetPasswordPIN} />
          <Route path='/reset-pin' Component={ResetPasswordPIN} />
          <Route path='/request-status' Component={RequestStatus} />
          <Route path='/clanshop' element={<AllClanShops />} />
          <Route path='/clanshop/:shop_id' element={<ClanShopUser />} />
          <Route path='/clanshop/product-details' Component={ProductDetails} />
          <Route path='/request-a-demo' Component={RequestDemo} />
          <Route path='/v360/clients/registration' Component={V360ClientRegistration} />
          <Route path='/share-analytics' Component={ShareAnalytics} />
          <Route path='/share-analytics-yt' Component={ShareAnalyticsYouTube} />
          <Route path='/socialconnect/confirmation' Component={SocialConnect_Confirmation} />

          <Route
            path='/clanshop/product-details/user-details'
            Component={UserDetails}
          />
          <Route
            path='/clanshop/product-details/place-order'
            element={<ClanShopRoute Component={UserPlaceOrder} />}
          />
          <Route
            path='/clanshop/product-details/place-order/order-status'
            element={<ClanShopRoute Component={OrderStatus} />}
          />
          <Route path='/clanshop/user/cart' element={<ClanShopCart />} />
          <Route path='/clanshop/order-details' Component={OrderDetails} />

          <Route
            path='/switch/account'
            element={<ProtectedRoute Component={SwitchAccounts} />}
          />
          <Route
            path='/switch/user'
            element={<ProtectedRoute Component={SwitchUser} />}
          />
          <Route
            path='/print/invoice'
            element={<ProtectedRoute Component={PrintInvoice} />}
          />
          {/* For subscription invoice */}
          <Route
            path='/subscription/invoice'
            Component={SubscriptionInvoice}
          />
          <Route
            path='/influencer/'
            element={<ProtectedRoute Component={MainInfluencer} />}
          >
            <Route index element={<Navigate to={'dashboard'} />} />
            <Route path='brand-list' element={<BrandList />} />
            {/* <Route path='/influencer/' element={<MainInfluencer />}> */}
            <Route path='campaign-msg' Component={InfluencerCampaignMsg} />
            <Route path='direct-msg' Component={InfluencerDirectMsg} />
            <Route path='dashboard' element={<InfluencerDashbaord />} />

            <Route path='mycampaigns' element={<InfluencerMyCampaign />} />
            <Route path='clanshop' element={<InfluencerClanShop />} />
            <Route path='clanshop/myshop/:shop_id/:shop_name' element={<InfluencerShop />} />
            <Route path='profile-view' element={<ProfileViewHome />} />
            <Route path='clanshop/product_shop' element={<ProductShop />} />
            <Route
              path='clanshop/manage_product'
              element={<ProductDetailsInfl />}
            />

            

            <Route path='settings' element={<InfluencerSetting />}>
              <Route index element={<Navigate to={'personal-info'} />} />
              <Route path='personal-info' element={<InfluencerPersonalInfo />} />
              <Route path='subscription' element={<UserSubscription />} />
              <Route path='subscription-history' element={<UserSubscriptionHistory />} />
              <Route path='companydetails' element={<CommonCompanyDetails />} />
              {/* <Route path='security' element={<UserPersonalInfo />} /> */}
              <Route path='socialconnect' element={<InfluencerSocialConnect />} />
              <Route path='Notifications' element={<UserNotification />} />
              <Route path='paymentmethods' element={<PaymentMethod />} />
              <Route path='shippingaddress' element={<ShippingAddress />} />
              <Route path='brand-list' element={<BrandList />} />

              {/* <Route path='companytradedetails' element={<CommonCompanyDetails />} /> */}
              {/* <Route path='invoicesetting' element={<InfluencerInvoice />} /> */}
              <Route
                path='campaignPreferences'
                element={<InfluencerPreferences />}
              />
              <Route path='referearn' element={<ReferEarn />} />
              <Route path='security' element={<Security />} />
            </Route>

            {/* For subscription confirmation page */}
            <Route path='orders/confirmed' element={<InfluencerSubscriptionConfirmed />} />

            <Route path='campaign/' element={<InfluencerMainCampDetail />}>
              <Route index element={<Navigate to={'campaigndetails'} />} />
              <Route path='campaigndetails' element={<CampaignDetails />} />
              <Route path='proposal' element={<InfluencerProposal />} />
              <Route path='creative' element={<InfluencerCreative />} />
              <Route path='analytics' element={<AnalyticsTab />} />
              <Route path='sendinvoice' element={<InfluencerSendInvoice />} />
              <Route path='detailed-analytics' element={<AnalyticsTab />} />
            </Route>
            <Route path='tools/' element={<InfluencerTools />}>
              {/* <Route index element={<InfluencerTools/>} /> */}
              <Route path='new-automation' element={<NewAutomation />} />
              <Route path='available-automation' element={<ExistingAutomation />} />
            </Route>
          </Route>
          <Route path='/talent-partner/' element={<ProtectedRoute Component={MainTalentPartner} />}>
            <Route path='dashboard' element={<TalentPartnerDashboard />} />
            <Route path='settings' element={<TalentPartnerSetting />}>
              <Route path='companydetails' element={<CommonCompanyDetails />} />
              <Route path='personal-info' element={<UserPersonalInfo />} />
              <Route path='security' element={<Security />} />
              <Route path='subscription' element={<ABTIUserSubscription />} />
              <Route path='subscription-history' element={<ABTIUserSubscriptionHistory />} />
              <Route path='Notifications' element={<UserNotification />} />
              <Route path='paymentmethods' element={<PaymentMethod />} />
              <Route path='get-subscription' element={<UserSubscription />} />
            </Route>
          </Route>
          <Route
            path='/agency/'
            element={<ProtectedRoute Component={MainAgency} />}
          >
            <Route path='dashboard' element={<AgencyDashboard />} />
            <Route path='settings' element={<AgencySetting />}>
              <Route path='companydetails' element={<CommonCompanyDetails />} />
              <Route path='personal-info' element={<UserPersonalInfo />} />
              <Route path='security' element={<Security />} />
              <Route path='subscription' element={<ABTIUserSubscription />} />
              <Route path='subscription-history' element={<ABTIUserSubscriptionHistory />} />
              <Route path='Notifications' element={<UserNotification />} />
              <Route path='paymentmethods' element={<PaymentMethod />} />
              <Route path='get-subscription' element={<UserSubscription />} />
            </Route>
          </Route>
          <Route path='/brand/' element={<ProtectedRoute Component={MainBrand} />}>
            {/* <Route path='/brand/' element={<MainBrand />}> */}
            <Route index element={<Navigate to={'dashboard'} />} />

            <Route path='dashboard' element={<BrandDashboard />} />
            <Route path='discover' element={<Discover />} />
            <Route path='clanshop' element={<BrandClanShop />} />
            <Route path='clanshop/add-product' element={<AddProduct />} />
            <Route path='clanshop/affiliate-dashboard' element={<AffiliateDashboard />} />
            <Route path='list' element={<InfluencerList />} />
            <Route path='agreements' element={<InfluencerAgreements />} />
            {/* <Route path='info/details' element={<BrandsData />} /> */}
            <Route path='info/details' element={<BrandPulse />} />

            <Route path='internal-campaign' element={<InternalCampaign />} />
            <Route path='campaign-msg' Component={CampaignMsg} />
            <Route path='direct-msg' Component={DirectMsg} />
            <Route path='admin-dashboard' element={<AdminDashboard />}>
              <Route path='influencer-signups' element={<Influencers />} />
              <Route path='brand-signups' element={<Brands />} />
              <Route path='userbase' element={<UserBase />} />
              <Route path='userbrief' element={<UserBrief />} />
              <Route path='campaign-verification' element={<CampaignVerification />} />
              <Route path='talent-verification' element={<TalentVerification />} />
              <Route path='disconnect-feedbacks' element={<DisconnectFeedbacks />} />
              <Route path='campaign-details' element={<PlatformCampaignDetails />} />
              <Route path='influencer-db' element={<InfluencerDatabaseHome />} />
              <Route path='crud' element={<Crud />} />
              <Route path='agency-brands' element={<AgencyBrand/>} />
            </Route>

            <Route path='admin-dashboard' element={<AdminDashboard />}>
              <Route path='signup' element={<Influencers />} />
              {/* <Route path='platform' element={<Platform />} /> */}
              <Route path='crud' element={<Crud />} />
            </Route>

            <Route
              path='clanshop/product_integeration'
              element={<BrandProductIntegeration />}
            />
            {/* Temp Working */}
            <Route path='plans' element={<MainPlan />} />
            <Route path='campaign' element={<MainCampaign />}>
              <Route index element={<Navigate to={'createplan'} />} />
              <Route path='createplan' element={<PlanCreate />} />
              <Route path='createcampaign' element={<CampaignCreate />} />
              <Route path='createproduct' element={<CampaingProduct />} />
              <Route path='influencer' element={<CampaingInfluencer />} />
              <Route path='post' element={<CampaingPost />} />
            </Route>
            <Route path='compare-ig' element={<CompareIG />} />
            <Route path='compare-yt' element={<CompareYT />} />
            <Route path='settings' element={<BrandSetting />}>
              <Route index element={<Navigate to={'socialconnect'} />} />
              <Route path='companydetails' element={<CommonCompanyDetails />} />
              <Route path='personal-info' element={<UserPersonalInfo />} />
              {/* <Route path='security' element={<UserPersonalInfo />} /> */}
              <Route path='security' element={<Security />} />
              <Route path='socialconnect' element={<InfluencerSocialConnect />} />
              <Route path='subscription' element={<UserSubscription />} />
              <Route path='subscription-history' element={<UserSubscriptionHistory />} />
              <Route path='Notifications' element={<UserNotification />} />
              <Route path='paymentmethods' element={<PaymentMethod />} />
              <Route path='shippingaddress' element={<ShippingAddress />} />
              {/* <Route path='companytradedetails' element={<CommonCompanyDetails />} /> */}
              {/* <Route path='invoicesetting' element={<InfluencerInvoice />} /> */}
              <Route
                path='campaignPreferences'
                element={<InfluencerPreferences />}
              />
            </Route>

            {/* For subscription confirmation page */}
            <Route path='orders/confirmed' element={<BrandSubscriptionConfirmed />} />

            <Route path='configurator' element={<CampaignConfigurator />} />
            <Route path='configure' element={<CampaignConfigure1 />} />
            <Route path='configure-2' element={<CampaignConfigure2 />} />
            <Route path='profile-insight' element={<ProfileInsight />} />
            <Route path='profile-insight-yt' element={<YoutubeProfileInsight />} />


            {/* Brand Proposal Routes */}
            <Route path="campaigns" element={<CampaignView />}>
              <Route index element={<Navigate to={'campaign-details'} />} />
              <Route path="proposals" element={<BrandProposal />} />
              <Route path="payment" element={<BrandPayment />} />
              <Route path="creative" element={<BrandCreatives />} />
              <Route path="analytics" element={<AnalyticsTab />} />
              <Route path="campaign-details" element={<BrandCampaignDetails />} />
              <Route path="add-influencers" element={<AddInfluencers />} />
            </Route>
          </Route>

          <Route path="/faq" Component={FAQ} />
        </Routes>
      }
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    guest_user: state.guestUserReducer.guest_user,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data))
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(RoutesComponent);

export { connectToRedux as RoutesComponent };
