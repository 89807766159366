import React from "react";
import ReactWordcloud from "react-wordcloud";

const size: any = [600, 400];

const sizeMapping = (value: any) => {
  if (value < 5) return 1;
  else if (value < 10) return 1.5;
  else if (value < 30) return 2;
  else return 2.5;
};

const WordCloudComponent = (props: any) => {
  const formattedWords = props.wordCloud.map((word: any) => ({
    ...word,
    value: sizeMapping(word.value),
  }));

  const options: any = {
    fontSizes: [10, 30], // Min and max font sizes
    rotations: 2, // Allow only horizontal (0°) and vertical (90°)
    rotationAngles: [0, 90], // Restrict to horizontal and vertical
    spiral: "archimedean", // Helps prevent overlap
    deterministic: true, // Ensures words don't shuffle
    random: () => 0.5, // Disables automatic shuffling
  };

  return (
    <div className="border border-secondary">
      <ReactWordcloud options={options} words={formattedWords} />
    </div>
  );
};

export default WordCloudComponent;
