import * as React from 'react';
import { Button } from "@mui/material";
import CommonPopUp from '../popup/common-popup';

interface Props {
  open: boolean;
  data: any;
  popupMinWidth?: any;
  popupMaxWidth?: any;
  onClose: (data:any) => void; // Receive onClose callback as a prop
  continueConnect: (data:any) => void;
}

const SocialConnectRestriction: React.FC<Props> = ({
 open,
 data,
 onClose,
 continueConnect,
}) => {
  return (
    <CommonPopUp
      modalTitle="Social Connect"
      modalContent=""
      openModal={open}
      onClose={() => onClose(data.id)}
      className="modal-medium mx-auto"
    >
      <div className="mb-4">
       <p>This account is already connected.</p>
         <div className="helper-text d-flex align-items-baseline mt-3">
                    <p className="inline">Do you wish to continue?</p>
                    <div className="d-flex justify-content-end ml-auto">
                        <Button
                            className="btn btn-primary btn-mui ms-2 me-2"
                            onClick={() => continueConnect(data.id)}
                            variant="contained"
                        >
                            Yes
                        </Button>
                        <Button
                            className="btn btn-outline-primary btn-mui"
                            onClick={() => onClose(data.id)}
                            variant="outlined"
                        >
                            No
                        </Button>
                    </div>
                </div>
       <p>you will be logout from the other device.</p>
      </div>
    </CommonPopUp>
  );
};

export default SocialConnectRestriction;