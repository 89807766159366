import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Box, Tooltip, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FileUploadPopup from '../../../components/popup/file-upload-popup';
import { v360organizationDataTypeProps, v360EntityRegistrationFormProps, v360EntityRegistrationFormErrorProps } from 'custom-type';
import MaterialSymbol from '../../../components/icons/material-symbol';
import { CommonService, LocalService } from '../../../core/services';
import * as gtUtil from "../../../core/utility";

const commonService:CommonService = new CommonService();
const localService: LocalService = new LocalService();
const formValidation = new gtUtil.FormValidation();

export const ThirdPartyRegistrationForm: React.FC<v360organizationDataTypeProps> = (props) => {
  const navigate = useNavigate();
  let path = 'entities/gst_certificates';
  if (props.entity_type === 'Vendor'){
    path = `vendors/gst_certificates/${props.organization_name}`;
  } else {
    path = `clients/gst_certificates/${props.organization_name}`;
  }
  // const [showUploadPopup, setShowUploadPopup] = useState<any>(false);

  const [popUps, setPopUps] = useState({
    openUploadGstCertificateModal: false
  });

  const handleOpenUploadGstCertificateModal = () => setPopUps({ ...popUps, openUploadGstCertificateModal: true });

  function handleCloseUploadGstCertificateModal() {
    setPopUps({ ...popUps, openUploadGstCertificateModal: false });
  }

  const v360organizationData: v360organizationDataTypeProps = props;
  
  const [formData, setFormData] = useState<v360EntityRegistrationFormProps>({
    entity_type: v360organizationData.entity_type,
    v360_group_organization_id: v360organizationData.organization_id,
    v360_group_organization_name: v360organizationData.organization_name,
    company_name: "",
    brand_name: "",
    company_address: "",
    entity_sector: "Echnology and IT",
    entity_sector_other: "",
    pan: "",
    tan: "",
    date_of_incorporation: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    rec_type: "Company",
    rec_type_other: "",
    gst_registration_number: "",
    // hsn_code: "",
    gst_certificate_file_type: "",
    gst_certificate_file_name: "",
    gst_certificate_storage_size: 0,
    gst_certificate_file_path: "",
    gst_certificate_file_url: "",
    finance_person_name: "",
    finance_person_designation: "",
    finance_person_mobile: "",
    finance_person_email: "",
    same_as_finance: "false",
    contact_person_name: "",
    contact_person_designation: "",
    contact_person_mobile: "",
    contact_person_email: ""
  });

  const [formError, setFormError] = useState<v360EntityRegistrationFormErrorProps>({
    entity_type: { status: false, message: "", touched: false, required: false },
    v360_group_organization_id: { status: false, message: "", touched: false, required: false },
    v360_group_organization_name: { status: false, message: "", touched: false, required: false },
    company_name: { status: false, message: "Legal company name is required", touched: false, required: true },
    brand_name: { status: false, message: "Brand name is required", touched: false, required: true },
    company_address: { status: false, message: "Address is required", touched: false, required: true },
    entity_sector: { status: false, message: "Client sector is required", touched: false, required: true },
    entity_sector_other: { status: false, message: "Client sector is required", touched: false, required: false },
    pan: { status: false, message: "Pan number is required", touched: false, required: true },
    tan: { status: false, message: "Tan number is required", touched: false, required: true },
    date_of_incorporation: { status: false, message: "Date of incorporation is required", touched: false, required: true },
    rec_type: { status: false, message: "Rec type is required", touched: false, required: true },
    rec_type_other: { status: false, message: "Rec type is required", touched: false, required: false },
    // gst_business_name: { status: false, message: "", touched: false, required: true },
    gst_registration_number: { status: false, message: "GST registration number is required", touched: false, required: true },
    // hsn_code: { status: false, message: "HSN code is required", touched: false, required: true },
    gst_certificate_file_type: { status: false, message: "gst_certificate_file_type error", touched: false, required: false },
    gst_certificate_file_name: { status: false, message: "gst_certificate_file_name is error", touched: false, required: false },
    gst_certificate_storage_size: { status: false, message: "gst_certificate_storage_size", touched: false, required: false },
    gst_certificate_file_path: { status: false, message: "gst_certificate_file_path", touched: false, required: false },
    gst_certificate_file_url: { status: false, message: "Upload GST certificate", touched: false, required: true },
    finance_person_name: { status: false, message: "Name is required", touched: false, required: true },
    finance_person_designation: { status: false, message: "Designation is required", touched: false, required: true },
    finance_person_mobile: { status: false, message: "Mobile number is required", touched: false, required: true },
    finance_person_email: { status: false, message: "Email is required", touched: false, required: true },
    same_as_finance: { status: false, message: "", touched: false, required: false },
    contact_person_name: { status: false, message: "Contact person name is required", touched: false, required: true },
    contact_person_designation: { status: false, message: "Contact person designation is required", touched: false, required: true },
    contact_person_mobile: { status: false, message: "Contact person mobile number is required", touched: false, required: true },
    contact_person_email: { status: false, message: "Contact person email is required", touched: false, required: true }
  });

  
  // const [sameAsFinance, setSameAsFinance] = useState(false);
  // const [isAutoFilled, setIsAutoFilled] = useState(false);

  // Sync contact details with finance details if checkbox is checked
  useEffect(() => {
    if (formData.same_as_finance === 'true') {
      // setIsAutoFilled(true);
      setFormData((prevData) => ({
        ...prevData,
        contact_person_name: prevData.finance_person_name,
        contact_person_designation: prevData.finance_person_designation,
        contact_person_mobile: prevData.finance_person_mobile,
        contact_person_email: prevData.finance_person_email,
      }));
      // Reset validation errors for contact fields
    }
  }, [formData.same_as_finance === 'true', formData.finance_person_name, formData.finance_person_designation, formData.finance_person_mobile, formData.finance_person_email]);


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    // setSameAsFinance(isChecked);
    setFormData((prevData) => ({
      ...prevData,
      same_as_finance: `${isChecked}`
    }));
    if (!isChecked) {
      // setIsAutoFilled(false);
      
      // Clear contact person fields
      // setFormData((prevData) => ({
      //   ...prevData,
      //   contact_person_name: '',
      //   contact_person_designation: '',
      //   contact_person_mobile: '',
      //   contact_person_email: '',
      // }));

      // Reapply validation errors when unchecked
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        contact_person_name: { status: true, message: 'Contact person name is required', required: true },
        contact_person_designation: { status: true, message: 'Contact person designation is required', required: true },
        contact_person_mobile: { status: true, message: 'Contact person mobile number is required', required: true },
        contact_person_email: { status: true, message: 'Contact person email is required', required: true }
      }));

    } else {
      // setIsAutoFilled(true);

      // Autofill contact person fields
      // setFormData((prevData) => ({
      //   ...prevData,
      //   contact_person_name: prevData.finance_person_name,
      //   contact_person_designation: prevData.finance_person_designation,
      //   contact_person_mobile: prevData.finance_person_mobile,
      //   contact_person_email: prevData.finance_person_email,
      // }));

      // Remove validation errors when checked
      setFormError((prevErrors: any) => ({
        ...prevErrors,
        contact_person_name: { status: false },
        contact_person_designation: { status: false },
        contact_person_mobile: { status: false },
        contact_person_email: { status: false },
      }));
    }
  };

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Validate field if it's been touched
    if (formError[name as keyof v360EntityRegistrationFormErrorProps]?.touched) {
      validateField(name as keyof v360EntityRegistrationFormProps, value);
    }
    // If manually edited, remove autofill status
    // if (name.startsWith("contact_person_") || name.startsWith("finance_person_")) {
    //   setIsAutoFilled(false);
    // }

  };


  // Handle date change
  const handleDateChange = (date: any) => {
    setFormData({
      ...formData,
      date_of_incorporation: dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    });

    if (formError.date_of_incorporation.touched) {
      validateField('date_of_incorporation', dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
    }
  };


  const validateField = (fieldName: keyof v360EntityRegistrationFormProps, value: any) => {
    let isValid: { status: boolean; message: string } = { status: formError[fieldName]?.status, message: formError[fieldName]?.message };

    // Always fetch the predefined message from formError
    const fieldErrorMessage = formError[fieldName]?.message; // Fallback message

    if (!value || (typeof value === "string" && value.trim() === "")) {
      // If empty, show the predefined error message
      isValid = { status: false, message: fieldErrorMessage };
    } else {
      switch (fieldName) {

        case "company_name":
        case "brand_name":
        case "company_address": 
        case "entity_sector":
        case "finance_person_name" : 
        case "finance_person_designation" :
        case "contact_person_name" :
        case "contact_person_designation" :

          const nameValidation = formValidation.GetNameValidate(value);
          isValid = {
            status: nameValidation.status, // Ensure `true` means valid
            message: !nameValidation.status ? fieldErrorMessage : "", // Show error only if invalid
          };
          break;
        case "finance_person_email" :
        case "contact_person_email" :
          const emailValidation = formValidation.GetEmailControlIsValid(value);
          isValid = {
            status: emailValidation.status, // Ensure `true` means valid
            message: !emailValidation.status ? emailValidation.message : "", // Show error only if invalid
          };
          break;
        case "finance_person_mobile" :
        case "contact_person_mobile" :
          const mobileValidation = formValidation.GetMobileControlIsValid(value);
          isValid = {
            status: mobileValidation.status, // Ensure `true` means valid
            message: !mobileValidation.status ? mobileValidation.message : "", // Show error only if invalid
          };
          break;
        case "pan":
          const formattedValue = value.toUpperCase().trim();
          const panValidation = formValidation.getPANisValid(formattedValue);

          isValid = {
            status: panValidation.status,
            message: !panValidation.status ? panValidation.message : "", // Keep message only if invalid
          };
          break;
        case "tan":
          const tanformattedValue = value.toUpperCase().trim();
          const tanValidation = formValidation.getTANisValid(tanformattedValue);

          isValid = {
            status: tanValidation.status,
            message: !tanValidation.status ? tanValidation.message : "", // Keep message only if invalid
          };
          break;
        case "gst_registration_number":
          const GSTValue = value.toUpperCase().trim();
          const gstValidation = formValidation.getgstIsValid(GSTValue);

          isValid = {
            status: gstValidation.status,
            message: !gstValidation.status ? gstValidation.message : "", // Keep message only if invalid
          };
          break;
        default:
          isValid = { status: true, message: "" }; // Default to valid state if not empty
      }
    }

    setFormError(prev => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        status: !isValid.status, // Flip status correctly
      }
    }));
  };

  // Handle field blur (mark as touched)
  const handleBlur = (fieldName: keyof v360EntityRegistrationFormProps) => {
    setFormError(prev => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        touched: true
      }
    }));
    // Validate the field
    validateField(fieldName, formData[fieldName as keyof v360EntityRegistrationFormProps]);
  };

  // Handle rec_type change
  const handleRecTypeChange = (e: any) => {
    const value = e.target.value;

    setFormData({
      ...formData,
      rec_type: value,
      rec_type_other: value === "Other" ? formData.rec_type_other : "",
    });

    // Validate rec_type
    validateField('rec_type', value);

    // Clear rec_type_other error when not "Other"
    if (value !== "Other") {
      setFormError(prev => ({
        ...prev,
        rec_type_other: {
          ...prev.rec_type_other,
          required: false
        },
        rec_type: {
          ...prev.rec_type,
          required: true
        }
      }));
    } else {
      setFormError(prev => ({
        ...prev,
        rec_type_other: {
          ...prev.rec_type_other,
          required: true
        },
        rec_type: {
          ...prev.rec_type,
          required: false
        }
      }));
    }
  };

  // Handle entity_sector change
  const handleEntitySectorChange = (e: any) => {
    const value = e.target.value;

    setFormData({
      ...formData,
      entity_sector: value,
      entity_sector_other: value === "Other" ? formData.entity_sector_other : "",
    });

    // Validate entity_sector
    validateField('entity_sector', value);

    // Clear entity_sector_other error when not "Other"
    if (value !== "Other") {
      setFormError(prev => ({
        ...prev,
        entity_sector_other: {
          ...prev.entity_sector_other,
          required: false
        },
        entity_sector: {
          ...prev.entity_sector,
          required: true
        }
      }));
    } else {
      setFormError(prev => ({
        ...prev,
        entity_sector_other: {
          ...prev.entity_sector_other,
          required: true
        },
        entity_sector: {
          ...prev.entity_sector,
          required: false
        }
      }));
    }
  };

  const insertGstCertificateDetail = async (e: any) => {
    try {
      const updatedFormData = {
        ...formData,
        gst_certificate_file_url: e.url,
        gst_certificate_file_path: e.key,
        gst_certificate_file_type: e.fileType,
        gst_certificate_file_name: e.fileName,
        gst_certificate_storage_size: e.storage_size,
      };

      setFormData(updatedFormData);

      // ✅ Clear the validation error if file is uploaded
      if (e.url) {
        setFormError((prev) => ({
          ...prev,
          gst_certificate_file_url: {
            ...prev.gst_certificate_file_url,
            status: false,
            touched: false,
          },
        }));
      }
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      handleCloseUploadGstCertificateModal();
    }
  };


  // Validate all required fields
  const validateAllFields = () => {
    const newErrors = {...formError};
    let isValid = true;
  
    // Check all required fields
    (Object.keys(newErrors) as Array<keyof v360EntityRegistrationFormErrorProps>).forEach(key => {
      if (newErrors[key].required) { // Only validate required fields
        const fieldValue = formData[key as keyof v360EntityRegistrationFormProps];
        const fieldIsValid = !!fieldValue;
        // if (!fieldIsValid) {
        //   isValid = false;
        //   newErrors[key] = {
        //     ...newErrors[key],
        //     status: true,
        //     touched: true
        //   };
        // }
        
        // Special case for rec_type_other - only required if rec_type is "Other"
        if (key === 'rec_type_other') {
          const recTypeOtherRequired = formData.rec_type === 'Other' && !fieldValue;
          newErrors[key] = {
            ...newErrors[key],
            status: recTypeOtherRequired,
            touched: true
          };
          if (recTypeOtherRequired) isValid = false;
        } else if (!fieldIsValid) {
          isValid = false;
          newErrors[key] = {
            ...newErrors[key],
            status: true,
            touched: true
          };
        }
        if (key === 'entity_sector_other') {
          const recTypeOtherRequired = formData.entity_sector === 'Other' && !fieldValue;
          newErrors[key] = {
            ...newErrors[key],
            status: recTypeOtherRequired,
            touched: true
          };
          if (recTypeOtherRequired) isValid = false;
        } else if (!fieldIsValid) {
          isValid = false;
          newErrors[key] = {
            ...newErrors[key],
            status: true,
            touched: true
          };
        }
      }
    });
    // GST Certificate validation - only show error if empty
    if (!formData.gst_certificate_file_url) {
      isValid = false;
      newErrors.gst_certificate_file_url = {
        ...newErrors.gst_certificate_file_url,
        status: true,
        touched: true,
      };
    } else {
      // Clear error if a file exists
      newErrors.gst_certificate_file_url = {
        ...newErrors.gst_certificate_file_url,
        status: false,
        touched: true,
      };
    }
    return { isValid, errors: newErrors };
  };
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    
    // Validate all fields and get updated errors
    const { isValid, errors } = validateAllFields();
    // Update all errors at once
    setFormError(errors);
    if (isValid) {
      // Submit the form
      commonService.entityRegistration(formData)
      .then((res: any) => {
        const data = res?.data?.rows;
        // if (res.status === gtUtil.Constants.SUCCESS) {
        //   localService.toastify(res.info, 'success', 1000);
        //   if (props.showSubmitStatus) {
        //     props.showSubmitStatus({status: true, message: res.info});
        //   }
        // } else {
        //   localService.toastify(res.info, 'error', 1000);
        //   navigate('/');
        // }
        localService.toastify(res.info, res.status === gtUtil.Constants.SUCCESS ? 'success' : 'error', 1000);
        if (props.showSubmitStatus && res.status === gtUtil.Constants.SUCCESS) {
          props.showSubmitStatus({status: res.status === gtUtil.Constants.SUCCESS, message: res.info});
        }
      })
      .catch((e: any) => {
        // localService.toastify('Something went wrong! Please contact administrator.', 'error', 1000);
        // navigate('/');
        let message = 'Something went wrong! Please contact administrator.';
        localService.toastify(message, 'error', 1000);
        if (props.showSubmitStatus) {
          props.showSubmitStatus({status: false, message: message});
        }
      });
    }
  };

  return (
    <>
      <Grid container justifyContent="center" sx={{ my: 4 }}>
        <Grid item>
          {props.organization_logo && (
            <Box
              component="img"
              src={props.organization_logo}
              alt="Organization Logo"
              sx={{
                maxWidth: 150,
                maxHeight: 150,
                objectFit: 'contain'
              }}
            />
          )}
        </Grid>
      </Grid>
      <div className='form-client-registration'>
        
      <strong className='d-flex mb-2'>{props.entity_type} Registration Form <span className='fs-12 ms-auto'>All fields are mandatory</span></strong>
        <form onSubmit={handleSubmit} noValidate>
          {/* Company Name */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['company_name'].required}
            id="company_name"
            label="Legal Company Name (as per GST registration)"
            value={formData.company_name}
            name='company_name'
            onChange={handleInputChange}
            onBlur={() => handleBlur('company_name')}
            error={formError.company_name.status}
            helperText={formError.company_name.status ? formError.company_name.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
            
          />
           {/* Brand Name */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['brand_name'].required}
            id="brand_name"
            label="Brand Name"
            value={formData.brand_name}
            name='brand_name'
            onChange={handleInputChange}
            onBlur={() => handleBlur('brand_name')}
            error={formError.brand_name.status}
            helperText={formError.brand_name.status ? formError.brand_name.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
            
          />

          {/* Company Address */}
          <TextField 
            className='w-100 mb-3 input-common'
            required={formError['company_address'].required}
            id="company_address"
            label="Address"
            multiline
            value={formData.company_address}
            name='company_address'
            onChange={handleInputChange}
            onBlur={() => handleBlur('company_address')}
            error={formError.company_address.status}
            helperText={formError.company_address.status ? formError.company_address.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
          />

          {/* Client Sector */}
          {/* <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['entity_sector'].required}
            id="entity_sector"
            label="Client Sector"
            value={formData.entity_sector}
            name='entity_sector'
            onChange={handleInputChange}
             onBlur={() => handleBlur('entity_sector')}
            error={formError.entity_sector.status}
            helperText={formError.entity_sector.status ? formError.entity_sector.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
          /> */}
          {/* Rec Type */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-3">
            <FormControl fullWidth size="small" className=" input-common" error={formError.entity_sector.status}>
              <InputLabel id="rec-type-label" required>{props.entity_type} Sector</InputLabel>
              <Select
                labelId="rec-type-label"
                id="entity_sector"
                value={formData.entity_sector}
                onChange={handleEntitySectorChange}
                name="entity_sector"
                required={formError['entity_sector'].required}
                onBlur={() => handleBlur('entity_sector')}
              >
                <MenuItem value="Echnology and IT">Echnology and IT</MenuItem>
                <MenuItem value="Finance and Banking">Finance and Banking</MenuItem>
                <MenuItem value="Healthcare and Pharmaceuticals">Healthcare and Pharmaceuticals</MenuItem>
                <MenuItem value="Automotive and Transportation">Automotive and Transportation</MenuItem>
                <MenuItem value="Consumer Goods and Retail">Consumer Goods and Retail</MenuItem>
                <MenuItem value="Real Estate and Construction">Real Estate and Construction</MenuItem>
                <MenuItem value="Energy and Utilities">Energy and Utilities</MenuItem>
                <MenuItem value="Education and E-Learning">Education and E-Learning</MenuItem>
                <MenuItem value="Hospitality and Travel">Hospitality and Travel</MenuItem>
                <MenuItem value="Media and Entertainment">Media and Entertainment</MenuItem>
                <MenuItem value="Agriculture and AgriTech">Agriculture and AgriTech</MenuItem>
                <MenuItem value="Logistics and Supply Chain">Logistics and Supply Chain</MenuItem>
                <MenuItem value="Telecommunications">Telecommunications</MenuItem>
                <MenuItem value="Legal and Compliance">Legal and Compliance</MenuItem>
                <MenuItem value="Food and Beverage">Food and Beverage</MenuItem>
                <MenuItem value="Aerospace and Defense">Aerospace and Defense</MenuItem>
                <MenuItem value="Manufacturing and Industrial">Manufacturing and Industrial</MenuItem>
                <MenuItem value="Non-Profit and Social Impact">Non-Profit and Social Impact</MenuItem>
                <MenuItem value="Fashion and Lifestyle">Fashion and Lifestyle</MenuItem>
                <MenuItem value="Sports and Fitness">Sports and Fitness</MenuItem>
                <MenuItem value="Government and Public Sector">Government and Public Sector</MenuItem>
                <MenuItem value="Luxury and High-End Brands">Luxury and High-End Brands</MenuItem>
                <MenuItem value="Human Resources and Recruitment">Human Resources and Recruitment</MenuItem>
                <MenuItem value="Sustainability and Environmental Solutions">Sustainability and Environmental Solutions</MenuItem>
                <MenuItem value="Art and Culture">Art and Culture</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {formError.entity_sector.status && (
                <div className="MuiFormHelperText-root Mui-error">
                  {formError.entity_sector.message}
                </div>
              )}
            </FormControl>

            {formData.entity_sector === "Other" && (
              <TextField
                className="w-100 mb-3 input-common"
                size="small"
                required={formError['entity_sector_other'].required}
                id="entity_sector_other"
                label="Enter Rec Type"
                name="entity_sector_other"
                value={formData.entity_sector_other}
                onChange={handleInputChange}
                onBlur={() => handleBlur('entity_sector_other')}
                error={formError.entity_sector_other.status}
                helperText={formError.entity_sector_other.status ? formError.entity_sector_other.message : ""}
                FormHelperTextProps={{
                  style: {
                    marginLeft:0,
                     // Adjust as needed
                  }
                }}
                sx={{ 
                  mb: formError.entity_sector_other.status ? 2 : 3 // More space when error shows
                }}
              />
            )}
          </div>

          {/* PAN No. */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['pan'].required}
            id="pan"
            label="PAN No."
            value={formData.pan}
            name='pan'
            onChange={handleInputChange}
            onBlur={() => handleBlur('pan')}
            error={formError.pan?.status} // Use direct value instead of `!!`
            // helperText={formError.pan?.status ? formError.pan.message : ""}
            helperText={formError.pan?.status  ? formError.pan?.message  : ""} 
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
          />
          {/* TAN No. */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['tan'].required}
            id="tan"
            label="TAN No."
            value={formData.tan}
            name='tan'
            onChange={handleInputChange}
            onBlur={() => handleBlur('tan')}
            error={formError.tan.status}
            helperText={formError.tan.status ? formError.tan.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
          />

          {/* Date of Incorporation */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>      
            <DatePicker
              className='w-100  input-common mb-3'
              label="Date of incorporation" 
              value={dayjs(formData.date_of_incorporation)}
              onChange={handleDateChange}
              onClose={() => handleBlur('date_of_incorporation')}
              maxDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  error: formError.date_of_incorporation.status,
                  helperText: formError.date_of_incorporation.status ? formError.date_of_incorporation.message : "",
                },
              }}
            />
          </LocalizationProvider> */}

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <DatePicker
              className="w-100 input-common mb-3"
              label="Date of Incorporation"
              value={dayjs(formData.date_of_incorporation)}
              onChange={handleDateChange}
              format="DD-MM-YYYY"  // Set the date format here
              onClose={() => handleBlur("date_of_incorporation")}
              maxDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  error: formError.date_of_incorporation.status,
                  helperText: formError.date_of_incorporation.status ? formError.date_of_incorporation.message : "",
                },
              }}
            />
          </LocalizationProvider>

          {/* Rec Type */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-3">
            <FormControl fullWidth size="small" className=" input-common" error={formError.rec_type.status}>
              <InputLabel id="rec-type-label" required>Rec Type - Individual / Firm / Company</InputLabel>
              <Select
                labelId="rec-type-label"
                id="rec_type"
                value={formData.rec_type}
                onChange={handleRecTypeChange}
                name="rec_type"
                required={formError['rec_type'].required}
                onBlur={() => handleBlur('rec_type')}
              >
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Firm">Firm</MenuItem>
                <MenuItem value="Company">Company</MenuItem>
                <MenuItem value="Private Limited">Private Limited</MenuItem>
                <MenuItem value="Limited">Limited</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {formError.rec_type.status && (
                <div className="MuiFormHelperText-root Mui-error">
                  {formError.rec_type.message}
                </div>
              )}
            </FormControl>

            {formData.rec_type === "Other" && (
              <TextField
                className="w-100 mb-3 input-common"
                size="small"
                required={formError['rec_type_other'].required}
                id="rec_type_other"
                label="Enter Rec Type"
                name="rec_type_other"
                value={formData.rec_type_other}
                onChange={handleInputChange}
                onBlur={() => handleBlur('rec_type_other')}
                error={formError.rec_type_other.status}
                helperText={formError.rec_type_other.status ? formError.rec_type_other.message : ""}
                FormHelperTextProps={{
                  style: {
                    marginLeft:0,
                     // Adjust as needed
                  }
                }}
                sx={{ 
                  mb: formError.rec_type_other.status ? 2 : 3 // More space when error shows
                }}
              />
            )}
          </div>

          <strong className='mb-1 d-block'>GST Details</strong>
          {/* GST Registration No. */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['gst_registration_number'].required}
            id="gst_registration_number"
            label="GST registration no."
            name='gst_registration_number'
            value={formData.gst_registration_number}
            onChange={handleInputChange}
            onBlur={() => handleBlur('gst_registration_number')}
              error={formError.gst_registration_number.status}
              helperText={formError.gst_registration_number.status ? formError.gst_registration_number.message : ""
            }
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                  // Adjust as needed
              }
            }}
          />

          {/* HSN Code */}
          {/* <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['hsn_code'].required}
            id="hsn_code"
            label="HSN Code"
            name='hsn_code'
            value={formData.hsn_code}
            onChange={handleInputChange}
            onBlur={() => handleBlur('hsn_code')}
            error={formError.hsn_code.status}
            helperText={formError.hsn_code.status ? formError.hsn_code.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
              }
            }}
          /> */}

          {/* Upload Button */}
          {(formData.gst_certificate_file_url !== '' && formData.gst_certificate_file_name !== '') && (
            <div style={{ paddingRight: '10px', display: 'inline-flex', alignItems: 'center' }}>
              <Tooltip title={formData.gst_certificate_file_name} arrow>
                <NavLink
                  target="_blank"
                  key={`gst_certificate_${formData.gst_certificate_file_name}`}
                  to={formData.gst_certificate_file_url}
                  data-tip={formData.gst_certificate_file_url}
                >
                  <IconButton size="small">
                    <MaterialSymbol
                      iconName="description"
                      fontweight="200"
                      fontSize="20"
                    />
                  </IconButton>
                  <span>{formData.gst_certificate_file_name}</span>
                </NavLink>
              </Tooltip>
            </div>
          )}

          <div>
          <Button
            className='btn btn-outline-primary upload_button'
            onClick={() => setPopUps({ ...popUps, openUploadGstCertificateModal: true })}
            variant='contained'
          >
            {formData.gst_certificate_file_url === '' ? 'Upload GST Certificate' : 'Change'}
          </Button>
          
          {formError.gst_certificate_file_url?.status && (
            <span className="text-danger fs-12 d-block" style={{color:'#d32f2f'}}>{formError.gst_certificate_file_url.message}</span>
          )}
           {formError.gst_certificate_file_name?.status && (
            <span className="text-danger fs-12 d-block" style={{color:'#d32f2f'}}>{formError.gst_certificate_file_name.message}</span>
          )}
          {formError.gst_certificate_file_path?.status && (
            <span className="text-danger fs-12 d-block" style={{color:'#d32f2f'}}>{formError.gst_certificate_file_path.message}</span>
          )}
          {formError.gst_certificate_file_type?.status && (
            <span className="text-danger fs-12 d-block" style={{color:'#d32f2f'}}>{formError.gst_certificate_file_type.message}</span>
          )}
        </div>
          {/* {formError.gst_certificate_file_url.status ? formError.gst_certificate_file_url.message : ""}
          {/* {formError.gst_registration_number.status ? formError.gst_registration_number.message : ""} 
          {formError.gst_certificate_file_name.status ? formError.gst_certificate_file_name.message : ""}
          {formError.gst_certificate_file_path.status ? formError.gst_certificate_file_path.message : ""}
          {formError.gst_certificate_storage_size.status ? formError.gst_certificate_storage_size.message : ""} */}
          <br/>
          <br/>
          <strong className='mb-1 d-block'>Contact Detail of Finance/Account</strong>
          {/* Finance Person Name */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['finance_person_name'].required}
            id="finance_person_name"
            label="Name"
            value={formData.finance_person_name}
            name='finance_person_name'
            onChange={handleInputChange}
            onBlur={() => handleBlur('finance_person_name')}
            error={formError.finance_person_name.status}
            helperText={formError.finance_person_name.status ? formError.finance_person_name.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
          />

          {/* Finance Person Designation */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['finance_person_designation'].required}
            id="finance_person_designation"
            label="Designation"
            value={formData.finance_person_designation}
            name='finance_person_designation'
            onChange={handleInputChange}
            onBlur={() => handleBlur('finance_person_designation')}
            error={formError.finance_person_designation.status}
            helperText={formError.finance_person_designation.status ? formError.finance_person_designation.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                 // Adjust as needed
              }
            }}
          />

          {/* Finance Person Mobile */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['finance_person_mobile'].required}
            id="finance_person_mobile"
            label="Mobile Number"
            name='finance_person_mobile'
            value={formData.finance_person_mobile}
            onChange={handleInputChange}
            onBlur={() => handleBlur('finance_person_mobile')}
            error={formError.finance_person_mobile.status}
            helperText={formError.finance_person_mobile.status ? formError.finance_person_mobile.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                marginTop: 0,
              }
            }}
          />

          {/* Finance Person Email */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['finance_person_email'].required}
            type='email'
            id="finance_person_email"
            label="Email Address"
            name='finance_person_email'
            value={formData.finance_person_email}
            onChange={handleInputChange}
            onBlur={() => handleBlur('finance_person_email')}
            error={formError.finance_person_email.status}
            helperText={formError.finance_person_email.status ? formError.finance_person_email.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                marginTop: 0,
                marginBottom: '10px', // Adjust as needed
              }
            }}
          /> 
          <strong className='mb-1 d-block mt-3'>Details of contact person</strong>
          {/* Contact Person Name */}
          
          <FormControlLabel control={<Checkbox checked={formData.same_as_finance === 'true'} 
          onChange={handleCheckboxChange}  />} label="Same as finance" />

          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['contact_person_name'].required}
            disabled={formData.same_as_finance === 'true'}
            id="contact_person_name"
            label="Name"
            value={formData.contact_person_name}
            name='contact_person_name'
            onChange={handleInputChange}
            onBlur={() => handleBlur('contact_person_name')}
            error={formError.contact_person_name.status}
            helperText={formError.contact_person_name.status ? formError.contact_person_name.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                marginTop: 0, // Adjust as needed
              }
            }}
          />

          {/* Contact Person Designation */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['contact_person_designation'].required}
            disabled={formData.same_as_finance === 'true'}
            id="contact_person_designation"
            label="Designation"
            value={formData.contact_person_designation}
            name='contact_person_designation'
            onChange={handleInputChange}
            onBlur={() => handleBlur('contact_person_designation')}
            error={formError.contact_person_designation.status}
            helperText={formError.contact_person_designation.status ? formError.contact_person_designation.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                marginTop: 0, // Adjust as needed
              }
            }}
          />

          {/* Contact Person Mobile */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['contact_person_mobile'].required}
            disabled={formData.same_as_finance === 'true'}
            id="contact_person_mobile"
            label="Mobile Number"
            name='contact_person_mobile'
            value={formData.contact_person_mobile}
            onChange={handleInputChange}
            onBlur={() => handleBlur('contact_person_mobile')}
            error={formError.contact_person_mobile.status}
            helperText={formError.contact_person_mobile.status ? formError.contact_person_mobile.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                marginTop: 0,
                marginBottom: '0px', // Adjust as needed
              }
            }}
          />

          {/* Contact Person Email */}
          <TextField 
            className='w-100 mb-3 input-common'
            size='small'
            required={formError['contact_person_email'].required}
            disabled={formData.same_as_finance === 'true'}
            type='email'
            id="contact_person_email"
            label="Email ID of contact person"
            name='contact_person_email'
            value={formData.contact_person_email}
            onChange={handleInputChange}
            onBlur={() => handleBlur('contact_person_email')}
            error={formError.contact_person_email.status}
            helperText={formError.contact_person_email.status ? formError.contact_person_email.message : ""}
            FormHelperTextProps={{
              style: {
                marginLeft:0,
                marginTop: 0,
                marginBottom: '0px', // Adjust as needed
              }
            }}
          />

          <button type="submit" className="btn btn-primary">
            Submit
          </button>                                 
        </form>
        {popUps.openUploadGstCertificateModal && (
          <FileUploadPopup 
            openModal={popUps.openUploadGstCertificateModal} 
            onClose={(e) => handleCloseUploadGstCertificateModal()} 
            onSend={(e: any) => insertGstCertificateDetail(e)}
            path={path}
            acceptedFileTypes=".pdf, .doc, .docx, .jpg, .jpeg, .png" 
          />
        )}
      </div>
    </>
  )
};