import React, { useState } from 'react'
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import { Tabs, Tab } from "react-bootstrap";

import Grid from '@mui/material/Grid';
interface Props{
    UserReducer: any;
    loading: (data: any) => void;
    comments:any;
}
const CommentsTab:React.FC<Props> = (props: Props) => {
    const [key, setKey] = useState("positive");
    const positiveComments = props.comments.filter((comment:any) => comment.score > 0);
    const neutralComments = props.comments.filter((comment:any) => comment.score == 0);
    const negativeComments = props.comments.filter((comment:any) => comment.score < 0);
    
    
  return (
    <>
    <Tabs
      id="controlled-tabs"
      activeKey={key}
      onSelect={(k:any) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="positive" title="positive">
      {
      positiveComments.map((item:any) => 
        (<li>{item.comments}-{item.user_commenting}</li>))
    }

      </Tab>
      <Tab eventKey="negative" title="negative">
      {negativeComments.map((item:any) => (
         <li>{item.comments}-{item.user_commenting}</li>
     ))}
      </Tab>
      <Tab eventKey="neutral" title="neutral">
      {neutralComments.map((item:any) => (
         <li>{item.comments}-{item.user_commenting}</li>
    ))}
      </Tab>
    </Tabs>

    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(CommentsTab);
  
  export { connectToRedux as  CommentsTab };
  
  