import React, { useState, useEffect } from "react";
import "./security.scss";
import { connect } from "react-redux";
import { FormValidation } from "../../../core/utility";
import { LocalService } from "../../../core/services";
import { UserService } from "../../../core/services";
import CommonPopUp from "../../../../src/components/popup/common-popup";
import * as gtUtil from "../../../core/utility";
import { AdminDashboardService } from "../../../core/services/admin-dashboard.service";
import {
  Button,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";

const localService = new LocalService();
const adminDashboardService = new AdminDashboardService();
const userService = new UserService();
interface Props {
  UserData: any;
}
interface ErrorTypes {
  status: boolean;
  message: string;
}


const Security: React.FC<Props> = (props: Props) => { 
  const formValidation = new FormValidation();
  const [popup, set_popup] = useState({
    pop_deactivate: false,
    pop_delete: false,
    pop_delete_step2: false,
    pop_deactivate_step2: false,
  });
  const [formError, setFormError] = useState({
    remark: { status: true, message: "" },
    remarkDescription: { status: true, message: "" },
  });
  const [remark, setRemark] = useState("");
  const [otherRemark, setOtherRemark] = useState(false);
  const [remarkDescription, setRemarkDescription] = useState("");
  const [timerState, setTimerState] = useState({
    isDisabledPassword: false,
    isDisabledPIN: false,
    passwordTimeLeft: 0,
    pinTimeLeft: 0,
  });
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const [isContinueDeleteDisabled, setIsContinueDeleteDisabled] = useState(true);

  // Single useEffect to handle both countdowns
  useEffect(() => {
    if (timerState.passwordTimeLeft <= 0 && timerState.pinTimeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimerState((prevState) => ({
        ...prevState,
        passwordTimeLeft: prevState.passwordTimeLeft > 0 ? prevState.passwordTimeLeft - 1 : 0,
        pinTimeLeft: prevState.pinTimeLeft > 0 ? prevState.pinTimeLeft - 1 : 0,
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, [timerState.passwordTimeLeft, timerState.pinTimeLeft]);


  const forgetPasswordPin = (login: string, forgot: string) => {
    if (!login.trim()) {
      localService.toastify("Valid Email or Mobile Number is required", "error", 1000);
      return;
    }
  
    userService.forgetPasswordPin({ login, forgot }).then((res) => {
      if (res.status === gtUtil.Constants.SUCCESS) {
        localService.toastify(res.info, "success", 1000);
  
        const countdownTime = 180; // 3 minutes
  
        setTimerState((prevState) => ({
          ...prevState,
          isDisabledPassword: forgot === "forgot password" ? true : prevState.isDisabledPassword,
          isDisabledPIN: forgot === "forgot pin" ? true : prevState.isDisabledPIN,
          passwordTimeLeft: forgot === "forgot password" ? countdownTime : prevState.passwordTimeLeft,
          pinTimeLeft: forgot === "forgot pin" ? countdownTime : prevState.pinTimeLeft,
        }));
  
        setTimeout(() => {
          setTimerState((prevState) => ({
            ...prevState,
            isDisabledPassword: forgot === "forgot password" ? false : prevState.isDisabledPassword,
            isDisabledPIN: forgot === "forgot pin" ? false : prevState.isDisabledPIN,
          }));
        }, countdownTime * 1000);
      } else {
        setTimerState({
          isDisabledPassword: false,
          isDisabledPIN: false,
          passwordTimeLeft: 0,
          pinTimeLeft: 0,
        });
        localService.toastify(res.info, "error", 1000);
      }
    });
  };

  const handleRemarkDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setRemarkDescription(selectedValue);
    const isValidRemarkDescription =
      formValidation.GetNameValidate(selectedValue).status;

    if (remark === "Other" && !isValidRemarkDescription) {
      setFormError((prev) => ({
        ...prev,
        remarkDescription: { status: false, message: "This Field is required" },
      }));
      // return;
    } else {
      setFormError((prev) => ({
        ...prev,
        remarkDescription: { status: true, message: "" },
      }));
    }
    // setFormData({ ...formData, reason: (event.target as HTMLInputElement).value });
    // validateForm();
  };

  const onCloseDeactivate = (value: boolean) => {
  set_popup((prevState) => ({
      ...prevState,
      pop_deactivate: value,
      pop_deactivate_step2: value,
    }));
  setRemark("");
  setRemarkDescription(""); 
  setOtherRemark(false); 
  setIsContinueDisabled(true); 
};

  const handleOpenDeactivateFinalStep = (value: boolean) => {
    set_popup((prevState) => ({
      ...prevState,
      pop_deactivate_step2: true,
    }));
    
  }
    const handleOpenDeleteFinalStep = (value: boolean) => {
    set_popup((prevState) => ({
      ...prevState,
      pop_delete_step2: true,
    }));
    
  }

  

  const onCloseDelete = (value: boolean) => {
    
    setRemark("");
    setOtherRemark(value);
    setRemarkDescription("");
    set_popup((prevState) => ({
      ...prevState,
      pop_deactivate_step2: value,
      pop_delete: value,
      pop_delete_step2: value,
    }));
    setIsContinueDeleteDisabled(true)
    
  };

  async function account_status(status: string) {
    try {
      const isValidRemark = remark.length > 0;
      const isValidRemarkDescription =
        formValidation.GetNameValidate(remarkDescription).status;

      if (!isValidRemark) {
        localService.toastify("Please select a valid reason", "error", 1000);
        return;
      }

      if (remark === "Other" && !isValidRemarkDescription) {
        setFormError((prev) => ({
          ...prev,
          remarkDescription: {
            status: false,
            message: "This Field is required",
          },
        }));
        return;
      }
      const res = await userService.accountStatus({
        account_id: props.UserData.account_id,
        user_id: props.UserData.user_id,
        status: status,
        remark: remark,
        remark_description: remarkDescription,
      });
      if (res.status === gtUtil.Constants.SUCCESS) {
        localService.logout();
      }
    } catch (error) {
      console.error("Error deactivateAccount:", error);
    } finally {
    }
  }

  const deleteUser = async (loginEmailMobile: string, accountType: string) => {
    let response;
    try {
      const isValidRemark = remark.length > 0;
      const isValidRemarkDescription =
        formValidation.GetNameValidate(remarkDescription).status;

      if (!isValidRemark) {
        localService.toastify("Please select a valid reason", "error", 1000);
        return;
      }

      if (remark === "Other" && !isValidRemarkDescription) {
        setFormError((prev) => ({
          ...prev,
          remarkDescription: {
            status: false,
            message: "This Field is required",
          },
        }));
        return;
      }
      if (accountType === "Influencer") {
        response = await adminDashboardService.deleteInfluencer({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription,
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
      if (accountType === "Brand") {
        response = await adminDashboardService.deleteBrand({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription,
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
      if (accountType === "Agency") {
        response = await adminDashboardService.deleteAgency({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription,
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
      if (accountType === "Talent Partner") {
        response = await adminDashboardService.deleteTalentPartner({
          login: loginEmailMobile,
          remark: remark,
          remark_description: remarkDescription,
        });
        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.logout();
        }
      }
    } catch (error) {
      console.log(`deleteUser error :: ${JSON.stringify(error)}`);
    }
  };

  const onSelectChange = async (data: any) => {
    try {
      setIsContinueDisabled(false)
      setIsContinueDeleteDisabled(false);
      setRemark(data);
      if (data == "Other") {
        setOtherRemark(true);
      } else {
        setOtherRemark(false);
        setRemarkDescription("");
      }
    } catch (error) {
      console.log(`onSelectChange error :: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    if (otherRemark && (remarkDescription.trim() !== "" && remarkDescription.length > 2)) {
      setIsContinueDisabled(false);
      setIsContinueDeleteDisabled(false);

    } else if (!otherRemark && remark) {
      setIsContinueDisabled(false);
      setIsContinueDeleteDisabled(false);
    } else {
      setIsContinueDisabled(true);
      setIsContinueDeleteDisabled(true);
    }
  }, [otherRemark, remarkDescription, remark]);


  return (
    <>
      <div className="security-settings">
        {/* Section Title */}
        <div className="list-section-content rounded">
          <strong className="d-block">Security</strong>
        </div>

        {/* Security Options - Password & PIN */}
        <div className="security-options">
          {/* Forgot Password Section */}
          <div className="security-item">
            <span className="security-label">Password</span>
            <div className="security-btn-container">
              <Button
                type="button"
                variant="contained"
                className="btn btn-outline-primary"
                onClick={() =>
                  forgetPasswordPin(
                    props.UserData.login_email,
                    "forgot password"
                  )
                }
                disabled={timerState.isDisabledPassword}
              >
                Reset Password
              </Button>
              {timerState.isDisabledPassword && timerState.passwordTimeLeft > 0 && (
                <span className="error">
                  {Math.floor(timerState.passwordTimeLeft / 60)}:
                  {(timerState.passwordTimeLeft % 60).toString().padStart(2, "0")}
                </span>
              )}
            </div>
          </div>
          {/* Forgot PIN Section */}
          <div className="security-item">
            <span className="security-label">PIN</span>

            <div className="security-btn-container">
              <Button
                type="button"
                variant="contained"
                className="btn btn-outline-primary"
                onClick={() =>
                  forgetPasswordPin(props.UserData.login_email, "forgot pin")
                }
                disabled={timerState.isDisabledPIN}
              >
                Reset PIN
              </Button>
              {timerState.isDisabledPIN && timerState.pinTimeLeft > 0 && (
                <span className="error">
                  {Math.floor(timerState.pinTimeLeft / 60)}:
                  {(timerState.pinTimeLeft % 60).toString().padStart(2, "0")}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* Account Actions - Deactivate & Delete */}
        <div className="security-options">
          {/* Deactivate Account */}
          <div className="security-item">
            <span className="security-label deactivate-text">Deactivate your account</span>
            <Button
              type="button"
              variant="contained"
              className="btn btn-outline-primary"
              onClick={() =>
                set_popup((prevState) => ({
                  ...prevState,
                  pop_deactivate: true,
                }))
              }
            >
              Deactivate
            </Button>
          </div>

          {/* Delete Account */}
          <div className="security-item">
            <span className="security-label deactivate-text">Delete your account</span>
            <Button
              type="button"
              variant="contained"
              className="btn btn-outline-primary"
              onClick={() =>
                set_popup((prevState) => ({
                  ...prevState,
                  pop_delete: true,
                }))
              }
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      {popup.pop_deactivate && (
        <>
        {/* STEP 1 */}
          <CommonPopUp
            modalTitle="Deactivate"
            modalContent=""
            openModal={popup.pop_deactivate}
            onClose={() => onCloseDeactivate(false)}
            className="modal-medium mx-auto deactivate-popup-container"
          >
            <div className="deactivate-popup">
              <FormControl className="deactivate-popup-input-sec" fullWidth size="small">
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  We’d hate to see you go - help us understand why you want to deactivate your account.
                </FormLabel>
                {formError.remark.message && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {formError.remark.message}
                  </div>
                )}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  id="gender_id"
                  value={remark}
                  onChange={(e) => {
                    onSelectChange(e.target.value);
                  }}
                  className="fs-14"
                >
                  <FormControlLabel className="mb-2"
                    value="I’m taking a break from influencer marketing"
                    control={<Radio className="py-0" size="small" />}
                    label="I’m taking a break from influencer marketing."
                  />
                  <FormControlLabel className="mb-2"
                    value="I’m not receiving enough collaboration opportunities"
                    control={<Radio className="py-0" size="small" />}
                    label="I’m not receiving enough collaboration opportunities."
                  />
                  <FormControlLabel className="mb-2"
                    value="The platform is difficult to use"
                    control={<Radio className="py-0" size="small" />}
                    label="The platform is difficult to use."
                  />
                  <FormControlLabel className="mb-2"
                    value="My goals have changed"
                    control={<Radio className="py-0" size="small" />}
                    label="My goals have changed."
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio className="py-0" size="small" />}
                    label="Other"
                    onClick={() => setOtherRemark(true)}
                  />
                  {otherRemark && (
                    <div className="mt-2">
                      <TextField
                        className="w-100"
                        id="remarkDescription"
                        name="remarkDescription"
                        multiline
                        rows={4}
                        value={remarkDescription}
                        error={!formError.remarkDescription.status}
                        helperText={formError.remarkDescription.message}
                        onChange={handleRemarkDescription}
                        onBlur={() => {
                          setFormError({
                            ...formError,
                            remarkDescription:
                              formValidation.GetNameValidate(remarkDescription),
                          });
                        }}
                      />
                      {/* {formError.remarkDescription.message && <div style={{ color: 'red', marginTop: '8px' }}>{formError.remarkDescription.message}</div>} */}  
                    </div>
                  )}
                </RadioGroup>
              </FormControl>
              <div className="popup-footer">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-primary btn-mui  ms-2"
                    onClick={()=>handleOpenDeactivateFinalStep(true)}
                    variant="outlined"
                    disabled={isContinueDisabled}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </CommonPopUp>
          {/* STEP 2 */}
          <CommonPopUp
            modalTitle="Deactivate"
            modalContent=""
            openModal={popup.pop_deactivate_step2}
            onClose={() => onCloseDeactivate(false)}
            className="modal-medium mx-auto deactivate-popup-container"
          >
            <div className="deactivate-popup">
              <span className="pb-3 d-block fs-14">{props.UserData.email_primary}</span>
              <span  className="pb-1 d-block fs-14">Please note:</span>
              <ul className="fs-14 list-style-none ps-0" >
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">Profile Becomes Invisible</strong>
                  <span>Your profile will no longer be visible to brands or other users.</span> 
                </li>
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">No New Campaigns</strong>
                  <span>You won’t receive new campaign offers or collaboration opportunities.
                  </span> 
                </li>
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">Existing Campaigns May Be Affected</strong>
                  <span>Any ongoing campaigns may be paused or impacted.</span> 
                </li>
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">Data Remains Intact</strong>
                  <span>Your account data is saved, and you can reactivate anytime by logging back in.</span> 
                </li>
                <li>
                  <strong className="d-block">No Notifications</strong>
                  <span>You won’t receive platform updates, emails, or notifications.</span> 
                </li>
              </ul>
              <div className="popup-footer">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-outline-primary btn-mui ms-2"
                    onClick={() => onCloseDeactivate(false)}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-primary btn-mui  ms-2"
                    onClick={() => {
                      account_status("Deactivate");
                    }}
                    variant="outlined"
                  >
                    Deactivate
                  </Button>
                </div>
              </div>
            </div>
          </CommonPopUp>
        </> 
      )}

      {popup.pop_delete && (
        <>
        {/* STEP 1 */}
        <CommonPopUp
          modalTitle="Delete"
          modalContent=""
          openModal={popup.pop_delete}
          onClose={() => onCloseDelete(false)}
          className="modal-medium mx-auto deactivate-popup-container"
        >
          <div className="deactivate-popup">
            <div>
              <FormControl fullWidth size="small" className="mt-2">
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ fontSize: "14px", marginBottom: "10px"}}
                >
                  We’d hate to let you go - help us understand why you want to delete your account.
                </FormLabel>
                {formError.remark.message && (
                  <div style={{ color: "red", marginTop: "8px" }}>
                    {formError.remark.message}
                  </div>
                )}
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  id="gender_id"
                  value={remark}
                  onChange={(e) => {
                    onSelectChange(e.target.value);
                  }}
                  className="deactivate-popup-input-sec"
                >
                  <FormControlLabel className="mb-2"
                    value="I’m concerned about privacy and data security"
                    control={<Radio className="py-0" size="small" />}
                    label="I’m concerned about privacy and data security."
                  />
                  <FormControlLabel className="mb-2"
                    value="I've moved to another platform"
                    control={<Radio className="py-0" size="small" />}
                    label="I've moved to another platform."
                  />
                  <FormControlLabel className="mb-2"
                    value="I no longer want to use the platform"
                    control={<Radio className="py-0" size="small" />}
                    label="I no longer want to use the platform."
                  />
                  <FormControlLabel className="mb-2"
                    value="The platform doesn't meet my expectations"
                    control={<Radio className="py-0" size="small" />}
                    label="The platform doesn't meet my expectations."
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio className="py-0" size="small" />}
                    label="Other"
                    onClick={() => setOtherRemark(true)}
                  />
                  {otherRemark && (
                    <div className="mt-2">
                      <TextField
                        className="w-100"
                        id="remarkDescription"
                        name="remarkDescription"
                        multiline
                        rows={4}
                        value={remarkDescription}
                        error={!formError.remarkDescription.status}
                        helperText={formError.remarkDescription.message}
                        onChange={handleRemarkDescription}
                        onBlur={() => {
                          setFormError({
                            ...formError,
                            remarkDescription:
                              formValidation.GetNameValidate(remarkDescription),
                          });
                        }}
                      />
                      {/* {formError.remarkDescription.message && <div style={{ color: 'red', marginTop: '8px' }}>{formError.remarkDescription.message}</div>} */}
                    </div>
                  )}
                </RadioGroup>
              </FormControl>
              <div className="popup-footer">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-primary btn-mui  ms-2"
                    onClick={()=>handleOpenDeleteFinalStep(true)}
                    variant="outlined"
                    disabled={isContinueDeleteDisabled}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
        {/* STEP 2 */}
        <CommonPopUp
          modalTitle="Delete"
          modalContent=""
          openModal={popup.pop_delete_step2}
          onClose={() => onCloseDelete(false)}
          className="modal-medium mx-auto deactivate-popup-container"
        >
          <div className="deactivate-popup">
           <span className="pb-3 d-block fs-14">{props.UserData.email_primary}</span>
              <span  className="pb-1 d-block fs-14">Please note:</span>
              <ul className="fs-14 list-style-none ps-0" >
                
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">Profile & Data Permanently Removed</strong>
                  <span>
                    Your profile, campaign history, and all associated data will be erased.
                  </span> 
                </li>
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">No Reactivation</strong>
                  <span>Once deleted, the account cannot be restored. You will need to create a new account to use the platform again.
                  </span> 
                </li>
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">Campaign Access Lost</strong>
                  <span>Any active or past collaborations, messages, and earnings will be permanently inaccessible.</span> 
                </li>
                <li className="pb-3 border-bottom mb-3">
                  <strong className="d-block">Pending Payments May Be Affected</strong>
                  <span>If applicable, ensure all payments or invoices are settled before deleting your account.</span> 
                </li>
                <li>
                  <strong className="d-block">Loss of Subscriptions & Benefits</strong>
                  <span>Any active subscriptions or premium benefits will be forfeited without refunds.</span> 
                </li>
              </ul>
            <div className="popup-footer">
              <div className="d-flex justify-content-end">
                <Button
                  className="btn btn-outline-primary btn-mui ms-2"
                  onClick={() => onCloseDelete(false)}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-primary btn-mui  ms-2"
                  onClick={() => {
                    deleteUser(
                      props.UserData.login_mobile ||
                        props.UserData.mobile_whatsapp,
                      props.UserData.account_type
                    );
                  }}
                  variant="outlined"
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </CommonPopUp>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserData: state.UserReducer.UserData,
  };
};
const connectToRedux = connect(mapStateToProps, null)(Security);
export { connectToRedux as Security };
