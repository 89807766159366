import { LoadingButton } from '@mui/lab';
import { Skeleton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CommonPopUp from '../../../../../../components/popup/common-popup';
import HeadingStyleSecondary from '../../../../../../components/heading-style/heading-style-secondary';
import { createPortal } from 'react-dom';
import CustomDrawer from '../../../../../../components/custom-drawer/custom-drawer';
import { ApprovedCreative } from './approved-creative';
import { Send } from '@mui/icons-material';
import ConfirmPopup from '../../../../../../components/confirm-popup/confirm-popup';
import { DefaultUserProfile } from '../../../../../../config/config';
import { Profile } from '../proposals/proposal-components';
type CommentTypes = { comments: string, from: string, created_at: string, status: string, influencer_id: string | number, account_id: string | number };
type Props = {
    creatives?:any,
    loading?:any,
    comments?:string, 
    setComments: any,
    handleFeedbackModal: (creative_id: any, proposal_id: any) => void
    handleAcceptProposal: (id: any) => void
    handleRejectProposal: (id: any) => void
    openAcceptPopup:boolean;
    handleAcceptPopupClose: () => void;
    openRejectPopup: boolean;
    handleRejectPopupClose: () => void;
    openApprovedCreativeModal: boolean;
    handleApprovedCreativeCloseModal: () => void;
    handleAcceptReject: (data: any, action: string) => Promise<void>
    openModal:boolean;
    activeInfluencerDetail:any;
    creativeId:any;
    acceptRejectPopupId:number;
    approvedCreative:any;
    apiStatus:boolean;
    proposalId: number,
    setCreatives:any;
    handleCloseModal: () => void;
    commentsList: any;
    userReducer:any;
    profileData:any;
    handleSendComment: (e: any) => Promise<void>;
    smID:any;
}

const CreativeContainer = ({ creatives, loading, comments, setComments, handleFeedbackModal, handleAcceptProposal, handleRejectProposal, openAcceptPopup, handleAcceptPopupClose, openRejectPopup,
    handleRejectPopupClose,
    openApprovedCreativeModal,
    handleApprovedCreativeCloseModal,
    handleAcceptReject,
    openModal,
    activeInfluencerDetail,
    creativeId,
    acceptRejectPopupId,
    approvedCreative,
    apiStatus,
    proposalId,
    setCreatives,
    handleCloseModal,
    commentsList,
    userReducer,
    handleSendComment,
    profileData,
    smID
    

 }: Props) => {

    const [handleCreative, setHandleCreative] = useState(false)
    const handleCreativeZoom = () => {
        setHandleCreative(true)
        const bodyClass = document.getElementsByTagName('html')[0];
        if (bodyClass) {
            bodyClass.classList.add("bodyInnerModalActive");
        }
    }
    const handleCloseCreativeImageModal = () => {
        setHandleCreative(false)
        const bodyClass = document.getElementsByTagName('html')[0];
        if (bodyClass) {
            bodyClass.classList.remove("bodyInnerModalActive");
        }
    }
  return (
      <div className="brand-creative-container">
          <div className="creatives-container">
            
              {creatives &&
                  creatives
                  .filter((data1: any) => {
                    return data1.proposal_id === proposalId})
                  .map((data: any, key_1: number) => {
                      return (
                          <>
                          <div className="creatives-container-list" key={key_1}>
                              <span
                                  className={[`creative-status ${data.status}`].join(
                                      " "
                                  )}
                              >
                                  {data.status}
                              </span>
                              {loading ?
                                  <Skeleton /> : (
                                      <div className="creative-card" style={{ cursor: 'pointer' }} onClick={() => {
                                        handleFeedbackModal(data?.creative_id, data?.proposal_id)
                                        
                                        }}>

                                          {data.file_type === "image" ? (
                                              <img
                                                  src={data.url}
                                                  className="selected-creative"
                                                  alt="creative"
                                              />
                                          ) : (
                                              <>
                                                  {loading ? <Skeleton /> :
                                                      <>
                                                          <span
                                                              className="icon-video material-symbols-rounded icon-filled">play_circle
                                                          </span>
                                                          <video
                                                              className="selected-creative"
                                                              height={400}
                                                              width={300}
                                                          >
                                                              <source src={data?.url} type="video/mp4"></source>
                                                          </video>
                                                      </>
                                                  }
                                              </>
                                          )}
                                              <div className="creative-card-action">
                                                  {data.status === "Pending" && (
                                                      <>

                                                          <Tooltip placement="left" title="Approve" arrow>
                                                              <span

                                                                  onClick={(e) => {
                                                                      handleAcceptProposal(data?.creative_id);
                                                                      e.stopPropagation()
                                                                  }}
                                                                  className="material-symbols-rounded">check_circle
                                                              </span>
                                                          </Tooltip>
                                                          <Tooltip placement="left" title="Reject" arrow>
                                                              <span className="material-symbols-rounded"
                                                                  onClick={(e) => {
                                                                      handleRejectProposal(data?.creative_id);
                                                                      e.stopPropagation()
                                                                  }}
                                                              >cancel</span>
                                                          </Tooltip>

                                                      </>
                                                  )}
                                                <Tooltip placement="left" title="Message" arrow>
                                                  <span
                                                    onClick={() => handleFeedbackModal(data?.creative_id, data?.proposal_id)}
                                                    className="material-symbols-rounded position-relative">
                                                    chat_bubble
                                                    {data.unread_count > 0 && (
                                                      <span
                                                        className="d-flex align-items-center justify-content-center position-absolute"
                                                        style={{
                                                          top: '-3px',
                                                          right: '-3px',
                                                          width: '10px',
                                                          height: '10px',
                                                          borderRadius: '50%',
                                                          backgroundColor: '#00AAE5',
                                                          fontSize: '10px'
                                                        }}>
                                                      </span>
                                                    )}
                                                  </span>
                                                </Tooltip>
                                             </div>
                                      </div>
                                  )
                              }
                          </div>
                          </>

                      );
                  })}
          </div>

          {false && (
              <div className="row">
                  <div className="col send-comment">
                      <TextField
                          className="w-100 m-2"
                          onChange={(e) => setComments(e.target.value)}
                          id="outlined-basic"
                          label="Type Message"
                          variant="outlined"
                      />
                      <LoadingButton
                          className="btn btn-primary m-2"
                          loading={loading}
                          loadingPosition="start"
                          variant="contained"
                      // onClick={handleSubmit}
                      >
                          <Send />
                      </LoadingButton>
                  </div>
              </div>
          )}
          
         
          {handleCreative && creatives
              ?.filter((creative: any) => {
                return creative?.creative_id === creativeId})
              .map((filtered: any) => (
                  <>
                  <CommonPopUp
                      key={filtered?.creative_id}
                      className="creative-img-popup"
                      modalContent=""
                      openModal={openModal}
                      modalId={`creative-img-${filtered?.creative_id}`}
                      onClose={() => handleCloseCreativeImageModal()}
                  >
                      <div className="container-fluid">
                          <div className="d-flex flex-column pb-4">
                              <HeadingStyleSecondary className='mb-0' title={activeInfluencerDetail?.insta_name ? activeInfluencerDetail?.insta_name : activeInfluencerDetail?.username} />

                              {/* <span className="">Type</span> */}
                          </div>
                          <div className='row creative-detail inner'>
                              <div className='col-md-12'>

                                  <div className="creative-detail-img">{filtered?.file_type === "image" ? (
                                      <img
                                          src={filtered?.url}
                                          className="selected-creative"
                                          alt="creative"
                                      />
                                  ) : (
                                      <video
                                          className="selected-creative"
                                          controls
                                      >
                                          <source src={filtered?.url} type="video/mp4"></source>
                                      </video>
                                  )}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </CommonPopUp>
                  </>
              ))}
          {/* All Approved Creative */}
          <>
              {createPortal(
                  <CustomDrawer
                      open={openApprovedCreativeModal}
                      className="popup-approved custom-drawer-lg"
                      onClose={handleApprovedCreativeCloseModal}
                      drawerTitle=''
                  >
                      <div className='custom-drawer-body'>
                          <ApprovedCreative data={approvedCreative} />
                      </div>
                  </CustomDrawer>
                  , document.body)}
              {/* {createPortal(
                  <>{creatives
                      ?.filter((creative: any) => creative?.creative_id === acceptRejectPopupId)
                      .map((filtered: any) => {
                          return <CustomDrawer
                              open={openAcceptPopup}
                              onClose={handleAcceptPopupClose}
                              drawerTitle=''
                              className="popup-confirm popup-accept"
                          >
                              <div className='custom-drawer-body'>
                                  <div className='custom-drawer-body-inner'>
                                      <div className='confirm-body'>
                                          <div className='d-flex flex-column'>
                                              <span className="material-symbols-fw-200 material-symbols-rounded confirm-icon">
                                                  help
                                              </span>
                                              <strong className='confirm-title'>Confirm</strong>
                                          </div>
                                          <p>Are you sure want to approve the creative?</p>
                                      </div>
                                  </div>
                                  <div
                                      className='d-flex justify-content-end custom-drawer-footer'
                                      style={{ borderTop: '1px solid #d7d7d7' }}
                                  >
                                      <LoadingButton
                                          className='btn btn-primary btn-lg'
                                          // loading={loading}
                                          loadingPosition='start'
                                          variant='contained'
                                          onClick={() =>
                                              handleAcceptReject(filtered, "Approved")
                                          }
                                          style={{ marginLeft: '10px' }}
                                      >
                                          Confirm
                                      </LoadingButton>

                                  </div>
                              </div>
                          </CustomDrawer>
                      })}
                  </>
                  , document.body)} */}

          </>
          {openAcceptPopup && (createPortal(
              <>{creatives?.filter((creative: any) => creative?.creative_id === acceptRejectPopupId)
                  .map((filtered: any) => {
                      return <ConfirmPopup confirmPopupInnerText="Are you sure want to approve the creative" openPopup={openAcceptPopup} confirmClosePopup={handleAcceptPopupClose} confirmClickAction={() => handleAcceptReject(filtered, "Approved")} drawerTitle="" />
                  })}</>, document.body))}
          {openRejectPopup && (createPortal(
              <>{creatives?.filter((creative: any) => creative?.creative_id === acceptRejectPopupId)
                  .map((filtered: any) => {
                      return <ConfirmPopup confirmPopupInnerText="Are you sure want to reject the creative" openPopup={openRejectPopup} confirmClosePopup={handleRejectPopupClose} confirmClickAction={() => handleAcceptReject(filtered, "Rejected")} drawerTitle="" />
                  })}</>, document.body))}
          {/* {

            
              createPortal(
                  <> {creatives
                      ?.filter((creative: any) => creative?.creative_id === acceptRejectPopupId)
                      .map((filtered: any) => {
                          return <CustomDrawer
                              open={openRejectPopup}
                              onClose={handleRejectPopupClose}
                              drawerTitle=''
                              className="popup-confirm popup-reject"
                          >
                              <div className='custom-drawer-body'>
                                  <div className='custom-drawer-body-inner'>
                                      <div className='confirm-body'>
                                          <div className='d-flex flex-column'>
                                              <span className="material-symbols-fw-200 material-symbols-rounded confirm-icon">
                                                  help
                                              </span>
                                              <strong className='confirm-title'>Confirm</strong>
                                          </div>
                                          <p>Are you sure want to reject the creative?</p>
                                      </div>
                                  </div>
                                  <div
                                      className='d-flex justify-content-end custom-drawer-footer'
                                      style={{ borderTop: '1px solid #d7d7d7' }}
                                  >
                                      <LoadingButton
                                          className='btn btn-primary'
                                          // loading={loading}
                                          loadingPosition='start'
                                          variant='contained'
                                          onClick={() =>
                                              handleAcceptReject(filtered, "Rejected")
                                          }
                                          style={{ marginLeft: '10px' }}
                                      >
                                          Confirm
                                      </LoadingButton>
                                  </div>
                              </div>
                          </CustomDrawer>
                      })}</>, document.body)} */}



      </div>
  )
}

export default CreativeContainer