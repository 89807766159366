import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InstagramIcon from "@mui/icons-material/Instagram";
import SendIcon from "@mui/icons-material/Send";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../../config/config";
import { BASE_URL_UI } from "../../../config/config";
import { CLIENT_ID } from "../../../config/config";
import * as gtUtil from "../../../core/utility";
import { useGoogleLogin } from '@react-oauth/google';
import { HandlesService } from '../../../core/services/handles.service';
import { toast } from 'react-toastify';
import { IconButton, Tooltip, useMediaQuery, Skeleton } from '@mui/material';
import SocialConnectRestriction from '../../../../src/components/popup/social-connect-restrict';
import ContentLoading from '../../../components/loader/loading';

import {
  Autocomplete,
  Button,
  Checkbox,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import {
  PublicService,
  SignUpService,
  LocalService,
  UserService,
} from "../../../core/services";
import { FormValidation } from "../../../core/utility";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import HeadingStyle from "../../../components/heading-style/heading-style";
import useBodyClass from "../../../hooks/useBodyClass";
import { Password, Photo, Visibility, VisibilityOff } from "@mui/icons-material";
import InfluencerProfileCard from "./influencer-profile-card";
import { CaptchaService } from "../../../core/services/captcha.service";
import { DefaultAccountLogo, DefaultUserProfile } from "../../../config/config";
import { LoadingButton } from "@mui/lab";
import "./_influencerSignUp.scss";
import InfluencerContent from '../../../components/signup-right-sec/Influencer-content';
import MaterialSymbol from "../../../components/icons/material-symbol";
import Footer from "../../../components/footer/footer";
import { MuiOtpInput } from "mui-one-time-password-input";
import { MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect, MenuPropsSelectSmall } from "../../../core/utility/style";
import { BASE_URL_WEB } from "../../../config/config";

import useConditionalFocus from "../../../hooks/useConditionalFocus";
import YTInfluencerProfileLeft from "../../influencer/profile-view/yt-influencer-profile-left";
import { Helmet } from "react-helmet";
import { useCopyPasteListener } from "../../../hooks/useCopyPasteListener";
const signupService = new SignUpService();
const captchaService = new CaptchaService();


const localService: LocalService = new LocalService();
const publicService: PublicService = new PublicService();
const handlesService = new HandlesService();

interface ErrorTypes {
  status: boolean;
  message: string;
}


interface FormData {
  full_name: string;
  whatsapp_number: string;
  email_id: string;
  gender: string;
  genre: string[];
  location_id: string | null;
  terms: boolean;
  language: string[];
  password: string;
  confirm_password: string;
  ig_influencer_profile_data: any; // Adjust type as necessary
  yt_influencer_profile_data: any; // Adjust type as necessary
  pin: string;
  dd: string;
  mm: string;
  yyyy: string;
  captchaToken: any;
  dob: string;
  mobile_verified: string;
  email_verified: string;
  logo: string;
  photo : string;
  instagram : boolean;
  youtube : boolean;
  influencer_id : any;
}

interface IsActiveField {
  name: boolean;
  email: boolean;
  mobile: boolean;
  password: boolean;
  confirmPassword: boolean;
  gender: boolean;
  location: boolean;
  genre: boolean;
  language: boolean;
  terms: boolean;
  social_media: boolean;
  pin: boolean;
  dd: boolean;
  mm: boolean;
  yyyy: boolean;
}

interface whats_app_boolean_fields {
  disableWhatsappfieldInput: boolean;
  disableWhatsappfield: boolean;
  verifiedfield: boolean;
  isEditButtonDisabled: boolean;
  isResendButtonDisabled: boolean;
  isTimerDisabled: boolean;
  showResendEdit: boolean;
  showotpBox: boolean;
  verifiedWAfield: boolean;
}

interface email_boolean_fields {
  disableEmailfieldInput: boolean;
  disableEmailField: boolean;
  verifiedEmail: boolean;
  isEditButtonEmailDisabled: boolean;
  isResendButtonEmailDisabled: boolean;
  isEmailTimerDisabled: boolean;
  showEmailResendEdit: boolean;
  showEmailotpBox: boolean;
  verifiedEmailfield: boolean;

}

const defaultFormData: FormData = {
  full_name: '',
  whatsapp_number: '',
  email_id: '',
  gender: '',
  genre: [],
  location_id: null,
  terms: true,
  language: [],
  password: '',
  confirm_password: '',
  ig_influencer_profile_data: null,
  yt_influencer_profile_data: null,
  pin: '',
  dd: '',
  mm: '',
  yyyy: '',
  captchaToken: '',
  dob: '',
  mobile_verified: '',
  email_verified: '',
  logo: '',
  photo : '',
  instagram: false,
  youtube: false,
  influencer_id: null
};

const defaultIsActiveField: IsActiveField = {
  name: false,
  email: false,
  mobile: false,
  password: false,
  confirmPassword: false,
  gender: false,
  location: false,
  genre: false,
  language: false,
  terms: false,
  social_media: false,
  pin: false,
  dd: false,
  mm: false,
  yyyy: false,
};

const default_whats_app_boolean_fields: whats_app_boolean_fields = {
  disableWhatsappfieldInput: false,
  disableWhatsappfield: true,
  verifiedfield: false,
  isEditButtonDisabled: false,
  isResendButtonDisabled: true,
  isTimerDisabled: true,
  showResendEdit: false,
  showotpBox: false,
  verifiedWAfield: false
};

const default_email_boolean_fields: email_boolean_fields = {
  disableEmailfieldInput: false,
  disableEmailField: true,
  verifiedEmail: false,
  isEditButtonEmailDisabled: false,
  isResendButtonEmailDisabled: true,
  isEmailTimerDisabled: true,
  showEmailResendEdit: false,
  showEmailotpBox: false,
  verifiedEmailfield: false
};

interface Props {
  loading: (data: any) => void;
}

const Influencer: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [phoneloading, setPhoneLoading] = useState(false);
  const [emailOtpVerifyloading, setEmailOtpVerifyLoading] = useState(false);
  const [phoneOtpVerifyloading, setPhoneOtpVerifyLoading] = useState(false);
  const [emailloading, setEmailLoading] = useState(false);
  const [igVerifyLoading, setIgVerifyLoading] = useState(false);
  const [ytVerifyLoading, setYtVerifyLoading] = useState(false);
  const theme = useTheme();
  const [selectedLocation, setSelectedLocation] = useState(null);
  //set validate
  const [gender, setGender] = useState("");
  const [genre, setGenre] = useState<string[]>([]);
  const [language, setLanguage] = useState<string[]>([]);
  const [location, setLocation] = useState([]);
  const [genricList, setGenricList] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [showotpBox, setOtpBox] = useState(false);
  const [disableWhatsappfield, setDisableWhatsAppField] = useState(true);
  const [showEmailotpBox, setEmailOtpBox] = useState(false)
  const [instagramProfile, setInstagramProfile] = useState("");
  const [youtubeProfile, setYoutubeProfile] = useState("");
  const [whatsappnumber, setWhatsAppNumber] = useState("");
  const [showInstaProfileCard, setShowInstaProfileCard] = useState(false);
  const [verifyIgId, setverifyIgId] = useState(false);
  const [verifyYtId, setverifyYtId] = useState(false);
  const [showYtProfileCard, setShowYtProfileCard] = useState(false);
  const [instaCardData, setInstaCardData] = useState<any>(null);
  const [ytCardData, setYtCardData] = useState<any>(null);
  const [captchaToken, setCaptchaToken] = useState<any>();
  const [disableEmailField, setDisableEmailField] = useState(true);
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [dd, setDD] = useState("");
  const [mm, setMM] = useState("");
  const [yyyy, setYYYY] = useState("");
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [isTimerDisabled, setIsTimerDisabled] = useState(true);
  const [showResendEdit, setShowResendEdit] = useState(false);
  const phoneRef = useRef<any>(null);
  const [isEditButtonEmailDisabled, setIsEditButtonEmailDisabled] = useState(false);
  const [isResendButtonEmailDisabled, setIsResendButtonEmailDisabled] = useState(true);
  const [Emailtimer, setEmailTimer] = useState(60);
  const [isEmailTimerDisabled, setIsEmailTimerDisabled] = useState(true);
  const [showEmailResendEdit, setShowEmailResendEdit] = useState(false);
  const emailRef = useRef<any>(null);
  const [passwordChecking, setPasswordChecking] = useState('');
  const [emailOtpError, setEmailOtpError] = useState<boolean>(false);
  const [emailOtpErrorMsg, setEmailOtpErrorMsg] = useState<string>('Wrong One Time Password (OTP)');
  const [phoneOtpError, setPhoneOtpError] = useState<boolean>(false);
  const [phoneOtpErrorMsg, setPhoneOtpErrorMsg] = useState<string>('Wrong One Time Password (OTP)');
  const [username, setUserName] = React.useState('');
    const [faceBookButton, setFaceBookButton] = React.useState(true);
    const [verifyUserName, setVerifyUserName] = React.useState(true);
    const [username_verify, set_username_verify] = useState<String | null>(null);
  const [loadingIg, setLoadingIg] = React.useState(false);
    const [socialRestrictionPopup, set_socialRestrictionPopup] = useState({
        youtube: false,
        instagram: false
    });
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const [form_data, set_form_data] = useState<FormData>(() => {
    const savedData = localStorage.getItem('form_data');
    return savedData ? JSON.parse(savedData) : defaultFormData;
  });

  const [whats_app_boolean_fields, set_whats_app_boolean_fields] = useState<whats_app_boolean_fields>(() => {
    const savedData = localStorage.getItem('whats_app_boolean_fields');
    return savedData ? JSON.parse(savedData) : default_whats_app_boolean_fields;
  });

  const [email_boolean_fields, set_email_boolean_fields] = useState<email_boolean_fields>(() => {
    const savedData = localStorage.getItem('email_boolean_fields');
    return savedData ? JSON.parse(savedData) : default_email_boolean_fields;
  });
  
  //validation
  const [errorWhatsApp, setErrorWhatsApp] = useState<ErrorTypes>();
  const [errorFullName, setErrorFullName] = useState<ErrorTypes>();
  const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
  const [errorGender, setErrorGender] = useState<ErrorTypes>();
  const [errorLocation, setErrorLocation] = useState<ErrorTypes>();
  const [errorGenre, setErrorGenre] = useState<ErrorTypes>();
  const [errorTerms, setErrorTerms] = useState<ErrorTypes>();
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [errorIgRegistered, setErrorIgRegistered] = useState<ErrorTypes>();
  const [errorYtRegistered, setErrorYtRegistered] = useState<ErrorTypes>();
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<ErrorTypes>();

  // For Verification----------------------------------------------
  const [verifiedEmailfield, setVerifiedEmailfield] = useState(false);
  const [verifiedWAfield, setVerifiedWAfield] = useState(false);
  //Login with Pin
  const [otpWhatsAppValue, setOtpWhatsAppValue] = React.useState<string>('')
  const [otpEmailValue, setOtpEmailValue] = React.useState<string>('')
  const [otpPINValue, setOtpPINValue] = React.useState<string>('')
  const [influencerId,setInfluencerId] =useState(null)
  const [social_loader, set_social_loader] = useState(
    {
      ig_social_loading: false,
      yt_social_loading: false
    }
  );

    // Disable Input Field
  const [disableWhatsappfieldInput, setDisableWhatsAppFieldInput] = useState(false);
  const [disableEmailfieldInput, setDisableEmailfieldInput] = useState(false);

  const [isActiveField, setIsActiveField] = useState<IsActiveField>(() => {
    const savedData = localStorage.getItem('isActiveField');
    return savedData ? JSON.parse(savedData) : defaultIsActiveField;
  });

  const genderRef = useRef<HTMLInputElement>(null);
  // const dobRef = useRef<HTMLInputElement>(null);
  const ddRef = useRef<HTMLInputElement>(null);
  const mmRef = useRef<HTMLInputElement>(null);
  const yyyyRef = useRef<HTMLInputElement>(null);
  const genreRef = useRef<HTMLInputElement>(null);
  const languageRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const socialRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const pinRef = useRef<HTMLInputElement>(null);

  useConditionalFocus(phoneRef, isActiveField.name);
  useConditionalFocus(emailRef, isActiveField.mobile);
  useConditionalFocus(genderRef, isActiveField.email);
  useConditionalFocus(ddRef, isActiveField.gender);
  useConditionalFocus(mmRef, isActiveField.dd);
  useConditionalFocus(yyyyRef, isActiveField.mm);
  useConditionalFocus(genreRef, isActiveField.yyyy);
  useConditionalFocus(languageRef, isActiveField.genre);
  useConditionalFocus(locationRef, isActiveField.language);
  useConditionalFocus(socialRef, isActiveField.location);
  useConditionalFocus(passwordRef, isActiveField.social_media);
  useConditionalFocus(confirmPasswordRef, isActiveField.password);
  useConditionalFocus(pinRef, isActiveField.confirmPassword);

  const location_val = useLocation();
  let influencer_id: any = null; // Default value if not found
  if (location_val && location_val.state && location_val.state.influencer_id) {
    influencer_id = location_val.state.influencer_id;
  }

  const [errorDate, setErrorDate] = useState({
    dd: { status: true, message: "" },
    mm: { status: true, message: "" },
    yyyy: { status: true, message: "" },
  });
  const [errorSocialMediaHandle, setErrorSocialMediaHandle] = useState<ErrorTypes>();
  const [errorPin, setErrorPin] = useState<ErrorTypes>();
  const [errorLanguage, setErrorLanguage] = useState<ErrorTypes>();

  //redux
  const navigate = useNavigate();

  useEffect(() => {
    props.loading(true);

    //get Geo (Location)
    async function getLocations() {
      try {
        props.loading(true);
        const res = await publicService.getLocation({format: 'city,state,country'});
        if (res.data.status) {
          // if (res?.data?.rows[0].state === 'India' && res?.data?.rows[0].city === 'India' ){
          //   res?.data?.rows.shift();
          // }
          // const filteredAndSortedRows = res?.data?.rows
          //   .filter((row: any) => row.state === "Puducherry" || row.city !== row.state) // Filter logic
          //   .sort((a: any, b: any) => a.state.localeCompare(b.state));
          setLocation(res?.data?.rows);
        } else {
          setLocation([]);
        }
      } catch (error) {
        setLocation([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {
        props.loading(false);
      }
    }
    getLocations();
    PublicService.getGenre()
      .then((res) => {
        setGenricList(res?.data?.rows);
        props.loading(false);
      })
      .catch((error) => {
        props.loading(false);
        throw new Error(`getGenre error :: ${JSON.stringify(error)}`);
      });
    async function getLanguage() {
      try {
        props.loading(true);
        const res = await publicService.getLanguage();
        if (res.data.status) {
          let languageList = res?.data?.rows;

          // Rearrange the language list to show English and Hindi first
          const english = languageList.find((lang:any) => lang.language_name === "English");
          const hindi = languageList.find((lang:any) => lang.language_name === "Hindi");

          // Remove English and Hindi from the original list
          languageList = languageList.filter((lang:any) => lang.language_name !== "English" && lang.language_name !== "Hindi");
          
          languageList.sort((a:any, b:any) => a.language_name.localeCompare(b.language_name));
          
          // Add English and Hindi to the beginning of the list
          if (hindi) languageList.unshift(hindi);
          if (english) languageList.unshift(english);

          setLanguageList(languageList);
          props.loading(false);
        } else {
          setLanguageList([]);
        }
      } catch (error) {
        props.loading(false);
        throw new Error(`getLanguage error :: ${JSON.stringify(error)}`);
      }
    }

    getLanguage();
   
    
  }, []);

  useEffect(() => {
    // Clear localStorage after setting state
    localStorage.removeItem('form_data');
    localStorage.removeItem('isActiveField');
    localStorage.removeItem('whats_app_boolean_fields');
    localStorage.removeItem('email_boolean_fields');
  }, [form_data, isActiveField]);

  useEffect(() => {
    let countdown: any;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setTimer(0);
      setIsResendButtonDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    let countdown: any;
    if (Emailtimer > 0) {
      countdown = setInterval(() => {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setEmailTimer(0);
      setIsResendButtonEmailDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [Emailtimer]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) {
      getInstagramAccessToken(code);
    }
  }, []);
  
  const getInstagramAccessToken = async (code: string) => {
    try {
      handlesService
      .instagramSignupConnect({code: code, redirect_uri: `/signup/influencer`})
      .then((res: any) => {
        if(res.status == 1){
            setInstaCardData(res.data);
            set_form_data({...form_data,ig_influencer_profile_data:res.data})
            setShowInstaProfileCard(true);
            setverifyIgId(true);
            setIsActiveField({
              ...isActiveField,
              social_media: true
            })
          }     
      }
                  )
                  .catch((err: any) => console.log(err));
    } catch (err) {
      console.error("Failed to fetch Instagram access token", err);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    set_form_data((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLocationChange = (event:any, value:any) => {
    // setSelectedLocation(value);
    set_form_data({...form_data,location_id: value})
    // You can perform any action here based on the selected location
    if(influencer_id){
      setIsActiveField((prevIsActiveField) => ({
        ...prevIsActiveField,
        social_media:false,
      }));   
    }
  };

  const handleEmail = async (e: any) => {
    const value = e?.target?.value ?? e;
    // setEmail(e.target.value)
    set_form_data({...form_data,email_id:e.target.value})
    let isValid: { status: boolean; message: string } =
      formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    if (isValid.status) {
      const response = await publicService.checkEmailIsRegister({
        email: value,
        user_type: "influencer",
      });
      if (response.data.status.message.length === 0) {
        // setDisableEmailField(false);
        set_email_boolean_fields({...email_boolean_fields,disableEmailField:false})
      }
      else {
        setVerifiedEmailfield(false);
        // setDisableEmailField(true)
        set_email_boolean_fields({...email_boolean_fields,disableEmailField:true})
      }

      if (response) {
        isValid = response.data.status;
        setErrorEmail(isValid);
      }
    }else{
      // setDisableEmailField(true);
      set_email_boolean_fields({...email_boolean_fields,disableEmailField:true})
    }

    return isValid;
  };
  const handleEmailOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setEmailOtpVerifyLoading(true);
      PublicService.emailVerifyOtp({ email: form_data.email_id, otp: otp })
        .then((res) => {
          if (res.status === gtUtil.Constants.SUCCESS) {
            set_email_boolean_fields((prev) => ({
              ...prev,
             showEmailotpBox:false,
              disableEmailField:true,
              verifiedEmailfield:true,
              verifiedEmail:true,
              showEmailResendEdit:false,

            }));
            setEmailOtpError(false);
            setIsActiveField({
              ...isActiveField,
              email: true,
            })
            localService.toastify(res.info);
          } else {
            setEmailOtpError(true);
            setEmailOtpErrorMsg(res.info);
            // localService.toastify(res.info);
          }
        })
        .catch((error) => {
          setEmailOtpVerifyLoading(false);
          throw new Error('VerifyOtp Error ::: ' + error);
        });
    }}catch(error){
      console.log('handleEmailOtp Error',error);
    }finally{
      setEmailOtpVerifyLoading(false);
    }
  };

  const handleEmailSendOTP = async () => {
    try {
      if (!form_data.email_id) {
        setErrorEmail({ status: true, message: "Email is required." });
        return;
      }
      // Regular expression for email validation
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(form_data.email_id)) {
        setErrorEmail({
          status: true,
          message: "Invalid email format.",
        });
        return;
      }
      if (!errorEmail?.status) {
        setErrorEmail(formValidation.GetEmailControlIsValid(form_data.email_id));
        return;
      }

      setEmailLoading(true)
      // Send email OTP
      const response = await PublicService.emailSendOtp({ email: form_data.email_id });
      // setDisableEmailfieldInput(true)
      set_email_boolean_fields({...email_boolean_fields,disableEmailfieldInput:true})
      setEmailLoading(false);
      if (response.status === gtUtil.Constants.SUCCESS) {
        set_email_boolean_fields((prev) => ({
          ...prev,
          showEmailResendEdit: true,
          isResendButtonEmailDisabled:true,
          isEditButtonEmailDisabled: true,
          isEmailTimerDisabled: true,
          showEmailotpBox: true,
          disableEmailField: true
        }));
        setEmailTimer(30);
        localService.toastify(response?.data?.rows?.message);
        
      } else {
        setErrorEmail(response?.data?.rows?.message);
        localService.toastify(response?.data?.rows?.message);
        set_email_boolean_fields({...email_boolean_fields,disableEmailfieldInput:false})
      }

    } catch (error) {
      console.warn(`Email OTP Response Error :: ${error}`);
      set_email_boolean_fields({...email_boolean_fields,disableEmailfieldInput:false})

    }
  };

  const handlePin = (pin: any) => {
    setPin(pin);
    setIsActiveField({
      ...isActiveField,
      pin: true,
    })
  };
  useEffect(() => {
    returnInfluencerId()
  }, []);

  const returnInfluencerId = async () => {
    if (influencer_id) {
      try {
        const res = await signupService.createInfluencerAccountWAFlow({
          data: { influencer_id },
        });
  
        if (res && res.data && res.data.influencer_data) {
          const { influencer_data } = res.data;
          const { primary_email, primary_phone, name } = influencer_data;
  
          // Handle primary email
          if (primary_email && primary_email.length > 0) {
            set_email_boolean_fields((prev) => ({
              ...prev,
              disableEmailField: false,
              disableEmailfieldInput: true,
            }));
            set_form_data((prevData) => ({ ...prevData, email_id: primary_email }));
          }
  
          // Handle primary phone
          if (primary_phone) {
            set_whats_app_boolean_fields((prev) => ({
              ...prev,
              disableWhatsappfield: false,
              disableWhatsappfieldInput: true,
              verifiedWAfield: true,
            }));
            set_form_data((prevData) => ({ ...prevData, whatsapp_number: primary_phone }));
          }
  
          // Set influencer's name
          if (name && name.length > 0) {
            set_form_data((prevData) => ({ ...prevData, full_name: name }));
          }
          // Optionally, handle Instagram and YouTube profiles if needed
          // if (res.data?.ig_username) {
          //   setInstagramProfile(res.data.ig_username);
          //   setShowInstaProfileCard(true);
          //   setverifyIgId(true);
          // }
  
          // if (res.data?.yt_channel_id) {
          //   setYoutubeProfile(res.data.yt_channel_id);
          //   setShowYtProfileCard(true);
          //   setverifyYtId(true);
          // }
        }
  
        setInfluencerId(influencer_id); // Set influencer ID once data is successfully retrieved
        setverifyIgId(true);
        setverifyYtId(true);
  
      } catch (error) {
        console.error("Error fetching influencer data:", error);
        // Optionally, handle the error (e.g., show a message to the user)
      }
    }
  };
  

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const form = new FormData(e.target);
    // const data = Object.fromEntries(form);
    const storeData: any = { ...form_data };
    let isValidConfirmPassword;
    let {
      full_name,
      whatsapp_number,
      email_id,
      gender,
      genre,
      location_id,
      terms,
      language,
      password,
      confirm_password
    } = storeData;
    storeData["influencer_id"] = influencer_id;
    storeData["instagram"] = verifyIgId;
    storeData["youtube"] = verifyYtId;
    storeData["ig_influencer_profile_data"] = instaCardData;
    storeData["yt_influencer_profile_data"] = ytCardData;

    try {
      setErrorWhatsApp(
        formValidation.GetMobileControlIsValid(`${whatsapp_number}`)
      );
      setErrorEmail(formValidation.GetEmailControlIsValid(`${email_id}`));
      setErrorFullName(formValidation.GetNameValidate(`${full_name}`));
      setErrorGender(formValidation.GetNameValidate(`${gender}`));
      setErrorLocation(formValidation.getSplitValidation(`${location_id}`));
      setErrorPassword(formValidation.GetPasswordValidate(password));
      setErrorGenre(formValidation.getSplitValidation(`${genre}`));
      setErrorPin(formValidation.GetPinIsValid(`${form_data.pin}`));
      setErrorLanguage(formValidation.getSplitValidation(`${language}`));
      const isValidPin = formValidation.GetPinIsValid(`${form_data.pin}`).status
      const isValidEmail = formValidation.GetEmailControlIsValid(email_id).status
      const isValidName = formValidation.GetNameValidate(`${full_name}`).status;
      const isValidGender = formValidation.GetNameValidate(`${gender}`).status;
      const isValidPassword = formValidation.GetPasswordValidate(password).status
      if(confirm_password == ''){
        setErrorConfirmPassword({
          status:false,
          message:'This field is required'
        })
        isValidConfirmPassword = false;
      }else{
        if(password == confirm_password){
          isValidConfirmPassword = true;
          setErrorConfirmPassword({
            status:true,
            message:''
          })
        }else{
          setErrorConfirmPassword({
            status:false,
            message:'Password does not match'
          })
          isValidConfirmPassword = false;
        }
      }
      const isValidLocation = formValidation.getSplitValidation(`${location_id}`).status;
      const isValidGenre = formValidation.getSplitValidation(`${genre}`).status;
      const isValidLanguage = formValidation.getSplitValidation(`${language}`).status;

      // setErrorTerms()
      if (location_id) {
        const getLocation_id: any = location.find(
          (res: any) =>
            `${res.city}, ${res.state}, ${res.country}` === location_id
        );
        storeData.location_id = getLocation_id.location_id;
      }

      if (verifyIgId || verifyYtId) {
      } else {
        setErrorSocialMediaHandle({
          message: "Atleast One Social Media Handle is Required.",
          status: true,
        });
      }

      if (terms) {
      } else {
        setErrorTerms({ message: "Please Accept", status: true });
      }

      if (!whats_app_boolean_fields.verifiedWAfield && whatsappnumber) {
        localService.toastify("WhatsApp No. Not Verified.", "error", 1000);
      }
      if (!verifiedEmailfield && email) {
        localService.toastify("Email Id. Not Verified.", "error", 1000);
      }

      const verifyCaptcha = async () => {
        try {
          const res = await captchaService.verifyCaptcha({ token: form_data.captchaToken });
          return res?.data?.rows?.message === "Captcha verified successfully!";
        } catch (error) {
          throw new Error(`verifyCaptcha Error :: ${JSON.stringify(error)}`);
        }
      };


      if (!verifyIgId && !verifyYtId) {
        localService.toastify('please Verify handlename ', 'info', 1000);
      }
      if (
        isValidName &&
        whatsapp_number &&
        isValidEmail &&
        isValidGender &&
        isValidGenre &&
        isValidLocation &&
        isValidLanguage &&
        (verifyIgId || verifyYtId) &&
        terms &&
        whats_app_boolean_fields.verifiedWAfield &&
        email_boolean_fields.verifiedEmailfield &&
        isValidPin &&
        isValidPassword &&
        isValidConfirmPassword
      ) {
        storeData["dob"] = `${storeData.yyyy}-${storeData.mm}-${storeData.dd}`
        storeData["pin"] = pin
        storeData["mobile_verified"] = "Yes";
        storeData["email_verified"] = "No";
        storeData["logo"] = DefaultAccountLogo.influencer;
        storeData["photo"] =
          (gender + "").toLocaleLowerCase() === "male"
            ? DefaultUserProfile.influencer_male_default
            : (gender + "").toLocaleLowerCase() === "female"
              ? DefaultUserProfile.influencer_female_default
              : DefaultUserProfile.influencer_unknown_default;
        delete storeData.yyyy;
        delete storeData.mm;
        delete storeData.dd;

        //call verifycaptcha
        props.loading(true);
        if (await verifyCaptcha()) {
          try {
            const res = await signupService.createInfluencerAccount(storeData);
            if (res.status === gtUtil.Constants.SUCCESS) {
              // let date = new Date();
              localService.setStorageItem("login", `${email_id}`)
              localService.setStorageItem("password", `${password}`)
              navigate(`/welcome/influencer?id=${res.data?.rows[0]?.user_id}`);
            }
            props.loading(false);

          }
          catch (error) {
            props.loading(false);
            console.warn("Captcha Verification (Influencer) Error in ", error)
          }
        }
        else {
          props.loading(false);

        }
      }
    } catch (error) {
      props.loading(false);
      throw new Error(
        `Influencer Create Account function error :: ${JSON.stringify(error)}`
      );
    }
  };
  const [verifiedfield, setVerifiedfield] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(false)
  const handleWhatsAppOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setPhoneOtpVerifyLoading(true);
      PublicService.verifyOtp({ mobile: +form_data.whatsapp_number, otp: otp })
        .then((res) => {
          if (res.status === gtUtil.Constants.SUCCESS) {
            set_whats_app_boolean_fields((prev) => ({
              ...prev,
              showResendEdit: false,
              showotpBox: false,
              disableWhatsappfield: true,
              verifiedfield:true,
              verifiedWAfield:true
            }));
            localService.toastify(res?.data?.rows?.message, "success", 1000);
            setPhoneOtpError(false);
            setIsActiveField({
              ...isActiveField,
              mobile: true,
            })
            if(influencer_id && email.length){

              // setDisableEmailfieldInput(true);
              set_whats_app_boolean_fields({...whats_app_boolean_fields,disableWhatsappfieldInput:true})
              setDisableEmailField(false);
            }
          } else {
            setPhoneOtpError(true);
            setPhoneOtpErrorMsg(res.info);
            // localService.toastify(res.info, "error", 1000);
          }
        })
        .catch((error) => {
          setPhoneOtpVerifyLoading(false);
          throw new Error("VerifyOtp Error ::: " + error);
        });
    }}catch(error){
      console.log('handleWhatsAppOtp error',error);
    }finally{
      setPhoneOtpVerifyLoading(false);
    }
  };

  const handleSendOtpButton = () => {
    let isValid: any = formValidation.GetMobileControlIsValid(form_data.whatsapp_number);
    set_whats_app_boolean_fields({...whats_app_boolean_fields,disableWhatsappfieldInput:true})

    if (isValid.status) {
      setErrorWhatsApp(isValid);
      PublicService.sendOtp({ mobile: +form_data.whatsapp_number })
        .then((res: any) => {
          if (res.status === gtUtil.Constants.SUCCESS) {
            set_whats_app_boolean_fields((prev) => ({
              ...prev,
              showResendEdit: true,
              isResendButtonDisabled: true,
              isTimerDisabled: true,
              showotpBox: res.status === gtUtil.Constants.SUCCESS,
              disableWhatsappfield: res.status === gtUtil.Constants.SUCCESS,
            }));
            setTimer(30);
            localService.toastify(res.info, "success", 1000);
          } else {
            localService.toastify(res.info, "error", 1000);
            set_whats_app_boolean_fields((prev) => ({
              ...prev,
              disableWhatsappfieldInput:false,
              verifiedWAfield:false
            }));
          }
          setPhoneLoading(false);
        })
        .catch((error: any) => {
          setPhoneLoading(false);
          set_whats_app_boolean_fields((prev) => ({
            ...prev,
            disableWhatsappfieldInput:false,
            verifiedWAfield:false
          }));
          console.warn(`Otp Response Error :: ${error}`);
        });

    } else {
      setErrorWhatsApp(isValid);
    }
  };

  const handleWhatsApp = (e: any) => {
    try {
      if (e.target.value.length < 10) {
        // setWhatsAppNumber((e.target.value));
        set_form_data({...form_data,whatsapp_number : e.target.value})
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value));
        // setDisableWhatsAppField(true)
        set_whats_app_boolean_fields({...whats_app_boolean_fields,disableWhatsappfield:true})
      }
      else if (e.target.value.length == 10 && formValidation.GetMobileControlIsValid(e.target.value).status == true) {
        // setWhatsAppNumber((e.target.value));
        set_form_data({...form_data,whatsapp_number : e.target.value})
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value));
        // setDisableWhatsAppField(false)
        set_whats_app_boolean_fields({...whats_app_boolean_fields,disableWhatsappfield:false})
      }
      else if(e.target.value.length > 10) {
        // setWhatsAppNumber(e.target.value.slice(0, 10));
        set_form_data({...form_data,whatsapp_number : e.target.value.slice(0, 10)})
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value.slice(0, 10)));

      }
    } catch (error) {
      throw new Error(`handleWhatsApp error :: ${error}`);
    }
  };


  // Initialize the keydown listener for Ctrl+C and Ctrl+V detection
  useCopyPasteListener();

  const handleFullName = (e: any) => {
    setErrorFullName(formValidation.GetNameValidate(e.target.value));
    if(!isActiveField.name){
      setIsActiveField({
        ...isActiveField,
        name:formValidation.GetNameValidate(e.target.value).status
      }) 
    }
  };

  const handlePassword = (e: any) => {
    const {
      target: { value },
    } = e;
    setPasswordChecking(value);
    set_form_data({...form_data,password:value})
    setErrorPassword(formValidation.GetPasswordValidate(value));
    if(!isActiveField.password){
      setIsActiveField({
        ...isActiveField,
        password: formValidation.GetPasswordValidate(value).status
      })
    }
  };

  const handleConfirmPassword = (e: any) => {
    const {
      target: { value },
    } = e;
    if(value !== form_data.password) {
      setErrorConfirmPassword({status: false, message: 'Password does not match'});
    }else{
      set_form_data({...form_data,confirm_password:value})
      setErrorConfirmPassword({status: true, message: ''});
      setIsActiveField({
        ...isActiveField,
        confirmPassword: true
      })
    }
  };


  //Element Heights

  const handleGenre = (event: any) => {
    const {
      target: { value },
    } = event;
    if (form_data.genre.length !== 3) {
      set_form_data({...form_data,genre:value})
    } else {
      if (value.length === 2) {
        set_form_data({...form_data,genre:value})
      }
    }
  };

  const handleGenreOnBlur = (event: any) => {
    if(form_data.genre.length > 0){
      setIsActiveField({
        ...isActiveField,
        genre: true
      }) 
    }
  };

  const handleLanguage = (event: any) => {
    const {
      target: { value },
    } = event;
    if (form_data.language.length !== 4) {
      set_form_data({...form_data,language: value})
    } else {
      if (value.length === 3) {
        set_form_data({...form_data,language: value})
      }
    }

    setIsActiveField({
      ...isActiveField,
      language: true
    })
  };

  const handleLanguageOnBlur = (event: any) => {
    if(form_data.language.length > 0){
      setIsActiveField({
        ...isActiveField,
        language: true
      })
    }  
  };


  // Extra component
  function getStyles(name: string, personName: string[], theme: Theme) {
    try {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    } catch (error) { }
  }
  useBodyClass("common-signup-page");

  const handleInstagramProfile = () => {
    localStorage.setItem('form_data', JSON.stringify(form_data));
    localStorage.setItem('isActiveField', JSON.stringify(isActiveField));
    localStorage.setItem('whats_app_boolean_fields', JSON.stringify(whats_app_boolean_fields));
    localStorage.setItem('email_boolean_fields', JSON.stringify(email_boolean_fields));
    const clientId = `${CLIENT_ID}`;
    const redirectUri = `${BASE_URL_UI}/signup/influencer`;
    // const scope = "business_basic,business_manage_messages,business_manage_comments,business_content_publish";
    const scope = "instagram_business_basic, instagram_business_content_publish, instagram_business_manage_messages, instagram_business_manage_comments";
    // const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;

    // const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;
    const instagramAuthUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;
  
    window.location.href = instagramAuthUrl;
  };
  

  const handleChangePhone = () => {
    set_whats_app_boolean_fields((prev) => ({
      ...prev,
      disableWhatsappfieldInput:false,
      showotpBox:false,
      showResendEdit:false,
      verifiedWAfield:false
    }));
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }

  const handleChangeEmail = () => {
    set_email_boolean_fields((prev) => ({
      ...prev,
      verifiedEmailfield: false,
      showEmailotpBox:false,
      showEmailResendEdit:false,
      disableEmailfieldInput:false
    }))
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }

      
    const handlePINChange = (newValue: string) => {
      const filteredValue = newValue.replace(/\D/g, '');
      // setOtpPINValue(filteredValue);
      set_form_data({...form_data,pin:filteredValue})
      setPin(filteredValue);
    }
    const handleWhatsAppChange = (newValue: string) => {
      const filteredValue = newValue.replace(/\D/g, ''); // Replace any non-digit character
      setOtpWhatsAppValue(filteredValue);
    }
    const handleEmailChange = (newValue: string) => {
      const filteredValue = newValue.replace(/\D/g, '');
      setOtpEmailValue(filteredValue)
    }
    // Function to check if a value is numeric
    const matchIsNumeric = (text: any) => {
        return !isNaN(Number(text));
    };

    // Validation function for MuiOtpInput
    const validateChar = (value: string) => {
        // return matchIsNumeric(value);
        return /^\d*$/.test(value);
    };

    const signupLoginGoogle = useGoogleLogin({
      flow: 'auth-code',
      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly',
      onSuccess: async (tokenResponse: any) => {
          const data: any = {
              code: tokenResponse.code,
          };
          try{
            handlesService  
                .signupGoogleAuth(data)
                .then((res: any) => {
                  setYtCardData(res.data.status);
                  set_form_data({...form_data,yt_influencer_profile_data:res.data.status})
                  setShowYtProfileCard(true);
                  setverifyYtId(true);
                  setIsActiveField({
                    ...isActiveField,
                    social_media: true
                  })
                            })
                            .catch((err: any) => console.log(err));
          }catch(err){
                console.log(err);
          }finally{}
      },
      onError: (errorResponse:any) => console.log(errorResponse),
    });

    async function faceBookAction(e?: any) {

      try {
          setLoadingIg(true);
          const getPublicData = await publicService.instagramVerify(username);
          if (getPublicData?.status === 1) {
              set_username_verify(username);
              setFaceBookButton(false);
              setLoadingIg(false);
              toast.success('Username Verified');
          } else {
              setLoadingIg(false);
              toast.success('Username not verified, This is not a Business/Creator account');
          }

      } catch (err) {
          console.log('Error faceBookAction ', err);
          setLoadingIg(false);
      }finally{
          setLoadingIg(false);
      }
  }

  const handleStateUpdate = (value: any) => {
      setUserName(value);
      setVerifyUserName(!value);
  };

  const handlePaste = (e: any) => {
      const pastedText = e.clipboardData.getData('Text');
      handleStateUpdate(pastedText); // Or handle the pasted text as needed
  };
  const handleInput = (e: any) => {
      const inputValue = e.target.value;
      handleStateUpdate(inputValue); // Update the state or handle input
      setUserName(inputValue); // Set the username
      inputValue ? setVerifyUserName(false) : setVerifyUserName(true); // Enable/disable button
  };

const instagramConnect = async () => {
  try {
      const response = await handlesService.instagramConnect();

      if (response.status === gtUtil.Constants.SUCCESS) {
          // Open Instagram OAuth in a popup window
          const popup = window.open(
              response.data.url, // Ensure this is correct
              "InstagramOAuthPopup",
              "width=500,height=600,left=100,top=100"
          );

          if (!popup) {
            console.error("Popup blocked by the browser.");
            return;
        }

        // Message listener for receiving auth code
        const messageListener = async (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;

            if (event.data?.type === 'social-auth-success') {
                const authCode = event.data.code;

                try {

                      set_social_loader(prevState => ({
                        ...prevState,
                        ig_social_loading: true 
                      }));
                      // Send auth code to backend
                      const response = await handlesService.postInstagramConnect({
                          code: authCode,
                      });

                      if (response.status === gtUtil.Constants.SUCCESS) {
                        setInstaCardData(response.data.profile_data);
                        setShowInstaProfileCard(true);
                        setIsActiveField({
                          ...isActiveField,
                          social_media: true
                        })
                        setverifyIgId(true);
                        set_social_loader(prevState => ({
                          ...prevState,
                          ig_social_loading: false 
                        }));
                      } else {
                          if(response.info == 'Social account already connected to the other account.'){
                            setInstaCardData(response.data.profile_data);
                              set_socialRestrictionPopup(prevState => ({
                                  ...prevState,
                                  instagram: true 
                              }));
                          }
                      }

                    } catch (error) {
                      console.error("Error processing Instagram auth response:", error);
                      toast.error("Instagram authentication failed.");
                  } finally {
                      set_social_loader(prevState => ({
                        ...prevState,
                        ig_social_loading: false 
                      }));
                      // Remove event listener after handling response
                      window.removeEventListener("message", messageListener);
                  }
              }
          };
          // Attach event listener
          window.addEventListener("message", messageListener);
      } else {
          console.error("Error fetching Instagram OAuth URL:", response.info);
          toast.error("Failed to get Instagram authentication URL.");
      }
  } catch (error) {
      console.error("Instagram Login Error:", error);
      toast.error("Instagram login failed");
  }finally{
    set_social_loader(prevState => ({
      ...prevState,
      ig_social_loading: false 
    }));
  }
};
const fb_instagramConnect = async () => {
  try {
      if (!username_verify) {
          console.error("Username is required for Facebook authentication.");
          toast.error("Username is required.");
          return;
      }

      const response = await handlesService.fb_instagramConnect();

      if (response.status === gtUtil.Constants.SUCCESS) {
          // Open Facebook OAuth in a popup window
          const popup = window.open(
              response.data.url, 
              "FacebookOAuthPopup",
              "width=500,height=600,left=100,top=100"
          );

          if (!popup) {
              console.error("Popup blocked by the browser.");
              return;
          }

          // Message listener for receiving auth code
          const messageListener = async (event: MessageEvent) => {
              if (event.origin !== window.location.origin) return;

              if (event.data?.type === 'social-auth-success') {
                  const authCode = event.data.code;

                  try {

                    set_social_loader(prevState => ({
                      ...prevState,
                      ig_social_loading: true 
                    }));
                      // Send auth code to backend
                      const tokenResponse = await handlesService.postFacebookConnect({
                          code: authCode,
                          username: username_verify
                      });

                      if (tokenResponse.status === gtUtil.Constants.SUCCESS) {
                          setInstaCardData(tokenResponse.data.profile_data);
                          setShowInstaProfileCard(true);
                          setIsActiveField((prev) => ({
                              ...prev,
                              social_media: true
                          }));
                          setverifyIgId(true);
                          set_social_loader(prevState => ({
                            ...prevState,
                            ig_social_loading: false 
                        }));
                      } else {
                          if (tokenResponse.info === 'Social account already connected to the other account.') {
                              setInstaCardData(tokenResponse.data.profile_data);
                              set_socialRestrictionPopup((prevState) => ({
                                  ...prevState,
                                  instagram: true
                              }));
                          }
                      }
                  } catch (error) {
                      console.error("Error processing Facebook auth response:", error);
                      toast.error("Facebook authentication failed.");
                  } finally {
                      // Remove event listener after handling response
                      set_social_loader(prevState => ({
                        ...prevState,
                        ig_social_loading: false 
                    }));
                      window.removeEventListener("message", messageListener);
                  }
              }
          };

          // Attach event listener
          window.addEventListener("message", messageListener);
      } else {
          console.error("Error fetching Facebook OAuth URL:", response.info);
          toast.error("Failed to get Facebook authentication URL.");
      }
  } catch (error) {
      console.error("Facebook Login Error:", error);
      toast.error("Facebook Connect failed.");
  }finally{
    set_social_loader(prevState => ({
      ...prevState,
      ig_social_loading: false 
  }));
  }
};

const loginGoogleyt = async () => {
  try {
      const response = await handlesService.googleConnected();

      if (response.status === gtUtil.Constants.SUCCESS) {
          const popup = window.open(
              response.data.url,
              "GoogleOAuthPopup",
              "width=500,height=600,left=100,top=100"
          );

          if (!popup) {
              console.error("Popup blocked by the browser.");
              return;
          }

          // Listen for messages from the popup
            const messageListener = async (event: MessageEvent) => {
              if (event.origin !== window.location.origin) return;

              if (event.data?.type === 'social-auth-success') {
                  const authCode = event.data.code;

                try {

                  set_social_loader(prevState => ({
                    ...prevState,
                    yt_social_loading: true 
                  }));
                  
                  // Send auth code to backend
                  const tokenResponse = await handlesService.postGoogleConnected({ code: authCode });

                  if (tokenResponse.status === gtUtil.Constants.SUCCESS) {
                      setYtCardData(tokenResponse.data.profile_data);
                      setShowYtProfileCard(true);
                      setIsActiveField({
                          ...isActiveField,
                          social_media: true
                      });
                      setverifyYtId(true);
                      set_social_loader(prevState => ({
                        ...prevState,
                        yt_social_loading: false 
                      }));
                  } else {
                      if (tokenResponse.info === 'Social account already connected to the other account.') {
                          setYtCardData(tokenResponse.data.profile_data);
                          set_socialRestrictionPopup(prevState => ({
                              ...prevState,
                              youtube: true 
                          }));
                      }
                  }

                } catch (error) {
                  console.error("Error processing Youtube auth response:", error);
                  toast.error("Youtube authentication failed.");
                } finally {
                  // Remove event listener after handling response
                  window.removeEventListener("message", messageListener);
                  set_social_loader(prevState => ({
                    ...prevState,
                    yt_social_loading: false 
                }));
                }

              }
            };

            window.addEventListener("message", messageListener);
      }
    }  catch (error) {
      console.error("Login Error:", error);
      toast.error("Google Connect failed");
    }finally{
      set_social_loader(prevState => ({
        ...prevState,
        yt_social_loading: false 
      }));
    }
  };


const handleCloseSocialPopup_YT = async (id: any) => {
  set_socialRestrictionPopup(prevState => ({
      ...prevState,
      youtube: false
  }));
  
  try {
      const response = await handlesService.continueConnect({
          yt_id: id,
          continues: false,
      });

      if (response.status === gtUtil.Constants.SUCCESS) {
        setverifyYtId(false);
        setShowYtProfileCard(false);
        setYtCardData({});
        set_social_loader(prevState => ({
          ...prevState,
          yt_social_loading: false 
      }));
      } else {
          console.log('Error occurred');
      }
  } catch (error) {
      console.error('API Error:', error);
  }finally{
    set_social_loader(prevState => ({
      ...prevState,
      yt_social_loading: false 
  }));
  }
};

const handleContinueConnect_YT = async (id: any) => {
  set_socialRestrictionPopup(prevState => ({
      ...prevState,
      youtube: false 
  }));

  try {
    setverifyYtId(true);
    setShowYtProfileCard(true);
    setIsActiveField({
      ...isActiveField,
      social_media: true
    })
    set_social_loader(prevState => ({
      ...prevState,
      yt_social_loading: false 
  }));
  } catch (error) {
      console.error('API Error:', error);
  }finally{
    set_social_loader(prevState => ({
      ...prevState,
      yt_social_loading: false 
  }));
  }
};

const handleCloseSocialPopup_IG = async (id: any) => {
  set_socialRestrictionPopup(prevState => ({
      ...prevState,
      instagram: false 
  }));

  try {
      const response = await handlesService.continueConnect({
          ig_id: id,
          continues: false,
      });

      if (response.status === gtUtil.Constants.SUCCESS) {
        setverifyIgId(false);
        setShowInstaProfileCard(false);
        setInstaCardData({});
        set_social_loader(prevState => ({
          ...prevState,
          ig_social_loading: false 
      }));
      } else {
          console.log('Error occurred');
      }
  } catch (error) {
      console.error('API Error:', error);
  }finally{
    set_social_loader(prevState => ({
      ...prevState,
      ig_social_loading: false 
  }));
  }
};

const handleContinueConnect_IG = async (id: any) => {
  set_socialRestrictionPopup(prevState => ({
      ...prevState,
      instagram: false 
  }));

  try {
    setverifyIgId(true);
    setShowInstaProfileCard(true);
    setIsActiveField({
      ...isActiveField,
      social_media: true
    })
    set_social_loader(prevState => ({
      ...prevState,
      ig_social_loading: false 
  }));
  } catch (error) {
      console.error('API Error:', error);
  }finally{
    set_social_loader(prevState => ({
      ...prevState,
      ig_social_loading: false 
  }));
  }
};

  return (
    <>
      {
        String(BASE_URL_UI).includes("clanconnect.ai") &&
        <Helmet>
          <link rel="canonical" href={`${BASE_URL_UI}/signup/influencer`} />
        </Helmet>
      }
      <div className="_influencer-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <p className="_influencer-login-btn">
                Already have an account?{" "}
                <span>
                  <NavLink
                    to="/login"
                    className="_influencer-signup-navlink"
                  >
                    Login
                  </NavLink>
                </span>
              </p>
              <div className="_influencer-signup">
                <div className="d-flex flex-wrap align-items-center">
                  <div className="d-flex">
                    <NavLink to="/" className="__navLink __backArrow_icon">
                      {/* <ArrowBackIcon className="_icon" /> */}
                      <MaterialSymbol iconName="arrow_back" className="_icon d-flex justify-content-center align-items-center" fontweight="200" />
                    </NavLink>
                    <HeadingStyle title="Influencer Signup" />
                  </div>

                  <span className="mandatory-msg ms-auto pt-2 pt-sm-0 ps-2">
                    All fields are mandatory
                  </span>
                </div>
                <form onSubmit={handleSubmit} className="__signup_form">
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <TextField
                        className="w-100 input-style input-common"
                        size="small"
                        autoFocus
                        id="full_name_id"
                        onBlur={handleFullName}
                        name="full_name"
                        value={form_data.full_name}
                        onChange={(e) => set_form_data({...form_data,full_name : e.target.value})}
                        placeholder="Your Full Name"
                        label="Full Name"
                        variant="outlined"
                      />
                      <span className="error">{errorFullName?.message}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12  mb-3">
                      <div className="__wa_column __wa_column_mobile  mt-1">
                        <div className="d-flex">
                        {/* <span className="me-2 fs-14 align-self-center fixed-country-code">+91-</span> */}
                        <TextField
                          disabled={!isActiveField.name}
                          className="w-100 input-style input-common"
                          type="number"
                          size="small"
                          color="primary"
                          InputProps={{
                            readOnly: whats_app_boolean_fields.disableWhatsappfieldInput,
                            inputMode: 'numeric',
                            startAdornment: (<InputAdornment position="start"><span className="fs-14 font-weight-normal">+91</span></InputAdornment>),
                          }}
                          inputRef={phoneRef}
                          onChange={handleWhatsApp}
                          name="whatsapp_number"
                          value={form_data.whatsapp_number}
                          id="whatsapp_number_id"
                          label="WhatsApp No."
                          variant="outlined"
                         
                        onKeyDown={(e: any) => formValidation.GetHandleKeyDown(e, setWhatsAppNumber, whatsappnumber, 'number', setErrorWhatsApp)}
                        onPaste={(e:any) => formValidation.GethandlePaste(e, setWhatsAppNumber,"phone", (trimmedData) => handleWhatsApp({ target: { value: trimmedData } }))}
                        />
                        {!whats_app_boolean_fields.verifiedfield &&
                        <LoadingButton
                              className={`__button __button-influender ${whats_app_boolean_fields.disableWhatsappfield ? "btn-disabled" : ""}`}
                          loading={phoneloading}
                          loadingPosition="start"
                          variant="contained"
                          startIcon={<WhatsAppIcon />}
                          color="success"
                          disabled={whats_app_boolean_fields.disableWhatsappfield}
                          onClick={handleSendOtpButton}
                        >
                          Send OTP
                        </LoadingButton>
                        }
                        {whats_app_boolean_fields.verifiedfield && 
                          <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                              <MaterialSymbol iconName="verified" fontweight="200" />
                            </IconButton>
                          </Tooltip>
                        }
                        </div>
                        <span className="error">{errorWhatsApp?.message}</span>
                        {whats_app_boolean_fields.showResendEdit &&
                          <div className="resend-otp d-flex align-items-center w-100 mb-2">
                            {whats_app_boolean_fields.isEditButtonDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangePhone}>Change Phone</p>}
                            <div className="resend-otp-container d-flex">
                              <button
                                className="otp-btn fs-12 btn btn-text-link"
                                onClick={handleSendOtpButton}
                                type="button"
                                disabled={whats_app_boolean_fields.isResendButtonDisabled}
                              >
                                Resend OTP
                              </button>
                              {whats_app_boolean_fields.isTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{timer < 10 ? `0${timer}` : timer}</p>}
                            </div>

                          </div>
                        }
                        {/* {whats_app_boolean_fields.showotpBox && <OtpField handleOtp={handleWhatsAppOtp} />} */}
                          {whats_app_boolean_fields.showotpBox && 
                          <div className="mobile-verification">
                          <MuiOtpInput
                            className="otp-fields"
                            value={otpWhatsAppValue}
                            onChange={handleWhatsAppChange}
                            length={4}
                            autoFocus
                            TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                            validateChar={validateChar} 
                            />
                            
                            <LoadingButton
                            className="verify-button"
                            loading={phoneOtpVerifyloading}
                            variant="contained"
                            color="success"
                            disabled={otpWhatsAppValue.length == 4 ? false : true}
                            onClick={()=>handleWhatsAppOtp(otpWhatsAppValue)}
                          >
                            Verify
                          </LoadingButton>
                          </div>
                            }
                          {phoneOtpError && <span className="error">{phoneOtpErrorMsg}</span>}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="__wa_column mt-1">
                        <TextField
                          className={`w-100 input-style input-common ${email_boolean_fields.verifiedEmail ? "verified-email": ""}`}
                          disabled={!isActiveField.mobile}
                          size="small"
                          id="email_id"
                          onChange={handleEmail}
                          name="email_id"
                          label="Your Email Id"
                          variant="outlined"
                          inputRef={emailRef}
                          value={form_data.email_id}
                          InputProps={{ readOnly: email_boolean_fields.disableEmailfieldInput }}
                          onPaste={(e:any) => formValidation.GethandlePaste(e, setEmail,"email", (trimmedData) => handleEmail({ target: { value: trimmedData } }))}
                          onKeyDown={(e: any) => formValidation.GetHandleKeyDown(e, setEmail, email, "email")}
                        />
                        {!email_boolean_fields.verifiedEmail &&
                        <LoadingButton
                              className={`__button ${email_boolean_fields.disableEmailField ? "btn-disabled" : ""}`}
                          color="success"
                          loading={emailloading}
                          loadingPosition="start"
                          variant="contained"
                          startIcon={<SendIcon />}
                          onClick={handleEmailSendOTP}
                          disabled={email_boolean_fields.disableEmailField}
                        >
                          {emailOtpSent ? "OTP Sent" : "Send OTP"}
                        </LoadingButton>
                      }
                        {email_boolean_fields.verifiedEmail &&
                          <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                              <MaterialSymbol iconName="verified" fontweight="200" />
                            </IconButton>
                          </Tooltip>
                        }
                        <span className="error">{errorEmail?.message}</span>
                        {email_boolean_fields.showEmailResendEdit &&
                          <div className="resend-otp d-flex align-items-center w-100 mb-2">
                            {email_boolean_fields.isEditButtonEmailDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangeEmail}>Change Email</p>}
                            <div className="resend-otp-container d-flex">
                              <button
                                className="otp-btn fs-12 btn btn-text-link"
                                onClick={handleEmailSendOTP}
                                type="button"
                                disabled={email_boolean_fields.isResendButtonEmailDisabled}
                              >
                                Resend OTP
                              </button>
                              {email_boolean_fields.isEmailTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{Emailtimer < 10 ? `0${Emailtimer}` : Emailtimer}</p>}
                            </div>

                          </div>
                        }
                        {/* {showEmailotpBox && <OtpField handleOtp={handleEmailOtp} />} */}
                        {/* {showEmailotpBox && <MuiOtpInput
                          value={otpEmailValue}
                          onChange={handleEmailChange}
                          onComplete={handleEmailOtp}
                          length={4}
                          autoFocus
                          validateChar={validateChar}
                          TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }} 
                          />} */}
                          {email_boolean_fields.showEmailotpBox && 
                          <div className="mobile-verification">
                          <MuiOtpInput
                            className="otp-fields"
                            value={otpEmailValue}
                            onChange={handleEmailChange}
                            length={4}
                            autoFocus
                            TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                            validateChar={validateChar} 
                            />
                            
                            <LoadingButton
                            className="verify-button"
                            loading={emailOtpVerifyloading}
                            variant="contained"
                            color="success"
                            disabled={otpEmailValue.length == 4 ? false : true}
                            onClick={()=>handleEmailOtp(otpEmailValue)}
                          >
                            Verify
                          </LoadingButton>
                          </div>
                            }
                          {emailOtpError && <span className="error">{emailOtpErrorMsg}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-3">
                      <FormControl fullWidth size="small" className="input-style input-common">
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="gender_id"
                          disabled={!isActiveField.email}
                          value={form_data.gender}
                          inputRef={genderRef}
                          label="Gender"
                          name="gender"
                          onChange={(e) => {
                            set_form_data({...form_data,gender:e.target.value})
                            setIsActiveField({
                              ...isActiveField,
                              gender: true,
                            })
                          }}
                          MenuProps={MenuPropsSelect}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
                      </FormControl>
                      <span className="error">{errorGender?.message}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-3 d-flex _signup_dob_section d-flex flex-column">
                      <div className="d-flex">
                      <TextField
                        className="w-100 _dobinput input-style input-common"
                        name="dd"
                        disabled={!isActiveField.gender}
                        error={!errorDate.dd.status}
                        // helperText={!errorDate.dd.message}
                        inputRef={ddRef}
                        value={form_data.dd}
                        type="number"
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;

                          if (/^\d{0,2}$/.test(value)) {
                            set_form_data({...form_data,dd:value});

                            setErrorDate((previous) => ({
                              ...previous,
                              dd: formValidation.IsValidDay(value),
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          let formattedValue = e.target.value;
                          if (formattedValue.length === 1 && formattedValue !== '0') {
                            formattedValue = `0${formattedValue}`;
                            set_form_data({...form_data,dd:formattedValue});

                            setErrorDate((previous) => ({
                              ...previous,
                              dd: formValidation.IsValidDay(formattedValue),
                            }));
                          }

                          if(!isActiveField.dd){
                            setIsActiveField({
                              ...isActiveField,
                              dd: formValidation.IsValidDay(formattedValue).status
                            })
                          }
                        }}
                        inputMode="numeric"
                        size="small"
                        id="dd_id"
                        label="DD"
                        variant="outlined"
                      />
                      <span className="_influencer_dob_slash ">/</span>
                      <TextField
                        className="w-100 _dobinput input-style input-common"
                        name="mm"
                        disabled={!isActiveField.dd}
                        error={!errorDate.mm.status}
                        inputRef={mmRef}
                        // helperText={errorDate.mm.message}
                        value={form_data.mm}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (/^\d{0,2}$/.test(value)) {
                            set_form_data({...form_data,mm:value});

                            setErrorDate((previous) => ({
                              ...previous,
                              mm: formValidation.IsValidMonth(value),
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          let formattedValue = e.target.value;
                          if (formattedValue.length === 1 && formattedValue !== '0') {
                            formattedValue = `0${formattedValue}`;
                            set_form_data({...form_data,mm:formattedValue});

                            setErrorDate((previous) => ({
                              ...previous,
                              mm: formValidation.IsValidMonth(formattedValue),
                            }));
                          }

                          if(!isActiveField.mm){
                            setIsActiveField({
                              ...isActiveField,
                              mm: formValidation.IsValidMonth(formattedValue).status
                            })
                          }
                        }}
                        type="number"
                        size="small"
                        id="mm_id"
                        label="MM"
                        variant="outlined"
                      />
                      <span className="_influencer_dob_slash">/</span>
                      <TextField
                        className="w-100 _dobinput input-style input-common"
                        name="yyyy"
                        disabled={!isActiveField.mm}
                        error={!errorDate.yyyy.status}
                        inputRef={yyyyRef}
                        onBlur={() => {
                          setErrorDate((e) => ({
                            ...e,
                            yyyy: formValidation.IsvalidYear(form_data.yyyy),
                          }));

                          if(!isActiveField.yyyy){
                            setIsActiveField({
                              ...isActiveField,
                              yyyy: formValidation.IsvalidYear(form_data.yyyy).status
                            })
                          }
                        }}
                        value={form_data.yyyy}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (value.length <= 4) {
                            set_form_data({...form_data,yyyy:value});
                            setIsActiveField((prevIsActiveField) => ({
                              ...prevIsActiveField,
                              dob: formValidation.IsvalidYear(value).status,
                            }));
                          } else {
                            set_form_data({...form_data,yyyy:form_data.yyyy});
                          }
                        }}
                        type="number"
                        size="small"
                        id="yyyy_id"
                        label="YYYY"
                        variant="outlined"
                      />
                      </div>
                      <div className="d-flex flex-column">
                        {errorDate.dd.message && <span className="error fs-12">{errorDate.dd.message}</span>}
                      {errorDate.mm.message && <span className="error fs-12">{errorDate.mm.message}</span>}
                        {errorDate.yyyy.message && <span className="error fs-12">{errorDate.yyyy.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <FormControl className="w-100 input-style input-common" size="small">
                        <InputLabel id="demo-multiple-name-label">
                          Genre (Max. 3)
                        </InputLabel>
                        <Select
                          className="_genre_option common-select-dropdown"
                          labelId="demo-multiple-name-label"
                          id="Genre_id"
                          multiple
                          value={form_data.genre}
                          inputRef={genreRef}
                          onBlur={handleGenreOnBlur}
                          onChange={handleGenre}
                          disabled={!isActiveField.yyyy}
                          input={<OutlinedInput label="Genre (Max. 3)" />}
                          MenuProps={MenuPropsSelectSmall}
                          name="genre"
                        >
                          {genricList ? (
                            genricList.map((res:any) => (
                              <MenuItem
                                className="small-dropdown-menu"
                                key={res.category_id}
                                value={res.category_id}
                              >
                                <Checkbox
                                  checked={form_data.genre.includes(res.category_id)}
                                  style={{ marginRight: 8 }}
                                />
                                {res.category}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem key={1} value={1}>
                              {"No Data"}
                            </MenuItem>
                          )}
                        </Select>

                      </FormControl>
                      <span className="error">{errorGenre?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <FormControl className="w-100 input-style input-common" size="small">
                        <InputLabel id="demo-multiple-name-label">
                          Language (Max. 4)
                        </InputLabel>
                        <Select
                          className="_genre_option common-select-dropdown"
                          labelId="demo-multiple-name-label"
                          id="Language_id"
                          disabled={!isActiveField.genre}
                          multiple
                          value={form_data.language}
                          inputRef={languageRef}
                          onBlur={handleLanguageOnBlur}
                          onChange={handleLanguage}
                          input={<OutlinedInput label="Language (Max. 3)" />}
                          MenuProps={MenuPropsSelectSmall}
                          name="language"
                        >
                          {languageList ? (
                            languageList?.map((res: any) => (
                              <MenuItem
                                key={res.id}
                                value={res.id}
                              >
                                <Checkbox
                                  checked={form_data.language?.includes(res.id)}
                                  style={{ marginRight: 8 }}
                                />
                                {`${res.language_name} (${res.language_original_name})`}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              key={1}
                              value={1}
                            >
                              {"No Data"}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <span className="error">{errorLanguage?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <Autocomplete
                        className="inputFieldSp input-common"
                        size="small"
                        disablePortal
                        disabled={!isActiveField.language} 
                        value={form_data.location_id}
                        id="combo-box-demo"
                        onChange={handleLocationChange}
                        options={
                          location
                            ? location?.map(
                              (res: {
                                id: number;
                                city: string;
                                state: string;
                                country: string;
                              }) => {
                                return `${res.city}, ${res.state}, ${res.country}`;
                              }
                            )
                            : ["No Data"]
                        }
                        ListboxProps={MenuPropsAutoCompleteNoCheckBox.PaperProps}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              inputRef={locationRef}
                              name="location_id"   
                              onBlur={() => {
                                if(!isActiveField.location) {
                                setIsActiveField((prevIsActiveField) => ({
                                  ...prevIsActiveField,
                                  location: form_data.location_id ? true : false,
                                }));
                              }}}
                              label="Location"
                            />
                          );
                        }}
                      />

                      <span className="error">{errorLocation?.message}</span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <FormControl
                        variant="outlined"
                        className="w-100 input-common"
                        size="small"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Create Password
                        </InputLabel>
                        <OutlinedInput
                          id="password_id"
                          type={showPassword ? "text" : "password"}
                          disabled={!isActiveField.location}
                          inputRef={passwordRef}
                          name="password"
                          onBlur={handlePassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Create Password"
                        />
                      </FormControl>
                      <span className="fs-12" style={{lineHeight: '14px',display: 'block', paddingTop: '5px'}}>Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</span>
                      <span className="error">{errorPassword?.message}</span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <FormControl
                        variant="outlined"
                        className="w-100 input-common"
                        size="small"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          id="confirm_password_id"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirm_password"
                          disabled={!isActiveField.password}
                          onBlur={handleConfirmPassword}
                          inputRef={confirmPasswordRef}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                        />
                      </FormControl>
                      {errorConfirmPassword && !errorConfirmPassword.status && <span className="error">{errorConfirmPassword?.message}</span>}
                    </div>
                  </div>
                  <div className="row __manually mb-3">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <span style={{ fontSize: "14px" }}> Create Login Pin  </span>
                    </div>
                    <div className="col">
                      <div className="__wa_column">
                      <MuiOtpInput
                          value={form_data.pin}
                          onChange={handlePINChange}
                          onComplete={handlePin}
                          ref={pinRef}
                          style={{ pointerEvents: isActiveField.confirmPassword ? 'auto' : 'none', opacity: isActiveField.confirmPassword ? 1 : 0.5 }}
                          length={4}
                          TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                          validateChar={validateChar} 
                          />
                      </div>
                      <span className="error">
                        {errorPin?.message}
                      </span>
                    </div>
                  </div>
                  {!influencerId && (<>
                  <div className="row">
                    <div className="col-12 mt-1 mb-1">
                      <p
                        style={{
                          margin: `0px`,
                          marginBottom: "10px",
                          fontSize: "14px",
                        }}
                      >
                        Social Media Handle (atleast one)
                        <span style={{ color: `red` }}>*</span>
                        <span
                          className="__influencer-warn d-block"
                          style={{
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          Please ensure the profiles mentioned belong to you and
                          is genuine.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row __manually mb-3">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <span style={{ fontSize: "14px", marginBottom: '10px', display: 'block' }}>
                        <InstagramIcon className="icon-insta" /> Instagram
                      </span>
                    </div>
                      <div className="col">
                        {social_loader.ig_social_loading ?  
                            <ContentLoading width='150' height='150' />
                            :                        
                            <>
                              {!showInstaProfileCard && 
                                <>
                                      <div className="connected-account-buttons mb-3">
                                          <Button
                                              variant="outlined"
                                              className={`btn me-2 ${selectedOption === "facebook" ? "active" : ""}`}
                                              onClick={() => setSelectedOption("facebook")}
                                              disabled={!isActiveField.pin}
                                          >
                                              Connect via Facebook
                                          </Button>
                                          <Button
                                              variant="outlined"
                                              className={`btn me-2 ${selectedOption === "instagram" ? "active" : ""}`}
                                              onClick={() => setSelectedOption("instagram")}
                                              disabled={!isActiveField.pin}
                                          >
                                              Connect Directly
                                          </Button>
                                      </div>
                              
                                    {/* Connect via Facebook */}
                                    {selectedOption === "facebook" && (
                                        <>
                                            <span>Connect Instagram via Facebook</span>
                                            <div className="connected-account-buttons">
                                                <div className="connected-account-input d-flex mb-2">
                                                    <TextField
                                                        className="influencer_personal_info"
                                                        size="small"
                                                        fullWidth
                                                        name="username"
                                                        type="text"
                                                        label="Instagram username"
                                                        placeholder="e.g., virat.kohli"
                                                        variant="outlined"
                                                        onPaste={handlePaste}
                                                        onInput={handleInput}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const username = e.target.value;
                                                            setUserName(username);
                                                            setVerifyUserName(!username);
                                                        }}
                                                        disabled={!isActiveField.pin}
                                                    />
                                                    <Button
                                                        variant="outlined"
                                                        className="btn ms-2"
                                                        onClick={faceBookAction}
                                                        disabled={verifyUserName}
                                                    >
                                                        Verify
                                                    </Button>
                                                </div>


                                                    {(loadingIg ? (
                                                        <Skeleton variant="rounded" height={40} width={102} />
                                                    ) : (
                                                        <LoadingButton
                                                            className={`__button __button-influender ${showInstaProfileCard ? "btn-disabled" : ""}`}
                                                            loading={igVerifyLoading}
                                                            loadingPosition="start"
                                                            variant="contained"
                                                            color="success"
                                                            onClick={() => fb_instagramConnect()}
                                                            disabled={faceBookButton}
                                                        >
                                                            Connect
                                                        </LoadingButton>
                                                    ))}
                                            </div>
                                        </>
                                    )}

                                    {/* Connect Directly to Instagram */}
                                    {selectedOption === "instagram" && (
                                        <>
                                            <span>Connect Instagram</span>
                                            <div className="connected-account-buttons">
                                                <div className="connected-account-input d-flex mb-2">
                                                    <LoadingButton
                                                      className={`__button __button-influender ${showInstaProfileCard ? "btn-disabled" : ""}`}
                                                      loading={igVerifyLoading}
                                                      loadingPosition="start"
                                                      variant="contained"
                                                      color="success"
                                                      onClick={instagramConnect}
                                                      disabled={!isActiveField.pin}
                                                    >
                                                        Connect
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                              }
                              {showInstaProfileCard && (
                                <InfluencerProfileCard
                                  platform="instagram"
                                  name={instaCardData?.insta_name}
                                  username={instaCardData?.username}
                                  profile_image={instaCardData?.profile_image_url}
                                  description={instaCardData?.biography}
                                  subscriber={instaCardData?.followers}
                                  follows={instaCardData?.follows}
                                  total_media={instaCardData?.media_count}
                                />)
                              }
                            </>
                          }
                      </div>
                  </div>
                  <div className="row __manually mb-3">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <span style={{ fontSize: "14px", marginBottom: '10px', display:'block' }}>
                        {" "}
                        <YouTubeIcon style={{ color: "#f00" }} /> Youtube
                      </span>
                    </div>
                      <div className="col"> 
                        {social_loader.yt_social_loading ?  
                          <ContentLoading width='150' height='150' />
                          :
                          <>          
                            {!showYtProfileCard && (
                            <div className="connected-account-buttons">
                                                <LoadingButton
                                                  className={`__button __button-influender ${showYtProfileCard ? "btn-disabled" : ""}`}
                                                  color="success"
                                                  loading={ytVerifyLoading}
                                                  loadingPosition="start"
                                                  variant="contained"
                                                  // disabled={showYtProfileCard ? true : !isActiveField.location}
                                                  disabled={!isActiveField.pin}
                                                  onClick={() => loginGoogleyt()}
                                                >
                                                    Connect
                                                </LoadingButton>
                              </div>
                            )}
                            {showYtProfileCard && (
                              <InfluencerProfileCard
                                platform="youtube"
                                name={ytCardData?.title}
                                username={ytCardData?.custom_url}
                                profile_image={ytCardData?.profile_image_url}
                                description={ytCardData?.description}
                                subscriber={ytCardData?.subscriber_count}
                                follows={0}
                                total_media={ytCardData?.video_count}
                              />
                            )}
                          </>  
                        }
                      </div>
                  </div></>)}
                  <div className="row">
                    <div className="col">
                      <ReCAPTCHA
                        sitekey={CAPTCHA_SITE_KEY}
                        // onChange={(token: any) => setCaptchaToken(token)}
                        onChange={(token: any) => set_form_data({...form_data,captchaToken:token})}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col pt-2" style={{ fontSize: "12px" }}>
                      <Checkbox
                        style={{
                          padding: "0",
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                        inputProps={{
                          "aria-label": "Checkbox",
                        }}
                        checked={form_data.terms}
                        onChange={handleCheckboxChange}
                        name="terms"
                        size="small"
                      />
                      <span>
                        I agree to the{" "}
                        <div className="d-inline-flex align-items-end">
                          <NavLink
                            to="/terms_condition"
                            className="btn btn-text-link"
                          >
                            Terms and Conditions
                          </NavLink>
                          <span className="px-1">and</span> <NavLink
                            to="/privacy_policy"
                            className="btn btn-text-link"
                          >Privacy Policy</NavLink>
                        </div>
                      </span>
                      <br />
                      <span className="error">{errorTerms?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-end">
                      <Button
                        type="submit"
                        variant="contained"
                        className={`btn btn-primary ${!influencerId ? !isActiveField.social_media ? "btn-disabled":"" : !isActiveField.pin ? "btn-disabled":""}`}
                        disabled={!influencerId ? !isActiveField.social_media : !isActiveField.pin}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-0 _influencer-content-sec">
              <InfluencerContent />
            </div>
          </div>

          {socialRestrictionPopup.youtube &&
            <SocialConnectRestriction open={socialRestrictionPopup.youtube} data = {ytCardData} onClose={handleCloseSocialPopup_YT} continueConnect={handleContinueConnect_YT} />
          }

          {socialRestrictionPopup.instagram &&
            <SocialConnectRestriction open={socialRestrictionPopup.instagram} data = {instaCardData} onClose={handleCloseSocialPopup_IG} continueConnect={handleContinueConnect_IG} />
          }
        </div>
      </div>
      <Footer />
    </>
  );
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(null, mapDispatchToProps)(Influencer);

export { connectToRedux as Influencer };
