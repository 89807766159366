import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import { Skeleton, Box } from "@mui/material";

interface CustomDataGridProps {
  rows: any[];
  columns: GridColDef[];
  checkboxSelection?: boolean;
  getRowHeight?: () => number;
  columnHeaderHeight?: number;
  loading?: boolean;
  disableColumnFilter?: boolean;
  rowHeight?: number
}

const SkeletonLoader = () => (
  <Box sx={{ width: "100%" }}>
    {[...Array(10)].map((_, rowIndex) => (
      <Box key={rowIndex} sx={{ display: "flex", mb: 1 }}>
        {[...Array(10)].map((_, colIndex) => (
          <Skeleton
            key={colIndex}
            variant="rectangular"
            width={100}
            height={40}
            sx={{ mr: 1 }} // Add margin between skeletons to simulate columns
          />
        ))}
      </Box>
    ))}
  </Box>
);

const NoRowsOverlay = () => (
  <GridOverlay>
    <Box sx={{ p: 2, textAlign: "center" }}>No Data Available</Box>
  </GridOverlay>
);

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
  rows,
  columns,
  checkboxSelection = false,
  getRowHeight,
  rowHeight= 65,
  columnHeaderHeight = 38,
  loading = false,
}) => {
  // Create empty rows when loading
  const loadingRows = Array.from({ length: 10 }, (_, index) => ({ id: `loading-${index}` }));
  return (
    <DataGrid
      disableColumnFilter={true}
      rows={loading ? loadingRows : rows.map((row, index) => ({ ...row, id: row?.influencer_id || index }))}
      columns={columns}
      checkboxSelection={checkboxSelection}
      loading={loading}
      hideFooterPagination
      hideFooter={true}
      getRowHeight={getRowHeight || (() => rowHeight)}
      sx={{
        border: 0,
        "& .MuiDataGrid-row": {
          minHeight: "42px",
          maxHeight: "42px",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#000",
          color: "#ffffff",
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily:'Open Sans',
        },
        "& .MuiDataGrid-cell": {
          fontSize: "14px",
          color: "#333",
          fontFamily:'Open Sans',
        },
        "& .MuiCheckbox-root": {
          color: "#000",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          visibility: "visible !important",
          marginLeft: "auto",
          width: "30px !important"
        },
        "& .MuiDataGrid-iconButtonContainer .MuiButtonBase-root": {
          color: "#fff",
        },
        
      }}
      columnHeaderHeight={columnHeaderHeight}
      components={{
        NoRowsOverlay: loading ? SkeletonLoader : NoRowsOverlay,
      }}
    />
  );
};

export default CustomDataGrid;
