import React, { useEffect, useState } from 'react'
import * as gtUtil from "../../../core/utility";
import InstagramIcon from "../../../components/icons/instagram-icon";
import { BrandDataService } from "../../../core/services/brand-data.service";
import { Launch, Search } from "@mui/icons-material";
import InstagramIcon1 from '../../../components/icons/instagram-icon1';
import YouTube from '@mui/icons-material/YouTube';
import {DetailedInfluencerAnalytics} from './detailed-influencer-analytics';
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { BrandPulseProfileCard } from './brand-pulse-profile-card';
import "./brand-data.scss";
import { MetricsCard } from './metrics-card';
import {FollowersGrowthChart} from './followers-growth-chart';
import { ContentTypeChart } from './content-type-chart';
import {InfluencerGrid} from './influencer-grid';
import { PostErChart } from './post-er-chart';
import {TopInfluencer} from './topInfluencer';
import {ComparisionTable} from './comparsion-table';
import {EngagedInfluencers} from './engaged-influencers';
import { RolesService } from '../../../core/services/roles.service';
import { LocalService } from '../../../core/services/local.service';
import { useNavigate } from 'react-router-dom';
import { EngagedInfluencerTable } from './engaged-influencer-table';
import { Grid } from '@mui/material';
import { ColumnChart, PieChart } from '../../../components/charts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3d from 'highcharts/highcharts-3d'
import { ContentTypeChartER } from './content-type-chart-er';
import BrandPulseWordCloud from './brandPulseWordCloud';
import WordCloudComponent from './brandPulseWordCloud';
import { CommentsTab } from './comments-tab';

const rolesService = new RolesService();
const localService: LocalService = new LocalService();
const brandDataService: BrandDataService = new BrandDataService();
Highcharts3d(Highcharts);

interface Props 
{
    UserReducer: any;
    loading: (data: any) => void;
}

const BrandPulse: React.FC<Props> = (props: Props) => {
  const [searchValue, setSearchValue] = useState("")
  const [searchList, setSearchList] = useState<any>([]);
  const [brandImage, setBrandImage] = useState("")
  const [brandRefId,setBrandRefId] = useState("");
  const [detailedInfluencer,setDetailedInfluencer] =useState("")
  const [profileData,setProfileData] =useState("");
  const [isSelected,setIsSelected] = useState(false)
  const [userStatus,setUserStatus] = useState(false)
  const [postFrequency,setPostFrequency] = useState("")
  const [profileEr,setProfileEr]=useState("")
  const [paidFrequency,setPaidFrequency]=useState("");
  const [organicFrequency,setOrganicFrequency]=useState("")
  const [subsGrowthStockChartData, setSubsGrowthStockChartData] = useState<any[]>([]);
  const [contentTypeChart,setContentTypeChart]=useState("")
  const [influencerData,setinfluencerData] =useState<any[]>([]);
  const [postErChart,setPostErChart]=useState<any[]>([]);
  const [topInfluencer,setTopInfluencer]=useState<any[]>([]);
  const [topCompetitors,setTopCompetitors]=useState<any[]>([]);
  const [comparisionData,setComparisionData]=useState<any[]>([]);
  const [influencerDetails,setInfluencerDetails]=useState<any[]>([]);
  const [paidOrganicER,setPaidOrganicER]=useState<any[]>([]);
  const [worldCloud,setWorldCloud]=useState<any[]>([]);
  const [comments,setComments]=useState<any[]>([]);

  const [amount,setAmount]= useState("")
  const navigate = useNavigate();


  useEffect(() => {
    // CHECK IF USER ALLOWED  ACCESS 
    checkAccessFunction();
  }, []);

  const checkAccessFunction = async () => {
    try {
      const res = await rolesService.checkAccess({ access_roles: ["view_admin_dashboard"], user_role_id: props?.UserReducer?.user_role_id })
      if (res.data.rows.length > 0) {
        setUserStatus(true);
      }
      else {
        localService.toastify("You are not allowed to access", "error", 1000);
        navigate(`/brand/dashboard`)
      }
    }
    catch (error) {
      console.error('Error fetching getAdminDashData:', error);

    }
  }
  // For Searching Items.......

  const handleSearchBar = async ({target}: React.ChangeEvent<HTMLInputElement>) => {
    try 
    {
      const inputValue = target.value.toLowerCase();
      setSearchValue(inputValue);
      if (!inputValue || inputValue.length < 3) 
      {
        setSearchList([]);
        return;
      }
      const response = await brandDataService.getBrandSearch(inputValue);
      if (response.status == gtUtil.Constants.SUCCESS) 
      {
        setSearchList(response.data.rows);
        setBrandImage(response.data.rows)
      }
    } 
    catch (error) {
      console.log(`getBrandSearch error :: ${JSON.stringify(error)}`);
    }
  };

  // For Rendring Profile List Items....................................
  
  const renderProfileListItem = (item: any) => {
    return <>
      <li className="insta-search-list" key={item?.ref_id}>
        <div className="col">
          <div className="d-flex flex-wrap align-items-center">
            <span onClick={() => handleBrandData(item?.ref_id)}>
              {item?.platform === 'instagram' ? <InstagramIcon1 width={25} /> : <YouTube fontSize="small" style={{ color: 'red' }} />}
            </span>
            <strong>
              <span className="insta-username insta-search-username mx-2" onClick={() => handleBrandData(item?.ref_id)}>
                {item?.platform === 'instagram' ? item?.username : item?.title}
              </span>
            </strong>
            <span className="ms-auto" style={{ color: 'black' }} onClick={() => handleBrandData(item?.ref_id)}>
              <Launch fontSize="small" sx={{ height: 'auto' }} />
            </span>
          </div>
        </div>
        <p>
          <span style={{ textDecoration: 'none', color: 'black' }} onClick={() => handleBrandData(item?.ref_id)}>
            {item.platform === 'instagram' ? item?.insta_name : item?.title}
          </span>
        </p>
      </li>
    </>

  };

  // For Getting Brand Data
  const handleBrandData = async (selectedRefId: any) => 
  {
      const response = await brandDataService.getBrandData(selectedRefId);
      if(response.status == gtUtil.Constants.SUCCESS){
        setBrandRefId(selectedRefId);
        setIsSelected(false);
        setDetailedInfluencer(response.data.rows.influencer_engaged)
        setProfileData(response.data.rows.brand_profile)
        setPostFrequency(response.data.rows.post_frequency)
        setProfileEr(response.data.rows.profile_er)
        setPaidFrequency(response.data.rows.paid_frequency)
        setOrganicFrequency(response.data.rows.organic_frequency)
        setWorldCloud(response.data.rows.hashtags)  
        setComments(response.data.rows.comments)
        // setContentTypeChart(response.data.rows.content_type)

        const monthOrder: Record<string, number> = {
          Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, 
          Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
        };

        const sortedContentType:any = [...response.data.rows.content_type].sort((a, b) => {
          const [monthA, yearA] = a.month_year.split(" ");
          const [monthB, yearB] = b.month_year.split(" ");

          // Convert year to a number for proper comparison
          const yearDiff = parseInt(yearA) - parseInt(yearB);
          if (yearDiff !== 0) return yearDiff; // Sort by year first

          return monthOrder[monthA] - monthOrder[monthB]; // Then by month
        });

        setContentTypeChart(sortedContentType);

        setinfluencerData(response.data.rows.related_profile)
        setPostErChart(response.data.rows.post_er_graph)
        setTopInfluencer(response.data.rows.top_influencers)
        setTopCompetitors(response.data.rows.top_competitors)
        setInfluencerDetails(response.data.rows.influencer_engagement_price)
        setPaidOrganicER(response.data.rows.paid_organic_er)
        setAmount(response.data.rows.amount)
        const subsGrowthData = response?.data?.rows?.subscriber_growth_chart;
        if (Array.isArray(subsGrowthData)) {
          setSubsGrowthStockChartData(subsGrowthData)
        }
        const res = await brandDataService.compareBrandData(selectedRefId);//Comparision Data
        if(res.status == gtUtil.Constants.SUCCESS){
          setComparisionData(res.data.rows)  

        }
      }
  }
  return (
    <>
     {userStatus && <div className='d-flex justify-content-center align-items-center brand-pulse'>
      {/* .....................For Search Bar................................... */}
      <div className='container custom-container'>
        <div className="search-container-content" >
            <InstagramIcon className="search-plt-type-icon icon-instagram" width={20} height={20}/>
              <form className="d-flex" >
                <input
                  type="search"
                  placeholder="Search by name or username"
                  className="form-control"
                  id="brand-search-by-name"
                  onChange={handleSearchBar}
                  onClick={() => setIsSelected(true)}
                />  

                <button
                  type="button"
                  className="btn d-flex btn-search"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <Search />
                </button>
              </form>
              {
                searchList.length > 0 && isSelected && (
                  <div className="search-suggestion" style={{cursor:'pointer'}}>
                    <ul>
                      {searchList.slice(0, 10).map((item: any) => (renderProfileListItem(item)))}
                    </ul>
                  </div>
                )
              }
        </div>
      </div>
      {/* .....................End of Search Bar................................... */}

      </div>
      }
      {
      brandRefId && profileData.length > 0 && 
      <div style={{paddingTop:'24px',paddingBottom:'24px',display:'flex',flexDirection:'column',gap:'24px'}}>
        <div className='container custom-container'>
          <BrandPulseProfileCard profileData= {profileData}/>
          <MetricsCard profileEr ={profileEr} postFrequency ={postFrequency} paidFrequency = {paidFrequency} organicFrequency = {organicFrequency} paidOrganicEr = {paidOrganicER} budget ={amount}/>
          <Grid container spacing={2} className="mt-2 mb-3">
            <Grid item xs={12} md={6} lg={4}>
              <FollowersGrowthChart subsGrowthStockChartData={subsGrowthStockChartData}/>
            </Grid>
            {contentTypeChart?.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ContentTypeChart subsGrowthStockChartData={contentTypeChart}/>
              </Grid>
            }
            {contentTypeChart?.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ContentTypeChartER subsGrowthStockChartData={contentTypeChart}/>
              </Grid>
            }
          </Grid>
          <Grid container spacing={2} className="mb-3"> 
            {Array.isArray(postErChart) && postErChart.some(item => item.average_er > 0) && (
              <Grid item xs={12} sm={12} md={8}>
                <PostErChart postErChart={postErChart} />
              </Grid>
            )}
            {(topInfluencer.length > 0 || topCompetitors.length > 0) && 
              <Grid item xs={12} sm={12} md={4}>
                <TopInfluencer topInfluencer={topInfluencer} topCompetitors={topCompetitors}/>
              </Grid>
            } 
          </Grid>
          {(Array.isArray(comparisionData) && comparisionData.length > 0) && 
          (Array.isArray(influencerDetails) && influencerDetails.length > 0) ? (
            <Grid item xs={12} sm={12} md={12} className='mb-3'>
              <section className="brand-insight-highchart">
                <div className="brand-insight-highchart-top">
                  <strong className="brand-insight-highchart-title d-flex flex-column">
                    <span className="brand-insight-highchart-subtitle">Engaged</span>
                    Influencers
                    <span className="brand-insight-highchart-subtitle">Amount spent and EMV</span>
                  </strong>
                </div>
                
                <div className="brand-insight-highchart-data">
                  <Grid container spacing={2} className="mt-2">
                    
                        {/* <Grid item xs={12} sm={12} md={6}>
                          {Array.isArray(comparisionData) && comparisionData.length > 0 && <EngagedInfluencers data={(comparisionData.filter((item: any) => item.ref_id == brandRefId)[0])} />
                          }
                        </Grid> */}

                      <Grid item xs={12} md={12} lg={12} xl={6}>
                        {Array.isArray(comparisionData) && comparisionData.length > 0 &&
                          comparisionData.filter((item: any) => item.ref_id == brandRefId).length > 0 && (
                            <EngagedInfluencers data={comparisionData.filter((item: any) => item.ref_id == brandRefId)[0]} />
                          )
                        }
                      </Grid>
                    
                    <Grid item xs={12} md={12} lg={12} xl={6}>
                      {Array.isArray(influencerDetails) && influencerDetails.length > 0 && 
                        <EngagedInfluencerTable detailedInfluencer={influencerDetails}/>
                      } 
                    </Grid>
                    
                  </Grid>

                </div>
              </section>
            </Grid>
          ) : null}
            <Grid container spacing={2} className="mb-3"> 
              <Grid item xs={12} sm={12} md={8}>
               {comments.length > 0 && <CommentsTab comments = {comments}/>}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
               {worldCloud.length > 0 && <WordCloudComponent wordCloud= {worldCloud}/>}
              </Grid>
          </Grid>

          {detailedInfluencer.length > 0 && <DetailedInfluencerAnalytics detailedInfluencer={detailedInfluencer}/>}
          {influencerData.length > 0 && <InfluencerGrid influencerData={influencerData} />} 
          {comparisionData.length > 0 && <ComparisionTable data = {comparisionData}/>}
        </div>
      </div>
      }
    </>
  )
}
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(BrandPulse);

export { connectToRedux as  BrandPulse };



