import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';

const http = new HttpService();

export class InfluencerProfileService {
  async getInfluencerProfile(data: { sm_id: number | string, user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string, user_role_id: number | string  }): Promise<any> {
    const path = BASE_URL + `/influencer/profile-insight?sm_id=${data.sm_id}&user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}&user_role_id=${data.user_role_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Influencer Profile page :: ${error}`);
    }
  }

  async getBrandProfile(data: { sm_id: number | string, user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string, user_role_id: number | string  }): Promise<any> {
    const path = BASE_URL + `/brand/profile-insight?sm_id=${data.sm_id}&user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}&user_role_id=${data.user_role_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Influencer Profile page :: ${error}`);
    }
  }


  async getInfluencerProfileYt(data: { sm_id: number | string, user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string, user_role_id: number | string }): Promise<any> {
    const path = BASE_URL + `/influencer/profile-insight-yt?sm_id=${data.sm_id}&user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}&user_role_id=${data.user_role_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Influencer Youtube Profile Page :: ${error}`);
    }
  }

  async getBrandProfileYt(data: { sm_id: number | string, user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string, user_role_id: number | string }): Promise<any> {
    const path = BASE_URL + `/brand/profile-insight-yt?sm_id=${data.sm_id}&user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}&user_role_id=${data.user_role_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Influencer Youtube Profile Page :: ${error}`);
    }
  }
}
