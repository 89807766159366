import { useEffect, useState } from 'react';
import { AnalyticsService } from '../../../core/services';
import AnalyticsDashboard from './components/analytics-dashboard';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Tab } from '@mui/material';
import { MostEngagingPosts } from './components/most-engaging-posts';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BrandDashboardService } from '../../../core/services/dashboard-brand.service';
import { AdminDashboardService } from '../../../core/services/admin-dashboard.service';
import { MostEngagingInfluencer } from './components/most-engaging-influencer';
import DetailedInsightsInfluencer from './components/detailed-insights-influencer';
import DetailedInsightsPost from './components/detailed-insights-post';
import ContentLoading from '../../../components/loader/loading';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import InstagramEmbed from '../../../components/instagram-embed/instagram-embed';
import SampleDetailedAnalytics from './components/sample-detailed-analytics';
import { sampleMostEngagingInfluencers, sampleInfluencerDetailedData, samplePostDetailedData, sampleMostEngagingPosts } from '../../../data/data';
const analyticsService = new AnalyticsService();
const adminDashboardService = new AdminDashboardService();

export interface SortingColumnsType {
    name: string | "element key Ex. instagram_handle_ig",
    columnSize: string | "columnSize Ex. 120px",
};





export const ShareAnalytics = () => {
  const [MostEngagingInfluencers, setMostEngagingInfluencers] = useState(sampleMostEngagingInfluencers)
    const urlParams = new URLSearchParams(window.location.search);
    const urlPathname = window.location.pathname;
    const campaignPlatform = urlParams.get("platform");
    const campaignToken = urlParams.get("accessKey");
    const hasShareAnalytics = urlPathname.includes('share-analytics') || urlPathname.includes('share-analytics-yt');
    const data = { campaignToken, campaignPlatform };
    const navigate = useNavigate()
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
    const [postFilterData, setPostFilterData] = useState<any>([]);
      const [analyticsCumulativeDataIgNumericData, setAnalyticsCumulativeDataIgNumericData] = useState<any>([]);
  const [analyticsCumulativeDataIgMediaData, setAnalyticsCumulativeDataIgMediaData] = useState<any>([]);
    const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
    const [totalCost, setTotalCost] = useState(0);
  const [totalPost, setTotalPost] = useState<any>(0);
    const [graphData, setGraphData] = useState<any>([]);
    
    const [selectedCampaign, setSelectedCampaign] = useState<any>({});
    const [selectedCampaignTitle, setSelectedCampaignTitle] = useState<any>("");
    const [loading, setLoading] = useState(false)
    const [addedLinksDetails, setAddedLinksDetails] = useState<any>([]);
    const campaignId = selectedCampaign?.campaign_id;
    const getPostLinkData = async (campaignId:any) => {
      const res = await analyticsService.getAnalyticsLinks(campaignId);
      if (res.data.status) {
        setAddedLinksDetails(res.data.rows);
      }
    }
                        
useEffect(() => {
    const verifiedUser = async () => {
        setLoading(true); // Set loading to true when starting data fetch
        try {
            const res = await analyticsService.verifySharedUser(data);
            if (res?.data?.status === 1) {// Ensure the status indicates a valid token response
                let response;
                if (campaignPlatform === 'Instagram') {
                    response = await analyticsService.shareBrandAnalytics({ 
                        campaign_id: res.data?.rows[0]?.campaign_id, 
                        sm_id: null 
                    });
                    
                    if (response?.data) {
                        setSelectedCampaignTitle(response?.data?.campaignTitle)
                        setSelectedCampaign(response?.data?.campaign_details);
                        getPostLinkData(res.data?.rows[0]?.campaign_id)
                        
                        if (response?.data?.analytics_data_ig_media?.length > 0) { setAnalyticsCumulativeDataIgMediaData(response?.data?.analytics_data_ig_media[0])}
                        if (response.data.analytics_data_ig_numeric?.length > 0) {
                            setAnalyticsCumulativeDataIgNumericData(response.data.analytics_data_ig_numeric[0]);
                        }
                        if (response?.data?.graph_data_array?.length > 0) {
                        setGraphData(response.data.graph_data_array);
                        }
                        
                        if (response?.data?.influencer_data_content?.length > 0) {
                        setInfluencerFilterData(response.data.influencer_data_content);
                        }
                        if (response?.data?.post_data_content?.length > 0) {
                        setPostFilterData(response.data.post_data_content);

                        }
                        setTotalCost(response?.data?.amount_spent);
                        setTotalPost(response?.data?.total_posts);
                    }
                } 
            } else {
                // If the token is invalid, log the error and avoid the redirect
                console.error('Invalid or expired token:', res.info);
                // Optionally show an error message on the page instead of redirecting to login
            }
        } catch (error) {
            console.error('Error occurred during user verification or data fetch:', error);
            // Handle any other errors (network issues, etc.)
        } finally {
            setLoading(false); // Set loading to false after the process completes (whether successful or not)
        }
    };

    verifiedUser();
}, [campaignToken, campaignPlatform]);



  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Likes',
      },
    },
    series: [
      {
        name: 'Date',
        data: graphData?.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Plays Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Total Views',
        data: graphData?.map((entry: any) => entry.video_plays),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
    },
    yAxis: {
      title: {
        text: 'Total Comments',
      },
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: 'Total Comments',
        data: graphData?.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
  const erOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'ER Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.date),
      format: '{value:.2f}'
    },
    yAxis: {
      title: {
        text: 'Average ER %',
      },

    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Average ER %',
        data: graphData?.map((entry: any) => parseFloat(entry.er.toFixed(2))),
      },
    ],
    tooltip: {
      pointFormat: 'Avg ER: {point.y}%',
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
    // -----------
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };
  const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_plays: "Plays",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  video_plays: "Plays",
  total_likes: "Likes",
  total_comments: "Comments"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "Post",
    columnSize: "35%",
  },
  ...(!hasShareAnalytics
  ? [
      {
        name: "",
        columnSize: "10%",
      },
    ]
  : []),
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },
];

const InfluencerDetailedData: SortingColumnsType[] = [

  {
    name: "Influencer",
    columnSize: "35%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },

];

    return <>{loading ? (
        <div className='d-flex align-items-center justify-content-center' style={{height:'calc(100vh - 250px)'}}>
            <ContentLoading width='150' height='150' /> 
            </div>
        ) : ( <div className="share-analytics brand-analytics detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="container custom-container">
            <div className=''>
            {selectedCampaignTitle && <strong className='fs-20 pb-4 d-block'>{selectedCampaignTitle}</strong>}
            </div>
            <div className="row project-detail-sec">
              <div className="col-md-12">
                <div className="upper-grid">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>

                      <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataIgMediaData} campaign_platform={campaignPlatform} analyticsCumulativeDataNumericData={analyticsCumulativeDataIgNumericData} totalCost={totalCost} totalPost={totalPost} />
                    </Grid>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container custom-container'>
          {postFilterData.length === 0 && addedLinksDetails.length === 0 && <>
          
          <strong className="mb-3 d-block sample-report-text">Sample analytics report <span className="fs-12">(Once the links are added, the analytics will be visible as shown below)</span></strong>
          <SampleDetailedAnalytics platform={campaignPlatform} sampleMostEngagingInfluencers={sampleMostEngagingInfluencers} sampleInfluencerDetailedData={sampleInfluencerDetailedData} samplePostDetailedData={samplePostDetailedData} sampleMostEngagingPosts={sampleMostEngagingPosts} /></>}
          {((addedLinksDetails && addedLinksDetails.length>0) && postFilterData.length === 0) &&
            <>
              <strong>Post Links:</strong>
              <div className="post-links row">{addedLinksDetails && addedLinksDetails?.map((postLink:any)=>{
                return <div className="col-md-3">{loading ? <Skeleton /> : <InstagramEmbed embedUrl={postLink?.post_url} />}</div>
                
                
              })}</div>
            </>
            }

        {
          (() => {
            if (postFilterData.length > 0) {
              return (
                <>
                  <div className="toggle-button-div sec-stockchart-w-tab">
                    <TabContext value={value}>

                      <TabList className='nav sub-tabs'
                        onChange={handleChange}
                      >
                        <Tab
                          className='nav-link'
                          label={`Views`}
                          value="1"
                          icon={<span className="material-symbols-rounded">play_arrow</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Likes`}
                          value="2"
                          icon={<span className="material-symbols-rounded">favorite</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Comments`}
                          value="3"
                          icon={<span className="material-symbols-rounded">chat_bubble</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`ER`}
                          value="4"
                          icon={<span className="material-symbols-rounded">percent</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                      </TabList>

                      <TabPanel value="1" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={playsOptions} />

                      </TabPanel>
                      <TabPanel value="2" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                      </TabPanel>
                      <TabPanel value="3" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                      </TabPanel>
                      <TabPanel value="4" className='px-0'>
                        <HighchartsReact highcharts={Highcharts} options={erOptions} />
                      </TabPanel>
                    </TabContext>

                  </div>

                  <>
                    <div className='d-flex flex-column'>
                      <strong className='me-auto mb-1'>Most Engaging Posts</strong>
                      <>
                        {(() => {
                          const sortedData = [...postFilterData].sort((a: any, b: any) => (b.selectedValue > a.selectedValue) ? 1 : (b.selectedValue < a.selectedValue) ? -1 : 0).slice(0, 5);
                          return (<MostEngagingPosts sortedData={sortedData} selectCampaign={selectedCampaign} />
                          );
                        })()}
                      </>
                      {/* {/* )} */}
                    </div>
                    {(
                      <div className="analytics-most-engaging">
                        <div className='d-flex flex-column'>
                          <strong className='mb-1'>Most Engaging Influencers</strong>
                          {(() => {
                            const sortedData = influencerFilterData.sort((a: any, b: any) => (b.total_er > a.total_er) ? 1 : (b.total_er < a.total_er) ? -1 : 0).slice(0, 5);
                            return <MostEngagingInfluencer sortedData={sortedData} selectCampaign={selectedCampaign} />
                          })()}

                        </div>

                      </div>
                    )}
                  </>

                  <div className="toggle-button-div2">
                    <div className="d-flex flex-wrap">
                      <strong>Detailed Insights</strong>
                    </div>
                     <TabContext value={value2}>

                      <TabPanel value="1" className='px-0'>
                        <div className="toggle-button-div3">
                          <TabContext value={value4}>
                            <>
                              <TabList
                                onChange={handleChange4}
                                className="filter-tab-list nav sub-tabs mb-3"
                              >
                                <Tab className='nav-link'
                                  label={`Posts`}
                                  value="1"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                                <Tab className='nav-link'
                                  label={`Influencers`}
                                  value="2"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                              </TabList>
                            </>
                            <TabPanel value="1" className='px-0 pt-0'>
                              <div className="post-table">
                                <>
                                  <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                                    orderByColumnName=""
                                    inputDataSource={postFilterData}
                                    sortingColumnType={PostDetailedData}
                                    renderData={postFilterData}
                                    setRenderData={(e: any) => setPostFilterData(e)}
                                    selectCampaign={selectedCampaign}
                                    hasShareAnalytics={hasShareAnalytics} />
                                </>

                              </div>
                            </TabPanel>
                            <TabPanel value="2" className='px-0 pt-0'>
                              <div className="influencer-table">

                                <DetailedInsightsInfluencer columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                  orderByColumnName=""
                                  inputDataSource={influencerFilterData}
                                  sortingColumnType={InfluencerDetailedData}
                                  renderData={influencerFilterData}
                                  setRenderData={(e: any) => setInfluencerFilterData(e)}
                                  selectCampaign={selectedCampaign} />
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </TabPanel>
                    </TabContext> 

                  </div >
                </>
              )
            }

          })()

        }
        </div>
       
      </div >)}</>;
};