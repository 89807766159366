import React, { useState } from 'react'
import "./brand-data.scss";
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import { Methods } from '../../../core/utility/methods';
import Pagination from "@mui/material/Pagination";

const methods = new Methods();
 
interface Props {
    UserReducer: any;
    loading: (data: any) => void;
    influencerData: any;
  }

  
const InfluencerGrid : React.FC<Props> = (props: Props)=> {
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event:any, value:any) => {
    setCurrentPage(value);
  };
  const startIndex = (currentPage - 1) * 10;
  const currentItems = props.influencerData.slice(startIndex, startIndex + 10);


  return (
    <div className='brand-pulse-similar-brands'>
      <strong>Similar Brands & Influencers</strong>
      <div className="influencer-grid">
        {currentItems.map((item:any) => (
      <div className="card">
        <div className='card-top'>
          <img src={item.gcp_profile_pic_url} alt={`${item.full_name} logo`} className="card-logo" />
          <div className='card-right'>
            <h3 className="card-name">{item.full_name}</h3>
            <p className="card-username">@{item.username}</p>
          </div>
        </div>
      <div className="card-stats">
        <div className="d-flex flex-column bg-white p-2 flex-grow-1 flex-md-grow-auto" style={{borderRadius:'5px'}}>
          <p className="m-0" style={{fontSize:'16px',fontWeight:'600',lineHeight:'20px',color:'#000'}}>{methods.formatNumber(item.followers_count)}</p> 
          <p className="m-0" style={{fontSize:'13px',fontWeight:'400',lineHeight:'20px',color:'#000'}}>Followers</p>
          </div>
        {/* <span>{item.media_count} Posts</span> */}
        <div className="d-flex flex-column bg-white p-2 px-4 flex-grow-1 flex-md-grow-auto" style={{borderRadius:'5px'}}>
          <p className="m-0" style={{fontSize:'16px',fontWeight:'600',lineHeight:'20px',color:'#000'}}>{methods.formatNumber(item.media_count)}</p> 
          <p className="m-0" style={{fontSize:'13px',fontWeight:'400',lineHeight:'20px',color:'#000'}}>Posts</p>
          </div>
      </div>
    </div>
      ))}
      </div>

    <div>
          <Pagination
          count={Math.ceil(props.influencerData.length / 10)}
          page={currentPage}
          onChange={handleChange}
          color="primary"
          style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(InfluencerGrid);
  
  export { connectToRedux as InfluencerGrid };
  