import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { StockChart, PieChart } from '../../../components/charts';
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { HandlesService } from "../../../core/services/handles.service";
import { InfluencerProfileService } from "../../../core/services/influencer-profile.service";
import { LocalService } from "../../../core/services";
import { CreateCampaign as CampaignService } from "../../../core/services";
import { Methods } from "../../../core/utility";
import { Link, NavLink } from 'react-router-dom';
import YTMostViewedVideos from './yt-most-viewed-videos';
import { LineChartDropDown } from "../../../components/charts/line-chart";
import AddToCampaign from "../../../components/add-to-campaign/add-to-campaign";
import AddToList from "../../../pages/brand/discover/add-to-list";
import YTInfluencerProfileLeft from "./yt-influencer-profile-left";
import FollowerCard from "../../brand/profile-insight/follower-card";
import IconYouTube from "../../../components/icons/icon-youtube";
import { useNavigate } from "react-router-dom";
import useBodyClass from "../../../hooks/useBodyClass";
import * as gtUtil from "../../../core/utility";



const handlesService = new HandlesService();
const inflProfileService: InfluencerProfileService = new InfluencerProfileService()
const localService: LocalService = new LocalService();
const methods = new Methods()


interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  getSubscriptionDetail: any;
}


const YoutubeProfileInsight: React.FC<Props> = (props: Props) => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)
  const [ytInfluencerData, setytInfluencerData] = useState<any>([]);
  const [loading, setIsLoading] = useState(false)
  const [maleGenderSplit, setMaleGenderSplit] = useState()
  const [femaleGenderSplit, setFemaleGenderSplit] = useState()
  const [genderSplit, setGenderSplit] = useState<any[]>([]);
  const [columnChartData, setColumnChartData] = useState<any[]>([]);
  const [viewStockChartData, setViewStockChartData] = useState<any[]>([]);
  const [viewSubscriberGrowthData, setViewSubscriberGrowthData] = useState<any[]>([]);
  const [minStockChartData, setMinStockChartData] = useState<any[]>([]);
  const [deviceViewStockChartData, setDeviceViewStockChartData] = useState<any[]>([]);
  const [deviceAvgViewPieChartData, setDeviceAvgViewPieChartData] = useState<any[]>([]);
  const [deviceMinStockChartData, setDeviceMinStockChartData] = useState<any[]>([]);
  const [avgDeviceMinPieChartData, setAvgDeviceMinPieChartData] = useState<any[]>([]);
  const [geoViewStockChartData, setGeoViewStockChartData] = useState<any[]>([]);
  const [geoEstMinChartData, setGeoEstMinChartData] = useState<any[]>([]);
  const [geoAvgViewPieChartData, setGeoAvgViewPieChartData] = useState<any[]>([]);
  const [geoAvgViewPerPieChartData, setGeoAvgViewPerPieChartData] = useState<any[]>([]);
  const [socialSharePieChartData, setSocialSharePieChartData] = useState<any[]>([]);
  const [influencerId] = useState(() => {
    const idString = new URLSearchParams(window.location.href).get('influencerId');
    return idString ? parseInt(idString, 10) : 0;
  });
  const [influencerList, setInfluencerList] = useState<any>(null);
  const [selectInflu, setSelectInflu] = useState<any>([]);
  const [genderArrayLabel, setGenderArrayLabel] = useState()
  const [showPopAddToCampaign, setShowPopAddToCampaign] = useState(false);
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);
  const [countryName, setCountryName] = useState<any>()
  const [countryNameAvg, setCountryNameAvg] = useState<any>()
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState('');
  const [subscription_status, setSubscription_status] = useState<any>({
    status: null,
    plan_start_date: null,
    plan_end_date: null
  });
  const [categoriesData, setCategoriesData] = useState([]);
  const [language, setLanguages] = useState([]);
  const [connected_profile, set_connected_profile] = useState<Boolean>(false);

  const navigate = useNavigate();

  // to get connected youtube account sm_id
  async function getConnectedAccountId(influencer_id: number | string) {
    try {
      const res: any = await handlesService.getGoogleConnectedAccounts(influencer_id,props.UserReducer.account_type);
      if (res?.data?.rows.length > 0) {
        const connectedAccountData = res.data.rows.filter(
          (filter: any) => filter.token_status === 'Connected' || (props.UserReducer.account_type === 'Talent Partner' && filter.token_status === 'No')
        );
        if (connectedAccountData.length > 0) {
          return connectedAccountData[0].yt_id;
        }
      }
    } catch (err: any) {
      console.error('Error fetching connected accounts:', err);
      // Handle the error as needed...
    }
    // Return a default value or handle the case when no connected account is found
    return null;
  }

  useEffect(() => {
    try {
      const fetchData = async () => {
        let idToSet;
        try {
          if (urlParams.get("sm_id") == null) {
            idToSet = await getConnectedAccountId(props?.UserReducer?.influencer_id);
          } else {
            idToSet = urlParams.get("sm_id");
          }

        } catch (error) {
          console.error('Error setting Instagram ID:', error);
        }
        getProfileInsightYt(idToSet);

      };
      fetchData();
    } catch (error) {
      console.error('Error fetching connected accounts:', error);
    }
  }, []);

  //Colors Array
  const getColorBasedOnIndex = (index: any) => {
    const colors = ['#f9d1d1',
            '#ffa4B6',
            '#f765a3',
            '#a155b9',
            '#165baa',
            '#0b1354', "#e2bbe6", "#FF8C00", "#32CD32", "#8A2BE2", "#FFD700", "#DC143C", "#B0C4DE", "#2E8B57", "#FF6347"];
    return colors[index % colors.length];
  }


  // Function to convert date strings to Date.UTC format
  const convertToUTC = (dateString: any) => {
    try {
      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0], 10);
      const monthIndex = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      return Date.UTC(year, monthIndex, day);
    }
    catch (error) {
      console.error("Error converting date to UTC:", error);
    }
  }

  //Name convert of country
  const countryNameMapping: { [key: string]: string } = {
    "Iran, Islamic Republic of": "Iran",
    "Russian Federation": "Russia",
    "Tanzania, United Republic of": "Tanzania",
    "United Arab Emirates": "UAE",
    "United Kingdom of Great Britain and Northern Ireland": "UK",
    "United States of America": "USA"
  };


  const getProfileInsightYt = async (youtubeID: any) => {
    setIsLoading(true)
    try {

      let response;

      if (props?.UserReducer?.account_type === 'Brand' || props?.UserReducer?.account_type === 'Agency') {
        response = await inflProfileService.getBrandProfileYt({
          sm_id: youtubeID,
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          brand_id: props.UserReducer.brand_id,
          influencer_id: props?.UserReducer?.account_type === 'Influencer' || props?.UserReducer?.account_type === 'Talent Partner'
            ? props?.UserReducer?.influencer_id
            : urlParams.get('influencerId') || 0,
          user_role_id: props?.UserReducer?.user_role_id
        });
      }else{
        response = await inflProfileService.getInfluencerProfileYt({
          sm_id: youtubeID,
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          brand_id: props.UserReducer.brand_id,
          influencer_id: props?.UserReducer?.account_type === 'Influencer' || props?.UserReducer?.account_type === 'Talent Partner'
            ? props?.UserReducer?.influencer_id
            : urlParams.get('influencerId') || 0,
          user_role_id: props?.UserReducer?.user_role_id
        });
      }

      if (response.status === gtUtil.Constants.SUCCESS) {
        set_connected_profile(false);
        setSelectInflu(response?.data?.rows?.detail)
        setytInfluencerData(response?.data?.rows)
        setPhone(response?.data?.rows?.email_phone[0]?.primary_phone)
        setEmail(response?.data?.rows?.email_phone[0]?.primary_email)
        setSubscription_status(response?.data?.rows?.subscription_status);
        const data = response.data.rows.location_category_language[0];
        const locationString = `${data.state}, ${data.country}`;
        setLocation(locationString);

        // Extract categories and filter out null values
        const categoryList: any = [
          data.category1,
          data.category2,
          data.category3,
          data.category4
        ].filter(category => category !== null);
        setCategoriesData(categoryList);
        const languageList: any = [
          data.lang1,
          data.lang2,
          data.lang3,
          data.lang4
        ].filter(language => language !== null);
        setLanguages(languageList);

        setIsLoading(false);
        //age-gender-pie-chart-data
        const ageGenderSplitData = response?.data?.rows?.age_gender_split;
        if (Array.isArray(ageGenderSplitData) && ageGenderSplitData.length !== 0) {

          let maleSplit = ageGenderSplitData.length !== 0 && ageGenderSplitData.filter(entry => entry.gender === "male").reduce((total, entry) => total + entry.total_percentage, 0);
          let femaleSplit = ageGenderSplitData.length !== 0 && ageGenderSplitData.filter(entry => entry.gender === "female").reduce((total, entry) => total + entry.total_percentage, 0);
          setMaleGenderSplit(maleSplit)
          setFemaleGenderSplit(femaleSplit)
          const genderSplitData = [
            { name: "Male", y: maleSplit, color: "#3592FC" },
            { name: "Female", y: femaleSplit, color: "#FF78B1" },
          ];
          setGenderSplit(genderSplitData);
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //age-gender-column-data
        const ageGenderData = response?.data?.rows?.age_gender;
        if (Array.isArray(ageGenderData) && ageGenderData.length !== 0) {

          // let fGenderArray: any = ageGenderData?.filter((item) => item.gender.includes("female"));
          // let mGenderArray: any = ageGenderData?.filter((item) => item.gender === "male");
          let fGenderArray = ageGenderData
            .filter((item) => item.gender === "female")
            .map((item) => item.value);

          let mGenderArray = ageGenderData
            .filter((item) => item.gender === "male")
            .map((item) => item.value);

          let genderArrayLabelSet = new Set(ageGenderData.map(item => item.age_range_yt.replace(/[female, male]/g, '')));
          let genderLabel: any = [...genderArrayLabelSet];
          setGenderArrayLabel(genderLabel)
          const newColumnChartData = [
            {
              name: "Male",
              data: mGenderArray,
              color: '#3592FC'
            },
            {
              name: "Female",
              data: fGenderArray,
              color: '#FF78B1'
            },
          ];

          setColumnChartData(newColumnChartData);
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //channel-insight-views
        const channelViewData = response?.data?.rows?.view;
        if (Array.isArray(channelViewData) && channelViewData.length > 0) {

          let channelView: any = channelViewData.length > 0 && channelViewData.map(item => [convertToUTC(item.day), item.views])
          const newStockChartData = [
            {
              name: 'views',
              data: channelView
            }

          ]
          setViewStockChartData(newStockChartData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //channel-insight-min-watch
        const minViewData = response?.data?.rows?.estimated_min_watch;
        if (Array.isArray(minViewData)) {
          let minView: any = minViewData.map(item => ([convertToUTC(item.day), item.estimated_min]));

          const estMinStockChartData = [
            {
              name: 'Total Minutes Watched',
              data: minView
            }

          ]
          setMinStockChartData(estMinStockChartData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }


        //geo-insight-views
        const geoViewData = response?.data?.rows?.geo_view;
        if (Array.isArray(geoViewData) && geoViewData.length > 0) {

          const geoCViewData = geoViewData.map((item, index) => ({
            name: countryNameMapping[item.country] || item.country,
            y: item.views,
            color: getColorBasedOnIndex(index),
          }));

          setGeoViewStockChartData(geoCViewData)

        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //geo-insight-estimated-minutes
        const geoEstMinData = response?.data?.rows?.geo_estimated_min_watch;
        if (Array.isArray(geoEstMinData) && geoEstMinData.length > 0) {

          let country = geoEstMinData.map(item => item.country);
          country = country.map(name => countryNameMapping[name] || name);

          let dataMin = geoEstMinData.map(item => item.estimated_min);

          const countryData = [
            {
              name: "Minutes",
              data: dataMin
            }
          ];
          setGeoEstMinChartData(countryData)
          setCountryName(country)
          // const geoEstMinViewData = geoEstMinData.map((item, index) => ({
          //   name: item.country,
          //   data: item.estimated_min,
          //   color: getColorBasedOnIndex(index),
          // }));
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //geo-insight-averagev-views-duration and percentage
        const geoAvgViewData = response?.data?.rows?.geo_avg_view;
        if (Array.isArray(geoAvgViewData) && geoAvgViewData.length > 0) {
          let country = geoAvgViewData.map(item => item.country);
          country = country.map(name => countryNameMapping[name] || name);

          let dataAvgMin = geoAvgViewData.map(item => item.avg_views_duration);
          const geoEstMinViewData = [
            {
              name: "Minutes",
              data: dataAvgMin
            }
          ];
          setGeoAvgViewPieChartData(geoEstMinViewData)
          setCountryNameAvg(country)

          // const geoCAvgViewData = geoAvgViewData.map((item, index) => ({
          //   name: item.country,
          //   y: item.avg_views_duration,
          //   color: getColorBasedOnIndex(index),
          // }));
          // setGeoAvgViewPieChartData(geoCAvgViewData)

          //Average-view-percentage
          const geoCAvgPerViewData = geoAvgViewData.map((item, index) => ({
            name: countryNameMapping[item.country] || item.country,
            y: item.perce,
            color: getColorBasedOnIndex(index),
          }));

          setGeoAvgViewPerPieChartData(geoCAvgPerViewData)

        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //Device-insight-views
        const deviceViewData = response?.data?.rows?.device_insight;
        if (Array.isArray(deviceViewData) && deviceViewData.length > 0) {

          let mobileView: any = deviceViewData.map(item => [convertToUTC(item.DAY), item.mobile_views])
          let tabletView: any = deviceViewData.map(item => [convertToUTC(item.DAY), item.tablet_views])
          let tvView: any = deviceViewData.map(item => [convertToUTC(item.DAY), item.tv_views])

          const deviceVStockChartData = [
            {
              name: 'Mobile',
              data: mobileView
            },
            {
              name: 'Tablet',
              data: tabletView
            },
            {
              name: 'TV',
              data: tvView
            }

          ]
          setDeviceViewStockChartData(deviceVStockChartData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //Device-insight-avg-views
        const avgDeviceViewData = response?.data?.rows?.device_insight_avg;
        if (Array.isArray(avgDeviceViewData) && avgDeviceViewData.length > 0) {

          let mobileView;
          avgDeviceViewData.forEach(item => { mobileView = item.avg_mobile_views });
          let tabletView;
          avgDeviceViewData.forEach(item => { tabletView = item.avg_tablet_views });
          let tvView;
          avgDeviceViewData.forEach(item => { tvView = item.avg_tv_views });

          const avgGeoCViewData = [
            { name: "Mobile", y: mobileView, color: "#f9d1d1" },
            { name: "Tablet", y: tabletView, color: "#ffa4B6" },
            { name: "TV", y: tvView, color: "#f765a3" }
          ];
          setDeviceAvgViewPieChartData(avgGeoCViewData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }


        //Device-insight-estimated-minutes
        const deviceEstMinData = response?.data?.rows?.device_insight_est_min;
        if (Array.isArray(deviceEstMinData) && deviceEstMinData.length > 0) {

          let mobileEstMin: any = deviceEstMinData.map(item => [convertToUTC(item.DAY), item.estimated_min_mobile])
          let tabletEstMin: any = deviceEstMinData.map(item => [convertToUTC(item.DAY), item.estimated_min_tablet])
          let tvEstMin: any = deviceEstMinData.map(item => [convertToUTC(item.DAY), item.estimated_min_tv])

          const deviceVMinStockChartData = [
            {
              name: 'Mobile',
              data: mobileEstMin
            },
            {
              name: 'Tablet',
              data: tabletEstMin
            },
            {
              name: 'TV',
              data: tvEstMin
            }

          ]
          setDeviceMinStockChartData(deviceVMinStockChartData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }


        //Device-insight-avg-estimated-mins
        const avgDeviceEstMinData = response?.data?.rows?.device_insight_est_min_avg;
        if (Array.isArray(avgDeviceEstMinData)) {

          let mobileEstMin;
          avgDeviceEstMinData.forEach(item => { mobileEstMin = item.avg_mobile_estimated_minutes_watched });
          let tabletEstMin;
          avgDeviceEstMinData.forEach(item => { tabletEstMin = item.avg_tablet_estimated_minutes_watched });
          let tvEstMin;
          avgDeviceEstMinData.forEach(item => { tvEstMin = item.avg_tv_estimated_minutes_watched });

          const avgDeviceVMinStockChartData = [
            { name: "Mobile", y: mobileEstMin, color: "#f9d1d1" },
            { name: "Tablet", y: tabletEstMin, color: "#ffa4B6" },
            { name: "TV", y: tvEstMin, color: "#f765a3" }
          ];
          setAvgDeviceMinPieChartData(avgDeviceVMinStockChartData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //Social-share-data
        const socialShareData = response?.data?.rows?.social_shares;

if (Array.isArray(socialShareData)) {
  const threshold = 1; // Percentage threshold
  let smallPercentageData = [];
  let combinedShares = 0;

  const filteredData = socialShareData.filter((item) => {
    if (item.perce < threshold || item.sharing_service === "OTHER") {
      combinedShares += item.shares; // Add shares of small percentages and "OTHER"
      smallPercentageData.push(item); // Collect small percentage items
      return false; // Exclude from main data
    }
    return true; // Include items with perce >= threshold
  });

  // Find existing "Other" or "Others" in filteredData
  let existingOthersIndex = filteredData.findIndex(
    (item) =>
      item.sharing_service.toLowerCase() === "other" ||
      item.sharing_service.toLowerCase() === "others"
  );

  if (combinedShares > 0) {
    const totalShares = socialShareData.reduce((sum, i) => sum + i.shares, 0);
    const combinedOthers = {
      sharing_service: "Others",
      y: combinedShares,
      perce: (combinedShares / totalShares) * 100,
      color: "#ccc",
    };

    if (existingOthersIndex !== -1) {
      // If "Other" or "Others" already exists, merge values
      filteredData[existingOthersIndex].y += combinedOthers.y;
      filteredData[existingOthersIndex].perce += combinedOthers.perce;
      filteredData[existingOthersIndex].sharing_service = "Others"; // Standardize name
    } else {
      // Otherwise, push new "Others" entry
      filteredData.push(combinedOthers);
    }
  }

  const socialShareViewData = filteredData.map((item, index) => ({
    name: (item.name || item.sharing_service).toLowerCase() === "whats_app"
      ? "WhatsApp"
      : (item.name || item.sharing_service).replace(/_/g, " "),
    y: item.y || item.shares,
    color: getColorBasedOnIndex(index),
  }));

  setSocialSharePieChartData(socialShareViewData);
}


        // SUBSCRIBER GROWTH DATA
        const subscribersGrowthData = response?.data?.rows?.subscriber_growth;
        if (Array.isArray(subscribersGrowthData) && subscribersGrowthData.length > 0) {

          let subscriberGrowth: any = subscribersGrowthData.length > 0 && subscribersGrowthData.map(item => [convertToUTC(item.day), item.views])

          const newSubscriberGrowthChartData = [
            {
              name: 'views',
              data: subscriberGrowth
            }

          ]
          setViewSubscriberGrowthData(newSubscriberGrowthChartData)
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

      }else{
        if(response.status === gtUtil.Constants.DB_QUERY_NO_RECORD_FOUND){
          set_connected_profile(true);
        }
      }
    } catch (error) {
      console.log(`inflProfileService error :: ${JSON.stringify(error)}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    _InfluencerList();

  }, [props.UserReducer.brand_id, props.UserReducer.user_id]);

  function _InfluencerList() {
    CampaignService.influencerList({
      user_id: props.UserReducer.user_id,
      brand_id: props.UserReducer.brand_id,
    }).then((res: any) => {
      if (res.data.status === 1) {
        setInfluencerList(res.data.rows);
      } else {
        setInfluencerList(null);
      }
    });
  }

  const handleCloseAddToCamp = () => {
    setShowPopAddToCampaign(false);
  }

  const handleAddToListClose = () => {
    setShowAddToListPopup(!showAddToListPopup);
  };

  const handleSendMessage = () => {
    navigate("/brand/direct-msg", {
      state: {
        user: selectInflu && selectInflu.length > 0 ? {
          name: selectInflu[0].name,
          username: selectInflu[0].title,
          influencer_id: selectInflu[0].influencer_id,
          sm_id: selectInflu[0].yt_id,
          platform_type: selectInflu[0].platform_type,
          profile_image: selectInflu[0].gcp_profile_image_url ? selectInflu[0].gcp_profile_image_url : selectInflu[0].profile_image_url,
        } : null,
      },
    });
  };

  useBodyClass("page-profile-insight")
  return (
    <>
      {((props?.UserReducer?.account_type === 'Influencer' || props?.UserReducer?.account_type === 'Talent Partner') && connected_profile) ? (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          Please connect your Youtube account to retrieve the profile.
        </div>
      ) : (
          <div className="container">
            <div className="influencer-profile">
              {/* <div className="common-tab mb-0 d-lg-none common-tab-mobile">
                <span className="d-none common-tab-menu">Instagram</span>
                <span className="active common-tab-menu">YouTube</span>
              </div> */}
              <div className="influencer-profile__left">
                <YTInfluencerProfileLeft loading={loading} ytInfluencerData={ytInfluencerData} email={email} phone={phone} location={location} language={language} category={categoriesData} subscription_status={subscription_status} />

              </div>
              <div className="influencer-profile__right">
                <div className="d-flex flex-wrap w-100 align-items-center mb-3">
                  {/* <div className="common-tab mb-0 d-none d-lg-inline-flex">
                    {/* <span className="active common-tab-menu">Instagram</span> 
                    <span className="active common-tab-menu"><IconYouTube width="22px" />{ytInfluencerData && ytInfluencerData?.detail?.[0]?.custom_url}</span>
                  </div> */}
                  
                  {(props.UserReducer.account_type === 'Brand' || props.UserReducer.account_type === 'Agency') && (
                    <div className='infl-profile-detail-action  ms-auto'>
                      {selectInflu.length > 0 &&
                        <button
                          onClick={handleSendMessage}
                          disabled={props.getSubscriptionDetail && props.getSubscriptionDetail.isBasicUser ? true : false}
                          className="btn btn-text-link me-2"
                        >
                          Send message
                        </button>
                      }
                      <button
                        className="btn btn-text-link me-2"
                        disabled={props.getSubscriptionDetail && props.getSubscriptionDetail.isBasicUser ? true : false}
                        onClick={() => setShowPopAddToCampaign(!showPopAddToCampaign)}
                      >
                        Add to campaign
                      </button>
                      <button
                        className="btn btn-text-link me-2"

                        onClick={() => setShowAddToListPopup(!showAddToListPopup)}
                      >
                        Add to list
                      </button>
                    </div>
                  )}
                </div>
                {/* <Link
                  target="_blank"
                  to={`https://www.youtube.com/channel/${ytInfluencerData && ytInfluencerData?.detail?.[0]?.channel_id}`}
                  className="influencer-profile__social-chan d-none d-lg-flex"
                >
                  <span className="influ-prof-info-social-username d-flex justify-content-center align-items-baseline">

                    {ytInfluencerData && ytInfluencerData?.detail?.[0]?.custom_url}
                  </span>
                </Link> */}
                {/* FOLLOWER SEC */}
                <div className="follower-sec">
                  <FollowerCard loading={loading} title="Subscribers" iconName="person" value={methods.formatInfluencerProfileNumber(ytInfluencerData?.detail?.[0]?.subscriber_count)} />
                  <FollowerCard loading={loading} title="Avg. Views" iconName="visibility" value={methods.formatInfluencerProfileNumber(ytInfluencerData?.detail?.[0]?.avg_views)} />
                  <FollowerCard loading={loading} title="Total Videos" iconName="animated_images" value={`${methods.formatInfluencerProfileNumber(ytInfluencerData?.detail?.[0]?.video_count)}`} />
                </div>
                {(genderSplit.length > 0 || columnChartData.length > 0 ||  minStockChartData[0]?.data.length > 0 || minStockChartData[0]?.data.length > 0) &&
                <>
                <strong className='influencer-profile__common__title audience'>
                  Channel Insights
                </strong>
                <section className="influencer-profile__common w-bdr channel-insights">
                  <Grid container>
                    {(loading || genderSplit.length > 0) &&
                      <Grid item xs={12} sm={12} md={6} className="influencer-profile__common__inner">
                        <div className="influencer-profile__common__box">
                          <div className="inner-heading">
                            Gender
                          </div>
                          <div
                            id="paichart"
                            data-highcharts-chart={1}
                            style={{ overflow: "hidden" }}
                          >
                            {loading ? (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                  height: "400px",
                                  width: "400px",
                                  margin: "0 auto",
                                }}
                              >
                                <Skeleton
                                  variant="circular"
                                  animation="wave"
                                  width={278}
                                  height={278}
                                />
                              </div>
                            ) : (
                            <PieChart dataSource={genderSplit} showInLegend={false} property="percentage" title="" size="100%" height={400} marginTop={20} marginBottom={0} />
                            )
                            }
                          </div>
                          <div className="gender-value-sec gender">
                            <div className='gender-value female'>
                              {femaleGenderSplit === 0 ? (
                                  "NA"
                                ) : loading ? (
                                  <Skeleton width={100} />
                                ) : (
                                  `${femaleGenderSplit}% Female`
                                )
                              }
                              

                            </div>
                            <div className='gender-value male'>
                              {maleGenderSplit === 0 ? (
                                  "NA"
                                ) : loading ? (
                                  <Skeleton width={100} />
                                ) : (
                                  `${maleGenderSplit}% Male`
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </Grid>
                    }
                    {(loading || columnChartData.length > 0) &&
                      <Grid item xs={12} sm={12} md={6} className="influencer-profile__common__inner">
                        <div className="influencer-profile__common__box">
                          <div className="inner-heading"  >
                            Age &amp; Gender
                          </div>
                          <div
                            id="age-gender-chart"
                            className=""
                            data-highcharts-chart={0}
                            style={{ overflow: "hidden" }}
                          >
                            {loading ? (
                                                        <div
                                                          className="d-flex justify-content-center align-items-center"
                                                          style={{
                                                            height: "440px",
                                                            width: "400px",
                                                            margin: "0 auto",
                                                          }}
                                                        >
                                                          <Skeleton
                                                            variant="rectangular"
                                                            animation="wave"
                                                            width={440}
                                                            height={400}
                                                          />
                                                        </div>
                                                      ) :
                            <LineChartDropDown dataSource={columnChartData} categories={genderArrayLabel} exporting={true} legend={false} chartType='column' />
                                                    }
                          </div>
                          <div className="gender-value-sec">
                            <div className='gender-value female'> {loading ? 
                                  <Skeleton width={50} /> : "Female"}
                            </div>
                            <div className='gender-value male'> {loading ? 
                                  <Skeleton width={50} /> : "Male"}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    }
                    {(loading || viewStockChartData.length > 0) &&
                      <Grid item xs={12} sm={12} md={6} className="influencer-profile__common__inner">
                        <div className=" influencer-profile__common__box">
                          <span className="inner-heading">Views</span>
                          {loading ? (
                                                      <div
                                                        className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                          height: "400px",
                                                          width: "440px",
                                                          margin: "0 auto",
                                                        }}
                                                      >
                                                        <Skeleton
                                                          variant="rectangular"
                                                          animation="wave"
                                                          width={440}
                                                          height={340}
                                                        />
                                                      </div>
                                                    ) : (
                          <StockChart loading={loading} dataSource={viewStockChartData} title="" />
                                                    )
                          }
                        </div>
                      </Grid>
                    }

                    {(loading || minStockChartData[0]?.data.length > 0) &&
                      <Grid item xs={12} sm={12} md={6} className="influencer-profile__common__inner">
                        <div className="influencer-profile__common__box">
                          <span className="inner-heading">Estimated Minutes Watched</span>
                          {loading ? (
                                                      <div
                                                        className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                          height: "400px",
                                                          width: "440px",
                                                          margin: "0 auto",
                                                        }}
                                                      >
                                                        <Skeleton
                                                          variant="rectangular"
                                                          animation="wave"
                                                          width={440}
                                                          height={340}
                                                        />
                                                      </div>
                                                    ) : (
                          <StockChart dataSource={minStockChartData} title="" />
                                                    )}
                        </div>
                      </Grid>
                    }
                  </Grid>
                </section>
                </>}
                {(geoViewStockChartData.length > 0 || geoAvgViewPerPieChartData.length > 0 || geoAvgViewPieChartData.length > 0 || geoEstMinChartData.length > 0) && 
                <>
                <strong className='influencer-profile__common__title audience'>
                  Geo Specific Insights
                </strong>
                <section className="influencer-profile__common w-bdr geo-specific">
                  <Grid container>
                    {geoViewStockChartData.length > 0 &&
                      <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                        <div className="influencer-profile__common__box">
                          <span className="inner-heading">Views</span>
                          {loading ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                height: "400px",
                                width: "220px",
                                margin: "0 auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                animation="wave"
                                width={220}
                                height={220}
                              />
                            </div>
                          ) : (
                            <PieChart dataSource={geoViewStockChartData} showInLegend={true} title="" layout="vertical" align={'right'} verticalAlign={'middle'} height={400} size={'100%'}  />
                          )}
                        </div>
                      </Grid>
                    }
                    {geoAvgViewPerPieChartData.length > 0 &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Avg. View Percentage</span>
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "220px",
                              margin: "0 auto",
                            }}
                          >
                            <Skeleton
                              variant="circular"
                              animation="wave"
                              width={220}
                              height={220}
                            />
                          </div>
                        ) : (
                        <PieChart dataSource={geoAvgViewPerPieChartData} showInLegend={true} title="" layout="vertical" align={'right'} verticalAlign={'middle'} height={400} />
                        )}
                      </div> 
                    </Grid>
                    }
                    {geoAvgViewPieChartData.length > 0 &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Avg. View Duration</span>
                        {/* <PieChart dataSource={geoAvgViewPieChartData} showInLegend={true} title="" layout="vertical" align={'right'} verticalAlign={'middle'} /> */}
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "300px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={400}
                              height={300}
                            />
                          </div>
                        ) : (
                        <LineChartDropDown loading={loading} dataSource={geoAvgViewPieChartData} categories={countryNameAvg} dropdown={true} exporting={false} chartType='column' height="340px" yAxisTitle="Minutes" legend={false} />
                        )}
                      </div>
                    </Grid>
                    }
                    {geoEstMinChartData.length > 0 &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Est. Mins Watched</span>
                        {/* <PieChart dataSource={geoEstMinChartData} showInLegend={true} title="" layout="vertical" align={'right'} verticalAlign={'middle'} /> */}
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "300px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={400}
                              height={300}
                            />
                          </div>
                        ) : (
                        <LineChartDropDown dataSource={geoEstMinChartData} categories={countryName} dropdown={true} exporting={false} chartType='column' height="340px" yAxisTitle="Minutes" legend={false} />
                        )}
                      </div>
                    </Grid>
                    }
                    {(loading || viewSubscriberGrowthData.length > 0) &&
                      <Grid item xs={12} sm={12} md={12} className="influencer-profile__common__inner">
                        <div className="influencer-profile__common__box">
                          <span className="inner-heading">Subscriber Growth</span>
                          {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={400}
                              height={400}
                            />
                          </div>
                        ) : (<StockChart dataSource={viewSubscriberGrowthData} title="" />)}
                        </div>
                      </Grid>
                    }
                  </Grid>
                </section>
                </>
                }
                {/* DEVICE INSIGHTS */}
                {(deviceViewStockChartData.length > 0 ||  deviceMinStockChartData.length > 0 || deviceAvgViewPieChartData[0]?.y > 0 || deviceAvgViewPieChartData[1]?.y > 0 ||
                      deviceAvgViewPieChartData[2]?.y > 0 || avgDeviceMinPieChartData[0]?.y > 0 || avgDeviceMinPieChartData[1]?.y > 0 || avgDeviceMinPieChartData[2]?.y > 0) &&
                <>
                <strong className='influencer-profile__common__title audience'>
                  Device Insights
                </strong>
                <section className="influencer-profile__common w-bdr geo-specific">
                  <Grid container>
                    {deviceViewStockChartData.length > 0 &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Views</span>
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={400}
                              height={400}
                            />
                          </div>
                        ) : (
                        <StockChart dataSource={deviceViewStockChartData} title="" />
                        )}
                      </div>
                    </Grid>
                    }
                    {(deviceAvgViewPieChartData[0]?.y > 0 || deviceAvgViewPieChartData[1]?.y > 0 ||
                      deviceAvgViewPieChartData[2]?.y > 0) &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Avg. Views</span>
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={234}
                              height={234}
                            />
                          </div>
                        ) : (
                        <PieChart dataSource={deviceAvgViewPieChartData} showInLegend={true} title="" height={300} />
                        )}
                      </div>
                    </Grid>
                    }
                    {deviceMinStockChartData.length > 0 &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Est.  Minutes Watched</span>
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={400}
                              height={400}
                            />
                          </div>
                        ) : (
                        <StockChart dataSource={deviceMinStockChartData} title="" />
                        )}
                      </div>
                    </Grid>
                    }
                    {(avgDeviceMinPieChartData[0]?.y > 0 || avgDeviceMinPieChartData[1]?.y > 0 || avgDeviceMinPieChartData[2]?.y > 0) &&
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={6}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading">Avg. Est. Minutes Watched</span>
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={234}
                              height={234}
                            />
                          </div>
                        ) : (
                        <PieChart className='highchart-centered' dataSource={avgDeviceMinPieChartData} showInLegend={true} title="" height={300} />
                        )}
                      </div>
                    </Grid>
                    }
                  </Grid>
                </section>
                </>
                }

                {/* Social Shares */}
                {socialSharePieChartData.length > 0 &&
                <>
                <strong className='influencer-profile__common__title '>
                  Social Shares
                </strong>
                <section className="influencer-profile__common w-bdr social-share">
                  <Grid container>
                    <Grid className="influencer-profile__common__inner" item xs={12} sm={12} md={12}>
                      <div className="influencer-profile__common__box">
                        <span className="inner-heading"></span>
                        {loading ? (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "0 auto 20px",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={234}
                              height={234}
                            />
                          </div>
                        ) : (
                        <PieChart dataSource={socialSharePieChartData} showInLegend={true} title="" align={'center'} verticalAlign="bottom" height={500} size={'40%'} marginBottom={20} spacingBottom={-10} itemMarginBottom={10} itemMarginTop={10} distance={30} marginTop={-200} itemWidth={150} responsiveItemWidth={135} responsiveLegendWidth={'100%'} responsiveSize={'100%'} />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </section>
                </>
                }
                {/* MOST VIEWED VIDEOS */}
                {(loading || ytInfluencerData?.most_viewed_videos?.length > 0) &&
                  <section className="sec-media-posts yt-most-viewed-video">
                    <div className="container custom-container">
                      <strong className="sec-media-posts__title">Most Viewed Videos</strong>
                      <YTMostViewedVideos data={ytInfluencerData.most_viewed_videos} loading={loading} />
                      {ytInfluencerData && ytInfluencerData?.most_viewed_videos?.length === 0 && (
                        <div className="col-12 mt-3">
                          Not Available
                        </div>
                      )}
                    </div>
                  </section>
                }
              </div>
            </div>

          </div>
      )}
        {showPopAddToCampaign && (
          <AddToCampaign
            platform="youtube"
            UserReducer={props.UserReducer}
            selectedInfluencer={selectInflu}
            showPopAddToCampaign={showPopAddToCampaign}
            handleCloseAddToCamp={handleCloseAddToCamp}
          />
        )}

        {showAddToListPopup && (
          <div className='popper-popup center-screen'>
            {showAddToListPopup && (
              <AddToList
                platform="youtube"
                UserReducer={props.UserReducer}
                selectedInfluencer={selectInflu}
                showAddToListPopup={showAddToListPopup}
                handleAddToListClose={handleAddToListClose}
              />
            )}
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    getSubscriptionDetail: state.Subscription.subscription_detail
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(YoutubeProfileInsight);

export { connectToRedux as YoutubeProfileInsight };