import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Tooltip
} from "@mui/material";
// For bank details section
import AccountDetails from "./account-details";
// For download invoice buttons
import RenderDownloadInvoiceButtons from "./render-download-invoice-buttons";
// For Acknowledge receipt form influencer side
import RenderAcknoledgeReceiptForm from "./render-acknowledge-receipt-form";
// important common popup component
import CommonPopUp from "../../../../../../components/popup/common-popup";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { FormValidation } from "../../../../../../core/utility";
import FileUploadPopup from "../../../../../../components/popup/file-upload-popup";
import { InvoiceService, LocalService } from "../../../../../../core/services";
import { LoadingButton } from "@mui/lab";
import * as gtUtil from "../../../../../../core/utility";

const formValidation = new FormValidation();
const invoiceService = new InvoiceService();
const localService = new LocalService();

interface ProposalPaymentTableProps {
  UserReducer: any;
  acceptedProposalPaymentDetails: Proposal[];
  selectedCampaign: any;
  setUpdateAcceptedProposalPaymentDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Invoice {
  file_url: string;
  file_name: string;
  total_amount: number;// invoice total amount.
}

interface Proposal {
  total_price: number;
  name: string;
  influencer_id: number;
  proposal_id: number;
  payment_status: string;
  account_type?: string;
  bank_name?: string;
  ifsc_code?: string;
  account_holder_name?: string;
  account_number?: string;
  address?: string;
  micr_code?: string;
  // file_urls: string;
  invoices: Invoice[];
}

interface FormData {
  user_id: string;
  account_id: string;
  influencer_id: string;
  campaign_id: string;
  proposal_id: string;
  amount: number;
  transaction_id: string;
  comments: string;
  file_type: string;
  file_name: string;
  storage_size: number;
  file_path: string;
  file_url: string;
  submitted: string;
  invoice_id: any;
}

interface FormError {
  amount: { status: boolean; message: string; touched: boolean };
  transaction_id: { status: boolean; message: string; touched: boolean };
  comments: { status: boolean; message: string; touched: boolean };
  receipt: { status: boolean; message: string; touched: boolean };
}

const ProposalPaymentTable: React.FC<ProposalPaymentTableProps> = ({ UserReducer, acceptedProposalPaymentDetails: initialAcceptedProposalPaymentDetails, selectedCampaign, setUpdateAcceptedProposalPaymentDetails }) => {

  const path = `influencers/${UserReducer.influencer_id}/proposal_receipts`;

  const [acceptedProposalPaymentDetails, setAcceptedProposalPaymentDetails] = useState(initialAcceptedProposalPaymentDetails);

  const [invoiceId, setInvoiceId] = useState()

  useEffect(() => {
    setAcceptedProposalPaymentDetails(initialAcceptedProposalPaymentDetails);
  }, [initialAcceptedProposalPaymentDetails]);

  const handleUpdatePaymentStatus = (updatedProposal: Proposal) => {
    setAcceptedProposalPaymentDetails((prevDetails) =>
      prevDetails.map((proposal) =>
        proposal.proposal_id === updatedProposal.proposal_id ? updatedProposal : proposal
      )
    );
  };

  // Modal form data
  const [formData, setFormData] = useState<FormData>({
    user_id: UserReducer.user_id,
    account_id: UserReducer.account_id,
    influencer_id: UserReducer.influencer_id,
    campaign_id: selectedCampaign.campaign_id,
    proposal_id: '',
    amount: 0,
    transaction_id: '',
    comments: '',
    file_type: '',
    file_name: '',
    storage_size: 0,
    file_path: '',
    file_url: '',
    submitted: 'false',
    invoice_id: "",
  });

  // Set form error fields
  const [formError, setFormErrors] = useState<FormError>({
    amount: { status: false, message: '', touched: false },
    transaction_id: { status: false, message: '', touched: false },
    comments: { status: false, message: '', touched: false },
    receipt: { status: false, message: '', touched: false }
  });

  // set Modal variables and usestate
  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openReceiptModal: false,
    openUploadReceiptFile: false
  });
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('Payment Details');
  const handleOpenReceiptModal = () => setPopUps({ ...popUps, openReceiptModal: true });

  function handleCloseReceiptModal() {
    setPopUps({ ...popUps, openReceiptModal: false });
    setFormData({
      ...formData,
      proposal_id: '',
      amount: 0,
      transaction_id: '',
      comments: '',
      file_type: '',
      file_name: '',
      storage_size: 0,
      file_path: '',
      file_url: '',
      submitted: 'false',
      invoice_id:"",
    });
    setFormErrors({
      amount: { status: false, message: '', touched: false },
      transaction_id: { status: false, message: '', touched: false },
      comments: { status: false, message: '', touched: false },
      receipt: { status: false, message: '', touched: false }
    });
  }

  function handleCloseReceiptFileModal() {
    setPopUps({ ...popUps, openUploadReceiptFile: false });
  }

  const handleUploadReceipt = (event: any) => {
    setModalTitle('Payment Details');
    const dataset = event.target.dataset;
    setFormData({
      ...formData,
      invoice_id: dataset.invoice_id,
      proposal_id: dataset.proposal_id,
    })
    // Open the modal
    handleOpenReceiptModal();
  };

  const validateForm = (isSubmitting = false): boolean => {
    const errors: FormError = { ...formError };
    let isValid = true;

    if (isSubmitting || formError.amount.touched) {
      if (formData.amount <= 0) {
        errors.amount = { status: true, message: 'Amount should be greater than 0', touched: true };
        isValid = false;
      } else {
        errors.amount = { status: false, message: '', touched: true };
      }
    }

    if (isSubmitting || formError.transaction_id.touched) {
      if (!formData.transaction_id) {
        errors.transaction_id = { status: true, message: 'Transaction ID is required', touched: true };
        isValid = false;
      } else {
        errors.transaction_id = { status: false, message: '', touched: true };
      }
    }

    if (isSubmitting || formError.comments.touched) {
      if (!formData.comments) {
        errors.comments = { status: true, message: 'Comments are required', touched: true };
        isValid = false;
      } else {
        errors.comments = { status: false, message: '', touched: true };
      }
    }

    if (isSubmitting || formError.receipt.touched) {
      if (!formData.file_url) {
        errors.receipt = { status: true, message: 'Payment Detail is required', touched: true };
        isValid = false;
      } else {
        errors.receipt = { status: false, message: '', touched: true };
      }
    }

    setFormErrors(errors);
    return isValid;
  };
  const [loading, setLoading] = useState(false);

  const handleConfirmation = async (e: any) => {
    try {
      e.preventDefault();
      // validate form
      if (!validateForm(true)) {
        return;
      }
      setLoading(true); 
      const updatedFormData = {
        ...formData,
        submitted: 'true'
      };
      setInvoiceId(formData.invoice_id)
      setFormData(updatedFormData);
      const response: any = await invoiceService.uploadProposalReceipt(updatedFormData);
      // to update the acceptedProposalPaymentDetails
      setUpdateAcceptedProposalPaymentDetails(true);
      const checkStatus = response.status === gtUtil.Constants.SUCCESS ? 'success' : 'error';
      localService.toastify(response.info, checkStatus, 1000);
      // Close the modal after handling form submission
      handleCloseReceiptModal();
    } catch (error) {
      console.log('Upload Payment Details Error :: ', JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const insertCampaignInvoiceDetail = async (e: any) => {
    try {
      const updatedFormData = {
        ...formData,
        file_url: e.url,
        file_path: e.key,
        file_type: e.fileType,
        file_name: e.fileName,
        storage_size: e.storage_size
      };
      setFormData(updatedFormData);

      // const response: any = await invoiceService.uploadProposalReceipt(updatedFormData);
      // const checkStatus = response.status === gtUtil.Constants.SUCCESS ? 'success' : 'error';
      if (![undefined, null, ''].includes(e.url)) {
        setFormErrors({ ...formError, receipt: { status: false, message: '',  touched: false  }})
      }
      // localService.toastify(response.info, checkStatus, 1000);
      // handleCloseReceiptFileModal();
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      // setLoading(false);
      // getAcceptedProposalPaymentDetails();
      handleCloseReceiptFileModal();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = (event:any) => {
    if (
      event.key === "ArrowUp" ||
      event.key === "ArrowDown" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      event.preventDefault();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    const validValue = sanitizedValue === "" ? 0 : parseInt(sanitizedValue, 10);

    setFormData({ ...formData, amount: validValue });

    // Always keep the cursor at the end
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }
    }, 0);
  };

  const handleCursorControl = () => {
    // Ensure cursor always stays at the end
    if (inputRef.current) {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  };

  const [expandedProposalId, setExpandedProposalId] = useState<number | null>(null);

  const handleInvoiceExpand = (id: number) =>{
    if(id)
      setExpandedProposalId((prevId) => (prevId === id ? null : id));
    // setExpandInvoiceTable(true)
  }


  return (
    <div className="snapshot-wrapper snapshot-info d-flex flex-column infl-payout-detail">
      <strong className="snapshot-card-title pb-1">{UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id && 'Influencer'} Payout Details</strong>
      <div id="estimated-payment" className="">
        <div className="table-responsive">
          <TableContainer style={{minHeight:'300px'}}>
            <Table className="table table-bottom-influencer common-table">
              <TableHead className="common-table-header">
                <TableRow>
                  <TableCell>#</TableCell>
                  {UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id && <TableCell style={{ minWidth: "150px" }}>Influencer Name</TableCell>}
                  {/* <TableCell style={{ minWidth: "90px" }}>Total Amount</TableCell> */}
                  <TableCell style={{ minWidth: "120px" }}>Due Amount (Excl Taxes)</TableCell>
                  <TableCell style={{ minWidth: "60px" }}>Payment Status</TableCell>
                  <TableCell style={{ minWidth: "200px" }}>Account Detail</TableCell>
                  <TableCell style={{ minWidth: "130px" }}><span >Invoices</span></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="common-table-body">
                {(acceptedProposalPaymentDetails?.length === 0) 
                ? 
                (
                  <TableRow>
                    <TableCell colSpan={8} className="text-center">No transaction details to view.</TableCell>
                  </TableRow>
                ) 
                : 
                (
                  acceptedProposalPaymentDetails.map((proposal, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        {UserReducer?.influencer_id !== acceptedProposalPaymentDetails[0]?.influencer_id && <TableCell>{proposal.name}</TableCell>}
                        {/* <TableCell>{proposal.total_price}</TableCell> */}
                        <TableCell>{proposal.total_price}</TableCell>
                        <TableCell>{proposal.payment_status}</TableCell>
                        <TableCell>
                          {proposal.account_type
                            ?
                            <AccountDetails account={proposal} />
                            :
                            'NA'
                          }
                        </TableCell>
                        <TableCell>
                          <span className={`invoice-expandable ${expandedProposalId === proposal.proposal_id ? "active" : ""}`} onClick={()=>{handleInvoiceExpand(proposal?.proposal_id)}}>View Invoice</span>
                        </TableCell>
                      </TableRow>
                  
                      {expandedProposalId === proposal.proposal_id &&
                        <TableCell  colSpan={7} style={{ padding: 0 }}>
                          <div className="expandable active"  id={String(proposal?.proposal_id)}>
                            <RenderDownloadInvoiceButtons proposal={proposal} UserReducer={UserReducer} acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={selectedCampaign} handleUploadReceipt={handleUploadReceipt} setUpdateAcceptedProposalPaymentDetails={setUpdateAcceptedProposalPaymentDetails} />
                          </div>
                        </TableCell>
                      }
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {popUps.openReceiptModal && (
        <CommonPopUp
          modalTitle={modalTitle}
          modalContent={modalContent}
          openModal={popUps.openReceiptModal}
          onClose={() => handleCloseReceiptModal()}
          className="modal-small payment-advice-modal"

        >
          <form onSubmit={handleConfirmation}>
            <TextField
              label="Amount (Excl. Taxes)"
              size="small"
              onClick={handleKeyDown} // Restrict cursor on mouse click
              onKeyUp={handleCursorControl}
              onKeyDown={handleKeyDown}
              value={formData.amount}
              error={formError.amount.touched && formError.amount.status}
              helperText={formError.amount.touched && formError.amount.message}
              className="w-100 mb-3"
              variant="outlined"
              onBlur={() =>
                setFormErrors((prev) => ({ ...prev, amount: { ...prev.amount, touched: true } }))
              }
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                let value = input.value;

                // Allow only positive numbers and no leading zeros
                const sanitizedValue = value.replace(/^0+/, "").replace(/[^0-9]/g, "");

                if (sanitizedValue !== value) {
                  // Adjust caret position
                  const cursorPosition = input.selectionStart || sanitizedValue.length;
                  input.value = sanitizedValue;
                  input.setSelectionRange(cursorPosition, cursorPosition);
                }

                setFormData({ ...formData, amount: Number(sanitizedValue) || 0 });
              }}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon className="currencyIcon" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Transaction ID"
              value={formData.transaction_id}
              size="small"
              onChange={(e) => setFormData({ ...formData, transaction_id: e.target.value })}
              // error={ formError.transaction_id.status && !formError.transaction_id.status}
              
              // helperText={formError.transaction_id.message}
              error={formError.transaction_id.touched && formError.transaction_id.status}
              helperText={formError.transaction_id.touched && formError.transaction_id.message}
              className='w-100'
              id='outlined-basic'
              variant='outlined'
              onBlur={() => setFormErrors((prev) => ({ ...prev, transaction_id: { ...prev.transaction_id, touched: true } }))}
            />
            <TextField
              label="Comments"
              rows={3}
              multiline
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              error={formError.comments.touched && formError.comments.status}
              helperText={formError.comments.touched && formError.comments.message}
              className='w-100 my-3'
              id='outlined-basic'
              variant='outlined'
              // onBlur={validateForm}
            />
            {
              formData.file_name !== '' &&
              <p>
                <span className="me-2">{formData.file_name}</span>
                <Tooltip title={formData.file_name} arrow>
                  <NavLink target="_blank" key={`proposal_receipt`} to={formData.file_url} data-tip={formData.file_name}>
                    <i className="bi bi-file-earmark-text-fill"></i>
                  </NavLink>
                </Tooltip>
              </p>
            }

            <div className="row my-1" style={{ padding: '0px 11px' }}>
              {formData.file_name === '' &&
                <button type='button' className='btn btn-outline-primary px-2' onClick={() => setPopUps({ ...popUps, openUploadReceiptFile: true })}>Upload Payment Details</button>
              }
              {
                formError.receipt &&
                <p className="small fs-12 error">{formError.receipt.message}</p>
              }
              <div className='popup-footer mt-0 ms-0 px-0'>
                <div className='row'>
                  <div className='col text-end'>
                    <button disabled={loading} type="submit" className="btn btn-primary btn-mui">Send</button>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </CommonPopUp>
      )}

      {popUps.openUploadReceiptFile && (
        <FileUploadPopup
          openModal={popUps.openUploadReceiptFile}
          onClose={(e) => handleCloseReceiptFileModal()}
          onSend={(e: any) =>
            insertCampaignInvoiceDetail(e)
          }
          path={path}
          acceptedFileTypes=".doc, .docx, .pdf"
        />
      )}
    </div>
  );
};

export default ProposalPaymentTable;